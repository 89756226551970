<template>
  <div class="its_menu_content_item">
    <br><div class="seventeen__block3-content">
<h2>6. Разработка проекта ИТС НДТ</h2>

<p>Основные задачи <a href="http://burondt.ru/trg/2022">ТРГ</a> заключаются в анализе/рассмотрении/согласовании:</p>
<ul>
<li>проектов унифицированных отраслевых шаблонов,</li>
<li>полученных от Бюро НДТ данных, необходимых для определения технологических процессов, оборудования, технических способов и методов в качестве НДТ,</li>
<li>проекта справочника, представленного разработчиком,</li>
<li>дополнительных документов.</li>
</ul>
<p>Информационно-технические справочники делятся на межотраслевые (горизонтальные) и отраслевые (вертикальные). Отличаются они друг от друга наличием в последних маркерных веществ и технологических показателей.</p>
<p>Структура справочников утверждена национальным стандартом ГОСТ Р 113.00.03-2019.</p>
<br><br><center><img src="/img/itc/its-project-dev-img.jpg" alt="" width="1115" height="535" /></center>
<br><p>Проект справочника, в утвержденные рабочей группой сроки, разрабатывается по разделам, каждый из которых проходит этапы рассмотрения, доработки и голосования в&nbsp;рабочем кабинете ТРГ на информационном портале Бюро НДТ. Все замечания и предложения по итогам рассмотрения документов в личном кабинете ТРГ оформляются в сводку отзывов.</p>
<p>Разработка/актуализация проекта ИТС НДТ начинается параллельно с процессом&nbsp;<a href="https://burondt.ru/itc-development3/data-collect">сбора данных</a>. Первоочередной и ключевой задачей стоит определение области применения справочника, которая, также как и любой его раздел, утверждается голосованием в ТРГ. Параллельно с определением области применения разрабатываются разделы &laquo;введение&raquo; и &laquo;предисловие&raquo;. Далее, по завершению процесса сбора данных, готовятся остальные разделы ИТС НДТ в соответствии со &nbsp;структурой, приведенной в ГОСТ Р 113.00.03-2019 &laquo;НДТ. Структура информационно-технического справочника&raquo;.</p>
<p>Кроме самих разделов ИТС НДТ в технических рабочих группах разрабатывают и утверждают следующие приложения и документы: перечень маркерных веществ, перечень технологических показателей, перечень наилучших доступных технологий, сведения о ресурсной эффективности, заключение по наилучшим доступным технологиям.</p>
<br><br><center><img src="/img/itc/its-project-dev-img2.jpg" alt="" width="1115" height="204" /></center>
</div>
    
  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}
</style>
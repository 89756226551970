<template>
  <section v-if="loader" class="no_info">
    <loader
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
  </section>
    <ModalWindow :backPath="'/news'" v-else>
      <template v-slot:title>
        <h3 class="modal-title">{{ oneNewsData.pagetitle }}</h3>
      </template>
      <template v-slot:body>
        <div
          class="modal-textarea"
          placeholder="Добавьте отзыв"
          v-html="oneNewsData.content"
        ></div>
      </template>
    </ModalWindow>
</template>

<script>
import useAllNews from "@/composables/news/news.js";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
// import BackImagesComp from "../BackImagesComp.vue";
import ModalWindow from "../ModalWindow.vue";

export default {
  name: "News",
  components: {
    ModalWindow,
  },
  setup() {
    const route = useRoute();
    const newsId = ref(route.params.id);
    // const modalShow = route.meta.modalShow;
    const {
      loader,
      fetchOneNews,
      oneNewsData,
    } = useAllNews();
    onMounted(() => {
      fetchOneNews(newsId.value);
    });
    return {
      loader,
      oneNewsData,
    };
  },
};
</script>

<style scoped>

.twentyseven {
  padding: 0 7vw;
}

.twentyseven__wrapper {
  margin: 3vh 3vh 0 3vh;
  height: auto;
}
.twentyseven__title-main {
  color: #0073af;
  font-size: calc(100vw / 35);
}

.twentynine {
  padding: 0 7vw;
}
.twentynine__wrapper {
  margin: 3vh;
  margin-bottom: 10vw;
  height: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 0.01vw 2vw;
  background: white;
}
/*.twentynine__title {
  font-size: calc(100vw/55);
  color: black;
  padding: 2vh 0;
  border-bottom: 1px solid black;
}*/
.twentynine__items {
  display: flex;
  flex-direction: column;
}
.twentynine__item {
  border-bottom: 0.3vh solid #bdbbba;
  padding-bottom: 2vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1vw;
}

.twentynine__item:last-child {
  margin-bottom: 10vh;
  border: none;
}
.twentynine__item-wrapp {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 3vh 0;
}
.twentynine__block1 {
  width: 17%;
}
.twentynine__bg-img {
  /* width: 100%;
  height: 100%; */
  background: url("/img/20.png") no-repeat;
  background-size: 100%;
}
.twentynine__block2 {
  width: 90%;
}
.twentynine__title {
  margin: 0;
  width: 100%;
}
.twentynine__subtitle {
  padding: 0.5vw 0;
  display: inline-block;
  color: #396371;
}
.twentynine__text {
  margin: 0;
}

.twentynine__block-btn {
  display: flex;
  justify-content: flex-end;
}
.twentynine__btn-link {
  background: #005f84;
  color: white;
  padding: 5px;
  text-decoration: none;
}

@media (max-width: 1440px) {
  /*.twentyseven,.twentynine {
    padding: 0 7vw;
  }*/
  .twentyseven__item {
    font-size: 1.4vw;
  }
  .twentyseven__text {
    font-size: 1.1vw;
    line-height: 2.5vw;
  }
  .twentynine__block2 {
    /* font-size: 1.2vw;*/
  }
  .twentynine__item-wrapp {
    /*font-size: 1.2vw;*/
  }
  .twentynine__item {
    border-bottom: 0.1vh solid #bdbbba;
  }
  .twentynine__block1-h3 {
    font-weight: 500;
  }
  .twentynine__link-wrapp {
    margin: 0.7vw 0;
  }
}
@media (max-width: 480px) {
  .twentyseven {
    padding: 0 2vh;
  }
  .twentyseven__wrapper {
    margin: 2vh;
  }
  .twentyseven__text {
    font-size: calc(100vw / 40);
  }

  .twentyseven__title-main {
    font-size: 29px;
    max-width: 300px;
  }

  .twentyseven__items {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 3vh;
  }
  .twentyseven__item {
    font-size: 16px;
    max-width: 250px;
    width: 100%;
  }
  /*---------------------------twentynine----------------------------------*/

  .twentynine {
    padding: 0 6vw;
  }
  .twentynine__wrapper {
    padding: 20px;
    margin: 0 auto 7vh auto;
  }
  .twentynine__subtitle {
    padding: 2.5vw 0;
  }
  .twentynine__item {
    font-size: 3vw;
  }
  .twentynine__item-wrapp {
    width: 100%;
    height: auto;
    margin: 1vh 0;
    flex-direction: column;
  }
  .twentynine__block1 {
    /* width: 30%;
    height: 20vw; */
  }
  .twentynine__block2 {
    width: 100%;
  }
  .twentynine__title {
    width: auto;
  }
}
@media (max-width: 375px) {
  .twentyseven__item {
    font-size: 14px;
  }
}
</style>
<template>
  <div class="twentythree__item-wrapp">
    <slot name="title"></slot>
    <img
      @click="toggleAccordion()"
      :aria-expanded="isOpen"
      :src="getPicUrl()"
      alt=""
      class="twentythree__block2-img"
    />
  </div>

  <div v-show="isOpen" class="twentythree__prolapse">
    <slot name="content" />
  </div>
</template>

<script>
export default {
  name: "TK113Accordeon",
  data() {
    return {
      isOpen: false,
      picPath: require("../../../public/img/47.png"),
    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.picPath = require("../../../public/img/48.png");
      }
      if (!this.isOpen) {
        this.picPath = require("../../../public/img/47.png");
      }
      this.getPicUrl();
    },
    getPicUrl() {
      return this.picPath;
    },
  },
};
</script>

<style scoped>
.twentythree__item-wrapp {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.twentythree__block2-img,
.twentythree__block2-img2 {
  height: calc(100vw / 60);
  cursor: pointer;
}
.twentythree__prolapse {
  line-height: 20px;
}
@media (max-width: 480px) {
  .twentythree__block2-img,
  .twentythree__block2-img2 {
    height: calc(100vw / 20);
    cursor: pointer;
  }
}
</style>
export default function useScrollToEl(){

    function scrollToEl(){
        document.querySelector(".its_menu_content_item").scrollIntoView({block: "start", behavior: "smooth"})
    }

    return {
        scrollToEl
    }
    
}
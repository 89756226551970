<template>
  <section class="fourteen">
    <div class="fourteen__wrapper">
      <div class="fourteen__block-top">
        <h1 class="fourteen__title" v-html="title"></h1>
        <router-link v-if="$route.params.year >= firstRealYear" :to="typePage + firstArchieveYear">
          <span class="fourteen__link-text"  @click="reload">> Архив</span>
        </router-link>
        <router-link v-else :to="typePage + new Date().getFullYear()">
          <span class="fourteen__link-text" @click="reload">> Назад</span>
        </router-link>
      </div>
      <div class="fourteen__track">
        <div class="fourteen__years">
          <!-- <div v-for="year in years" :key="year['years_id']"> -->
          <router-link
            v-for="year in years"
            :key="year['years_id']"
            class="fourteen__year"
            :to="typePage+`${year['years_text']}`"
            v-slot="{ isActive }"
          >
            <img src="/img/5.png" alt="" class="fourteen__year-img" />
            <span :class="{ active: isActive }">{{ year["years_text"] }}</span>
          </router-link>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import useYears from "@/composables/yearsmenu.js";
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
export default {
    name: "YearsMenu",
    props: ["page", "title"],
  setup(props) {
    const firstRealYear = 2019
    const firstArchieveYear = 2015
    const typePage = ref(props.page)
    const pageTitle = ref(props.title)
    const route = useRoute();
    const { years, fetchYears } = useYears();
    onMounted(() => {
      fetchYears(route.params.year);
    });
    watch(
      () => route.params.year,
      (newYear, oldYear) => {
        if((oldYear < firstRealYear && newYear >= firstRealYear) || (oldYear >= firstRealYear && newYear < firstRealYear)){
          fetchYears(newYear)
        }
        
      }
    )
    return { years, typePage, firstRealYear, firstArchieveYear, pageTitle };
  },
};
</script>

<style scoped>
.fourteen__year {
  text-decoration: none;
}

span.active {
  transform: scale(1.3);
  color: black;
  font-weight: 600;
}
a {
  color: #c5c5c5;
}
.fourteen {
  padding: 0 4.5vw;
}

.fourteen__wrapper {
  height: auto;
  margin: 6vh 2vh;
}

.fourteen__block-top {
  height: calc(100vw / 12);
  display: flex;
  flex-direction: column;
}
.fourteen__title {
  font-size: calc(100vw / 35);
  color: #0071af;
  margin: 0;
}
.fourteen__link-text {
  color: #6e6b6b;
  font-size: calc(100vw / 95);
  padding-top: 1vh;
}

.fourteen__track {
  width: 60%;
  max-width: 2470px;
  border-top: 0.3vw solid #fca14e;
  margin-top: 10vh;
}

.fourteen__years {
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 0.8vw;
}
.fourteen__year-img {
  width: 1.5vw;
}
.fourteen__year {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  max-width: 30px;
}
.fourteen__year span {
  font-size: calc(100vw / 80);
  color: #7b7979;
  padding-top: 0.5vw;
}
.fourteen__year span:hover {
  color: black;
  cursor: pointer;
  font-weight: 600;
}
.fourteen__year:last-child {
  position: relative;
  left: 0.7vw;
}
.fourteen__year:first-child {
  position: relative;
  right: 0.7vw;
}
@media (max-width: 480px) {
  .fourteen {
    padding: 0 3vw;
  }
  .fourteen__title {
    font-size: 29px;
  }
  .fourteen__link-text {
    color: #c5c5c5;
    font-size: 14px;
    padding-top: 10px;
  }
  .fourteen__wrapper {
    padding: 0;
  }
  .fourteen__title-main {
    font-size: 29px;
    max-width: 300px;
  }

  .fourteen__items {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .fourteen__item {
    padding: 7px 20px;
    margin: 10px 0;
    font-size: 18px;
  }
  .fourteen__year:first-child {
    right: 0;
  }
  .fourteen__year:last-child {
    left: 0;
  }
  .fourteen__track {
    border-left: 2px solid #fca14e;
    border-top: none;
    height: 580px;
    width: 20%;
    position: absolute;
    margin-top: 6vw;
  }
  .fourteen__years {
    flex-direction: column;
    align-items: center;
    width: 37px;
    height: 600px;
    bottom: 15px;
  }
  .fourteen__year {
    flex-direction: row;
    width: 17vw;
    max-width: 60px;
    justify-content: space-between;
  }
  .fourteen__year-img {
    width: 20px;
  }
  .fourteen__year span {
    font-size: 3.5vw;
  }
  .fourteen__block-top {
    height: 77px;
    margin: 20px 0 70px 0;
  }
  .fourteen__block-top a {
    padding-top: 10px;
  }
}
@media (max-width: 375px) {
  .fourteen__item {
    font-size: 16px;
  }
}
</style>
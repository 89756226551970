import { ref } from "vue";
import { environment } from '@/composables/realhost.js';

export default function useDocumentsById() {
    const docsByIdData = ref([]);
    const loader = ref(true);
    const isVisible1 = ref(true);
    const count = ref(0);
    const loaderPagination = ref(true);

    function makeUrl(a, b){
        // return `http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=${a}&etkstructure_id=${b}`
        return `${environment.urlMakerHost}NDT/NDTDocsDetail.php?UrlId=${a}&etkstructure_id=${b}`
    }

    function getCount(id){
        fetch(environment.realHost + "documents/count/" + id)
        .then(response => response.json())
        .then(data => {
            return (data)
        })
    }

    function fetchDocsById(id) {
        fetch(environment.realHost + "documents/count/" + id)
        .then(response => response.json())
        .then(data => {
            // count.value = Math.ceil(data.count / 10)
            if(data > 10){
                fetchDocsByIdLimit(id)
            } else {
                fetchAllDocsById(id) 
            }
        })
        // let year = (new Date()).getFullYear()
        // fetch("http://slimtest/documents/" + id)
        
    }
    function fetchAllDocsById(id){
        loaderPagination.value = false
        loader.value = true
        fetch(environment.realHost + "documents/" + id)
            .then(response => response.json())
            .then(data => {
                // console.log(data.count)
                // if(data.docs.length){
                //     console.log("массив")
                // }
                for(let key in data.docs){
                    let pr = data.docs[key]
                    for(let i = 0; i < pr.length; i++){
                        pr[i].url = `http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=${pr[i].etkdocs_id}&etkstructure_id=${pr[i].etkstructure_id}`
                    }
                }
                count.value = Math.ceil(data.count / 10)
                docsByIdData.value = data
                loader.value = false
                isVisible1.value = true

            })
    }
    function fetchDocsByIdLimit(id, without = 0, quality = 10){
        loaderPagination.value = true
        fetch(environment.realHost + "documents/" + id + "/" + without + "/" + quality)
            .then(response => response.json())
            .then(data => {
                // console.log(data.count)
                // if(data.docs.length){
                //     console.log("массив")
                // }
                for(let key in data.docs){
                    let pr = data.docs[key]
                    for(let i = 0; i < pr.length; i++){
                        pr[i].url = `http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=${pr[i].etkdocs_id}&etkstructure_id=${pr[i].etkstructure_id}`
                    }
                }
                count.value = Math.ceil(data.count / 10)
                docsByIdData.value = data
                loader.value = false
                loaderPagination.value = false
                isVisible1.value = true

            })
    }
    return { fetchDocsById, docsByIdData, loader, isVisible1, makeUrl, count, getCount, fetchDocsByIdLimit, loaderPagination }
}
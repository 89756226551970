<template>
    <div class="actualisation-container">
        <p class="page-title">Всего ИТС НДТ</p>
        <div class="block-number">
            <p class="block-number-point">14</p>
        </div>
        <div class="main-block">
            <div class="main-block-actual">
                <div class="context" @click="showActual" :class="{ active: actual }">
                    <div class="context-title">Актуализация</div>
                    <div class="context-point">11</div>
                </div>
            </div>
            <div class="main-block-actual">
                <div class="context" @click="showIndicative" :class="{ active: indicativ }">
                    <div class="context-title">Индикативные<br>показатели</div>
                    <div class="context-point">3</div>
                </div>
            </div>
        </div>
        <hr class="hrr">
        <div class="sub-main-block">
            <div class="actualisations" v-if="actual">

                <div class="actualisation-item">
                    <div class="actualisation-title">
                        <a href="http://burondt.ru/trg/2023/1942/info" target="_blank">ТРГ 4<br>«Производство керамических изделий»</a>
                    </div>
                    <div class="actualisation-content">
                        <p>Формирование ТРГ: <span>до 14 января</span></p>
                        <p>Полноправные члены: <span>44</span></p>
                        <p>Наблюдатели: <span>34</span></p>
                        <p>Росприроднадзор: <span>1</span></p>
                    </div>
                </div>

                <div class="actualisation-item">
                    <div class="actualisation-title">
                        <a href="http://burondt.ru/trg/2023#" target="_blank">ТРГ 16<br>«Горнодобывающая промышленность. Общие процессы и методы»</a>
                    </div>
                    <div class="actualisation-content">
                        <p>Формирование ТРГ: <span>до 14 января</span></p>
                        <p>Полноправные члены: <span>44</span></p>
                        <p>Наблюдатели: <span>34</span></p>
                        <p>Росприроднадзор: <span>1</span></p>
                    </div>
                </div>

                <div class="actualisation-item">
                    <div class="actualisation-title">
                        <a href="http://burondt.ru/trg/2023/1936/info" target="_blank">ТРГ 37<br>«Добыча и обогащение угля»</a>
                    </div>
                    <div class="actualisation-content">
                        <p>Формирование ТРГ: <span>до 14 января</span></p>
                        <p>Полноправные члены: <span>44</span></p>
                        <p>Наблюдатели: <span>34</span></p>
                        <p>Росприроднадзор: <span>1</span></p>
                    </div>
                </div>

                <div class="actualisation-item">
                    <div class="actualisation-title">
                        <a href="http://burondt.ru/trg/2023/1943/info" target="_blank">ТРГ 39<br>«Производство текстильных изделий»</a>
                    </div>
                    <div class="actualisation-content">
                        <p>Формирование ТРГ: <span>до 14 января</span></p>
                        <p>Полноправные члены: <span>44</span></p>
                        <p>Наблюдатели: <span>34</span></p>
                        <p>Росприроднадзор: <span>1</span></p>
                    </div>
                </div>

                <div class="actualisation-item">
                    <div class="actualisation-title">
                        <a href="http://burondt.ru/trg/2023#" target="_blank">ТРГ 41<br>«Интенсивное разведение свиней»</a>
                    </div>
                    <div class="actualisation-content">
                        <p>Формирование ТРГ: <span>до 14 января</span></p>
                        <p>Полноправные члены: <span>44</span></p>
                        <p>Наблюдатели: <span>34</span></p>
                        <p>Росприроднадзор: <span>1</span></p>
                    </div>
                </div>

                <div class="actualisation-item">
                    <div class="actualisation-title">
                        <a href="http://burondt.ru/trg/2023/1938/info" target="_blank">ТРГ 42<br>«Интенсивное разведение сельскохозяйственной птицы»</a>
                    </div>
                    <div class="actualisation-content">
                        <p>Формирование ТРГ: <span>до 14 января</span></p>
                        <p>Полноправные члены: <span>44</span></p>
                        <p>Наблюдатели: <span>34</span></p>
                        <p>Росприроднадзор: <span>1</span></p>
                    </div>
                </div>

                <div class="actualisation-item">
                    <div class="actualisation-title">
                        <a href="http://burondt.ru/trg/2023#" target="_blank">ТРГ 43<br>«Убой животных на мясокомбинатах, мясохладобойнях, побочные продукты животноводств»</a>
                    </div>
                    <div class="actualisation-content">
                        <p>Формирование ТРГ: <span>до 14 января</span></p>
                        <p>Полноправные члены: <span>44</span></p>
                        <p>Наблюдатели: <span>34</span></p>
                        <p>Росприроднадзор: <span>1</span></p>
                    </div>
                </div>

                <div class="actualisation-item">
                    <div class="actualisation-title">
                        <a href="http://burondt.ru/trg/2023/1940/info" target="_blank">ТРГ 44<br>«Производство продуктов питания»</a>
                    </div>
                    <div class="actualisation-content">
                        <p>Формирование ТРГ: <span>до 14 января</span></p>
                        <p>Полноправные члены: <span>44</span></p>
                        <p>Наблюдатели: <span>34</span></p>
                        <p>Росприроднадзор: <span>1</span></p>
                    </div>
                </div>

                <div class="actualisation-item">
                    <div class="actualisation-title">
                        <a href="http://burondt.ru/trg/2023/1941/info" target="_blank">ТРГ 45<br>«Производство напитков, молока и молочной продукции»</a>
                    </div>
                    <div class="actualisation-content">
                        <p>Формирование ТРГ: <span>до 14 января</span></p>
                        <p>Полноправные члены: <span>44</span></p>
                        <p>Наблюдатели: <span>34</span></p>
                        <p>Росприроднадзор: <span>1</span></p>
                    </div>
                </div>

                <div class="actualisation-item">
                    <div class="actualisation-title">
                        <a href="http://burondt.ru/trg/2023/1944/info" target="_blank">ТРГ 47<br>«Системы обработки (обращения) со сточными водами и отходящими газами в химической промышленности»</a>
                    </div>
                    <div class="actualisation-content">
                        <p>Формирование ТРГ: <span>до 14 января</span></p>
                        <p>Полноправные члены: <span>44</span></p>
                        <p>Наблюдатели: <span>34</span></p>
                        <p>Росприроднадзор: <span>1</span></p>
                    </div>
                </div>

                <div class="actualisation-item">
                    <div class="actualisation-title">
                        <a href="http://burondt.ru/trg/2023/1927/info" target="_blank">ТРГ 48<br>«Повышение энергетической эффективности»</a>
                    </div>
                    <div class="actualisation-content">
                        <p>Формирование ТРГ: <span>до 14 января</span></p>
                        <p>Полноправные члены: <span>44</span></p>
                        <p>Наблюдатели: <span>34</span></p>
                        <p>Росприроднадзор: <span>1</span></p>
                    </div>
                </div>
                
            </div>
            <div class="indicative" v-if="indicativ">

                <div class="actualisation-item">
                    <div class="actualisation-title">
                        <a href="http://burondt.ru/trg/2023/1947/info" target="_blank">ЭГ 18<br>«Производство основных органических химических веществ»</a>
                    </div>
                    <div class="actualisation-content">
                        <p>Формирование ТРГ: <span>до 13 января</span></p>
                        <p>Полноправные члены: <span>44</span></p>
                        <p>Наблюдатели: <span>34</span></p>
                        <p>Росприроднадзор: <span>2</span></p>
                    </div>
                </div>

                <div class="actualisation-item">
                    <div class="actualisation-title">
                        <a href="http://burondt.ru/trg/2023/1945/info" target="_blank">ЭГ 25<br>«Добыча и обогащение железных руд»</a>
                    </div>
                    <div class="actualisation-content">
                        <p>Формирование ТРГ: <span>до 13 января</span></p>
                        <p>Полноправные члены: <span>44</span></p>
                        <p>Наблюдатели: <span>34</span></p>
                        <p>Росприроднадзор: <span>2</span></p>
                    </div>
                </div>

                <div class="actualisation-item">
                    <div class="actualisation-title">
                        <a href="http://burondt.ru/trg/2023/1946/info" target="_blank">ЭГ 27<br>«Производство изделий дальнейшего передела черных металлов»</a>
                    </div>
                    <div class="actualisation-content">
                        <p>Формирование ТРГ: <span>до 13 января</span></p>
                        <p>Полноправные члены: <span>44</span></p>
                        <p>Наблюдатели: <span>34</span></p>
                        <p>Росприроднадзор: <span>2</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
export default {
    setup() {
        // const show = ref(false)//показывает блоки с инфой
        const actual = ref(false)//показывает блок с актуализацией
        const indicativ = ref(false)//показывает блок и индикативными показ

        function showActual() {
            console.log("asas")
            actual.value = true
            indicativ.value = false
        }
        const showIndicative = () => {
            actual.value = false
            indicativ.value = true
        }
        return {
            showActual, showIndicative, actual, indicativ
        }
    }
}

</script>

<style scoped>
.actualisation-container {
    margin-top: 6rem;
    width: 100%;
    text-align: center;
}

.page-title {
    /* font-family: 'Montserrat'; */
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
}

.block-number-point {
    color: #54B69C;
    font-size: 120px;
    font-weight: bold;
    margin: 0 auto;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
}

.block-number-point::before {
    content: "";
    /* Указываем что наши линии будут строчно-блочные и 
    ** выравнивание по высоте - по центру */
    display: inline-block;
    vertical-align: middle;
    /* Задаем ширину 100% и выбираем высоту линии, 
    ** в нашем примере она равна 4 пикселям */
    width: 100%;
    height: 4px;
    /* Добавляем цвет для линии */
    background-color: #F3F3F3;
    /* Добавляем пседоэлемантам возможность изменить 
    ** позицию линии, для создания отступов от текста */
    position: relative;
    margin-left: -100%;
    left: -30px;
}

.block-number-point::after {
    content: "";
    /* Указываем что наши линии будут строчно-блочные и 
    ** выравнивание по высоте - по центру */
    display: inline-block;
    vertical-align: middle;
    /* Задаем ширину 100% и выбираем высоту линии, 
    ** в нашем примере она равна 4 пикселям */
    width: 100%;
    height: 4px;
    /* Добавляем цвет для линии */
    background-color: #F3F3F3;
    /* Добавляем пседоэлемантам возможность изменить 
    ** позицию линии, для создания отступов от текста */
    position: relative;
    margin-right: -100%;
    right: -30px;
}

.main-block {
    width: 40%;
    /* border: 1px solid black; */
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.main-block-actual {
    width: 40%;
}

.context {
    display: flex;
    border: 3px solid #D2D2D2;
    border-radius: 20px;
    padding: 10px;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

.main-block .main-block-actual .context.active {
    background-color: #D4E9F5;
    border-color: #D4E9F5;
}

.main-block .main-block-actual:nth-child(2n) .context.active {
    background-color: #54B69C;
    border-color: #54B69C;
}

.context.active .context-point {
    color: #2D89C2;
}

.context-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 16.8px
}

.context-point {
    color: #D2D2D2;
    font-size: 80px;
    font-weight: bold;
}

.actualisations,
.indicative {
    width: 60%;
    margin: 0 auto;
    display: grid;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 300px);
}

.actualisation-item {
    padding: 10px;
    /*width: 30%;*/
    background-color: #F3F3F3;
    margin-bottom: 2rem;
    border-radius: 15px;
    text-align: left;
}

.actualisation-content p {
    line-height: 8px;
}

.actualisation-content p span {
    color: #2D89C2;
}

.hrr {
    width: 50%;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.actualisations .actualisation-item .actualisation-title,
.indicative .actualisation-item .actualisation-title {
    text-decoration: none;
    border-bottom: 1px solid #C4C4C4;
}

.actualisations .actualisation-item .actualisation-title a,
.indicative .actualisation-item .actualisation-title a {
    text-decoration: none;
    width: 80%;
    color: #2D89C2;
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
}


.indicative .actualisation-item .actualisation-title a,
.indicative .actualisation-content p span {
    color: #54B69C !important;
}

</style>

<template>
  <main class="main">
    <back-images-comp />
    <YearsMenu :page="page" :title="title" />
    <section v-if="loader" class="eleven">
      <loader
        object="#ff9633"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular"
      ></loader>
    </section>
    <section v-else class="fifteen">
      <div v-if="!trgsData" class="container">
        <h3>Ничего не найдено</h3>
      </div>
      <div v-else class="fifteen__wrapper">
        <div class="fifteen__items">
          <BlocksMenu
            v-for="data in trgsData"
            :key="data.etkstructure_id"
            :dirName="data.etkstructure_num"
            :dirHref="`/trg/${$route.params.year}/${data.etkstructure_id}/info`"
            :dirAnnotation="data.etkstructure_title"
            :dirSmaller="'smaller'"
          />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import useTrgs from "@/composables/trg/trgs";
// import TrgMenu from "./TrgMenu.vue";
import YearsMenu from "../YearsMenu.vue";
import BackImagesComp from "../BackImagesComp.vue";
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import BlocksMenu from "../BlocksMenu.vue";

export default {
  name: "Trg",
  components: {
    BackImagesComp,
    YearsMenu,
    BlocksMenu,
  },
  props: {},
  setup() {
    const title = "Технические <br> рабочие группы"
    const page = "/trg/";
    // const smaller = 'smaller'
    const route = useRoute();
    const { trgsData, fetchTrgs, loader } = useTrgs();
    onMounted(() => {
      fetchTrgs(route.params.year);
    });
    watch(
      () => route.params.year,
      newYear => {
        fetchTrgs(newYear)
      }
    )
    return { trgsData, loader, page, title };
  },
};
</script>

<style scoped>

/*--------------------------------fifteen-----------------------------------*/
.fifteen {
  padding: 0 4.5vw;
}
.container {
  text-align: center;
  min-height: 400px;
}

.fifteen__items {
  width: 90%;
  max-width: 2945px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10vw;
}
.fifteen__item {
  width: 22vw;
  height: 12vw;
  border: 2px solid #354951;
  margin: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* background: white; */
  transition: 0.5s;
}
.fifteen__item:hover {
  box-shadow: 0 0 1vw rgb(0 0 0 / 50%);
  transform: scale(1.02);
}
.fifteen__item-title {
  font-size: calc(100vw / 88);
  color: black;
  padding: 0 0 0 3%;
  text-transform: uppercase;
}
.fifteen__hover {
  display: none;
  margin: 0 10px;
  width: 95%;
}
.fifteen__hover.active {
  display: block;
}
.fifteen__hover-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fifteen__hover-img {
  transform: translateY(3vh);
  width: 2.5vw;
  margin-bottom: 3rem;
}
a {
  /* по другому пока никак */
  text-decoration: none;
}
.fifteen__subtitle {
  padding: 1vh 0 1vh 0;
  margin: 0;
  width: 82%;
  font-size: calc(100vw / 107);
  color: #3f606b;
  text-decoration: none;
  margin-left: 1%;
}
@media (max-width: 480px) {
  .container {
    text-align: center;
    min-height: 620px;
  }
  .fifteen__item {
    width: 75%;
    height: 125px;
    margin: 10px 0;
    background: white;
  }

  .fifteen__items {
    width: 100%;
    margin-bottom: auto;
    justify-content: flex-end;
    padding-bottom: 50px;
  }

  .fifteen__item-title {
    font-size: 15px;
    transform: none;
    padding-left: 9px;
  }

  .fifteen__subtitle {
    transform: none;
    font-size: 3vw;
    margin-left: 0;
    padding: 1vh 0 2vh 0;
  }

  .fifteen__hover {
    display: none;
    margin: 0 10px;
    width: auto;
  }

  .fifteen__hover-img {
    display: block;
    transform: translateY(22px) !important;
    width: 7vw;
  }

  .fifteen {
    padding: 0 15px;
    min-height: 620px;
  }
}
</style>

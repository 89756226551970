<template>
  <div class="its_menu_content_item">
    <br><div class="seventeen__block3-content">
    <h2>12. Доработка проекта ИТС НДТ</h2>
<p>По итогам <a href="http://burondt.ru/discussion/2022">публичного обсуждения </a>и экспертизы в ТК 113 секретариат рабочей группы формирует сводку, с замечаниями/предложениями и направляет ее в личный кабинет ТРГ и разработчику проекта справочника на рассмотрение.</p>
<p>Рабочая группа совместно с разработчиком проекта справочника рассматривает сводку отзывов и формирует позицию по представленным замечаниям и предложениям.</p>
<p>Разработчик дорабатывает проект справочника в соответствии с отведенными сроками по плану работы ТРГ и согласованной сводке отзывов. После доработки документ&nbsp; направляется разработчиком в Бюро НДТ для размещения в личном кабинете ТРГ.&nbsp;</p>
<p>Бюро размещает доработанный проект справочника в личном кабинете ТРГ для принятия решения о готовности документа к утверждению или о необходимости доработки.</p>
</div>

  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}
</style>
<template>
  <div class="its_menu_content_item">
    <br />
    <div class="seventeen__block3-content">
      <h2>18. Размещение документов</h2>
<p>Росстандарт в течение 10 календарных дней со дня утверждения справочника публикует его в открытом бесплатном доступе на своём <a href="https://www.rst.gov.ru/portal/gost/home/activity/NDT">сайте</a>, являющемся официальным местом размещения справочника.</p>
<p>По согласованию с Росстандартом допускается опубликование справочников на иных информационных ресурсах в порядке, установленном законодательством Российской Федерации.</p>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js";
import { onMounted } from "vue";
export default {
  setup() {
    const { scrollToEl } = useScrollToEl();
    onMounted(() => {
      scrollToEl();
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
  padding: calc(100vw / 22);
  padding-top: calc(100vw / 60);
  background: white;
  margin-bottom: calc(100vw / 20);
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
}
</style>
import {ref} from 'vue';
import { environment } from '@/composables/realhost.js';

export default function getMvsDocumentsData(){
    const docs = ref([])

    function fetchMvsDocuments(){
        fetch(environment.realHost + "MVC/documents")
        .then(response => response.json())
        .then(data => {
            docs.value = data
        })
    }

    function formatDate(date) {
		var d = new Date(date)
		var month = d.getMonth() + 1
		date =
			d.getFullYear() +
			"-" +
			dateLength(month) +
			"-" +
			dateLength(d.getDate())
		return date
	}
	function dateLength(dd) {
		let d = String(dd)
		return d.length > 1 ? d : "0" + String(d)
	}
    function makeFileUrl(a){
        return `${environment.urlMakerHost}NDT/ProjectFileDownload.php?UrlId=${a}`
    }

    return {
        docs,
        fetchMvsDocuments,
        formatDate,
        makeFileUrl
    }
}
<template>
  <div class="its_menu_content_item">
    <br><div class="seventeen__block3-content">
    <h2>11. Экспертиза в ТК 113</h2>
<p>Экспертиза* проекта информационно-технического справочника по НДТ в техническом комитете &laquo;Наилучшие доступные технологии&raquo; 113 проводится на предмет соответствия требованиям, установленным к его содержанию и структуре в документах национальной системы стандартизации. В <a href="http://burondt.ru/tk113/about">ТК 113 </a>разработана и утверждена система стандартов НДТ для систематизации и стандартизации разработки/актуализации ИТС НДТ.</p>
<p>Экспертиза может проводиться параллельно с <a href="http://burondt.ru/discussion/2022">публичным обсуждением.</a></p>
<p>В этот же период, при необходимости,&nbsp; могут быть проведены иные экспертизы проектов ИТС НДТ, например экспертизы в отраслевых союзах и объединениях, профильных ТК по стандартизации, научно-исследовательских институтах и т.д.&nbsp;</p>
<p>ТК 113 по итогам экспертизы подготавливает и направляет в Бюро НДТ экспертное заключение.<br /><br />*<em><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=789&amp;etkstructure_id=1872">Приказ Росстандарта от 12 августа 2016 № 1080 &laquo;Об утверждении Порядка проведения экспертизы проектов ИТС НДТ в техническом комитете по стандартизации</a></em><em>&raquo;</em></p> 
</div>

  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}
</style>
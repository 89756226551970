<template>
  <section class="twentyeight">
    <div class="twentyeight__wrapper">
      <h1 class="twentyeight__title-main">Межведомственный<br />совет</h1>
      <div class="twentyeight__items">
        <router-link to="/mvs/documents" v-slot="{ isActive }"
          ><span :class="{ active: isActive }" class="twentyeight__item"
            >Документы</span
          ></router-link
        >
        <router-link to="/mvs/meetings" v-slot="{ isActive }"
          ><span :class="{ active: isActive }" class="twentyeight__item"
            >Заседания</span
          ></router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MvsMenu",
  setup() {},
};
</script>

<style scoped>
.twentyeight__item.active {
  color: black;
  border-left: 1px solid black;
  cursor: pointer;
}
.twentyeight__items a{
    text-decoration: none;
}

.twentyeight__wrapper {
  height: auto;
  margin: 5vh;
}
.twentyeight__title-main {
  font-size: calc(100vw / 35);
  color: #0071af;
  margin: 0;
  padding: 2vw 0;
}
.twentyeight__link-text {
  display: none;
}
.twentyeight__items {
  display: flex;
  justify-content: space-between;
  width: 22%;
}
.twentyeight__item {
  color: #a6a4a4;
  border-left: 1px solid #a6a4a4;
  padding: 10px 20px;
  font-size: calc(100vw / 70);
}
.twentyeight__item:hover {
  color: black;
  border-left: 1px solid black;
  cursor: pointer;
}
@media (max-width: 480px) {
  .twentyeight__title {
    font-size: calc(3px + 80 * (100vw / 1280));
  }
  .twentyeight__title-main {
    font-size: calc(100vw / 14);
  }
  .twentyeight__subtitle {
    font-size: calc(3px + 41 * (100vw / 1280));
    margin: 0;
  }
  .twentyeight__link-one,
  .twentyeight__link-two,
  .twentyeight__subtitle2 {
    font-size: calc(3px + 40 * (100vw / 1280));
  }

  .twentyeight__item {
    padding: 5px 10px;
    font-size: 14px;
  }
  .twentyeight__wrapper {
    margin: 3vh;
  }
  .twentyeight__items {
    width: 70%;
  }
}
</style>
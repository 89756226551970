<template>
  <main class="main">
    <back-images-comp />
    <TK113Menu />
    <section class="twentythree">
      <div class="twentythree__wrapper">
        <div v-if="meetings.length > 0" class="twentythree__items">
          <div class="twentythree__item" v-for="meeting in meetings" :key="meeting['meetings_id']">
            <TK113Accordeon>
              <template v-slot:title>
                <div class="twentythree__prolapse1">
                  <span class="twentythree__text1">{{
                      meeting["etkmeetings_dateb"]
                  }}</span>
                  <h3 class="twentythree__text2">
                    {{ meeting["etkmeetings_name"] }}
                  </h3>
                </div>
              </template>
              <template v-slot:content>
                <div class="twentythree__prolapse1">
                  <span class="twentythree__text1">место проведения</span>
                  <a href="#" class="twentythree__text2 text11">
                    {{ meeting["etkmeetings_place"] }}
                  </a>
                </div>
                <div class="twentythree__prolapse2">
                  <span class="twentythree__text3">материалы заседания</span>
                  <div class="twentythree__files-link">
                    <a :href="makeFileUrl(file.etkmeetingsfiles_id)" class="twentythree__file-link"
                      v-for="file in meeting['files']" :key="file['etkmeetingsfiles_id']">
                      <img src="/img/49.png" alt="" class="twentythree__img-link" />
                      {{ file["etkmeetingsfiles_name"] }}
                    </a>
                  </div>
                </div>
              </template>
            </TK113Accordeon>
          </div>
        </div>
        <div v-else class="twentythree__items no_info">
          <loader object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793"
            opacity="80" name="circular"></loader>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import TK113Menu from "./TK113Menu";
import TK113Accordeon from "./TK113Accordeon";
import useMeetings from "@/composables/tk113/meetings";
import BackImagesComp from "../BackImagesComp.vue";
import { onMounted } from "vue";
export default {
  name: "TK113Meetings",
  components: {
    TK113Menu,
    TK113Accordeon,
    BackImagesComp,
  },
  setup() {
    const { meetings, fetchMeetings, makeFileUrl } = useMeetings();
    onMounted(() => fetchMeetings());
    return { meetings, makeFileUrl };
  },
};
</script>

<style scoped>
.twentytwo {
  padding: 0 50px;
}

.twentytwo__wrapper {
  margin: 3vh;
  height: auto;
}

.twentytwo__title-main {
  color: #0073af;
  font-size: calc(100vw / 35);
}

.twentytwo__items {
  display: flex;
  justify-content: space-between;
  width: 85%;
}

.twentytwo__item {
  color: #a6a4a4;
  border-left: 1px solid #a6a4a4;
  padding: 2vh 3vh;
  font-size: calc(100vw / 70);
}

.twentytwo__item:hover {
  color: black;
  border-left: 1px solid black;
  cursor: pointer;
}

/*--------------------------twentythree------------------------------------*/

.twentythree {
  padding: 0 50px;
}

.twentythree__wrapper {
  margin: 3vh;
  margin-bottom: 200px;
  height: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background: white;
  padding: 3vw;
}

.twentythree__items {
  display: flex;
  flex-direction: column;
}

.twentythree__item {
  border-bottom: 0.15vw solid #a6a4a4;
  padding-bottom: 1vw;
  margin-top: 1vw;
  font-size: 10vw;
}

.twentythree__item:first-child {
  margin-top: 0;
}

.twentythree__item-wrapp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.twentythree__block1 {
  font-size: calc(100vw / 105);
}

.twentythree__block1-h3 {
  margin: 0;
  padding: 0;
  padding-top: 0.7rem;
}

.twentythree__block2 {
  border: 1px solid red;
}

.twentythree__block2-img,
.twentythree__block2-img2 {
  height: calc(100vw / 60);
  cursor: pointer;
}

.twentythree__prolapse {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
}

.twentythree__prolapse1 {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.twentythree__prolapse2 {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: 10px;
}

.twentythree__text1,
.twentythree__text3 {
  color: #414141;
  font-size: 1vw;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 2.5;
}

.twentythree__text2 {
  color: #414141;
  font-weight: 600;
  text-decoration: none;
  font-size: 1.1vw;
  margin: 0;
}

.text11 {
  font-weight: 400;
}

.twentythree__files-link {
  display: flex;
  flex-direction: column;
}

.twentythree__file-link {
  color: #414141;
  font-size: calc(3px + 10 * (100vw / 1280));
  font-weight: 400;
  text-decoration: none;
}

.twentythree__img-link {
  height: 18px;
}

.none {
  display: none;
}

/*----------------------------MOBILE---------------------------------*/
@media (max-width: 480px) {
  .twentytwo {
    padding: 0 30px;
  }

  .twentytwo__title-main {
    font-size: 29px;
    max-width: 300px;
  }

  .twentytwo__wrapper {
    margin: 0;
  }

  .twentytwo__items {
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 95%;
  }

  .twentytwo__item {
    padding: 5px 15px;
    margin: 9px 0;
    font-size: 14px;
    max-width: 171px;
    width: 50%;
  }

  /*--------------------------twentyfive-----------------------------------*/

  .twentyfive__input {
    width: calc(3px + 520 * (100vw / 1280));
  }

  .twentyfive__wrapper {
    margin-bottom: 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }

  .twentyfive__nomination,
  .twentyfive__items-mob {
    display: block;
  }

  .twentyfive__table-wrapp {
    display: none;
  }

  .twentyfive__nomination {
    display: flex;
    justify-content: space-between;
    padding: 0 7px;
    width: 230px;
  }

  .twentyfive__filter-text {
    font-size: calc(3px + 33 * (100vw / 1280));
  }

  .twentyfive__item-one {
    font-size: calc(3px + 33 * (100vw / 1280));
  }

  .twentyfive__items-mob {
    margin-bottom: 100px;
  }

  .twentyfive__item-mob2 {
    font-weight: 600;
  }

  .twentyfive__item-mobile {
    width: 95%;
    max-width: 1720px;
    margin: 7px auto;
    height: auto;
    max-height: 5000px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    padding: 20px 15px;
    display: flex;
  }

  .twentyfive__number {
    width: 40px;
  }

  .twentyfive__span {
    font-size: 18px;
    padding-left: 7px;
  }

  .twentyfive__block-wrapp {
    width: 280px;
  }

  .twentyfive__h3 {
    margin: 5px 0;
    font-size: calc(3px + 38 * (100vw / 1280));
  }

  .twentyfive__p {
    margin: 0;
  }

  .twentyfive__p:last-child {
    margin: 5px 0;
  }

  .twentyfive__years {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .twentyfive__year {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 45px;
  }

  .twentyfive__download {
    height: 17px;
  }

  /*---------------------------twentythree----------------------------------*/

  .twentythree {
    padding: 0 1vw;
  }

  .twentythree__wrapper {
    padding: 20px;
    margin-bottom: 7vh;
  }

  .twentythree__item:last-child {
    border: none;
  }

  .twentythree__item {
    border-bottom: 0.15vw solid #a6a4a4;
    padding-bottom: 1vw;
    margin-top: 1vw;
    font-size: 1vw;
  }

  .twentythree__block1-span {
    font-size: calc(3px + 30 * (100vw / 1280));
    line-height: 30px;
  }

  .twentythree__block1-h3 {
    font-size: calc(3px + 33 * (100vw / 1280));
    padding-top: 0;
  }

  .twentythree__block2-img,
  .twentythree__block2-img2 {
    height: calc(1px + 50 * (100vw / 1280));
  }

  .twentythree__prolapse {
    line-height: 20px;
    padding-bottom: 1vw;
  }

  .twentythree__prolapse1 {
    width: calc(3px + 870 * (100vw / 1280));
  }

  .twentythree__prolapse2 {
    width: calc(3px + 870 * (100vw / 1280));
  }

  .twentythree__text1,
  .twentythree__text3 {
    font-size: calc(3px + 25 * (100vw / 1280));
  }

  .twentythree__text2 {
    font-size: calc(1px + 40 * (100vw / 1280));
    font-weight: 500;
  }

  .twentythree__file-link {
    font-size: calc(3px + 30 * (100vw / 1280));
    text-decoration: revert;
  }
}

@media (max-width: 375px) {
  .twentytwo__item {
    font-size: 14px;
  }
}
</style>
<template>
  <div class="its_menu_content_item">
    <br><div class="seventeen__block3-content">
<h2>3. Установочное заседание</h2>
<p>В течение 30 календарных дней со дня утверждения состава <a href="http://burondt.ru/trg/2022">ТРГ</a> Бюро НДТ проводит первое (установочное) заседание.</p>
<p>На нем руководитель секретариата ТРГ инициирует и организует разработку плана деятельности ТРГ, содержащего сроки и этапы работ, установленные Бюро НДТ, знакомит членов группы с порядком и правилами работы, обозначает первостепенные задачи ТРГ: определение области распространения ИТС НДТ, согласование отраслевой анкеты для сбора данных.</p>
<p>Заседания ТРГ в процессе разработки ИТС НДТ проводятся по мере необходимости, по решению секретаря ТРГ или самой ТРГ. Зачастую для урегулирования спорных вопросов и достижения консенсуса при пересечении областей применения ИТС НДТ Бюро НДТ проводит совместные заседания нескольких ТРГ.</p>
<p>Материалы заседаний ТРГ, уведомления о проведении, форме, месте и времени заседания, а также повестка заседания направляются секретариатом ТРГ членам группы в электронном виде заблаговременно и размещаются на сайте. Итоги заседаний ТРГ оформляются протоколами, утверждаемыми руководителем Бюро НДТ, и размещаются на официальном сайте в разделе &laquo;Заседания&raquo;.</p>
 <br>
<center><img src="/img/itc/kick-off-meeting-img.jpg" alt="" width="1115" height="287" /></center>
</div>
    
  </div>
  
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}
</style>
import { ref } from "vue"
import { environment } from "@/composables/realhost.js"

export default function getTrgsContacts(){
    let contacts = ref([])
    function fetchTrgsContacts(){
        fetch(environment.realHost + "contacts")
        .then((response) => response.json())
        .then(data => {
            contacts.value = data
        })
    }
    return {
        contacts,
        fetchTrgsContacts
    }
}
<template>
  <div class="its_menu_content_item">
    
<br><div class="seventeen__block3-content">
  <h2>1. Формирование технической рабочей группы</h2>
<p>Согласно <a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1328&amp;etkstructure_id=1872">приказу Министерства промышленности и торговли РФ от 19 июня 2019 г. </a><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1328&amp;etkstructure_id=1872">№ 2130</a><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1328&amp;etkstructure_id=1872"> &laquo;Об утверждении порядка формирования и осуществления деятельности технических рабочих групп&raquo; </a>формирование технических рабочих групп ( <a href="http://burondt.ru/trg/2022">ТРГ</a>) осуществляет Минпромторг России.</p>
<p>Схематично порядок формирования ТРГ представлен на рисунке. После опубликования уведомления о формировании ТРГ на <a href="http://burondt.ru/">сайте Бюро НДТ</a> заинтересованные лица, к которым относятся представители промышленных предприятий, федеральных органов исполнительной власти (ФОИВ), научно-исследовательских институтов и экспертных организаций, учебных институтов, промышленных союзов(ассоциаций), общественных объединений и др., направляют в адрес Бюро НДТ заявку на участие в создаваемой ТРГ.</p>
<p>Заявка включает в себя официальное письмо с данными о кандидате в члены ТРГ: ФИО, организация, должность, контактные данные, сведения об опыте работы в области, соответствующей области применения справочника, и квалификации, желаемый статус участия, дополнительные данные и согласие на обработку персональных данных.</p>
<p>Срок формирования ТРГ определяется в уведомлении и не может быть менее 14 дней.</p>
 <br>
<center><img src="/img/itc/steps.jpg" alt="" width="1115" height="343" /></center>
<br>
<p>Структурно техническая рабочая группа состоит из секретариата, руководит которым руководитель секретариата, разработчика проекта ИТС НДТ (<a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=772&amp;etkstructure_id=1872">по ПП РФ от 23 декабря 2014 г. №1458</a>), полноправных членов и наблюдателей.</p>
<p>Полноправные члены&nbsp; имеют право на голосование, а наблюдатели имеют право давать свою позицию на стадии рассмотрения документов.</p>
<p>В состав группы могут входить представители: федеральных органов исполнительной власти, государственных научных организаций, некоммерческих организаций государственных корпораций, экспертных организаций, промышленных союзов (ассоциаций), объединений предпринимателей, иных организаций (<a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1328&amp;etkstructure_id=1872">Приказ Минпромторга России № 2130 от 19.06.2019 г.</a>).</p>
<p>Состав ТРГ должен быть сбалансирован и включать в себя представителей как органов власти, так и науки и бизнеса.</p>
 <br>
<center><img src="/img/itc/trg-formation2.jpg" alt="" width="660" height="582" /></center>

</div>    
  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}

</style>
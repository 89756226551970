<template>
    <section class="twentyseven">
        <div class="content backgr_cont_mwvs" style="overflow-y: auto;">[[Wayfinder? &startId=`222` &level=`1` &useWeblinkUrl = `1` &outerTpl=`menuOuter-7` &rowTpl=`menuRow-7` ]]
            <img src="/img/image-o.png" width="951" height="596" alt=""/><br><br><br>
        <div>

            <p style="text-align: center; font-weight: bold; font-size: 25px;">СИСТЕМЫ АВТОМАТИЧЕСКОГО КОНТРОЛЯ</p><br>
            <p style="text-indent: 25px; font-size: 19px; padding-top: 5px; padding-bottom: 5px; padding: 40px; text-align: justify;">На  объектах  I категории, стационарные источники выбросов загрязняющих веществ, сбросов  загрязняющих веществ, образующихся при эксплуатации технических устройств,  оборудования или их совокупности (установок), виды которых устанавливаются  Правительством Российской Федерации, должны быть оснащены автоматическими  средствами измерения и учета показателей выбросов загрязняющих веществ и (или)  сбросов загрязняющих веществ, а также техническими средствами фиксации и  передачи информации о показателях выбросов загрязняющих веществ и (или) сбросов  загрязняющих веществ в государственный реестр объектов, оказывающих негативное  воздействие на окружающую среду, на основании программы создания системы  автоматического контроля.</p>
        </div>

        <br>
        <div>
            <p style="text-align: center; font-weight: bold; font-size: 25px; color: #0096cd">Оснащение объектов первой категории системами автоматического контроля (САК)</p><br>
                <p style="text-indent: 8px; padding: 0 0px 10px 30px;">
                <a target="_blank" style="display: flex; align-items: center; font-size: 19px; padding: 18px" href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=768&etkstructure_id=1872">
                    <img src="img/pero.png" width="37" height="38">
                    <span style="color: #000">7-ФЗ (в  ред. 252-ФЗ от 29.07.2018 г.)</span>
                </a>
            </p>


            <p style="text-indent: 25px; font-size: 19px; padding-top: 5px; padding-bottom: 5px; padding: 45px; text-align: justify;">Стационарные источники выбросов ЗВ, сбросов ЗВ, образующихся при эксплуатации технических устройств, виды которых устанавливает Правительство РФ, должны быть оснащены САК</p>
            <p style="text-indent: 25px; font-size: 19px; padding-top: 5px; padding-bottom: 5px; padding: 45px; text-align: justify;">Срок создания САК – <span style="color: #35D40E">не более 4 лет</span> с момента получения <span style="color: #35D40E">комплексного экологического разрешения </span></p>


        <div style="display: flex; justify-content: space-between; padding: 20px 35px">
            
            <div style="display: flex; flex: 30% 0 0; flex-direction: column">
                <a style="display: flex; align-items: center; margin-bottom: 10px; color: #000" target="_blank" href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1240&etkstructure_id=1872">
                    <img style="width: 13%; margin-right: 8px" src="img/pero.png" alt="">
                    <span>РП РФ 428-р от 13.03.2019 г.</span>
                </a>
                <p style="margin-left: 42px">О видах технических устройств, оборудования или их совокупности (установок) на объектах I категории</p>
            </div>
            
            <div style="display: flex; flex: 30% 0 0; flex-direction: column">
                <a target="_blank" style="display: flex; align-items: center; margin-bottom: 10px; color: #000" href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1238&etkstructure_id=1872">
                    <img style="width: 13%; margin-right: 8px" src="img/pero.png" alt="">
                    <span>ПП РФ 263 от 13.03.2019 г.</span>
                </a>
                <p style="margin-left: 42px">О требованиях к автоматическим средствам измерения</p>
            </div>
            
            <div style="display: flex; flex: 30% 0 0; flex-direction: column">
                <a target="_blank" style="display: flex; align-items: center; margin-bottom: 10px; color: #000" href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1237&etkstructure_id=1872">
                    <img style="width: 13%; margin-right: 8px" src="img/pero.png" alt="">
                    <span>ПП РФ 262 от 13.03.2019 г.</span>
                </a>
                <p style="margin-left: 42px">Об утверждении правил создания и эксплуатации системы автоматического контроля</p>
            </div>
        </div>

        <!--table width="951" border="1">
        <tbody>
            <tr>
            <td><p style="text-indent: 25px; font-size: 19px; padding-top: 5px; padding-bottom: 5px; padding: 5px; text-align: justify;"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=768&etkstructure_id=1872"><img src="img/pero.png" width="37" height="38">7-ФЗ </a><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=768&etkstructure_id=1872">(в  ред. 252-ФЗ от 29.07.2018 г</a><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=768&etkstructure_id=1872">.) </a><br>О видах технических устройств, оборудования или их совокупности (установок) на объектах I категории
        </p></td>
            <td><p style="text-indent: 25px; font-size: 19px; padding-top: 5px; padding-bottom: 5px; padding: 5px; text-align: justify;"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=768&etkstructure_id=1872"><img src="img/pero.png" width="37" height="38">7-ФЗ </a><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=768&etkstructure_id=1872">(в  ред. 252-ФЗ от 29.07.2018 г</a><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=768&etkstructure_id=1872">.) </a><br>О требованиях к автоматическим средствам измерения
        </p></td>
            <td><p style="text-indent: 25px; font-size: 19px; padding-top: 5px; padding-bottom: 5px; padding: 5px; text-align: justify;"><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=768&etkstructure_id=1872"><img src="img/pero.png" width="37" height="38">7-ФЗ </a><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=768&etkstructure_id=1872">(в  ред. 252-ФЗ от 29.07.2018 г</a><a href="http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=768&etkstructure_id=1872">.) </a><br>Об утверждении правил создания и эксплуатации системы автоматического контроля
        </p></td>
            </tr>
        </tbody>
        </table-->
        </div>
        <img src="/img/image-r.png" width="951" height="643" alt=""/></div>
    </section>
</template>

<script>
export default {
    name: "SAK",
    setup() {
        
    },
}
</script>

<style scoped>

</style>
<template>
  <main class="main">
    <back-images-comp />
    <section class="five">
      <div class="five__wrapper">
        <AboutMenu />
        <section v-if="loader" class="no_info">
          <loader
            object="#ff9633"
            color1="#ffffff"
            color2="#17fd3d"
            size="5"
            speed="2"
            bg="#343a40"
            objectbg="#999793"
            opacity="80"
            name="circular"
          ></loader>
        </section>
        <div v-else class="five__description">
          <div class="five__description-title">
            Государственное регулирование
          </div>
          <div class="five__block-1">
            <div class="five__block-img"></div>
            <img src="/img/40.png" alt="" class="five__img1-m" />
            <div class="five__block1-wrap">
              <div class="five__block1-text">
                «Наилучшие доступные технологии – совокупность технологических, технических и управленческих решений, обеспечивающих высокую ресурсо- и энергоэффективность промышленного производства и минимизацию негативного воздействия на окружающую среду»
              </div>
              <div class="five__block1-fz">
                Федеральный закон от 10.01.2002 № 7-ФЗ <br />«Об охране
                окружающей среды»
              </div>
            </div>
          </div>
          <div class="five__block-2">
            <p>
              Наилучшие доступные технологии являются признанной на международном уровне основой рациональных моделей промышленного производства. Организуя эколого-технологическую модернизацию производства основываясь принципами НДТ повышается ресурсо и энергоэффективность при одновременном снижении негативного воздействия на окружающую среду. <br /><br />
              В России на законодательно уровне концепция НДТ положила свое начало с 2014 года, когда был подписан Федеральный закон № 219 от 21 июля 2014 года «О внесении изменений вФедеральныйзакон«Об охране окружающей среды» и отдельные законодательные акты Российской Федерации».
            </p>
          </div>
          <div class="five__block-3">
            <h3 class="five__block3-title"><!-- Начало повседневной работы --></h3>
            <div class="five__block3-wrap">
              <p class="five__block3-text">
                В этом же году был создан Технический комитет по стандартизации «Наилучшие доступные технологии» 113 для формирования научно-методической базы по НДТ и Правительством был утвержден перечень областей применения наилучших доступных технологий постановлением Правительства от 24 декабря 2014 года № 2674-р.
              </p>
              <!-- <img src="/img/39.png" alt="" class="five__block3-img" /> -->
            </div>
          </div>
          <div class="five__block-4">
            <p>
              С 2015 по 2017 года было разработано 51 информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) для создания фундамента перехода отечественной промышленности на принципы НДТ и выстраивания нового государственного регулирования. ИТС НДТ как один из ключевых элементов концепции определяет текущее положение отрасли промышленности; устанавливает показатели эмиссий, ресурсной эффективности, индикативные показатели углеродоемкости продукции и(или) технологических процессов; прогнозирует какие технологии в будущем могут стать НДТ. С 2019 года начался процесса актуализации ИТС НДТ для уточнения данных и показателей.
            </p><br>
            <p>В настоящее время показатели наилучших доступных технологий выступают критериями при оценки эколого-технологической эффективности предприятия. Соответствие показателям НДТ – отправная точкой в рамках повышения ресурсоэффективности идостижения целей устойчивого развития.</p><br>
            <p>Для успешного применения концепции НДТ в целях энергоперехода и ограничения выбросов парниковых газов необходимо задействовать все ее элементы, фрагментарное применение отдельных частей не приведет к желаемому результату. В первую очередь целесообразно сформировать систему релевантных показателей для сравнения, а также определить направления и порядок их применения в регулировании.</p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import AboutMenu from "./AboutMenu.vue";
import BackImagesComp from "../BackImagesComp.vue"
import { ref, onMounted } from "vue";
export default {
  name: "AboutRegulation",
  components: {
    AboutMenu,
    BackImagesComp
  },
  setup() {
    const loader = ref(true);
    onMounted(() => {
      setTimeout(function () {
        loader.value = false;
      }, 500);
    });
    return {
      loader,
    };
  },
};
</script>

<style scoped>

.no_info {
  min-height: 300px;
}
.five__wrapper {
  margin: 0 auto;
  padding: 0 calc(100vw / 22);
}
.five__description {
  margin-top: calc(100vw / 25);
  height: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: calc(100vw / 22);
  padding-top: calc(100vw / 60);
  background: white;
  margin-bottom: calc(100vw / 20);
}
.five__description-title {
  color: #1298c8;
  border-bottom: 0.1vw solid black;
  font-size: calc(100vw / 33);
  font-weight: 600;
  margin: 2vh 0;
  padding: 2vh 0;
}
.five__block-1 {
  display: flex;
}
.five__block-img {
  background: url("/img/38.png") no-repeat;
  background-size: auto;
  background-size: 100% 100%;
  width: calc(100vw / 0.7);
  height: calc(100vw / 3.6);
}
.five__img1-m {
  display: none;
}
.five__block1-text {
  font-size: calc(100vw / 55);
  line-height: calc(100vw / 32);
  padding: 0 2.7vw;
  color: #242424;
}
.five__block1-fz {
  font-size: calc(100vw / 70);
  line-height: calc(100vw / 50);
  padding: 10px 0;
  font-weight: 600;
  border-top: 1px solid black;
  width: 90%;
  margin: 15px auto 0 auto;
}
.five__block-2,
.five__block-3,
.five__block-4 {
  font-size: calc(100vw / 60);
  line-height: calc(100vw / 40);
}
.five__block3-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.five__block3-img {
  width: 30vw;
  padding: 0 calc(100vw / 35);
}
/* .five__block-4 {
  font-size: calc(3px + 21 * (100vw / 1280));
  line-height: calc(3px + 34 * (100vw / 1280));
  margin-top: 7vw;
} */

@media (max-width: 480px) {
  .main {
    height: auto;
  }
  .five__wrapper {
    max-width: 1720px;
    padding: 0 20px;
  }
  .five__block-img {
    display: none;
  }
  .five__description-title {
    font-size: 22px;
    padding-bottom: 7px;
    border-bottom: 1px solid black;
    margin-bottom: 25px;
  }
  .five__block-1 {
    flex-direction: column;
  }
  .five__block1-text {
    font-size: 14px;
    line-height: 5vw;
    padding: 10px 5px;
  }
  .five__block1-fz {
    font-size: 13px;
    line-height: 5vw;
    border-top: 1px solid black;
    width: 100%;
  }
  .five__img1 {
    width: calc(3px + 1008 * (100vw / 1280));
    display: none;
  }
  .five__img1-m {
    display: block;
    width: calc(3px + 1008 * (100vw / 1280));
  }
  .five__block-2 {
    font-size: 14px;
    line-height: 5vw;
  }
  .five__block-3 {
    font-size: 14px;
    line-height: 5vw;
    /* height: 525px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .five__block-4 {
    display: none;
  }

  .five__block3-wrap {
    flex-direction: column-reverse;
  }
  .five__block3-img {
    width: calc(3px + 920 * (100vw / 1280));
  }
}
</style>
<template>
<!-- <router-link :to="backPath"> -->
  <div class="modal-shadow" @click="closeModal" ></div>
<!-- </router-link> -->
  <div class="modall">
    <div class="closel" @click="closeModal">&#10006;</div>
    <slot name="title">
      <h3 class="modal-title">Заголовок</h3>
    </slot>
    <slot name="body">
      <div class="modal-content">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt, minus porro in nisi temporibus consequatur tempore id fugit ipsam nam excepturi ab libero saepe enim eaque consectetur cupiditate culpa unde!

      </div>
    </slot>
  </div>
</template>

<script>
import {useRouter} from 'vue-router'
export default {
    props: ["backPath"],
  name: "ModalWindow",
  setup(props) {
      const router = useRouter()
      const closeModal = function(){
          router.push(props.backPath)
          document.body.style.overflow = "auto"
      }
      return {
          closeModal
      }
  },
};
</script>

<style scoped lang="scss">
.modal-shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.modall {
  z-index: 111;
  position: fixed;
  top: 5%;
  left: 7%;
  font-size: 1.2vw;
  padding: 4vw;
/*  transform: translateX(-50%);
  transform: translate(-50%,-50%); 
  margin: 0 15px;
  max-width: 1200px;*/
  width: 85%;
  min-height: 500px;
  max-height: 90vh;
  background-color: white;
  overflow-x: clip;
  overflow-y: scroll;

  .closel {
    border-radius: 50%;
    color: rgb(0, 0, 0);
    // background: #2a4cc7;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1vw;
    right: 1vw;
    width: 3vw;
    height: 3vw;
    cursor: pointer;
    /*border: 1px solid red;*/
  }

  .modal-title {
    color: #0971c7;
    margin: 1rem;
  }

  .modal-content {
    margin: 1rem;
    margin-bottom: 20px;
    height: 50vh;
    overflow-y: auto;
  }

  &-footer {
    &__button {
      margin: 1rem;
      background-color: #0971c7;
      color: #fff;
      border: none;
      text-align: center;
      padding: 8px;
      font-size: 17px;
      font-weight: 500;
      border-radius: 8px;
      min-width: 150px;
    }
  }
}

@media (max-width: 1440px) {

  .modall {
    font-size: 1.5vw;
  }

}

@media (max-width: 480px) {

  .modall {
    font-size: 3.2vw;
  }

}
</style>
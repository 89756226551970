import { ref } from "vue";
import { environment } from '@/composables/realhost.js';

export default function useTrgs(){
    const trgsData = ref([]);
    const loader = ref(true);

    function fetchTrgs(year){
        loader.value = true
        // let year = (new Date()).getFullYear()
        // fetch("http://slimtest/trg/" + year)
        fetch(environment.realHost + "trg/" + year)
        .then(response => response.json())
        .then(data => {
            if(data.length > 0){
                trgsData.value = data
            } else {
                trgsData.value = false
            }
            loader.value = false
        })
    }
    
    return { fetchTrgs,  trgsData, loader }
}
<template>
  <main class="main">
    <back-images-comp />
    <section v-if="loader" class="eleven">
      <loader
        object="#ff9633"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular"
      ></loader>
    </section>
    <section v-else class="twelve">
      <div class="twelve__wrapper">
        <div class="twelve__block-top">
          <h1 class="twelve__title">Документы</h1>
        </div>
      </div>
    </section>
    <section class="thirteen">
      <div class="thirteen__wrapper">
        <div class="thirteen__items">
            <BlocksMenu
              v-for="data in docTypesData"
              :key="data.etkdocstype_id"
              :dirName="data.etkdocstype_title"
              :dirHref="`/documents/${data.etkdocstype_id}`"
              :dirAnnotation="data.etkdocstype_title"
            />
          </div>
        </div>
    </section>
    <section class="fourteen"></section>
    <section class="fifteen"></section>
  </main>
</template>

<script>
import useDocumentsTypes from "@/composables/documents/documentsTypes.js";
import { onMounted } from "vue";
import BackImagesComp from "../BackImagesComp.vue";
import BlocksMenu from "../BlocksMenu.vue";
export default {
  components: { BackImagesComp, BlocksMenu },
  name: "DocumentsTypes",
  setup() {
    const { docTypesData, fetchDocTypes, loader } = useDocumentsTypes();
    onMounted(() => {
      fetchDocTypes();
    });
    return { docTypesData, loader };
  },
};
</script>

<style scoped>
:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}



.twelve__block-top {
  width: 100%;
  max-width: 460px;
  /* height: 7vw; */
  margin: 3vw 5.5vw;
}
.twelve__title {
  font-size: calc(100vw / 35);
  color: #0071af;
  margin: 0;
}

/*--------------------------------THIRTEEN-----------------------------------*/

.thirteen {
  padding: 0 4.5vw;
}

.thirteen__items {
  width: 90%;
  max-width: 2945px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10vw;
}

/*----------------------------MOBILE---------------------------------*/

@media (max-width: 480px) {
  .twelve__title {
    font-size: calc(3px + 85 * (100vw / 1280));
  }

  .twelve__block-top {
    width: 80%;
    /* height: 55px; */
    margin: 5vw 8vw;
  }

  /*------------------------THIRTEEN-------------------------------------*/

  .thirteen__items {
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;
  }
}
</style>
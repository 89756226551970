<template>
  <h2 class="five__title">О нас</h2>
  <div class="five__items">
    <router-link to="/about/regulation" v-slot="{ isActive }">
      <div :class="{ active: isActive }" class="five__item">
        Государственное регулирование
      </div>
    </router-link>
    <router-link to="/about/structure" v-slot="{ isActive }">
      <div :class="{ active: isActive }" class="five__item">
        Организационная структура
      </div>
    </router-link>
    <router-link to="/tk113/about" v-slot="{ isActive }">
      <div :class="{ active: isActive }" class="five__item">
        Технический комитет ТК 113
      </div>
    </router-link>
    <router-link to="/about/MVS" v-slot="{ isActive }">
      <div :class="{ active: isActive }" class="five__item">
        Межведомственное взаимодействие
      </div>
    </router-link>
  </div>
</template>

<script>

export default {
  name: "AboutMenu",
  setup() {
    
  },
};
</script>

<style scoped>
.five__items a {
  text-decoration: none;
  width: 20%;
}

.five__title {
  font-size: calc(100vw / 35);
  color: #0071af;
  margin: 3vw 0;
}
.five__items {
  display: flex;
  justify-content: space-between;
  width: 75%;
}
.five__item:hover {
  color: black;
  border-left: 1px solid black;
  cursor: pointer;
}
.five__item.active {
  color: black;
  border-left: 1px solid black;
  cursor: pointer;
}
a .five__item {
  border-left: 1px solid black;
  padding: 0.5vw 0 0.5vw 1vw;
  color: #777474;
  cursor: pointer;
  font-size: calc(100vw / 70);
  /* width: 20%; */
}

@media (max-width: 480px) {
  .five__title {
    font-size: calc(100vw / 14);
    margin: 10px 0;
  }
  .five__items {
    width: 95%;
    flex-wrap: wrap;
    height: 135px;
  }
  .five__items a {
    max-width: 162px;
    width: 50%;
  }
  a .five__item {
    display: inherit;
    align-items: center;
    max-width: 162px;
    font-size: 14px;
    padding: 1vw 0 1vw 2vw;
    margin: 10px 0;
    width: auto;
    border-left: 1px solid #a6a4a4;
  }
}
@media (max-width: 375px) {
  .five__item {
    max-width: 145px;
  }
}
</style>
import { ref } from "vue"
import { environment } from "@/composables/realhost.js"

export default function actualisation() {
	const loader = ref(false)
	const loadermain = ref(false)
	const actualData = ref([])
	const actual = ref(false) //показывает блок с актуализацией
	const indicativ = ref(false) //показывает блок и индикативными показ
	const actualCount = ref(0)
	const indicativeCount = ref(0)
	const allCount = ref(0)

	function getActual(year) {
		loader.value = true
		fetch(environment.realHost + "actual/" + year)
			.then((response) => response.json())
			.then((data) => {
				actualData.value = data
				loader.value = false
				actual.value = true
				indicativ.value = false
			})
	}
	function getIndicative(year) {
		loader.value = true
		fetch(environment.realHost + "indicative/" + year)
			.then((response) => response.json())
			.then((data) => {
				actualData.value = data
				loader.value = false
				indicativ.value = true
				actual.value = false
			})
	}
	function getDates(d) {
		let result = ""
		if (d) {
			let date = new Date(Date.parse(d))
			let formatter = new Intl.DateTimeFormat("ru", {
				day: "numeric",
				month: "long",
			})
			result = formatter.format(date)
		}
		return result
	}
	function getCounts(year){
		loadermain.value = true
		fetch(environment.realHost + "trgsCount/" + year)
			.then((response) => response.json())
			.then((data) => {
				allCount.value = data[0].allcount
				actualCount.value = data[0].actualcount
				indicativeCount.value = data[0].indicativecount
				loadermain.value = false
			})
	}

	return {
		getActual,
		getIndicative,
		getDates,
		getCounts,
		loader,
		actualData,
		actual,
		indicativ,
		allCount,
		actualCount,
		indicativeCount,
		loadermain
	}
}

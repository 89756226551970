import { ref } from "vue";
import { environment } from '@/composables/realhost.js';

export default function useMeetings(){
    let meeting = ref({});
    let meetings = ref([]);
    // let isOpen = ref(false)

    function makeFileUrl(a){
        // return `http://burondt.ru/ETK/EtkMeetingsFilesDownload.php?UrlId=${a}`
        return `${environment.urlMakerHost}ETK/EtkMeetingsFilesDownload.php?UrlId=${a}`
    }

    function fetchMeetings(){
        // fetch("http://slimtest/tk113/meetings")
        fetch(environment.realHost + "tk113/meetings")
        .then(response => response.json())
        .then(data => {
            meetings.value = data
        })
    }

    function fetchMeeting(meetingId){
        fetch(`url/${meetingId}`)
        .then(response => response.json())
        .then(data => {
            meeting.value = data
        })
    }

    // function open(){
    //     isOpen.value = !isOpen.value
    // }


    return {fetchMeetings, fetchMeeting, meetings, meeting, makeFileUrl}
}
import { ref } from "vue";
import { environment } from '@/composables/realhost.js';

export default function useDiscussions(){
    const discussionsData = ref([]);
    const loader = ref(true);

    function fetchDiscussions(year){
        loader.value = true
        // let year = (new Date()).getFullYear()
        // fetch("http://slimtest/discussion/" + year)
        fetch(environment.realHost + "discussion/" + year)
        .then(response => response.json())
        .then(data => {
            if(data.length > 0){
                discussionsData.value = data
            } else {
                discussionsData.value = false
            }
            loader.value = false
        })
    }
    
    return { fetchDiscussions,  discussionsData, loader }
}
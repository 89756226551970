<template>
  <div class="its_menu_content_item">
   
<br><div class="seventeen__block3-content">
   <h2>2. Утверждение состава ТРГ</h2>
    <p>После окончания формирования <a href="http://burondt.ru/trg/2022">ТРГ</a> Бюро НДТ обрабатывает полученные заявки в течение 10 рабочих дней и направляет в Минпромторг России предложения по составам ТРГ. При формировании предложений по составу рабочих групп не учитываются заявки, в которых отсутствуют сведения об опыте работы в области, соответствующей области применения справочника, и квалификации представителей организации, подавшей заявку.</p>
<p>Минпромторг России рассматривает предложения по составу ТРГ и утверждает составы приказом. Электронная копия приказа размещается на официальном сайте Министерства в информационно-телекоммуникационной сети &laquo;Интернет&raquo; и сайте Бюро НДТ в течение 5 рабочих дней со дня его подписания.</p>
<p>В течение 30 рабочих дней со дня утверждения состава рабочей группы руководитель секретариата подготавливает и представляет на утверждение в Бюро предложения по установлению статуса членов рабочей группы.</p>
<p>Вся работа рабочих групп проходит в личных кабинетах ТРГ на портале Бюро НДТ.</p>
</div>
  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
</style>
import { ref } from "vue";
import { environment } from '@/composables/realhost.js';

export default function useAbout(){
    let about = ref([]);

    function makeUrl(a, b){
        // return `http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=${a}&etkstructure_id=${b}`
        return `${environment.urlMakerHost}NDT/NDTDocsDetail.php?UrlId=${a}&etkstructure_id=${b}`
    }

    function fetchAbout(){
        fetch(environment.realHost + "tk113/about")
        // fetch("http://slimtest/tk113/about")
        .then(response => response.json())
        .then(data => {
            about.value = data
        })
    }

    // function fetchMeeting(meetingId){
    //     fetch(`url/${meetingId}`)
    //     .then(response => response.json())
    //     .then(data => meeting.value = data)
    // }
    return {fetchAbout, about, makeUrl}
}
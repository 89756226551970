<template>
  <div class="its_menu_content_item">
<br><div class="seventeen__block3-content">
    <h2>7. Рассмотрение проекта справочника</h2>
<p>Разработчик справочника направляет в секретариат рабочей группы проект документа для размещения на согласование в личном кабинете ТРГ.&nbsp;</p>
<p>Члены рабочей группы рассматривают проект справочника и размещают замечания / предложения по его содержанию в информационной системе в соответствии с порядком формирования и осуществления деятельности рабочих групп, утвержденным уполномоченным органом.</p>
<p>Все предложения и замечания участников проекта собирают в сводку отзывов, после чего секретариат рабочей группы направляет её разработчику для доработки. После доработки документ направляется на голосование в личном кабинете ТРГ.</p>   
</div>    

  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}
</style>
<template>
  <section class="sixteen">
    <div class="sixteen__wrapper">
      <h1 class="sixteen__title-main">Технический комитет 113</h1>
      <div class="sixteen__items">
        <div class="sixteen__item">
          <router-link
            to="/tk113/about"
            v-slot="{ isActive }"
            ><span :class="{ active: isActive }"
              >Технический комитет 113</span
            ></router-link
          >
        </div>
        <div class="sixteen__item">
          <router-link
            to="/tk113/documents"
            v-slot="{ isActive }"
            ><span :class="{ active: isActive }"
              >Документы на разработке</span
            ></router-link
          >
        </div>
        <div class="sixteen__item">
        <router-link
          to="/tk113/meetings"
          v-slot="{ isActive }"
          ><span :class="{ active: isActive }">Заседания</span></router-link
        >
        </div>
        <div class="sixteen__item">
        <router-link
          to="/tk113/standarts"
          v-slot="{ isActive }"
          ><span :class="{ active: isActive }"
            >Система стандартов НДТ</span
          ></router-link
        >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TK113Menu",
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.sixteen__wrapper {
  /*width: 95%;
  max-width: 1720px;*/
  margin: 50px auto;
  height: auto;
}
.sixteen__title-main {
  color: #0073af;
  font-size: 72px;
  max-width: 950px;
}
.sixteen__items {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.sixteen__item a{
  color: #a6a4a4;
}
.sixteen__item {
  width: 21%;
  color: #a6a4a4;
  border-left: 1px solid #a6a4a4;
  padding: 0.5vw 0 0.5vw 1vw;
  font-size: calc(100vw / 70);
  text-decoration: none !important;
}
.sixteen__item a span:hover {
  color: black;
  /* border-left: 1px solid black; */
  cursor: pointer;
}
.sixteen__item a span.active {
  color: black;
  /* border-left: 1px solid black; */
  cursor: pointer;
}

.sixteen__wrapper {
  margin: 3vh 3vh;
  height: auto;
}
.sixteen__title-main {
  color: #0073af;
  font-size: calc(100vw / 35);
}
/*.sixteen__item {
  color: #a6a4a4;
  border-left: 1px solid #a6a4a4;
  padding: 1vh 3vh;
  font-size: calc(100vw / 70);
}*/
.sixteen__item:hover {
  color: black;
  border-left: 1px solid black;
  cursor: pointer;
}
.sixteen {
  padding: 0 50px;
}

@media (max-width: 1440px) {
 /* .sixteen__item {
    padding: 0 0 0 2vh;
  }*/
  /*.sixteen__items {
    width: 85%;
  }*/
}
@media (max-width: 480px) {
  .sixteen,
  .seventeen {
    padding: 0 1vh;
  }
  .sixteen__title-main {
    font-size: 29px;
    max-width: 300px;
  }

  .sixteen__items {
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 95%;
  }
  .sixteen__items a {
    font-size: 14px;
    max-width: 171px;
    width: 50%;
  }
  .sixteen__item {
    padding: 5px 15px;
    margin: 9px 0;
    font-size: 14px;
    max-width: 171px;
    width: 50%;
  }
}
</style>
<template>
  <main class="main">
    <BackImagesComp />
    <section class="five">
      <div class="five__wrapper">
        <PublicationsMenu />
        <section v-if="loader" class="no_info">
          <loader
            object="#ff9633"
            color1="#ffffff"
            color2="#17fd3d"
            size="5"
            speed="2"
            bg="#343a40"
            objectbg="#999793"
            opacity="80"
            name="circular"
          ></loader>
        </section>
        <section class="twentynine__publ" v-else>
          <div class="twentynine__wrapper">
            <div class="twentynine__items">
              <div
                class="twentynine__item"
                v-for="p in publicationsData"
                :key="p.id"
              >
                <div class="twentynine__item-wrapp">
                  <div class="twentynine__block1">
                    <!-- <div class="twentynine__bg-img" style="background: url('/img/20.png') no-repeat;"></div> -->
                    <img
                      class="twentynine__bg-img_publ"
                      :src="makePublicationPicUrl(p.publishing_id)"
                      alt=""
                    />
                    <!-- <img :src="'https://burondt.ru/assets/lib/' + n.stub" alt="" width="120"> -->
                  </div>
                  <div class="twentynine__block2">
                    <h2 class="twentynine__title">{{ p.publishing_name }}</h2>
                    <span class="twentynine__subtitle">{{
                      p.publishing_date
                    }}</span>
                    <!-- <router-link :to="`/news/${n.id}`" class="twentynine__name">{{
                  n.pagetitle
                }}</router-link> -->
                    <p class="twentynine__text">{{ p.publishing_source }}</p>
                  </div>
                </div>
                <div class="twentynine__block-btn">
                  <a :href="makePublicationFileUrl(p.publishing_id, getPicExtension(p.publishing_file))" download target="_blank" class="twentynine__btn-link">Скачать</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </main>
</template>

<script>
import { onMounted } from "vue";
import PublicationsMenu from "./PublicationsMenu.vue";
import BackImagesComp from "../BackImagesComp.vue";
import usePublications from "@/composables/publications/publications.js";
export default {
  name: "PublicationsCollections",
  components: { PublicationsMenu, BackImagesComp },
  setup() {
    const {
      fetchPublications,
      publicationsData,
      loader,
      makePublicationPicUrl,
      makePublicationFileUrl,
      getPicExtension
    } = usePublications();
    onMounted(() => {
      fetchPublications(2);
    });
    return {
      publicationsData,
      loader,
      makePublicationPicUrl,
      makePublicationFileUrl,
      getPicExtension
    };
  },
};
</script>

<style scoped>
.five__wrapper {
  margin: 0 auto;
  padding: 0 calc(100vw / 22);
}
.twentynine__publ {
  /* padding: 0 7vw; */
}
.twentynine__wrapper {
  margin: 3vh;
  margin-left: 0;
  margin-right: 0;
  /* margin-bottom: 10vw; */
  height: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 0.01vw 2vw;
  background: white;
}
/*.twentynine__title {
  font-size: calc(100vw/55);
  color: black;
  padding: 2vh 0;
  border-bottom: 1px solid black;
}*/
.twentynine__items {
  display: flex;
  flex-direction: column;
}
.twentynine__item {
  border-bottom: 0.3vh solid #bdbbba;
  padding-bottom: 2vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1vw;
}

.twentynine__item:last-child {
  margin-bottom: 10vh;
  border: none;
}
.twentynine__item-wrapp {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 3vh 0;
}
.twentynine__block1 {
  /* width: 17%; */
  padding-right: 2vw;
}
.twentynine__bg-img {
  /* width: 100%; */
}
.twentynine__block2 {
  width: 90%;
}
.twentynine__title {
  margin: 0;
  width: 100%;
}
.twentynine__subtitle {
  padding: 0.5vw 0;
  display: inline-block;
  color: #396371;
}
.twentynine__text {
  margin: 0;
}

.twentynine__block-btn {
  display: flex;
  justify-content: flex-end;
}
.twentynine__btn-link {
  background: #005f84;
  color: white;
  padding: 5px;
  text-decoration: none;
}
@media (max-width: 1440px) {
  .twentynine__block2 {
    /* font-size: 1.2vw;*/
  }
  .twentynine__item-wrapp {
    /*font-size: 1.2vw;*/
  }
  .twentynine__item {
    border-bottom: 0.1vh solid #bdbbba;
  }
  .twentynine__block1-h3 {
    font-weight: 500;
  }
  .twentynine__link-wrapp {
    margin: 0.7vw 0;
  }
}

@media (max-width: 480px) {
  .five__wrapper {
    max-width: 1720px;
    padding: 0 5px;
  }
  .twentynine__publ {
    padding: 0 1vw;
  }
  .twentynine__wrapper {
    padding: 20px;
    margin: 0 auto 7vh auto;
  }
  .twentynine__subtitle {
    padding: 2.5vw 0;
  }
  .twentynine__item {
    font-size: 3vw;
  }
  .twentynine__item-wrapp {
    width: 100%;
    height: auto;
    margin: 1vh 0;
    flex-direction: column;
  }
  .twentynine__block1 {
    width: 30%;
    padding: 0;
  }
  .twentynine__block2 {
    width: 100%;
  }
  .twentynine__title {
    width: auto;
  }
}
</style>
<template>
  <main class="main">
    <back-images-comp/>
    <MvsMenu />
    <section class="twentythree">
      <div class="twentythree__wrapper">
        <div v-if="meetings.length > 0" class="twentythree__items">
          <div
            class="twentythree__item"
            v-for="meeting in meetings"
            :key="meeting['meetings_id']"
          >
            <TK113Accordeon>
              <template v-slot:title>
                <div class="twentythree__prolapse1">
                  <span class="twentythree__text1">{{
                    meeting["etkmeetings_dateb"]
                  }}</span>
                  <a href="#" class="twentythree__text2">
                    {{ meeting["etkmeetings_name"] }}
                  </a>
                </div>
              </template>
              <template v-slot:content>
                <div class="twentythree__prolapse1">
                  <span class="twentythree__text1">место проведения</span>
                  <a href="#" class="twentythree__text2 text11">
                    {{ meeting["etkmeetings_place"] }}
                  </a>
                </div>
                <div class="twentythree__prolapse2">
                  <span class="twentythree__text3">материалы заседания</span>
                  <div class="twentythree__files-link">
                    <a
                      :href="makeFileUrl(file.etkmeetingsfiles_id)"
                      class="twentythree__file-link"
                      v-for="file in meeting['files']"
                      :key="file['etkmeetingsfiles_id']"
                    >
                      <img
                        src="/img/49.png"
                        alt=""
                        class="twentythree__img-link"
                      />
                      {{ file["etkmeetingsfiles_name"] }}
                    </a>
                  </div>
                </div>
              </template>
            </TK113Accordeon>
          </div>
        </div>
        <div v-else class="twentythree__items no_info">
          <loader
            object="#ff9633"
            color1="#ffffff"
            color2="#17fd3d"
            size="5"
            speed="2"
            bg="#343a40"
            objectbg="#999793"
            opacity="80"
            name="circular"
          ></loader>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import TK113Accordeon from "../tk113/TK113Accordeon";
import getMvsMeetingsData from "@/composables/mvs/mvsmeetings";
import MvsMenu from "./MvsMenu.vue";
import BackImagesComp from '../BackImagesComp.vue'
import { onMounted } from "vue";
export default {
  name: "MvsMeetings",
  components: {
    TK113Accordeon,
    MvsMenu,
    BackImagesComp
  },
  setup() {
    const { meetings, fetchMvsMeetings, makeFileUrl } = getMvsMeetingsData();
    onMounted(() => {
      fetchMvsMeetings();
    });
    return {
      meetings,
      fetchMvsMeetings,
      makeFileUrl,
    };
  },
};
</script>

<style scoped>

.twentythree {
  /*padding: 0 50px;*/
}
.twentythree__wrapper {
  margin: 3vh;
    width: 95%;
    margin: 0 auto;
    margin-bottom: 200px;
    height: auto;
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: 3vw;
    background: white;
}
.twentythree__items {
  display: flex;
  flex-direction: column;
}
.twentythree__item {
  border-bottom: 0.1vw solid #a6a4a4;
  padding-bottom: 20px;
  margin-top: 30px;
}
.twentythree__item:first-child {
  margin-top: 0;
}
.twentythree__item-wrapp {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.twentythree__block1 {
  font-size: calc(100vw / 105);
}
.twentythree__block1-h3 {
  margin: 0;
  padding: 0;
  padding-top: 0.7rem;
}
.twentythree__block2 {
  border: 1px solid red;
}
.twentythree__block2-img,
.twentythree__block2-img2 {
  height: calc(100vw / 60);
  cursor: pointer;
}

.twentythree__prolapse {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
}

.twentythree__prolapse1 {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.twentythree__prolapse2 {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: 10px;
}

.twentythree__text1,
.twentythree__text3 {
  color: #414141;
  font-size: 0.7vw;
  line-height: 1.5vw;
  font-weight: 500;
  text-transform: uppercase;
}
.twentythree__text2 {
  color: #414141;
  font-size: 1.1vw;
  font-weight: 600;
  text-decoration: none;
}
.text11 {
  font-weight: 400 !important;
}

.twentythree__files-link {
  display: flex;
  flex-direction: column;
}
.twentythree__file-link {
  color: #414141;
  font-size: 1.1vw;
  line-height: 1.5vw;
  font-weight: 400;
  text-decoration: none;
}
.twentythree__img-link {
  height: 18px;
}
.none {
  display: none;
}
@media (max-width: 480px) {
  .twentythree {
    padding: 0 1vw;
  }
  .twentythree__wrapper {
    padding: 20px;
    margin-bottom: 7vh;
  }
  .twentythree__item:last-child {
    border: none;
  }
  .twentythree__item {
    border-bottom: 1px solid #a6a4a4;
  }
  .twentythree__img-link {
    height: 11px;
  }
  .twentythree__block1-span {
    font-size: calc(3px + 30 * (100vw / 1280));
    line-height: 30px;
  }
  .twentythree__block1-h3 {
    font-size: calc(3px + 33 * (100vw / 1280));
    padding-top: 0;
  }
  .twentythree__block2-img,
  .twentythree__block2-img2 {
    height: calc(1px + 50 * (100vw / 1280));
  }
  .twentythree__prolapse {
    line-height: 20px;
  }
  .twentythree__prolapse1 {
    width: calc(3px + 870 * (100vw / 1280));
  }
  .twentythree__prolapse2 {
    width: calc(3px + 870 * (100vw / 1280));
  }
  .twentythree__text1,
  .twentythree__text3 {
    font-size: calc(3px + 25 * (100vw / 1280));
    line-height: 20px;
  }
  .twentythree__text2 {
    font-size: calc(1px + 40 * (100vw / 1280));
    
  }
  .twentythree__file-link {
    font-size: calc(3px + 30 * (100vw / 1280));
    /*text-decoration: revert;*/
    line-height: 20px;
  }
}
</style>
<template>
  <main class="main">
    <back-images-comp />
    <YearsMenu :page="page" :title="title" />
    <section v-if="loader" class="eleven">
      <loader
        object="#ff9633"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular"
      ></loader>
    </section>
    <section v-else class="seven">
      <div class="seven__wrapper">
        <div class="seven__head-block">
          <h2 class="seven__head-title">{{ trgData.etkstructure_num }}</h2>
          <h3 class="seven__head-subtitle">
            {{ trgData.etkstructure_title }}
          </h3>
        </div>
        <TrgSubMenu />
        <div class="seven__wrapp">


        <div class="seven__block-3" v-if="trgData.etkstructure_id == 1922">
          <div class="seven__block-3">
            <h3 class="seven__block3-subtitle">
              
            </h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg1.jpg" alt="" />
                <p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующий основной вид деятельности:<br>
                - древесно-подготовительное производство (ДПП);<br>
                - производство сульфатной целлюлозы (СФА);<br>
                - производство механических видов древесной массы:<br>
                - дефибрерная древесная масса (ДДМ);<br>
                - термомеханическая масса (ТММ);<br>
                - химико-механическая масса (ХММ);<br>
                - химико-термомеханическая масса (ХТММ);<br>
                - производство бумаги, в том числе с использованием регенерированного волокна из макулатуры;<br>
                - производство картона, в том числе с использованием регенерированного волокна из макулатуры.</p>
            </div>
                <p>Справочник НДТ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды: <br>
                - подготовка сырья;<br>
                - хранение и подготовка топлива (черный щелок, коро-древесные отходы);<br>
                - производственные процессы вспомогательных производств (приготовление химикатов для отбелки, регенерация известкового шлама, очистка сточных вод).</p>

                <p>Справочник НДТ не распространяется на:<br>
                - производство упаковки из бумаги и картона; <br>
                - процессы лесозаготовительных работ.</p>

                <p><!--*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.--></p>
               </div>            
        </div>


        <div class="seven__block-3" v-if="trgData.etkstructure_id == 1923">
          <div class="seven__block-3">
            <h3 class="seven__block3-subtitle">
              
            </h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg6.jpg" alt="" />
                <p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующий основной вид деятельности:<br>
                - производство цементного клинкера во вращающихся печах с проектной мощностью 500 т/сут и более.<br>
                Также ИТС НДТ распространяется на процессы, связанные с основными производственными переделами цементного производства, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды:<br>
                - хранение и подготовка сырья;<br>
                - хранение и подготовка топлива;<br>
                - использование отходов в качестве сырья и (или) топлива — требования к качеству, контроль и подготовка; - производственные процессы;<br>
                - методы предотвращения и сокращения эмиссий и образования отходов;<br>
                - хранение, упаковка и отгрузка продукции.</p>
            </div>
                <p>Документ не распространяется на:<br>
                - некоторые процессы производства, такие как добыча сырья в карьере;<br>
                - вопросы, касающиеся исключительно обеспечения промышленной безопасности или охраны труда.<br>
                Вопросы охраны труда рассматриваются частично и только в тех случаях, когда оказывают влияние на виды деятельности, включенные в область применения данного ИТС НДТ.<br>
                <br><!--*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.--></p>
               </div>            
        </div>

          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1924">
            <h3 class="seven__block3-subtitle">
              
            </h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg7.jpg" alt="" />
              <p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:<br>
              - производство негашеной, гашеной и гидравлической извести;<br>
              - производство прочей неметаллической минеральной продукции; <br>
              - производство цемента, извести и гипса. <br>
              Также ИТС НДТ распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды: <br>
              - хранение и подготовка сырья; <br>
              - хранение и подготовка топлива; <br>
              - производственные процессы; <br>
              - методы предотвращения и сокращения эмиссий и образования отходов;<br>
              - хранение и подготовка продукции.</p>
            </div>
              <p>Документ не распространяется на:<br>
              - добычу сырья на месторождениях; <br>
              - вопросы, касающиеся исключительно обеспечения промышленной безопасности или охраны труда. <br>
              Вопросы охраны труда рассматриваются частично и только в тех случаях, когда они оказывают влияние на виды деятельности, включенные в область применения данного ИТС НДТ.<br>
              <br><!--*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.--></p>
          </div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1925">
            <h3 class="seven__block3-subtitle">
              
            </h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg8.jpg" alt="" />
<p>Данный межотраслевой («горизонтальный») информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) разработан во взаимосвязи с отраслевыми («вертикальными») ИТС НДТ и включает в себя описание универсальных подходов и методов, применимых при очистке сточных вод на предприятиях, относящихся к областям применения НДТ.<br>
Документ носит методический характер и содержит обобщённую информацию, сведения общего характера, общие подходы к межотраслевым технологиям, техническим и управленческим решениям по очистке сточных вод.<br> 
ИТС НДТ не содержит перечней маркерных веществ для каких-либо отраслей промышленности.<br> Рекомендации, содержащиеся в настоящем ИТС НДТ, подлежат применению в случае отсутствия соответствующих рекомендаций в отраслевом («вертикальном») ИТС НДТ, к области применения которого относится рассматриваемое предприятие (объект). </p>
            </div>
<p>В рамках ИТС НДТ особо рассматриваются технологии очистки сточных вод на предприятиях приоритетных областей применения НДТ применительно к целям данного ИТС НДТ, в которых:<br> 
- объёмы образования сточных вод сравнительно велики;<br> 
- проблемы, связанные с обращением со сточными водами, достаточно специфичны, однако обладают и общими с другими отраслями характеристиками.<br> 
В ИТС НДТ детально не рассматриваются технологии очистки сточных вод на тех предприятиях:<br>
- основной целью которых является обеспечение национальной и международной безопасности либо единственной целью которых является защита объектов и (или) территорий от природных катастроф;<br> 
- очистка сточных вод которых осуществляется исключительно с помощью универсальных НДТ очистки сточных вод, приведённых в разделе 4, и (или) НДТ, специфичных для одной или нескольких областей применения НДТ, рассмотренных в разделе 5, и (или) предприятия, состав сточных вод которых максимально приближен к составу хозяйственно-бытовых сточных вод;<br>
- очистка специфических сточных вод которых, по имеющейся информации, подробно рассматривается в соответствующих отраслевых («вертикальных») справочниках;<br> 
- обращение которых со сточными водами и (или) с загрязняющими веществами, содержащимися в таких сточных водах, регулируется специальным законодательством; <br>
- основной целью которых является обеспечение энергетической безопасности населения и предприятий Российской федерации путем бесперебойного энергоснабжения электрической и тепловой энергией в режиме комбинированного производства.<br> 
ИТС НДТ подлежит применению на объектах, оказывающих негативное воздействие на окружающую среду и отнесенных к объектам I и II категории.<br>
<br><!--*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.--></p>
          </div>      
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1926">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg32.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности: <br>
- производство синтетических каучуков, получаемых методом растворной и эмульсионной полимеризации;<br>
- производство синтетических каучуков специального назначения; <br>
- производство термоэластопластов (ТЭП);<br>
- производство полиэтилена; <br>
- производство полипропилена; <br>
- производство полистирола; <br>
- производство АБС-пластиков; <br>
- производство поливинилхлорида (ПВХ); <br>
- производство полиэтилентерефталата (ПЭТФ); <br>
- производство высокомолекулярных соединений специального назначения, в том числе поликарбонатов, полиамидов, полиуретанов, фторопласты, сэвилена (сополимера винилацетата с этиленом), биоразлагаемых полимеров; <br>
- производство латексов. </p>
</div>
<p>Также документ распространяется на следующие процессы, связанные с основными видами деятельности, которые могут оказывать влияние на объемы эмиссий или масштабы загрязнения окружающей среды: <br>
- хранение и подготовка сырья; <br>
- производственные процессы; <br>
- методы предотвращения и сокращения эмиссий, образования и размещения отходов.<br> 
При рассмотрении каждой отдельной технологии в рамках данного ИТС НДТ при определении НДТ учитываются удельные уровни эмиссии и удельные расходы потребления сырья и энергоресурсов.<br> При этом, основываясь на логике принципов НДТ, можно сказать, что оптимальными показателями будут обладать существующие или проектируемые производства, где применяются наилучшие решения, в том числе в обращении со сточными водами, выбросами в атмосферу и отходами.<br>
<br><!--*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.-->
</p>
</div>


        <div class="seven__block-3" v-if="trgData.etkstructure_id == 1928">
          <div class="seven__block-3">
            <h3 class="seven__block3-subtitle">
              
            </h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg53.jpg" alt="" />
                <p>Цель создания информационно-технического справочника наилучших доступных технологий «Ликвидация объектов накопленного вреда окружающей среде» (далее – справочник НДТ) заключается в систематизации сведений о технологических процессах, методах, способах, оборудовании и средствах, применяемых при ликвидации объектов накопленного вреда окружающей среде в Российской Федерации и в подготовке упорядоченных данных о наилучших доступных технологиях, технологических и организационных решениях ликвидации объектов накопленного вреда окружающей среде, применяемых в Российской Федерации и направленных на комплексное предотвращение и (или) минимизацию негативного воздействия на окружающую среду.</p>
            </div>
                <p>Настоящий справочник НДТ содержит систематизированные данные в области ликвидации объектов накопленного вреда окружающей среде и разработан с учетом имеющихся в Российской Федерации технологий, оборудования, ресурсов, а также с учетом климатических, геоморфологических, геологических, экономических и социальных особенностей Российской Федерации.</p>
                <p>*Завершение разработки информационно-технический справочник по наилучшим доступным технологиям планируется на конец 2022 года.</p>
               </div>            
          </div>



          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1930">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg5.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности: <br>
- производство листового стекла; <br>
- производство тарного стекла; <br>
- производство сортового стекла;<br>
- производство стекловолокна;<br>
 - производство силиката натрия растворимого. </p>
</div>
<p>Документ распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на ресурсоэффективность, характер и масштаб воздействия на окружающую среду: <br>
- хранение и подготовка сырья; <br>
- производственные процессы; <br>
- методы предотвращения и сокращения эмиссии и образования отходов.<br>
Данный ИТС НДТ не распространяется на: <br>
- некоторые процессы производства, такие как добыча сырья в карьере; <br>
- вопросы, касающиеся исключительно обеспечения промышленной безопасности или охраны труда.<br>
<br><!--*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.--></p>
</div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1927">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/48-23.jpg" alt="" />
<p>Данный межотраслевой («горизонтальный») информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) разработан во взаимосвязи с отраслевыми («вертикальными») ИТС НДТ и включает в себя описание общих подходов и методов повышения энергетической эффективности производства, которые могут применяться в первую очередь на предприятиях, относящихся к объектам I категории.<br>
Документ носит методический характер и содержит обобщённую информацию, сведения общего характера, общие подходы к методам и управленческим решениям, применяемым для повышения энергетической эффективности при осуществлении хозяйственной и (или) иной деятельности на предприятиях (объектах) I категории. </p>
</div>
<p>ИТС НДТ не содержит технологических показателей для каких-либо отраслей промышленности. Рекомендации, содержащиеся в настоящем межотраслевом («горизонтальном») документе, подлежат применению в дополнение к рекомендациям отраслевых справочников НДТ, а также в случае отсутствия соответствующих сведений об особенностях энергетического аудита и систем энергетического менеджмента, характерных для отрасли, в соответствующем отраслевом («вертикальном») ИТС НДТ, к области применения которого относится рассматриваемое предприятие (объект). <br>
В силу «горизонтального» характера документа конкретные технические решения по отраслям промышленности, перечни маркерных веществ, а также диапазоны значений технологических показателей могут быть приведены в соответствующих отраслевых «вертикальных» ИТС НДТ.<br>
Описанные ИТС НДТ управленческие методы повышения энергетической эффективности могут применяться на предприятиях различных категорий негативного воздействия на окружающую среду.<br>
<br>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1936">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/37-23.jpg" alt="" />
<p>Настоящий справочник НДТ распространяется на следующие основные виды деятельности:</p>
<p>- добычу угля, включая добычу каменного угля, антрацита и бурого угля (лигнита);</p>
<p>- обогащение угля (как отдельный производственный процесс).</p>
<p>Также данный справочник НДТ распространяется на дополнительные виды деятельности, являющиеся неотъемлемой частью технологического процесса и/или целью которых является подготовка угля к реализации (виды работ для получения продукции, пригодной для сбыта):</p>
<p>- хранение, перевалка и транспортировка угля в границах территории промышленной площадки добывающего и (или) перерабатывающего предприятия;</p>
<p>- дробление, очистка, сушка, брикетирование, сортировка и прочие подготовительные и сопутствующие операции на этапах добычи и обогащения угля.</p>
</div>
<p>Перечисленные виды работ обычно выполняются хозяйствующими субъектами, которые сами занимаются добычей угля и/или расположены в районе добычи. Некоторые технологические процессы могут также осуществляться специализированными предприятиями по заказу третьих сторон в качестве производственных услуг.</p>
<p>Данный справочник НДТ также распространяется на процессы и методы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий и (или) масштабы загрязнения окружающей среды:</p>
<p>- методы предотвращения и сокращения выбросов и сбросов загрязняющих веществ и образования отходов;</p>
<p>- хранение и транспортировка в границах территории промышленной площадки добывающего и (или) перерабатывающего предприятия пустой породы и хвостов обогащения.</p>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1937">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/41-23.jpg" alt="" />
<p>Настоящий справочник НДТ распространяется на следующие основные виды экономической деятельности на объектах, оказывающих негативное воздействие на окружающую среду и отнесенных к объектам I и II категории в соответствии с критериями, утвержденными постановлением Правительства Российской Федерации от 28 сентября 2015 № 1029:</p>
<p>- разведение свиней.</p>
<p>Разведение свиней относится в соответствии с общероссийским классификатором видов экономической деятельности к разделу 01.4 &mdash; Животноводство.</p>
</div>
<p>Настоящий справочник НДТ не распространяется на вопросы, относящиеся исключительно к обеспечению промышленной безопасности или охране труда.</p>
<p>Вопросы охраны труда рассматриваются частично и только в тех случаях, когда они оказывают непосредственное влияние на виды деятельности, включенные в область применения настоящего справочника НДТ.</p>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1938">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/42-23.jpg" alt="" />
<p>Настоящий справочник НДТ распространяется на объекты, оказывающие негативное воздействие на окружающую среду и отнесенные к объектам I категории в соответствии с критериями, утвержденными постановлением Правительства</p>
<p>Российской Федерации от 28 сентября 2015 № 1029:</p>
<p>- разведение сельскохозяйственной птицы.</p>
<p>Разведение сельскохозяйственной птицы относится в соответствии с общероссийским классификатором видов экономической деятельности к разделу</p>
<p>01.4 &mdash; Животноводство.</p>
</div>
<p>Эта группировка включает:</p>
<p>- выращивание и разведение сельскохозяйственной птицы: кур, индеек, уток, гусей и цесарок;</p>
<p>- производство яиц сельскохозяйственной птицы;</p>
<p>- деятельность инкубаторов для птицеводства.</p>
<p>Эта группировка не включает:</p>
<p>- производство пера и пуха.</p>
<p>Настоящий справочник НДТ не распространяется на вопросы, относящиеся исключительно к обеспечению промышленной безопасности или охране труда.</p>
<p>Вопросы охраны труда рассматриваются частично и только в тех случаях, когда они оказывают непосредственное влияние на виды деятельности, включенные в область применения настоящего справочника НДТ.</p>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1939">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/43-23.jpg" alt="" />
<p>Настоящий справочник НДТ распространяется на следующие основные виды экономической деятельности без ограничений по производственной мощности:</p>
<p>- технология убоя и первичной переработки крупного рогато скота;</p>
<p>- технология убоя и первичной переработки свиней;</p>
<p>- технология убоя и первичной переработки овец и коз;</p>
<p>- особенности технологической переработки лошадей, северных оленей, ослов, мулов, лошаков;</p>
<p>- технология убоя и первичной переработки птицы;</p>
<p>- переработка и хранение побочных продуктов убоя</p>
<p>- очистка сточных вод.</p>
</div>
<p>Производства указанных продуктов относятся в соответствии с Общероссийским классификатором видов экономической деятельности к &laquo;производству пищевых продуктов&raquo;.</p>
<p>Справочник НДТ также распространяется на технологические процессы, связанные с основными видами деятельности, которые могут оказать или оказывают влияние на объемы (массы) эмиссий в окружающую среду или на масштабы загрязнения окружающей среды:</p>
<p>- переработка и хранение вторичного сырья;</p>
<p>- хранение и подготовка топлива;</p>
<p>- производственные процессы;</p>
<p>- дезинфекция, дезинсекция, дератизация, дезакаризация производственных объектов;</p>
<p>- методы предотвращения и сокращения эмиссий и образования отходов;</p>
<p>- упаковка и хранение продукции.</p>
<p>Справочник НДТ не распространяется на:</p>
<p>- выращивание сельскохозяйственных животных в хозяйствах;</p>
<p>- вопросы, относящиеся исключительно к обеспечению промышленной безопасности или охране труда.</p>
<p>Вопросы охраны труда рассматриваются частично и только в тех случаях, когда они оказывают непосредственное влияние на виды деятельности, включенные в область применения настоящего справочника НДТ.</p>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1940">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/44-23.jpg" alt="" />
<p>Настоящий справочник НДТ распространяется на следующие основные виды деятельности:</p>
<ul>
<p>1 Переработка и консервирование мяса и мясной пищевой продукции</p>
<p>13 Производство продукции из мяса убойных животных и мяса птицы</p>
<p>13.1 Производство соленого, вареного, запеченного, копченого, вяленого и прочего мяса</p>
<p>13.2 Производство колбасных изделий</p>
<p>13.3 Производство мясных (мясосодержащих) консервов</p>
<p>13.4 Производство мясных (мясосодержащих) полуфабрикатов</p>
<p>13.6 Производство прочей пищевой продукции из мяса или мясных пищевых субпродуктов</p>
<p>3 Переработка и консервирование фруктов и овощей</p>
<p>39 Прочие виды переработки и консервирования фруктов и овощей</p>
<p>4 Производство растительных и животных масел и жиров</p>
<p>41.2 Производство нерафинированных растительных масел</p>
<p>41.5 Производство рафинированных растительных масел</p>
<p>41.6 - Производство гидрогенизированных и переэтерифицированных животных и растительных жиров и масел и их фракций</p>
<p>42 Производство маргариновой продукции</p>
<p>62.2 Производство нерафинированного кукурузного масла</p>
<p>62.3 Производство рафинированного кукурузного масла</p>
<p>8 Производство прочих пищевых продуктов</p>
<p>81 Производство сахара</p>
</ul>
</div>
<p>Справочник НДТ не распространяется на:</p>
<ul>
<p>11.1 Производство мяса в охлажденном виде;</p>
<p>11.2 Производство пищевых субпродуктов в охлажденном виде;</p>
<p>11.3 Производство мяса и пищевых субпродуктов в замороженном виде;</p>
<p>11.5 Производство животных жиров;</p>
<p>12.1 Производство мяса птицы в охлажденном виде;</p>
<p>12.2 Производство мяса птицы в замороженном виде;</p>
<p>12.3 Производство жиров домашней птицы;</p>
<p>12.4 Производство субпродуктов домашней птицы, пригодных для употребления в пищу;</p>
<p>32 - Производство соковой продукции из фруктов и овощей</p>
<p>39 Производство обжаренных орехов; производство пасты и прочих продуктов питания из орехов; производство скоропортящихся полуфабрикатов из фруктов и овощей, таких как: салаты, упакованные смешанные салаты, очищенные или нарезанные овощи;</p>
<p>вопросы, касающиеся исключительно обеспечения промышленной безопасности или охраны труда.</p>
</ul>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1941">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/45-23.jpg" alt="" />
<p>Настоящий справочник НДТ распространяется на следующие виды деятельности:</p>
<p>- производство молока и молочной продукции (с проектной мощностью 200 тонн перерабатываемого молока в сутки (среднегодовой показатель) и более);</p>
<p>- производство мороженого (с проектной мощностью 100 тонн готовой продукции в сутки).</p>
<p>Справочник НДТ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды:</p>
<p>- приготовление и использование моющих и дезинфицирующих средств.</p>
</div>
<p>Справочник НДТ не распространяется на:</p>
<p>- процесс получения молока сырого.</p>
<p>Вопросы охраны труда рассматриваются частично и только в тех случаях, когда оказывают влияние на виды деятельности, включенные в область применения настоящего справочника НДТ.</p>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1942">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/4-23.jpg" alt="" />
<p>Настоящий справочник НДТ распространяется на производство керамических изделий, в том числе следующие основные виды деятельности:</p>
<p>- производство керамических строительных материалов (кирпича, плиток и плит керамических);</p>
<p>- производство огнеупорных изделий;</p>
<p>- производство санитарно-технических изделий из керамики;</p>
<p>- производство электрических изоляторов из керамики;</p>
<p>- производство керамических хозяйственных и декоративных изделий (посуды).</p>
<p>В приложении А приведены соответствующие области применения коды ОКВЭД и ОКПД 2.</p>
</div>
<p>Справочник НДТ распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на ресурсоэффективность, характер и масштаб воздействия на окружающую среду:</p>
<p>- хранение и подготовка сырья;</p>
<p>- производственные процессы;</p>
<p>- методы предотвращения и сокращения эмиссий и образования отходов.</p>
<p>Ограниченные сведения приведены также для таких процессов, как:</p>
<p>- хранение и подготовка топлива (в случае использования твердого топлива);</p>
<p>- хранение и подготовка продукции.</p>
<p>Справочник НДТ не распространяется на:</p>
<p>- некоторые процессы производства, такие как добыча сырья в карьере и транспортировка сырьевых материалов с карьера на склад производства;</p>
<p>- вопросы, касающиеся исключительно обеспечения промышленной безопасности или охраны труда.</p>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1943">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/39-23.jpg" alt="" />
<p>В настоящем справочнике НДТ описаны процессы производств первичной обработки натуральных волокон (шерсти, льна, шелка), подготовительных производств, предшествующих стадии прядения, а также процессы отделочных производств, обеспечивающих облагораживание суровых тканей с получением требуемых показателей качества и потребительских свойств.</p>
<p>Настоящий справочник НДТ распространяется на виды деятельности производств, сопряженные с влажностными операциями и обработками волокон, полуфабрикатов (ровницы, ленты, пряжи), тканей, трикотажных полотен и текстильных изделий:</p>
</div>
<p>- первичная обработка шерсти (ПОШр);</p>
<p>- первичная обработка шелка (ПОШл);</p>
<p>- первичная обработка льна и котонизация (ПОЛиК);</p>
<p>- подготовительное производство льняной и льносодержащей ровницы к прядению (ПЛР);</p>
<p>- ватное производство (ВП);</p>
<p>- подготовительное и красильное производство нитей и пряжи (КПНиП);</p>
<p>- отделочное производство хлопчатобумажных тканей (ОПХБ);</p>
<p>- отделочное производство шелковых тканей (ОПШл);</p>
<p>- отделочное производство льносодержащих тканей (ОПЛ);</p>
<p>- подготовительное и красильное производство химических и шерстяных волокон в массе (ПКПХШр);</p>
<p>- отделочное производство шерстяных камвольных тканей (ОПШрК);</p>
<p>- отделочное производство шерстяных суконных тканей (ОПШрСТ);</p>
<p>- унифицированное отделочное производство тканей различного сырьевого состава (УОП);</p>
<p>- отделочное производство трикотажных полотен (ОПТП);</p>
<p>- отделочное производство текстильных изделий (ОПТИ).</p>
<p>Производство указанных продуктов относится в соответствии с общероссийским классификатором видов экономической деятельности к производству текстильных изделий.</p>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1944">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/47-23.jpg" alt="" />
<p>Настоящий справочник НДТ распространяется на системы обработки (обращения) со сточными водами и отходящими газами в химической промышленности, в том числе при производстве:</p>
<p>- основных органических химических веществ;</p>
<p>- продукции тонкого органического синтеза;</p>
<p>- полимеров;</p>
<p>- основных неорганических химических веществ;</p>
<p>- неорганических кислот, минеральных удобрений;</p>
<p>- твердых и других неорганических химических веществ;</p>
<p>- специальных неорганических химикатов;</p>
<p>- прочих основных неорганических химических веществ.</p>
</div>
<p>Справочник НДТ рассматривает способы и системы обработки (обращения) сточных вод и условно чистых вод, газообразных выделений, отходящих газов (в соответствии с определениями, приведенными во введении и приложении А), образующихся при производстве различной химической продукции, а также рассматриваются способы организационно-управленческого и технологического характера. Настоящий справочник НДТ разработан в соответствии с распоряжением Правительства Российской Федерации от 31 октября 2014 г. № 2178-р и включает в себя описание универсальных подходов и способов, применимых при производстве химической продукции на предприятиях, относящихся к областям применения НДТ, определенным распоряжением Правительства Российской Федерации от 24 декабря 2014 г. № 2674-р.</p>
<p>Настоящий справочник НДТ охватывает следующие аспекты хозяйственной деятельности:</p>
<p>- системы экологического менеджмента;</p>
<p>- производственный экологический контроль (ПЭК);</p>
<p>- решения по снижению потребления исходной (природной) воды;</p>
<p>- системы управления сточными водами, их сбор и обработку;</p>
<p>- системы управления выбросами в атмосферу, их сбор и обработку;</p>
<p>- термические способы обработки отходящих газов.</p>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
         <div class="seven__block-3" v-if="trgData.etkstructure_id == 1945">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/25-23.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности, определяемые в соответствии с общероссийским классификатором видов экономической деятельности (ОКВЭД 2) ОК 029-2014 (КДЕС Ред. 2) и в соответствии с общероссийским классификатором продукции по видам экономической деятельности (ОКПД 2) ОК 034-2014 (КПЕС 2008) (приняты и введены в действие приказом Федерального агентства по техническому регулированию и метрологии от 31 января 2014 года № 14):</p>
</div>
<p>а) добыча и обогащение железных руд, включая:</p>
<p>1) открытую добычу железных руд;</p>
<p>2) подземную добычу железных руд;</p>
<p>3) обогащение железных руд;</p>
<p>б) окускование, производство железорудных окатышей;</p>
<p>в) производство железа прямого восстановления;</p>
<p>г) дополнительные виды деятельности с целью подготовки железорудного сырья: дробление, измельчение, классификация, фильтрование, сушка и сортировка железных руд, окомкование.</p>
<p>Документ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий и (или) масштабы загрязнения окружающей среды:</p>
<p>- процессы, использующие методы предотвращения и сокращения эмиссий загрязняющих веществ и парниковых газов, утилизации отходов;</p>
<p>- хранение и транспортировка железорудного сырья.</p>
<p>ИТС НДТ не распространяется на:</p>
<p>- производство черных металлов;</p>
<p>- деятельность, которая касается исключительно обеспечения промышленной безопасности или охраны труда.</p>
<p>Вопросы охраны труда рассматриваются частично и только в тех случаях, когда оказывают влияние на виды деятельности, включенные в область применения настоящего справочника НДТ.</p>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
         <div class="seven__block-3" v-if="trgData.etkstructure_id == 1946">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/27-23.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:</p>
<p>- производство горячего проката;</p>
<p>- производство холоднокатаного проката;</p>
<p>- производство длинномерной продукции;</p>
<p>- производство горячекатаных труб;</p>
<p>- производство горячепрессованных труб;</p>
<p>- производство холоднодеформированных труб;</p>
</div>
<p>- производство прямошовных труб, изготовленных электродуговой сваркой под слоем флюса (ТБД);</p>
<p>- производство электросварных труб;</p>
<p>- производство труб непрерывной печной сваркой;</p>
<p>- производство труб сваркой в среде инертных газов;</p>
<p>- производство труб с покрытием.</p>
<p>Документ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий и (или) масштабы загрязнения окружающей среды: методы предотвращения и сокращения эмиссий и образования отходов.</p>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
         <div class="seven__block-3" v-if="trgData.etkstructure_id == 1947">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/18-23.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:</p>
<p>&ndash; производство низших олефиновых и диеновых углеводородов, в частности, производство этилена, пропилена, изобутилена, бутадиена и изопрена;</p>
<p>&ndash; производство ароматических соединений, в частности, бензола, этилбензола, стирола, изопропилбензола, &alpha;-метилстирола, фенола и ацетона;</p>
</div>
<p>&ndash; производство кислородсодержащих соединений, в частности, производство метанола, формальдегида, оксида этилена и этиленгликолей, оксида пропилена, метилтретбутилового, метил-трет-амилового и метил-втор-амиленового эфиров, этанола, бутиловых спиртов, 2-этилгексанола, акриловой кислоты и ее эфиров, терефталевой кислоты, диоктилтерефталата, ацетатов, ацетилена, ацетальдегида, уксусной кислоты;</p>
<p>&ndash; производство хлорсодержащих органических веществ, в частности, производство дихлорэтана и винилхлорида;</p>
<p>&ndash; производство азотсодержащих органических веществ, в частности, производство ацетатов, нитрила акриловой кислоты, аминов, меламина, капролактама.</p>
<p>ИТС НДТ распространяется на производство органических веществ из ископаемого углеродсодержащего сырья и из возобновляемых источников. Документ также распространяется на следующие процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды:</p>
<p>&ndash; хранение и подготовку сырья;</p>
<p>&ndash; методы предотвращения и сокращения эмиссий, образования и размещения отходов.</p>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
         <div class="seven__block-3" v-if="trgData.etkstructure_id == 1948">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/16-23.jpg" alt="" />
<p>Настоящий справочник НДТ распространяется на следующие основные виды деятельности, определяемые в соответствии с общероссийским классификатором видов экономической деятельности (ОКВЭД 2) ОК 029&mdash;2014 (КДЕС Ред. 2) (принят и введен в действие приказом Федерального агентства по техническому регулированию и метрологии от 31 января 2014 г. № 14-ст):</p>
<p>- добыча полезных ископаемых, включая:</p>
<p>- железные руды;</p>
<p>- руды цветных металлов: алюминия (боксита), меди, свинца, цинка, олова, марганца, хрома, никеля, кобальта, молибдена, тантала, ванадия;</p>
<p>- руды драгоценных металлов (золота, серебра, платины);</p>
<p>- уголь, включая добычу каменного угля, антрацита и бурого угля (лигнита).</p>
<p>- дополнительные виды деятельности с целью подготовки сырья к реализации: дробление, измельчение, очистка, просушка, сортировка и обогащение соответствующих полезных ископаемых.</p>
</div>
<p>Согласно положениям раздела В &laquo;Добыча полезных ископаемых&raquo; ОКВЭД 2, &laquo;перечисленные виды работ обычно выполняются хозяйствующими субъектами, которые сами занимаются добычей полезных ископаемых и/или расположены в районе добычи полезных ископаемых&raquo;. Таким образом, в части обогащения углей, обогащения и подготовки руд черных, цветных и драгоценных металлов настоящий справочник НДТ распространяется на методы и технологии, применяемые непосредственно на месторождениях соответствующих полезных ископаемых.</p>
<p>Настоящий справочник НДТ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий и (или) масштабы загрязнения окружающей среды:</p>
<p>- методы предотвращения и сокращения эмиссий и образования отходов;</p>
<p>- хранение и транспортировка продукции, пустой породы и хвостов обогащения.</p>
<p>Настоящий справочник НДТ не распространяется на:</p>
<p>- добычу и обогащение руд и песков драгоценных металлов, оловянных руд, титановых руд, хромовых руд на рассыпных месторождениях;</p>
<p>- обогащение руд черных и цветных металлов (как отдельный производственный процесс);</p>
<p>- производство черных и цветных металлов;</p>
<p>- производство первичных и вторичных драгоценных металлов;</p>
<p>- добычу урановой и ториевой руд, обогащение урановых и ториевых руд, производство ядерного топлива;</p>
<p>- добычу нерудных полезных ископаемых;</p>
<p>- вопросы, которые касаются исключительно обеспечения промышленной безопасности или охраны труда.</p>
<p>Вопросы обеспечения промышленной безопасности и охраны труда частично рассматриваются только в тех случаях, когда оказывают влияние на виды деятельности, включенные в область применения настоящего справочника НДТ.</p>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
         <div class="seven__block-3" v-if="trgData.etkstructure_id == 1949">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/49-23.jpg" alt="" />
<p>Настоящий справочник НДТ распространяется на следующие основные виды деятельности по извлечению драгоценных металлов из коренных (рудных), россыпных и техногенных месторождений с получением концентратов и других полупродуктов, содержащих драгоценные металлы, а именно:</p>
<p>- добычу (извлечение) руд драгоценных металлов на коренных месторождениях;</p>
<p>- добычу (извлечение) песков драгоценных металлов на россыпных месторождениях;</p>
<p>- добычу драгоценных металлов из отходов добычи полезных ископаемых и связанных с ней процессов извлечения драгоценного металла;</p>
</div>
<p>- технологические процессы по первичной переработке руд и песков драгоценных металлов.</p>
<p>- Справочник НДТ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать масштабное негативное воздействие на окружающую среду во всех аспектах (выбросы, сбросы, отходы):</p>
<p>- методы предотвращения и сокращения эмиссий и образования отходов;</p>
<p>- хранение и транспортировку продукции и отходов добычи драгоценных металлов.</p>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
         <div class="seven__block-3" v-if="trgData.etkstructure_id == 1950">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/eg1-23.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующий основной вид деятельности:</p>
<p>- древесно-подготовительное производство (ДПП);</p>
<p>- производство сульфатной целлюлозы (СФА);</p>
<p>- производство механических видов древесной массы:</p>
<p>- дефибрерная древесная масса (ДДМ);</p>
<p>- термомеханическая масса (ТММ);</p>
<p>- химико-механическая масса (ХММ);</p>
<p>- химико-термомеханическая масса (ХТММ);</p>
</div>
<p>- производство бумаги, в том числе с использованием регенерированного волокна из макулатуры;</p>
<p>- производство картона, в том числе с использованием регенерированного волокна из макулатуры.</p>
<p>Справочник НДТ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды:</p>
<p>- подготовка сырья;</p>
<p>- хранение и подготовка топлива (черный щелок, коро-древесные отходы);</p>
<p>- производственные процессы вспомогательных производств (приготовление химикатов для отбелки, регенерация известкового шлама, очистка сточных вод).</p>
<p>Справочник НДТ не распространяется на:</p>
<p>- производство упаковки из бумаги и картона;</p>
<p>- процессы лесозаготовительных работ.</p>
<p>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.</p>
</div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1921">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg38.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на деятельность по производству электрической и тепловой энергии через сжигание топлива крупными топливосжигающими установками, одновременно соответствующими следующим критериям:<br> 
- крупные топливосжигающие установки, предназначенные для производства (генерации) электрической энергии и (или) тепловой энергии в виде пара и (или) горячей воды, при этом типы потребителей (собственные нужды энергогенерирующего объекта или внешние потребители), цели и способы потребления произведенной электро- и (или) теплоэнергии для целей настоящего справочника НДТ не имеют значения. Объем производимой продукции при определении области применения справочника НДТ не учитывается;<br> 
- стационарные крупные топливосжигающие установки, т.е. установки, прочно связанные фундаментом с землей и технологически присоединенные к сетям инженерно-технического обеспечения;<br> 
- крупные топливосжигающие установки, потребляющие следующие виды топлива в основных режимах эксплуатации (основные виды топлива в соответствии с паспортом энергоустановки, при этом режимы пуска, останова, резервные и аварийные виды не учитываются):<br>
1) газ природный и попутный; <br>
2) жидкие углеводородные топлива; <br>
3) твердые виды топлива: антрациты, каменные и бурые угли, в том числе обогащенные; <br>
- крупные топливосжигающие установки тепловой мощностью 50 МВт и более, включая тепловую энергию, подводимую в режиме дожигания (например, в котлахутилизаторах или камерах промежуточного подогрева в газотурбинных установках), потребление топлива которых при номинальной нагрузке составляет 6,15 тонн условного топлива в час и более (по низшей рабочей теплотворной способности топлива).</p>
</div>
<p>В область применения ИТС НДТ не входят:<br> 
- блок-станции; <br>
- ГТЭС, не входящие в состав энергогенерирующих предприятий и компаний;<br>
- паровые и водогрейные котельные, не входящие в состав энергогенерирующих предприятий и компаний; <br>
- энергоустановки, используемые в качестве привода механического оборудования, насосов, компрессоров и т. п., энерготехнологические топливосжигающие установки, предназначенные для нагрева, сушки, испарения рабочих сред, сырья и продукции, для производства холода в виде льда, охлажденного воздуха и (или) воды; <br>
- энергоутилизационные установки, производящие тепло за счет утилизации энергии, образующейся в различных технологических процессах (энерготехнологические котлы, котлы-утилизаторы после металлургических печей, котлы для сжигания отходов производства и потребления и т.д.). <br>
<br>В документе не рассматриваются топливосжигающие установки, потребляющие следующие виды топлива: <br>
- искусственные газы; <br>
- жидкие производственные отходы и искусственные жидкие топлива;<br>
- твердые отходы производства и потребления, сланцы, торф и биомасса; <br>
- передвижные электрогенерирующие установки, энергоустановки транспортных средств, вне зависимости от видов и объемов используемого ими топлива.<br>
При описании технологий производства электрической и тепловой энергии с использованием крупных топливосжигающих установкок рассматривается комплекс технологического оборудования, осуществляющий полный технологический цикл энергопроизводства и включающий в свой состав следующие операции, осуществляемые на площадках энергогенерирующих предприятий: <br>
- разгрузка топлива из транспортных средств, его хранение и подготовка к сжиганию;<br>
- сжигание топлива и производство электроэнергии, тепла в виде пара и (или) горячей воды с очисткой и отведением дымовых газов; <br>
- преобразование энергии пара в электроэнергию, включая охлаждение технологического оборудования; <br>
- водоподготовка для нужд энергообъектов, исключая водоподготовку для целей подпитки тепловых сетей;<br> 
- техническое водоснабжение энергообъекта для целей охлаждения технологического оборудования, компенсации пароводяных потерь, золошлакоудаления, прочих производственных нужд; <br>
- обращение с отходами; <br>
- обращение с маслами (разгрузка, хранение, очистка).<br>
<br><!--*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.--></p>
</div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1931">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg2.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:<br> 
- производство аммиака;<br>
- производство минеральных удобрений;<br> 
- производство неорганических кислот, использующихся в производстве минеральных удобрений на территории Российской Федерации.
Документ также распространяется на следующие процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды: <br>
- хранение и подготовка сырья; <br>
- хранение и подготовка топлива; <br>
- обращение со сточными водами; <br>
- производственные процессы; <br>
- методы предотвращения и сокращения эмиссий, образования и размещения отходов; <br>
- хранение и подготовка продукции. <br>
</p>
</div>
<p><br>
Подходы при обращении со сточными водами, затрагиваемые в настоящем отраслевом («вертикальном») ИТС НДТ, являются приоритетными перед теми, что приводятся в ИТС 8- 2015 «Очистка сточных вод при производстве продукции (товаров), выполнении работ и оказании услуг на крупных предприятиях» на том основании, что в настоящем информационно-техническом справочнике по наилучшим доступным технологиях рассмотрены отраслевые особенности обращения и очистки специфичных сточных вод отрасли производства минеральных удобрений.<br><br>
ИТС НДТ не распространяется на: <br>
- производство серной кислоты из отходящих газов цветной металлургии и нефтехимической промышленности; <br>
- вопросы, касающиеся исключительно обеспечения промышленной безопасности или охраны труда.<br>
<br>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию для проведения отраслевого бенчмаркинга удельных выбросов парниковых газов.</p>
</div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1932">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg11.jpg" alt="" />
              <p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:<br> 
              - производство глинозема; <br>
              - производство первичного алюминия;<br>
              - производство анодов и анодной массы; <br>
              - литейное производство (производство товарной продукции из алюминия-сырца и алюминиевых сплавов); <br>
              - производство технического кремния; <br>
              - процессы подготовки вторичного алюминиевого сырья для переработки; <br>
              - процессы производства вторичного алюминия. </p>
              </div>
              <p>Документ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды:<br>
              - хранение и подготовка сырья, топлива, продукции; <br>
              - производственные процессы; <br>
              - методы предотвращения и сокращения эмиссий и образования отходов.<br><br>
              ИТС НДТ не распространяется на: <br>
              - деятельность, связанную с добычей и обогащением руд цветных металлов; <br>
              - блоки вспомогательных и подсобных производств (ремонтные, автотранспортные, железнодорожные, монтажные и т. п.); <br>
              - вопросы, касающиеся исключительно обеспечения промышленной безопасности или охраны труда.<br>
              Вопросы охраны труда рассматриваются частично и только в тех случаях, когда оказывают влияние на виды деятельности, включенные в область применения настоящего справочника.<br>
              <br>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию для проведения отраслевого бенчмаркинга удельных выбросов парниковых газов.</p>
            </div>
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1933">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg26.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности, определяемые в соответствии с общероссийским классификатором видов экономической деятельности (ОКВЭД 2) ОК 029–2014 (КДЕС Ред. 2) и в соответствии с общероссийским классификатором по видам продукции (ОКПД 2) ОК 034–2014 (КПЕС 2008) (приняты и введены в действие приказом Федерального агентства по техническому регулированию и метрологии от 31 января 2014 г. № 14 ст):<br> 
- агломерация железных руд; <br>
- производство кокса и полукокса, бурого угля (лигнита) или торфа, угля ретортного; <br>
- производство чугуна зеркального и передельного в чушках, болванках и прочих первичных формах; <br>
- производство ферросплавов, включая ферросилиций, силикокальций, силикомарганец, электроферромарганец, ферровольфрам, ферромолибден, феррованадий, пятиокись ванадия, феррониобий, ферротитан, ферробор, силикоцирконий, феррофосфор, ферроманганофосфор, феррохром, ферросиликохром, ферросиликомарганец, ферромарганец, ферросплавы и лигатуры;<br>
- производство стали нелегированной в слитках или прочих первичных формах и полуфабрикатов из нелегированной стали; <br>
- производство стали нержавеющей в слитках или прочих первичных формах и полуфабрикатов из нержавеющей стали; <br>
- производство стали легированной прочей в слитках или в прочих первичных формах и полуфабрикатов из прочей легированной стали.</p>
</div>
<p>ИТС НДТ также распространяется на процессы, связанные с основными перечисленными выше видами деятельности, которые могут оказать влияние на объемы и состав эмиссий и/или масштабы загрязнения окружающей среды:<br>
- процессы, использующие методы предотвращения и сокращения эмиссий загрязняющих веществ (выбросы в атмосферу и сбросы в водные объекты или канализацию), утилизации отходов; <br>
- деятельность по плавке и/или рафинированию черных металлов из руды, чушек или лома с использованием методов электрометаллургии и прочих металлургических процессов; <br>
- производство сплавов металлов, включая сплавы со специальными свойствами (например, сверхпрочные сплавы), путем добавления в исходный чистый металл прочих химических элементов. <br>
<br>Данный документ не распространяется на:<br>
- производство изделий дальнейшего передела черных металлов; <br>
- генерацию электрической и тепловой энергии;<br>
- водоотведение и очистку хозяйственно-бытовых стоков; <br>
- деятельность, которая касается исключительно обеспечения промышленной безопасности или охраны труда.<br>
<br>*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию для проведения отраслевого бенчмаркинга удельных выбросов парниковых газов.</p>
</div>  
          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1934">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg15.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие виды деятельности:<br>
- утилизация и обезвреживание отходов, кроме утилизации и обезвреживания отходов термическими способами, основанными на термодеструкции органических веществ, входящих в состав отходов, включая утилизацию и обезвреживание отходов, являющиеся неотъемлемым процессом обрабатывающих или иных производств, если в соответствующем отраслевом справочнике НДТ они не рассмотрены.<br>
ИТС НДТ не распространяется на:<br>
- технологии утилизации и обезвреживания отходов, содержащих в своем составе органические вещества, термическими способами с деструкцией органических веществ.<br>
В документе рассматривается деятельность по утилизации и обезвреживанию (кроме термических способов, основанных на термической деструкции) следующих групп отходов:<br>
- отходы нефтепродуктов, в том числе отходы минеральных масел, утративших потребительские свойства;<br>
- прочие нефтесодержащие отходы, включая отходы при бурении, связанном с добычей сырой нефти, природного (попутного) газа и газового конденсата;<br>
- отходы оборудования, содержащего ртуть;<br></p></div>
<div>
<p>
- изделия из резины, утратившие потребительские свойства, в том числе резиновые шины, покрышки и камеры;<br>
- пластмассовые изделия, утратившие потребительские свойства, в том числе упаковочные пластмассовые изделия;<br>
- электрическое и электронное оборудование, утратившее потребительские свойства, в том числе:<br>
- компьютеры и периферийное оборудование, утратившие потребительские свойства;<br>
- коммуникационное оборудование, утратившее потребительские свойства;<br>
- электронная бытовая техника, утратившая потребительские свойства;<br>
- оптические приборы и фотографическое оборудование, утратившие потребительские свойства;<br>
- электрические бытовые приборы, утратившие потребительские свойства;<br>
- o неэлектрические бытовые приборы, утратившие потребительские свойства;<br>
- o холодильное и вентиляционное промышленное оборудование, утратившее<br>
- потребительские свойства;<br>
- o осветительное электрическое оборудование, утратившее потребительские<br>
- свойства;<br>
- медицинские отходы;<br>
- биологические отходы;<br>
- отходы органических растворителей;<br>
- автомобильные аккумуляторы, утратившие потребительские свойства<br>
- первичные и аккумуляторные батареи, утратившие потребительские свойства;<br>
- отходы фильтров и отработанных фильтровальных материалов;<br>
- отходы продукции, содержащей галогенированные ароматические органические вещества, в том числе стойкие органические загрязнители;<br>
- отходы органических пестицидов и агрохимикатов;<br>
- отходы оборудования, содержащего озоноразрушающие вещества;<br>
- твердые коммунальные отходы;<br>
- золы и шлаки от сжигания твердого топлива;<br>
- катализаторы, адсорбенты;<br>
- отходы металлообработки, в том числе отходы гальванических производств.<br><br>
*Сейчас и иформационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.
</p>

</div>
</div>

          <div class="seven__block-3" v-if="trgData.etkstructure_id == 1935">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg9.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие виды деятельности: утилизация и обезвреживание отходов, содержащих в своем составе органические вещества, термическими способами с деструкцией органических веществ, в том числе:<br>
&ndash; сжигание отходов;<br>
&ndash; пиролиз;<br>
&ndash; газификация;<br>
&ndash; методы, основанные на применении плазменных источников энергии;<br>
&ndash; комбинация указанных выше методов.<br>
Реализация перечисленных методов может осуществляться с целью:<br>
&ndash; получения материальной продукции;<br>
&ndash; получения энергии (в том числе энергетическая утилизация ТКО);<br>
&ndash; обезвреживания отходов.</p></div>
<p>Данные виды деятельности согласно &laquo;ОК 029-2014 (КДЕС Ред. 2). Общероссийский классификатор видов экономической деятельности&raquo; (утв. Приказом Росстандарта) относятся к коду 38.2.<br>
Документ НДТ также распространяется на процессы, непосредственно связанные с основным видом деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды:<br>
&ndash; накопление (хранение) и предварительная подготовка утилизируемых и обезвреживаемых отходов;<br>
&ndash; выбор и подготовка потребляемых материалов и топлива;<br>
&ndash; методы предотвращения и сокращения эмиссий и образования отходов;<br>
&ndash; способы преобразования энергии, полученной при термических процессах, в электрическую или тепловую энергию;<br>
&ndash; технологии термической утилизации и обезвреживания отходов, являющиеся неотъемлемым подпроцессом технологического процесса производства отраслевой продукции на предприятиях, в случае если в соответствующем отраслевом справочнике НДТ они не рассмотрены.<br><br>
ИТС НДТ не распространяется на:<br>
&ndash; технологии утилизации и обезвреживания отходов, в процессе которых используются методы термического воздействия, не приводящие к деструкции отходов (сушка, дистилляция и т. п.);<br>
&ndash; технологии обеззараживания медицинских отходов, используемые непосредственно в медицинских учреждениях и не предусматривающие термическую деструкцию органических веществ, входящих в состав медицинских отходов, с использованием методов сжигания, пиролиза, газификации;<br>
&ndash; вопросы, касающиеся исключительно обеспечения промышленной безопасности или охраны труда.<br>
<br><!--*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию.--></p></div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1893">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg2.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности: <br> 
- производство аммиака;  <br>
- производство минеральных удобрений;  <br>
- производство неорганических кислот, использующихся в производстве минеральных удобрений на территории Российской Федерации.  <br>
– производство медного порошка и медного купороса.<br><br>

</p>
</div>
<p>Документ также распространяется на следующие процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды:  <br><br>

- хранение и подготовка сырья; <br>
- хранение и подготовка топлива;<br>
- обращение со сточными водами; <br>
- производственные процессы; <br>
- методы предотвращения и сокращения эмиссий, образования и размещения отходов; <br>
- хранение и подготовка продукции. <br><br>
Подходы при обращении со сточными водами, затрагиваемые в настоящем отраслевом («вертикальном») ИТС НДТ, являются приоритетными перед теми, что приводятся в ИТС 8- 2015 «Очистка сточных вод при производстве продукции (товаров), выполнении работ и оказании услуг на крупных предприятиях» на том основании, что в настоящем информационно-техническом справочнике по наилучшим доступным технологиях рассмотрены отраслевые особенности обращения и очистки специфичных сточных вод отрасли производства минеральных удобрений.<br><br>
ИТС НДТ не распространяется на: <br><br>
- производство серной кислоты из отходящих газов цветной металлургии и нефтехимической промышленности; <br>
- вопросы, касающиеся исключительно обеспечения промышленной безопасности или охраны труда.<br><br>

*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию для проведения отраслевого бенчмаркинга удельных выбросов парниковых газов.

</p>
</div>


<div class="seven__block-3" v-if="trgData.etkstructure_id == 1894">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg3.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:<br> 
– производство меди из руд, концентратов или вторичных сырьевых материалов посредством пирометаллургических, гидрометаллургических, электролитических процессов; <br>
– выплавка медных заготовок, включая производство сплавов, в том числе из вторичных продуктов, на плавильных производствах; <br>
– литье, прокат, волочение и прессование в случаях, когда они интегрированы с производством меди; <br>
– производство медного порошка и медного купороса.<br><br>

</p>
</div>
<p>Документ распространяется на методы производства как первичной, так и вторичной меди. Между производством первичной и вторичной меди есть много общего, причем нередко невозможно провести четкое различие между применяемыми методами. В некоторых случаях при производстве первичной меди в рамках общих производственных процессов с целью экономии энергии, минимизации производственных затрат и вторичного использования образующихся отходов используется вторичное сырье. <br><br>
ИТС НДТ также распространяются на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий и (или) масштабы загрязнения окружающей среды: <br><br>
– хранение и подготовка сырья;<br>
– хранение и подготовка топлива;<br>
– производственные процессы (пирометаллургические, гидрометаллургические и электролитические);<br>
– методы предотвращения и сокращения эмиссий и образования отходов;<br>
– хранение и подготовка продукции ИТС НДТ не распространяется на:<br>
– добычу и обогащение медных и полиметаллических руд на месторождениях;<br>
– производство меди из медно-никелевых сульфидных руд;<br>
– вопросы, которые касаются исключительно обеспечения промышленной безопасности или охраны труда.<br>
Вопросы обеспечения промышленной безопасности и охраны труда частично рассматриваются только в тех случаях, когда оказывают влияние на виды деятельности, включенные в область применения настоящего справочника НДТ.<br></p>
</div>
<div class="seven__block-3" v-if="trgData.etkstructure_id == 1895">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg10.jpg" alt="" />
<p>В соответствии с Перечнем областей применения наилучших доступных технологий, утвержденным распоряжением Правительства Российской Федерации деятельность в сфере очистки сточных вод с использованием ЦСВП относится к данной области целиком, без ограничения производительности по объему отводимых сточных вод.<br><br>
Постановлением Правительства Российской Федерации «Об утверждении критериев отнесения объектов, оказывающих негативное воздействие на окружающую среду, к объектам I, II, III и IV категорий» утвержден следующий критерий: сбор и обработка сточных вод в части, касающейся очистки сточных вод ЦСВ с объемом отводимых сточных вод 20 тыс. куб. м и более в сутки.<br>

</p>

</div>
<p>Область применения данного информационно-технического справочника по наилучшим доступным технологиям (ИТС НДТ) определена «Правилами отнесения централизованных систем водоотведения (канализации) к централизованным системам водоотведения поселений или городских округов». <br>

По п. 2 Правил отнесение ЦСВ к ЦСВП осуществляется посредством утверждения схемы водоснабжения и водоотведения, содержащей сведения об отнесении ЦСВ к ЦСВП, или актуализации (корректировки) схемы водоснабжения и водоотведения в связи с внесением в нее сведений об отнесении ЦСВ к ЦСВП.<br>

Согласно п. 4 данных Правил ЦСВ подлежит отнесению к ЦСВП при соблюдении совокупности следующих критериев:<br>

а) объем сточных вод, принятых в ЦСВ, указанных в пункте 5 Правил (см. ниже), составляет более 50 процентов общего объема сточных вод, принятых в такую ЦСВ;<br>

б) одним из видов экономической деятельности, определяемых в соответствии с Общероссийским классификатором видов экономической деятельности, организации, является деятельность по сбору и обработке сточных вод.<br>
П. 5 Правил относит к сточным водам, принимаемым в ЦСВ, объем которых является критерием отнесения к ЦСВП:<br><br>
а) сточные воды, принимаемые от многоквартирных домов и жилых домов;<br>
б) сточные воды, принимаемые от гостиниц, иных объектов для временного проживания;<br>
в) сточные воды, принимаемые от объектов отдыха, спорта, здравоохранения, культуры, торговли, общественного питания, социального и коммунально-бытового назначения, дошкольного, начального общего, среднего общего, среднего профессионального и высшего образования, административных, научно-исследовательских учреждений, культовых зданий, объектов делового, финансового, административного, религиозного назначения, иных объектов, связанных с обеспечением жизнедеятельности граждан;<br>
г) сточные воды, принимаемые от складских объектов, стоянок автомобильного транспорта, гаражей;<br>
д) сточные воды, принимаемые от территорий, предназначенных для ведения сельского хозяйства, садоводства и огородничества;<br>
е) поверхностные сточные воды (для централизованных общесплавных и централизованных комбинированных систем водоотведения);<br>
ж) сточные воды, не указанные в подпунктах «а» – «е» пункта 5, подлежащие учету в составе объема сточных вод, являющегося критерием отнесения к централизованным системам водоотведения поселений или городских округов, в случае, предусмотренном пунктом 7 настоящих Правил.<br>
В случае если объем сточных вод, принятых в ЦСВ, указанных в подпунктах «а» – «е» пункта 5 Правил, за период, указанный в пункте 6 Правил, меньше 50 процентов общего объема сточных вод, принятых в такую централизованную систему водоотведения (канализации) за этот период, для целей отнесения ЦСВ к ЦСВП в объеме сточных вод, учитываемых в составе объема сточных вод, являющегося критерием отнесения к ЦСВП может быть учтен объем сточных вод, принимаемых в ЦСВ, в размере не более 50 процентов объема учитываемых сточных вод, при условии соответствия показателей состава таких сточных вод следующим показателям (не более мг/дм3, по валовому содержанию соответствующего вещества в натуральной пробе сточных вод): нефтепродукты — 3, фенолы (сумма) — 0,05, железо — 3, медь — 0,1, алюминий — 1, цинк — 0,5, хром (шестивалентный) — 0,01, никель — 0,1, кадмий — 0,005, свинец — 0,01, мышьяк — 0,01, ртуть — 0,0001; ХПК (бихроматная окисляемость) — 400.<br>
Также подлежат отнесению к ЦСВП централизованные ливневые системы водоотведения (канализации), предназначенные для отведения ПСВ с территорий поселений или городских округов (без оценки соблюдения совокупности критериев отнесения ЦСВ к ЦСВП в случае предоставления одного или нескольких документов, подтверждающих принадлежность ливневой ЦСВ к ливневой ЦСВП. Перечень таких документов, необходимых организациям, эксплуатирующим централизованные ливневые системы водоотведения, для отнесения таких систем к централизованным системам водоотведения поселений или городских округов утверждается Минстроем Российской Федерации.<br>
В случае если отнесение централизованной системы водоотведения (канализации) к централизованным системам водоотведения поселений или городских округов осуществляется при проектировании или строительстве объектов централизованной системы водоотведения (канализации), определение объема сточных вод, принимаемых в такую централизованную систему водоотведения (канализации), для целей оценки соответствия такого объема объему, являющемуся критерием отнесения к централизованным системам водоотведения поселений или городских округов, осуществляется в соответствии со сведениями, содержащимися в проектной документации объектов капитального строительства, строительство которых предполагается или осуществляется на территории такого поселения или городского округа, условиях подключения (технологического присоединения) к централизованной системе водоотведения, схеме водоснабжения и водоотведения и документах территориального планирования такого поселения или городского округа.<br>
В ОКВЭД имеется код 37.00 «Сбор и обработка сточных вод». Данная формулировка формулировка ОКВЭД однозначно выходит за пределы справочника, т.к. включает в себя сбор сточных вод, а также очистку промышленных сточных вод и целый ряд услуг в области эксплуатации систем водоотведения, что потребовало принятия вышеукзанных Правил.<br></p>
</div>


<div class="seven__block-3" v-if="trgData.etkstructure_id == 1896">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg11.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:  <br> 
- производство глинозема;   <br>
- производство первичного алюминия;  <br>
- производство анодов и анодной массы;   <br>
- литейное производство (производство товарной продукции из алюминия-сырца и алюминиевых сплавов); <br>
- производство технического кремния; <br>
- процессы подготовки вторичного алюминиевого сырья для переработки; <br>
- процессы производства вторичного алюминия. <br>

</p>
</div>
<p>Документ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды:  <br><br>

- хранение и подготовка сырья, топлива, продукции;  <br>
- производственные процессы; <br>
- методы предотвращения и сокращения эмиссий и образования отходов. <br><br>
ИТС НДТ не распространяется на:  <br><br>
- деятельность, связанную с добычей и обогащением руд цветных металлов;  <br>
- блоки вспомогательных и подсобных производств (ремонтные, автотранспортные, железнодорожные, монтажные и т. п.); <br>
- вопросы, касающиеся исключительно обеспечения промышленной безопасности или охраны труда.<br><br>
Вопросы охраны труда рассматриваются частично и только в тех случаях, когда оказывают влияние на виды деятельности, включенные в область применения настоящего справочника. <br><br>
<br><br>

*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию для проведения отраслевого бенчмаркинга удельных выбросов парниковых газов.

</p>
</div>


<!--div class="seven__block-3" v-if="trgData.etkstructure_id == 1932">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg11.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности: <br> 

- производство глинозема;  <br>
- производство первичного алюминия; <br>
- производство анодов и анодной массы;  <br>
- литейное производство (производство товарной продукции из алюминия-сырца и алюминиевых сплавов); <br>
- производство технического кремния;  <br>
- процессы подготовки вторичного алюминиевого сырья для переработки;  <br>
- процессы производства вторичного алюминия. <br>
</p>
</div>
<p>Документ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды:<br>

  - хранение и подготовка сырья, топлива, продукции; <br>
  - производственные процессы; <br>
  - методы предотвращения и сокращения эмиссий и образования отходов.<br>

ИТС НДТ не распространяется на: <br>

  - деятельность, связанную с добычей и обогащением руд цветных металлов; <br>
  - блоки вспомогательных и подсобных производств (ремонтные, автотранспортные, железнодорожные, монтажные и т. п.); <br>
  - вопросы, касающиеся исключительно обеспечения промышленной безопасности или охраны труда.<br>

<br>
Вопросы охраны труда рассматриваются частично и только в тех случаях, когда оказывают влияние на виды деятельности, включенные в область применения настоящего справочника.<br>
*Сейчас информационно-технический справочник по наилучшим доступным технологиям проходит актуализацию для проведения отраслевого бенчмаркинга удельных выбросов парниковых газов.<br></p>
</div-->
<div class="seven__block-3" v-if="trgData.etkstructure_id == 1897">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg12.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) «Производство никеля и кобальта» распространяется на процессы производства первичных никеля и кобальта, т. е. получаемых из рудного сырья.<br>
Поскольку производство никеля, кобальта и меди из сульфидных медно-никелевых руд включает ряд последовательных операций в едином взаимосвязанном производственно-технологическом процессе, справочник также распространяется на процессы производства меди из такого сырья.<br>
</p>
</div>
<p>Документ также распространяется на процессы, связанные с основными видами деятельности по производству первичного никеля и кобальта, которые могут оказать влияние на объёмы эмиссий и (или) масштабы загрязнения окружающей среды:<br>

  •  хранение, подготовка и транспортировка сырья;<br>
  •  хранение и подготовка топлива, материалов, реагентов и продукции;<br>
  •  производственные процессы (пирометаллургические, гидрометаллургические).
<br>
ИТС НДТ не распространяется на:<br>

  • добычу и обогащение никелевых и полиметаллических руд на месторождениях (область действия ИТС НДТ 23);<br>
  • вопросы, которые касаются исключительно обеспечения промышленной безопасности или охраны труда.<br>

<br>
Вопросы обеспечения промышленной безопасности и охраны труда частично рассматриваются только в тех случаях, когда оказывают влияние на виды деятельности, включённые в область применения настоящего справочника НДТ.<br></p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1898">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg18.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:<br> 
– производство низших олефиновых и диеновых углеводородов, в частности, производство этилена, пропилена, изобутилена, бутадиена и изопрена; <br>
– производство ароматических соединений, в частности, бензола, этилбензола, стирола, изопропилбензола, α-метилстирола, фенола и ацетона; <br>
– производство кислородсодержащих соединений, в частности, производство метанола, формальдегида, оксида этилена и этиленгликолей, оксида пропилена, метилтретбутилового, метил-трет-амилового и метил-втор-амиленового эфиров, этанола, бутиловых спиртов, 
</p><br>
</div>
<p>2-этилгексанола, акриловой кислоты и ее эфиров, терефталевой кислоты, диоктилтерефталата, ацетатов, ацетилена, ацетальдегида, уксусной кислоты; <br>
– производство хлорсодержащих органических веществ, в частности, производство дихлорэтана и винилхлорида;<br>
– производство азотсодержащих органических веществ, в частности, производство ацетатов, нитрила акриловой кислоты, аминов, меламина, капролактама. <br>

ИТС НДТ распространяется на производство органических веществ из ископаемого углеродсодержащего сырья и из возобновляемых источников.
Документ также распространяется на следующие процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды:<br>
– хранение и подготовку сырья;<br>
– методы предотвращения и сокращения эмиссий, образования и размещения отходов.<br></p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1899">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg13.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:<br> 
– процессы производства первичных и вторичных цинка, свинца и олова; <br>
– процессы рафинирования свинца; <br>
– процессы переплавки, легирования цинка и свинца с получением товарного продукта (цинк, свинец и сплавы на их основе в слитках); <br>
– процессы производства кадмия.<br>
Документ распространяется на методы производства как первичных, так и вторичных цинка, свинца, олова и кадмия. Первичные свинец, олово и цинк получают из рудного сырья. Вторичное производство цинка включает переработку цинксодержащих продуктов, таких как окиси цинка технические (пыли медных предприятий, содержащие цинк, катализаторы), продукты из латуни и изделия, полученные с помощью литья под давлением, цинковая стружка.
</p><br>
</div>
<p> Вторичное извлечение также осуществляется: при переработке изгари, шлаков, цинкового порошка и гартцинка от производств горячего цинкования стального проката и металлоконструкций; при переработке окиси цинка (вельц-оксидов), полученной при переработке с помощью специальных технологий пылей электродуговых печей (ЭДП). Вторичное производство свинца включает переработку различных продуктов, содержащих свинец, например, аккумуляторов, листового свинца. Также перерабатываются отходы и побочные продукты других производств: кеки, шламы, пыли и т. д. Настоящий справочник НДТ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий и (или) масштабы загрязнения окружающей среды:<br>
– хранение и подготовка сырья;<br>
– хранение и подготовка топлива;<br>
– производственные процессы (пирометаллургические, гидрометаллургические и электролитические);<br>
– методы предотвращения и сокращения эмиссий и образования отходов;<br>
– хранение и подготовка продукции;<br>
– производство серной кислоты из отходящих газов цинкового производства.
<br>
ИТС НДТ не распространяется на:<br>
– добычу и обогащение цинковых, свинцовых и полиметаллических руд на месторождениях;<br>
– вопросы, которые касаются исключительно обеспечения промышленной безопасности или охраны труда.<br>
Вопросы обеспечения промышленной безопасности и охраны труда частично рассматриваются только в тех случаях, когда оказывают влияние на виды деятельности, включенные в область применения настоящего справочника НДТ.</p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1900">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg9.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие виды деятельности: утилизация и обезвреживание отходов, содержащих в своем составе органические вещества, термическими способами с деструкцией органических веществ, в том числе:<br> 
– сжигание отходов; <br>
– пиролиз; <br>
– газификация; <br>
– методы, основанные на применении плазменных источников энергии;<br>
– комбинация указанных выше методов.<br>
Реализация перечисленных методов может осуществляться с целью: <br>
– получения материальной продукции;<br>
– получения энергии (в том числе энергетическая утилизация ТКО);<br>
– обезвреживания отходов.<br>


</p>
</div>
<p>Данные виды деятельности согласно «ОК 029-2014 (КДЕС Ред. 2). Общероссийский классификатор видов экономической деятельности» (утв. Приказом Росстандарта) относятся к коду 38.2.<br>
Документ НДТ также распространяется на процессы, непосредственно связанные с основным видом деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды:<br>
– накопление (хранение) и предварительная подготовка утилизируемых и обезвреживаемых отходов;<br>
– выбор и подготовка потребляемых материалов и топлива;<br>
– методы предотвращения и сокращения эмиссий и образования отходов;<br>
– способы преобразования энергии, полученной при термических процессах, в электрическую или тепловую энергию;<br>
– технологии термической утилизации и обезвреживания отходов, являющиеся неотъемлемым подпроцессом технологического процесса производства отраслевой продукции на предприятиях, в случае если в соответствующем отраслевом справочнике НДТ они не рассмотрены. <br>

ИТС НДТ не распространяется на:<br>
– технологии утилизации и обезвреживания отходов, в процессе которых используются методы термического воздействия, не приводящие к деструкции отходов (сушка, дистилляция и т. п.);<br>
– технологии обеззараживания медицинских отходов, используемые непосредственно в медицинских учреждениях и не предусматривающие термическую деструкцию органических веществ, входящих в состав медицинских отходов, с использованием методов сжигания, пиролиза, газификации;<br>
– вопросы, касающиеся исключительно обеспечения промышленной безопасности или охраны труда.<br></p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1901">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg14.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на предприятия, имеющие право осуществлять аффинаж драгоценных металлов, и включает следующие виды деятельности:<br> 
– производство драгоценных металлов из шламов, концентратов, природных концентратов (шлиховое золото, шлиховая платина), шлаков, кеков, вторичных сырьевых ресурсов посредством гидрометаллургических, пирометаллургических и электрохимических процессов; <br>
– производство порошков и слитков драгоценных металлов, производство сплавов на основе драгоценных металлов.<br>


</p>
</div>
<p>Справочник распространяется на методы производства первичных и вторичных драгоценных металлов. Между этими производствами практически невозможно провести четкую грань. Нередко при производстве первичных металлов в рамках общих производственных процессов с целью экономии энергии, минимизации производственных затрат и вторичного использования образующихся отходов используется вторичное сырье. Вторичное производство драгоценных металлов включает производство металла из вторичного сырья (электронного лома, отработанных катализаторов, отходов изделий и сплавов и т. п.) гидрометаллургическими, пирометаллургическими и электрохимическими методами.<br>
Документ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий и (или) масштабы загрязнения окружающей среды:<br>
– хранение и подготовка сырья;<br>
– хранение и подготовка топлива;<br>
– производственные процессы (пирометаллургические, гидрометаллургические и электролитические);<br>
– методы предотвращения и сокращения эмиссий и образования отходов;<br>
– хранение и подготовка продукции.<br>

ИТС НДТ не распространяется на:<br>
– виды деятельности, установленные в ИТС 49–2017 «Добыча драгоценных металлов»;<br>
– вопросы, которые касаются исключительно обеспечения промышленной безопасности или охраны труда.<br>
Вопросы обеспечения промышленной безопасности и охраны труда частично рассматриваются только в тех случаях, когда оказывают влияние на виды деятельности, включенные в область применения настоящего справочника НДТ.<br></p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1902">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg19.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:<br> 
• процессы хлорщелочного производства и производство соляной кислоты; <br>
• процессы производства гипохлорита натрия; <br>
• процессы производства гипохлорита кальция; <br>
• процессы производства хлорида железа (III);<br>
• процессы производства соды; <br>
• процессы производства перкарбоната натрия; <br>
• процессы производства технических солей на основе хлорида натрия;<br>
• процессы производства нитрита натрия и натриевой селитры;<br>
• процессы производства карбоната кальция и нитрата кальция;<br>
• процессы производства хлористого кальция;<br>

</p><br>
</div>
<p>
  • процессы производтства сульфата кальция;<br>
• процессы производтства сульфата алюминия;<br>
• процессы производтства фтористых соединений;<br>
• процессы производства фосфатов ― технических, кормовых, пищевых;<br>
• процессы производства карбида кремния;<br>
• процессы производства соединений хрома;<br>
• процессы производства карналлита.


Документ также распространяется на следующие процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды:<br>
• хранение и подготовка сырья;<br>
• хранение и подготовка топлива;<br>
• производственные процессы;<br>
• методы предотвращения и сокращения эмиссий, образования и размещения отходов;<br>
• хранение и подготовка продукции.<br>

Вопросы обеспечения промышленной безопасности и охраны труда частично рассматриваются только в тех случаях, когда оказывают влияние на виды деятельности, включенные в область применения настоящего справочника НДТ.</p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1903">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg24.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:<br> 
• процессы производства легких редких металлов; <br>
• процессы производства рассеянных редких металлов; <br>
• процессы производства тугоплавких редких металлов; <br>
• процессы производства редкоземельных элементов;<br>
• процессы производства щелочноземельных металлов. <br>

ИТС НДТ распространяется на методы производства редких и редкоземельных металлов. </p><br>
</div>
<p>Ни один редкий металл не получают непосредственным восстановлением из сырья, первоначальное сырье обогащают или вскрывают (выщелачивание, спекание), а полученные черновые концентраты и промпродукты перерабатывают в химические соединения. Помимо рудного сырья, источником получения редких металлов являются промышленные отходы цветной и черной металлургии, химических производств.
<br>
Документ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы негативного воздейтсвия и (или) масштабы загрязнения окружающей среды:<br>
• хранение и подготовка сырья;<br>
• хранение и подготовка топлива;<br>
• производственные процессы (химические, пирометаллургические, гидрометаллургические и электролитические);<br>
• методы предотвращения и сокращения выбросов и образования отходов;<br>
• хранение и подготовка продукции.<br>
• Справочник НДТ не распространяется на:<br>
• добычу и обогащение руд и продуктов, содержащих редкие металлы;<br>
• процессы производства радиоактивных металлов;<br>
• вопросы, которые касаются исключительно обеспечения промышленной безопасности или охраны труда.
<br>
Вопросы обеспечения промышленной безопасности и охраны труда частично рассматриваются только в тех случаях, когда оказывают влияние на виды деятельности, включенные в область применения настоящего справочника НДТ</p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1904">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg33.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:<br> 
– процессы производства цианистого натрия; <br>
– процессы производства гидросульфида натрия; <br>
– процессы производства катализаторов; <br>
– процессы производства треххлористого фосфора;<br>
– процессы производства специальных видов аммиачной селитры (пористой и водоустойчивой);<br>
– процессы производства технических солей и реактивов; <br>
– процессы производства треххлористого бора;<br>
– процессы производства диоксида титана;<br>
– процессы производства железоокисного пигмента как побочного продукта производства диоксида титана.
<br>
</p><br>
</div>
<p>Документ также распространяется на следующие процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий или масштабы загрязнения окружающей среды:<br>
– хранение и подготовка сырья;<br>
– хранение и подготовка топлива;<br>
– производственные процессы;<br>
– методы предотвращения и сокращения эмиссий, образования и размещения отходов;<br>
– хранение и подготовка продукции.
<br>
Вопросы обеспечения промышленной безопасности и охраны труда частично рассматриваются только в тех случаях, когда оказывают влияние на виды деятельности, включенные в область применения настоящего справочника НДТ.</p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1905">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg34.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:<br> 
– производство концентрированной азотной кислоты; <br>
– производство трихлорсилана и тетрахлорсилана; <br>
– производство азотнокислого стронция; <br>
– производство пероксида водорода;<br>
– производство водорода электролизом воды.
<br>
Документ также распространяется на технологические процессы, связанные с основными видами деятельности, которые могут оказать или оказывают влияние на количество (массы) эмиссий в окружающую среду или на масштабы загрязнения окружающей среды:<br>
</p><br>
</div>
<p>– хранение и подготовка сырья;<br>
– производственные процессы;<br>
– методы предотвращения и сокращения эмиссий и образования отходов;<br>
– упаковка и хранение продукции.
<br>
ИТС НДТ не распространяется на:<br>
– добычу и обработку сырья на месторождениях;<br>
– вопросы, относящиеся исключительно к обеспечению промышленной безопасности или охране труда.<br>
Вопросы охраны труда рассматриваются частично и только в тех случаях, когда они оказывают непосредственное влияние на виды деятельности, включенные в область применения настоящего справочник НДТ.</p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1909">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg28.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие виды деятельности:<br> 
• добыча нефти; <br>
• добыча нефтяного (попутного) газа; <br>
• предоставление услуг в области добычи нефти и нефтяного (попутного) газа; <br>
• подготовка, переработка и использование на собственные нужды нефти и нефтяного (попутного) газа в районе разработки месторождения.
<br>
Документ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий и (или) масштабы загрязнения окружающей среды:<br>

</p><br>
</div>
<p>- производственные процессы:<br>
▪ разработка месторождений,<br>
▪ добыча, сбор и внутрипромысловая транспортировка продукции нефтяных скважин,<br>
▪ подготовка и промысловая переработка нефти, нефтяного (попутного) газа и воды,<br>
▪ компримирование нефтяного (попутного) газа,<br>
▪ использование нефтяного (попутного) газа в условиях промысла,<br>
▪ поддержание пластового давления,<br>
▪ закачка углеводородов и подтоварной воды в пласт,<br>
▪ учет добываемых продуктов (нефть, нефтяной (попутный) газ) перед передачей потребителю;<br>
- методы предотвращения и сокращения воздействий на окружающую среду и образования отходов при добыче нефти и нефтяного (попутного) газа.
<br>
ИТС НДТ не распространяется на следующие виды деятельности:<br>
- добыча горючих (битуминозных) сланцев и битуминозных песков и извлечение из них нефти;<br>
- добыча природного газа;<br>
- поисково-разведочные работы на нефтяных и газовых скважинах;<br>
- разведочное и эксплуатационное бурение;<br>
- очистка нефтепродуктов;<br>
- разведка нефтяных месторождений и другие геофизические, геологические и
сейсмические исследования;<br>
- производство нефтепродуктов в процессе переработки нефти и конденсата;<br>
- производство сжиженных углеводородных газов, широкой фракции легких углеводородов, сухого (отбензиненного) газа, этановой фракции, индивидуальных углеводородов (пропана, бутана, пентана), бензина газового стабильного, моторных топлив, конденсата газового стабильного и других углеводородов из нефтяного (попутного) газа.
<br>
ИТС НДТ не распространяется на процессы:<br>
- строительства эксплуатационных и разведочных скважин;<br>
- консервации и ликвидации скважин и иных объектов добычи углеводородного сырья;<br>
- транспортирования нефти и нефтяного (попутного) газа вне промысла/месторождения;<br>
- обеспечения промышленной безопасности или охраны труда.</p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1910">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg30.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:<br> 
- переработка нефти; <br>
- производство нефтепродуктов; <br>
- производство оксигенатов (метил-трет-бутиловый эфир, трет-амилметиловый эфир и др.) на нефтеперерабатывающих заводах; <br>
- хранение нефти и нефтепродуктов.
</p><br>
</div>

</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1912">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg15.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие виды деятельности:<br> 
- утилизация и обезвреживание отходов, кроме утилизации и обезвреживания отходов термическими способами, основанными на термодеструкции органических веществ, входящих в состав отходов, включая утилизацию и обезвреживание отходов, являющиеся неотъемлемым процессом обрабатывающих или иных производств, если в соответствующем отраслевом справочнике НДТ они не рассмотрены.
<br>
ИТС НДТ не распространяется на:<br>
- технологии утилизации и обезвреживания отходов, содержащих в своем составе органические вещества, термическими способами с деструкцией органических веществ.
<br>
В документе рассматривается деятельность по утилизации и обезвреживанию (кроме термических способов, основанных на термической деструкции) следующих групп отходов:<br>

</p><br>
</div>
<div>
<p>• отходы нефтепродуктов, в том числе отходы минеральных масел, утративших потребительские свойства;<br>
• прочие нефтесодержащие отходы, включая отходы при бурении, связанном с добычей сырой нефти, природного (попутного) газа и газового конденсата;<br>
• отходы оборудования, содержащего ртуть;<br>
• изделия из резины, утратившие потребительские свойства, в том числе резиновые шины, покрышки и камеры;<br>
• пластмассовые изделия, утратившие потребительские свойства, в том числе упаковочные пластмассовые изделия;<br>
• электрическое и электронное оборудование, утратившее потребительские свойства, в том числе: <br>
• компьютеры и периферийное оборудование, утратившие потребительские свойства;<br>
• коммуникационное оборудование, утратившее потребительские свойства;<br>
• электронная бытовая техника, утратившая потребительские свойства;<br>
• оптические приборы и фотографическое оборудование, утратившие потребительские свойства;<br>
• электрические бытовые приборы, утратившие потребительские свойства;<br>
• o неэлектрические бытовые приборы, утратившие потребительские свойства;<br>
• o холодильное и вентиляционное промышленное оборудование, утратившее<br>
• потребительские свойства;<br>
• o осветительное электрическое оборудование, утратившее потребительские<br>
• свойства;<br>
• медицинские отходы;<br>
• биологические отходы;<br>
• отходы органических растворителей;<br>
• автомобильные аккумуляторы, утратившие потребительские свойства<br>
• первичные и аккумуляторные батареи, утратившие потребительские свойства;<br>
• отходы фильтров и отработанных фильтровальных материалов;<br>
• отходы продукции, содержащей галогенированные ароматические органические вещества, в том числе стойкие органические загрязнители;<br>
• отходы органических пестицидов и агрохимикатов;<br>
• отходы оборудования, содержащего озоноразрушающие вещества;<br>
• твердые коммунальные отходы;<br>
• золы и шлаки от сжигания твердого топлива;<br>
• катализаторы, адсорбенты;<br>
• отходы металлообработки, в том числе отходы гальванических производств.</p>
</div></div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1913">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg17.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на технологии размещения отходов (технологические процессы, методы, способы, оборудование и средства) на объектах, предназначенных для:<br> 
1) хранения отходов добычи и обогащения природных ресурсов: <br>
- отвалы отходов добычи полезных ископаемых; <br>
- отвалы отходов обогащения полезных ископаемых; <br>
- хранилища, предназначенные для хранения отходов добычи и/или обогащения полезных ископаемых, кроме отвалов;
<br>
2) хранения отходов, образующихся в обрабатывающих производствах и производствах электроэнергии и пара:<br>
</p>
</div>
<p>- отвалы отходов обрабатывающих производств;<br>
- отвалы отходов производства электроэнергии и пара;<br>
- хранилища, предназначенные для хранения отходов обрабатывающих производств, кроме отвалов;<br>
- хранилища, предназначенные для хранения отходов производства электроэнергии и пара, кроме отвалов;
<br>
3) захоронения отходов производства и потребления, кроме твердых коммунальных отходов:<br>
- полигоны приповерхностного захоронения отходов производства и потребления, кроме твердых коммунальных отходов;<br>
- системы подземного захоронения жидких и разжиженных отходов;<br>
- системы подземного захоронения жидких и разжиженных отходов при захоронении в пласт-коллектор;<br>
- системы подземного захоронения жидких и разжиженных отходов при захоронении в подземный резервуар;<br>
- системы подземного захоронения твердых и отвержденных отходов;
<br>
4) захоронения твердых коммунальных отходов:<br>
- объекты захоронения твердых коммунальных отходов.
<br>
В справочнике НДТ рассматриваются технические, технологические и организационные мероприятия, направленные на предотвращение и/или снижение загрязнения окружающей среды при:<br>
- строительстве и обустройстве;<br>
- эксплуатации;<br>
- консервации и ликвидации объектов размещения отходов.
<br>
Документ также распространяется на виды деятельности, связанные с хранением и захоронением отходов и влияющие на показатели технологий размещения.<br>
К таким видам деятельности относятся:<br>
- раздельный сбор отходов;<br>
- предварительная обработка отходов перед размещением, в том числе сортировка, измельчение, прессование, затаривание, компостирование и т. д.
<br>
Эти виды деятельности учитываются при выборе технологических процессов, методов, способов, оборудования и средств, применяемых при эксплуатации объектов размещения отходов.<br>
Виды деятельности, непосредственно не связанные с хранением и захоронением отходов, описываются в той мере, в которой они способствуют снижению негативного воздействия объектов размещения отходов на окружающую среду.<br>
В сферу распространения справочника НДТ не входят технологии в области обращения с радиоактивными отходами и веществами, разрушающими озоновый слой.</p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1914">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg21-1.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды экономической деятельности без ограничений по производственной мощности:<br> 
- производство оксида магния; <br>
- производство гидроксида магния; <br>
- производство хлорида магния (хлорида магния гексагидрата).
<br>
Производство указанных продуктов относится в соответствии с общероссийским классификатором видов экономической деятельности (ОКВЭД 2) к производству прочих основных неорганических химических веществ (код ОКВЭД 20.13).<br>
ИТС НДТ не распространяется на:<br>
- добычу и обработку сырья на месторождениях;<br>
- вопросы, относящиеся исключительно к обеспечению промышленной безопасности или охране труда.
<br>
Вопросы охраны труда рассматриваются частично и только в тех случаях, когда они оказывают непосредственное влияние на виды деятельности, включенные в область применения настоящего справочника НДТ. </p>
</div>

</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1915">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg22-1.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) разработан во взаимосвязи с отраслевыми справочниками НДТ, разрабатываемыми в соответствии с распоряжением Правительства Российской Федерации, и включает в себя описание универсальных подходов и методов, применимых при осуществлении процедур производственного экологического контроля (ПЭК) на предприятиях, относящихся к объектам I категории, в соответствии с критериями, утвержденными постановлением Правительства Российской Федерации «Об утверждении критериев отнесения объектов, оказывающих негативное воздействие на окружающую среду, к объектам I, II, III и IV категорий», в отношении объектов II и III категории справочник носит рекомендательный характер.<br>
</p>
</div>
<p>ИТС НДТ носит сквозной характер и содержит обобщенную информацию, сведения общего характера, общие подходы к методам и управленческим решениям, применяемым при осуществлении производственного экологического контроля на предприятиях I, II и III категории.<br>
Рекомендации справочника предназначены для формирования нормативных требований в области организации и осуществления ПЭК, а также ведения учета его результатов и формирования государственной отчетности о результатах ПЭК; Рекомендации справочника НДТ могут использоваться при разработке отраслевых справочников НДТ или национальных стандартов в части определения отраслевых НДТ ПЭК; рекомендации справочника НДТ могут также применяться при планировании и осуществлении ПЭК в организациях в части, не противоречащей действующему законодательству.<br>
Область применения справочника ограничена штатным режимом работы предприятий. Деятельность при аварийных ситуациях регламентируется документами в области промышленной безопасности — планом ликвидации аварий, планом ликвидации аварийной ситуации, планом ликвидации аварийных разливов нефти и т. п. и осуществляется под руководством представителей Министерства по чрезвычайным ситуациям Российской Федерации.<br>
Документ не содержит перечней маркерных веществ и технологических показателей для каких-либо отраслей промышленности. Рекомендации, содержащиеся в настоящем межотраслевом (горизонтальном) Справочнике НДТ, подлежат применению в случае отсутствия соответствующих рекомендаций в отраслевом (вертикальном) справочнике НДТ, к области применения которого относится рассматриваемое предприятие (объект).<br>
ИТС НДТ подготовлен в первую очередь для применения на объектах, оказывающих негативное воздействие на окружающую среду и отнесенных к объектам I категории, но не исключает возможности применения для объектов II и III категорий.<br>
Основные принципы производственного экологического контроля и его метрологического обеспечения, изложенные в справочнике, могут также быть приняты во внимание практиками, отвечающими за разработку и реализацию программ производственного контроля других объектов.</p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1916">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg25.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности, определяемые в соответствии с общероссийским классификатором видов экономической деятельности (ОКВЭД 2) ОК 029-2014 (КДЕС Ред. 2) и в соответствии с общероссийским классификатором продукции по видам экономической деятельности (ОКПД 2) ОК 034-2014 (КПЕС 2008) (приняты и введены в действие приказом Федерального агентства по техническому регулированию и метрологии от 31 января 2014 года № 14):<br> 
а) добыча и обогащение железных руд, включая: <br>
1) открытую добычу железных руд; <br>
2) подземную добычу железных руд; <br>
3) обогащение железных руд;<br>
б) окускование, производство железорудных окатышей; <br>
</p>
</div>
<p>в) производство железа прямого восстановления; <br>
г) дополнительные виды деятельности с целью подготовки железорудного сырья: дробление, измельчение, классификация, фильтрование, сушка и сортировка железных руд, окомкование.<br>
Документ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий и (или) масштабы загрязнения окружающей среды:<br>
- процессы, использующие методы предотвращения и сокращения эмиссий загрязняющих веществ и парниковых газов, утилизации отходов;<br>
- хранение и транспортировка железорудного сырья.
<br>
ИТС НДТ не распространяется на:<br>
- производство черных металлов;<br>
- деятельность, которая касается исключительно обеспечения промышленной безопасности или охраны труда.
<br>
Вопросы охраны труда рассматриваются частично и только в тех случаях, когда оказывают влияние на виды деятельности, включенные в область применения настоящего справочника НДТ.</p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1917">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg26.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности, определяемые в соответствии с общероссийским классификатором видов экономической деятельности (ОКВЭД 2) ОК 029–2014 (КДЕС Ред. 2) и в соответствии с общероссийским классификатором по видам продукции (ОКПД 2) ОК 034–2014 (КПЕС 2008):<br> 
- агломерация железных руд; <br>
- производство кокса и полукокса, бурого угля (лигнита) или торфа, угля ретортного; <br>
- производство чугуна зеркального и передельного в чушках, болванках и прочих первичных формах; <br>

</p><br>
</div>
<p>- производство ферросплавов, включая ферросилиций, силикокальций, силикомарганец, электроферромарганец, ферровольфрам, ферромолибден, феррованадий, пятиокись ванадия, феррониобий, ферротитан, ферробор, силикоцирконий, феррофосфор, ферроманганофосфор, феррохром, ферросиликохром, ферросиликомарганец, ферромарганец, ферросплавы и лигатуры;<br>
- производство стали нелегированной в слитках или прочих первичных формах и полуфабрикатов из нелегированной стали; <br>
- производство стали нержавеющей в слитках или прочих первичных формах и полуфабрикатов из нержавеющей стали; <br>
- производство стали легированной прочей в слитках или в прочих первичных формах и полуфабрикатов из прочей легированной стали.<br>
Документ также распространяется на процессы, связанные с основными перечисленными выше видами деятельности, которые могут оказать влияние на объемы и состав эмиссий и/или масштабы загрязнения окружающей среды:<br>
- процессы, использующие методы предотвращения и сокращения эмиссий загрязняющих веществ (выбросы в атмосферу и сбросы в водные объекты или канализацию), утилизации отходов;<br>
- деятельность по плавке и/или рафинированию черных металлов из руды, чушек или лома с использованием методов электрометаллургии и прочих металлургических процессов;<br>
- производство сплавов металлов, включая сплавы со специальными свойствами (например, сверхпрочные сплавы), путем добавления в исходный чистый металл прочих химических элементов.
<br>
ИТС НДТ не распространяется на:<br>
- производство изделий дальнейшего передела черных металлов;<br>
- генерацию электрической и тепловой энергии;<br>
- водоотведение и очистку хозяйственно-бытовых стоков;<br>
- деятельность, которая касается исключительно обеспечения промышленной безопасности или охраны труда.</p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1918">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg27.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:<br> 
- производство горячего проката; <br>
- производство холоднокатаного проката; <br>
- производство длинномерной продукции; <br>
- производство горячекатаных труб;<br>
- производство горячепрессованных труб; <br>
- производство холоднодеформированных труб; <br>
- производство прямошовных труб, изготовленных электродуговой сваркой под слоем флюса (ТБД);<br>
- производство электросварных труб;<br>
- производство труб непрерывной печной сваркой;<br>
- производство труб сваркой в среде инертных газов;<br>
- производство труб с покрытием.
<br>

</p><br>
</div>
<p>Документ также распространяется на процессы, связанные с основными видами деятельности, которые могут оказать влияние на объемы эмиссий и (или) масштабы загрязнения окружающей среды: методы предотвращения и сокращения эмиссий и образования отходов.</p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1919">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg31.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды деятельности:<br> 
- производство технического сероуглерода; <br>
- производство ксантогенатов калия: амилового и бутилового; <br>
- производство диметилдитиокарбамата натрия; <br>
- производство кормового метионина;<br>
- производство α-олефинов; <br>
- производство дициклопентадиен; <br>
- производство неодеканоата неодима;<br>
- производство алкилфенолов;<br>
- производство оксиэтилированных алкилфенолов (неонолы);<br>
- производство циклогексана;<br>
- производство тримеров и тетрамеров пропилена;<br>
</p>
</div>
<p>- производство диизобутилалюминийгидрида;<br>
- производство гексанового растворителя;<br>
- производство этилцеллозольва;<br>
- производство этилалюминийсесквихлорида;<br>
- производство полиэтиленгликолей;<br>
- производство пропиленгликолей;<br>
- производство метоксиполиэтиленгликолей;<br>
- производство ненасыщенных высокомолекулярных полиэтиленгликолей;<br>
- производство простых полиэфиров;<br>
- производство триэтилалюминия;<br>
- производство триизобутилалюминия;<br>
- производство очищенного пропана;<br>
- производство терефталоилхлорида-Т чешуированного;<br>
- производство ингибитора коррозии Викор ИСК-Д;<br>
- производство Каустамина-15;<br>
- производство катионного водорастворимого полиэлектролита ВПК-402;<br>
- производство Оксилина-6;<br>
- производство смазки Олеол-М;<br>
- производство сульфированной продукции: алкилбензолсульфокислоты, сульфоэтоксилатов жирных спиртов, алкилсульфата, алкилбензолсульфоната натрия (сульфонола);<br>
- производство оксиалкилированной продукции: синтанолов, полиэтиленгликолей, метоксиполиэтиленгликолей;<br>
- производство этаноламинов;<br>
- производство эмульгаторов ОП;<br>
- производство Синтамида-5К;<br>
- производство диметилэтаноламина;<br>
- производство метилдиэтаноламина;<br>
- производство поликарбоксилатов;<br>
- производство поглотителей сероводорода;<br>
- производство интенсификаторов помола;<br>
- производство хлорированных парафинов;<br>
- производство противоизносной присадки для дизельных топлив АддиТОП СМ-А;<br>
- производство 1М раствора боран-тетрагидрофуранового комплекса в тетрагидрофуране;<br>
- производство диметиламинборана;<br>
- производство метоксидиэтилборана;<br>
- производство триэтилбора;<br>
- производство диметилового эфира;<br>
- производство диэтилового эфира;<br>
- производство хладонов;<br>
- производство гексафторбутанола;<br>
- произвосдвто 1,4-дийодперфторбутан;<br>
- производство мономеров;<br>
- производство тетрафторэтилметилового эфира;<br>
- производство перфторкислот;<br>
- производство хлористого метилена;<br>
- произвосдтво хлористого этила;<br>
- производство метанового хлороформа;<br>
- произвосдтво четырехлористого углерода;<br>
- производство тетраэтоксисилана;<br>
- производство этилсиликата;<br>
- произвосдтво дифинилгуанилина;<br>
- производство ацетонанила-Н;<br>
- производсвто средств защиты растений.<br>
<br>
Документ также распространяется на технологические процессы, связанные с основными видами деятельности, которые могут оказать или оказывают влияние на количество (массы) эмиссий в окружающую среду или на масштабы загрязнения окружающей среды:<br>
- хранение и подготовка сырья;<br>
- производственные процессы;<br>
- методы предотвращения и сокращения эмиссий и образования отходов;<br>
- упаковка и хранение продукции.
<br>
ИТС НДТ не распространяется на:<br>
- добычу и обработку сырья на месторождениях;<br>
- вопросы, относящиеся исключительно к обеспечению промышленной безопасности или охране труда.
<br>
Вопросы охраны труда рассматриваются частично и только в тех случаях, когда они оказывают непосредственное влияние на виды деятельности, включенные в область применения настоящего справочник НДТ.</p>
</div>

<div class="seven__block-3" v-if="trgData.etkstructure_id == 1920">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg40.jpg" alt="" />
<p>Данный информационно-технический справочник по наилучшим доступным технологиям (ИТС НДТ) распространяется на следующие основные виды экономической деятельности без ограничений по производственной мощности:<br> 
- производство кожи из шкур крупного рогатого скота и лошадей; <br>
- производство кож из шкур овец, коз и свиней;<br>
- производство кож из шкур прочих животных, а также производство композиционной кожи. <br>
В соответствии с общероссийским классификатором видов экономической деятельности кожевенное производство относится к 15 классу: «Производство кожи и изделий из кожи». Данный класс входит в состав «Раздела C – Обрабатывающие производства» классификатора 2021 года ОКВЭД-2.</p>
</div>

</div>


<div class="seven__block-3" v-if="trgData.etkstructure_id == 1929">
            <h3 class="seven__block3-subtitle"></h3>
            <div class="seven__block3-content">
              <img class="seven__block3-img" src="/img/trg52.jpg" alt="" />
<p>Цель создания информационно-технического справочника наилучших доступных технологий «Обращение с отходами I и II классов опасности» (далее – справочник НДТ) заключается в систематизации сведений о технологических процессах, методах, способах, оборудовании и средствах, применяемых при обращении с отходами I и II классов опасности в Российской Федерации, и в подготовке упорядоченных данных о наилучших доступных технологиях, применяемых в Российской Федерации и направленных на комплексное предотвращение и (или) минимизацию негативного воздействия на окружающую среду.</p><br>
</div>
<p>Настоящий справочник НДТ содержит систематизированные данные в области обращения с отходами I и II классов опасности и разработан с учетом имеющихся в Российской Федерации технологий, оборудования, ресурсов, а также с учетом климатических, геоморфологических, геологических, экономических и социальных особенностей Российской Федерации.<br><br>
*Завершение разработки информационно-технический справочник по наилучшим доступным технологиям планируется на конец сентября 2022 года.<br>
</p>
</div>


  





          <div class="seven__block-1">
            <div class="seven__contact">
              <h3 class="seven__contact-title">СТАТУС</h3>
              <span
                v-if="trgData.etkstructure_status"
                class="seven__contact-subtitle"
                >АКТИВЕН</span
              >
              <span v-else class="seven__contact-subtitle">Не Активен</span>
              <!-- <span class="seven__contact-subtitle">АКТИВЕН</span> -->
            </div>
            <div
              class="mmm"
              v-for="secr in trgData.secr_data"
              :key="secr.users_id"
            >
              <div class="seven__contact">
                <h3 class="seven__contact-title">ОТВЕТСТВЕННЫЙ СЕКРЕТАРЬ</h3>
                <span class="seven__contact-subtitle">{{
                  secr.users_fio
                }}</span>
              </div>
              <div class="seven__contact">
                <h3 class="seven__contact-title">ТЕЛЕФОН</h3>
                <a href="javascript:void(0);" class="seven__contact-subtitle">{{
                  secr.user_tel
                }}</a>
              </div>
              <div class="seven__contact">
                <h3 class="seven__contact-title">ЭЛЕКТРОННЫЙ АДРЕС</h3>
                <a href="#" class="seven__contact-subtitle">{{
                  secr.users_email
                }}</a>
              </div>
            </div>
          </div>

          <div class="seven__block-2">
            <div class="seven__contact2">
              <h3 class="seven__contact2-title">Полноправные члены</h3>
              <a
                href="javascript:void(0);"
                class="seven__link-contact"
                v-for="member in trgData.members"
                :key="member.organiz_id"
              >
                <span class="seven__link-content">{{
                  member.organiz_nameshort
                }}</span>
                <br>
              </a>
              <div class="seven__link-block"></div>
            </div>

            <div class="seven__contact2">
              <h3 class="seven__contact2-title">Наблюдатели</h3>
              <a
                href="javascript:void(0);"
                class="seven__link-contact"
                v-for="observer in trgData.observers"
                :key="observer.organiz_id"
              >
                <span class="seven__link-content">{{
                  observer.organiz_nameshort
                }}</span><br>
              </a>
              <div class="seven__link-block"></div>
            </div>
            <div class="seven__contact2">
              <a href="javascript:void(0);" class="seven__link-contact">
                <h3 class="seven__contact2-title">
                  Область деятельности и др.
                </h3>
                <span
                  class="seven__link-content"
                  v-html="trgData.etkstructure_note"
                ></span>
              </a>
              <div class="seven__link-block"></div>
            </div>
            <div class="seven__contact2">
              <a href="javascript:void(0);" class="seven__link-contact">
                <h3 class="seven__contact2-title">Документы</h3>
                <!-- <span class="seven__link-content">...</span> -->
              </a>
              <div
                class="seven__link-block"
                v-for="doc in trgData.docs"
                :key="doc.etkdocs_id"
              >
                <a
                  :href="makeUrl(doc.etkdocs_id, trgData.etkstructure_id)"
                  class="seven__link-title"
                  >{{ doc.etkdocs_ond }}</a
                >
                <p class="seven__link-description">
                  {{ doc.etkdocs_nnd }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <BackButton :back="backLink" />
      </div>
     <!--  <BackButton :back="backLink" /> -->
      <!-- </div> -->
    </section>
  </main>
</template>

<script>
import useTrgMessages from "@/composables/trg/trg_messages";
import YearsMenu from "../YearsMenu.vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import BackImagesComp from "../BackImagesComp.vue";
import TrgSubMenu from "./TrgSubMenu.vue";
import BackButton from "../BackButton.vue";

export default {
  name: "TrgMessages",
  components: {
    YearsMenu,
    BackImagesComp,
    TrgSubMenu,
    BackButton,
  },
  props: {},
  setup() {
    const title = "Технические <br> рабочие группы";
    const page = "/trg/";
    const route = useRoute();
    const yearActive = route.params.year;
    const backLink = ref("/trg/" + yearActive); //ссылка для кнопки назад
    const { trgData, fetchTrgData, loader, makeUrl } = useTrgMessages();
    onMounted(() => {
      fetchTrgData(route.params.year, route.params.id);
    });

    return { trgData, loader, makeUrl, yearActive, backLink, page, title };
  },
};
</script>

<style scoped>
/*----------------------------SEVEN--------------------------------------*/

.six,
.seven {
  padding: 0 50px;
}
/* my */
.mmm .seven__contact {
  margin-top: 1.5rem;
}
/* end my */
.six,
.seven {
  /* padding: 0 4.5vw; */
}
.seven__wrapper {
  margin: 5vh;
    margin-bottom: 200px;
    height: auto;
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: 1vh 3vh;
    background: white;
    position: relative;
}

.seven__head-block {
  border-bottom: 2px solid black;
}
.seven__head-title {
  /* font-size: calc(100vw / 80);
  font-weight: 300; */
  font-size: 22px;
}
.seven__head-subtitle {
  /* font-size: calc(100vw / 55);
  margin: 0;
  padding: 2vh 0; */
  font-size: 35px;
  margin: 0;
}
.seven__wrapp {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}
.seven__block-1 {
  width: 25%;
  height: 15vw !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.seven__contact {
  /*max-width: 610px;
  height: 77px;*/
}
.seven__contact-title {
  margin: 5px 2px;
  font-weight: 300;
  font-size: calc(100vw / 100);
}
.seven__contact-subtitle {
  margin: 0 2px;
  color: black;
  font-weight: 700;
  font-size: calc(100vw / 80);
  text-decoration: none;
}
.seven__block-2 {
  width: 55%;
}
.seven__contact2 {
  background: #f4f9fb;
  /*height: 90px;*/
  margin-bottom: 30px;
}
.seven__link-contact {
  /*margin: 0 10px;*/
  margin: 0;
  text-decoration: none;
}
.seven__contact2-title {
  margin: 10px;
  color: black;
  /*font-size: calc(100vw/100);*/
  font-size: 1.2vw;
  padding: 1vw 0;

}
.seven__link-content {
  /*margin: 0 10px;*/
  margin: 0;
  font-size: calc(100vw / 100);
}
.seven__link-block {
  background: #f4f9fb;
  padding: 10px;
  /*height: 900px;*/
  font-size: 1.2vw;
}
.seven__link-title {
  color: #010101;
}
.seven__link-description {
  color: #848182;
  /*font-size: calc(100vw/110);*/
}
.seven__block-3 {
  display: block;
  margin: 3vh 0;
}
.seven__block-3 p {
  font-size: calc(100vw / 65);
}
.seven__block3-subtitle {
  font-size: calc(100vw / 55);
}
.seven__block3-content {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
}
.seven__block3-content p {
  font-size: calc(100vw / 65);
  margin-left: 30px;
}
.seven__block3-img {
  /*width: calc(100vw/5);*/
  height: calc(100vw / 3);
  float: left;
  margin-right: 1.5rem;
}

.seven__wrapp {
  /*width: 95%;*/
  margin: 7vh 0;
  height: auto;
}
.seven__bottoms {
  display: flex;
  justify-content: space-between;
  width: 35%;
}
.seven__bottom {
  color: #a6a4a4;
  border-left: 1px solid #a6a4a4;
  padding: 1vw !important;
  font-size: calc(100vw / 75);
  /* max-width: 400px;*/
}
.seven__bottom:hover {
  color: black;
  border-left: 1px solid black;
  cursor: pointer;
}

.seven__close-wrap {
  display: flex;
  justify-content: flex-end;
}
.seven__close-end {
  display: flex;
  justify-content: flex-end;
  width: 9%;
}
.seven__close-link {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
}
.seven__close-img {
  width: 45%;
  padding: 0 10px;
}
.seven__close-end span {
  border-bottom: 1px solid #0074b0;
  color: #0074b0;
  display: inline-block;
  font-size: calc(100vw / 105);
  font-style: italic;
}

@media (max-width: 1440px) {
  .six {
    padding: 0 2vw;
  }

  .six__wrapper {
    margin: 5vw;
  }
  .six__link-text {
    font-size: 1.4vw;
  }
  .six__track {
    width: 70%;
    border-top: 3px solid #fca14e;
  }
  .six__year span {
    font-size: 1.8vw;
    margin-top: 5px;
  }
  .six__track {
    margin-top: 8vw;
  }

  .seven__wrapper {
    margin: 3vh 0;
    padding: 3vw;
  }
  .seven__wrapp {
    margin: 5vw 0;
  }
  .seven {
    padding: 0 5.5vw;
  }
  .seven__head-title,
  .seven__head-subtitle {
    font-size: 2vw;
  }
  .seven__head-subtitle {
    padding: 1vh 0;
  }
  .seven__block3-content p,
  .seven__block-3 p {
    font-size: 1.6vw;
  }
  .seven__contact2-title {
    font-size: 1.4vw;
    margin: 0 10px;
  }
  .seven__contact-title,
  .seven__contact-subtitle {
    font-size: 1.2vw;
    margin: 0;
  }
  .seven__block-1 {
    width: 33%;
    height: 25vw;
  }
  .seven__link-content {
    font-size: 2vw;
  }
  .seven__close-end span {
    font-size: 1.5vw;
  }
  .seven__close-img {
    width: 30%;
    margin: 0 1vw;
    padding: 0;
  }
  .seven__close-end {
    width: 16%;
  }
  .seven__link-block {
    font-size: 1.4vw;
  }
}

/*----------------------------MOBILE---------------------------------*/

@media (max-width: 480px) {
  .main {
  }
  .pl-leftm,
  .pl-left2m,
  .pl-right2m,
  .pl-rightm,
  .pl-bottom {
    display: block;
    position: absolute;
    z-index: -1;
  }

  .pl-left {
    width: calc(100vw / 3);
    bottom: 94%;
  }
  .pl-leftm {
    top: 30%;
    left: 91%;
    right: 0;
    width: calc(100vw / 12);
  }
  .pl-left2m {
    top: 30%;
    left: -5%;
    width: 19vw;
  }
  .pl-right {
    display: none;
  }
  .pl-rightm {
    right: 0;
    width: calc(100vw / 6);
    top: 75%;
    position: absolute;
    z-index: -1;
  }
  .pl-right2m {
    left: 0;
    width: 27vw;
    top: 40%;
  }
  .pl-bottom {
    bottom: -100px;
    width: 55%;
    right: 0;
    z-index: 0;
  }

  .six,
  .seven {
    padding: 0 3vw;
  }
  .six__track {
    position: absolute;
    width: auto;
    height: 540px;
    border-left: 2px solid #fca14e;
    border-top: none;
    margin-top: auto;
  }
  .six__years {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 37px;
    height: 600px;
    bottom: 30px;
  }
  .six__year {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 17vw;
    max-width: 60px;
    justify-content: space-between;
  }
  .six__year-img {
    width: 20px;
  }
  .six__year span {
    font-size: 3.5vw;
  }
  .six__year:first-child,
  .six__year:last-child {
    right: 0;
    left: 0;
  }

  .six__wrapper {
    padding: 0;
  }
  .six__title-main {
    font-size: 29px;
    max-width: 300px;
  }
  .six__block-top {
    height: 77px;
    margin: 20px 0 70px 0;
  }
  .six__title {
    font-size: 29px;
    color: #0071af;
    margin: 0;
  }
  .six__link-text {
    color: #c5c5c5;
    font-size: 14px;
    padding-top: 10px;
  }
  .six__items {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .six__item {
    padding: 7px 20px;
    margin: 10px 0;
    font-size: 18px;
  }
  .seven__head-title {
    font-size: 14px;
    font-weight: 300;
    margin: 10px 0;
  }
  .seven__head-subtitle {
    font-size: 13px;
    margin: 0;
  }
  .seven__head-block {
    border-bottom: 1px solid black;
  }
  .seven__bottoms {
    display: none;
  }
  .seven__wrapper {
    padding: 10px 15px;
    margin: 0 22vw 10vh;
    width: 75%;
  }
  .seven__wrapp {
    margin: 0;
  }
  .seven__block-1 {
    width: auto;
    height: 50vw !important;
    margin-bottom: 7vw;
  }
  .seven__contact-title {
    font-size: 11px;
  }
  .seven__block-2 {
    width: auto;
    height: auto;
    padding-top: 22px;
  }
  .seven__block3-img {
    height: auto;
    width: 100%;
  }
  .seven__block3-content {
    flex-direction: column;
  }
  .seven__block3-subtitle {
    font-size: 15px;
  }
  .seven__block3-content p {
    font-size: calc(3px + 45 * (100vw / 1280));
    margin-left: 0;
  }
  .seven__block-3 p {
    font-size: 13px;
    margin: 10px 0;
  }
  .seven__contact-subtitle {
    font-size: 13px;
  }
  .seven__contact2 {
    margin-bottom: 20px;
  }
  .seven__contact2-title {
    font-size: 13px;
    margin: 0 10px;
  }
  .seven__link-block {
    padding: 0 10px 10px;
  }
  .seven__link-content {
    font-size: 15px;
  }
  .seven__link-title {
    font-size: 13px;
  }
  .seven__link-description {
    font-size: 13px;
  }
  .seven__close-wrap {
    display: none;
  }
}
</style>

import { ref } from "vue"
import { environment } from "@/composables/realhost.js"

export default function useAllNews() {
	const newsData = ref([])
	const oneNewsData = ref([])
	const count = ref(0);
	const loader = ref(true)

	function fetchNews(without = 0, quality = 10) {
		loader.value = true
		// let year = (new Date()).getFullYear()
		// fetch("http://slimtest/documents")
		fetch(environment.realHost + "news/" + without + "/" + quality)
			.then((response) => response.json())
			.then((data) => {
				for(let i = 0; i < data['news'].length; i++){
					sliceArr(data['news'][i], 300, 'content')
				}
				newsData.value = data['news']
				count.value = Math.ceil(data['count']/10) 
				loader.value = false
				document.body.style.overflow = 'auto'
			})
	}
	function sliceArr(arr, nums, field){
		for(var key in arr){
			if(key == field){
				if(arr[key].length >= nums){
					arr[key] = (cutTegs(arr[key])).slice(0, nums) + '...'
				} else {
					arr[key] = cutTegs(arr[key])
				}
				
			}
		}
	}

	function fetchOneNews(id) {
		fetch(environment.realHost + "news/" + id)
			.then((response) => response.json())
			.then((data) => {
				data[0].content = data[0].content.split("[[")[0]
				oneNewsData.value = data[0]
				loader.value = false
				document.body.style.overflow = 'hidden'
			})
	}

	function makeNewsDate(ms) {
		ms = ms + "000"
		ms = parseInt(ms, 10)
		let d = new Date(ms)
		let formatter = new Intl.DateTimeFormat("ru", {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric",
		})
		let formatter1 = new Intl.DateTimeFormat("ru", {
			hour: "numeric",
			minute: "numeric",
		})
		return formatter.format(d) + " | " + formatter1.format(d)
	}

	function getPicExtension(str) {
		if(str !== null){
			let arr = str.split(".")
			let ext = arr[arr.length - 1]
			return ext
		}
		
	}
	function cutTegs(str) {
		var regex = /( |<([^>]+)>)/ig
		var result = str.replace(regex, " ")
		return result
	}

	return {
		fetchNews,
		newsData,
		loader,
		makeNewsDate,
		getPicExtension,
		fetchOneNews,
		oneNewsData,
		cutTegs,
		count,
	}
}

<template>
    <div class="actualisation-container" v-if="loadermain">
        <loader object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40"
                    objectbg="#999793" opacity="80" name="circular"></loader>
    </div>
    <div class="actualisation-container" v-else>
        <p class="page-title">Всего ИТС НДТ</p>
        <div class="block-number">
            <p class="block-number-point">{{ allCount }}</p>
        </div>
        <div class="main-block">
            <div class="main-block-actual">
                <div class="context" @click="showActual" :class="{ active: actual }">
                    <div class="context-title">Актуализация</div>
                    <div class="context-point">{{ actualCount }}</div>
                </div>
            </div>
            <div class="main-block-actual">
                <div class="context" @click="showIndicative" :class="{ active: indicativ }">
                    <div class="context-title">Индикативные<br>показатели</div>
                    <div class="context-point">{{ indicativeCount }}</div>
                </div>
            </div>
        </div>
        <hr class="hrr">
        <div class="sub-main-block">
            <div class="actualisations" v-if="actual">
                <template v-if="actualData.length > 0">
                    <div class="actualisation-item" v-for="data in actualData" :key="data.etkstructure_id">
                        <div class="actualisation-title">
                            <router-link :to="'/trg/' + year + '/' + data.etkstructure_id +'/info'">
                                <h3>{{ data.etkstructure_num }} {{ data.etkstructure_title }}</h3>
                            </router-link>
                        </div>
                        <div class="actualisation-content">
                            <p>Формирование ТРГ: <span>до {{ getDates(data.etkstructure_date) }}</span></p>
                            <p>Полноправные члены: <span>{{ data.members_count }}</span></p>
                            <p>Наблюдатели: <span>{{ data.observers_count }}</span></p>
                            <p>Росприроднадзор: <span>1</span></p>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <h3>Нет данных</h3>
                </template>
            </div>
            <div class="actualisations" v-else-if="!actual && loader">
                <loader object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40"
                    objectbg="#999793" opacity="80" name="circular"></loader>
            </div>
            <div class="indicative" v-if="indicativ">
                <template v-if="actualData.length > 0">
                    <div class="actualisation-item" v-for="data in actualData" :key="data.etkstructure_id">
                        <div class="actualisation-title">
                            <router-link :to="'/trg/' + year + '/' + data.etkstructure_id +'/info'">
                                <h3>{{ data.etkstructure_num }} {{ data.etkstructure_title }}</h3>
                            </router-link>
                        </div>
                        <div class="actualisation-content">
                            <p>Формирование ТРГ: <span>до {{ getDates(data.etkstructure_date) }}</span></p>
                            <p>Полноправные члены: <span>{{ data.members_count }}</span></p>
                            <p>Наблюдатели: <span>{{ data.observers_count }}</span></p>
                            <p>Росприроднадзор: <span>1</span></p>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="no-datas">
                        <h3>Нет данных</h3>
                    </div>
                </template>
            </div>
            <div class="indicative" v-else-if="!indicativ && loader">
                <loader object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40"
                    objectbg="#999793" opacity="80" name="circular"></loader>
            </div>
        </div>
    </div>
</template>

<script>

import getItsActual from "@/composables/its/actualisation.js";
import { onMounted } from "vue";
export default {
    setup() {
        const { getActual, getIndicative, getCounts, getDates, loader, actualData, actual, indicativ, allCount, actualCount, indicativeCount, loadermain } = getItsActual();
        const year = '2023'

        onMounted(() => {
            getCounts(year)
        })

        function showActual() {
            if(!actual.value){
                getActual(year)
            }
            

        }
        const showIndicative = () => {
            if(!indicativ.value){
                getIndicative(year)
            }
            
        }
        return {
            showActual, showIndicative, getCounts, getDates, actual, indicativ, loader, actualData, allCount, actualCount, indicativeCount, year, loadermain
        }
    }
}

</script>

<style scoped>
.actualisation-container {
    margin-top: 6rem;
    width: 100%;
    text-align: center;
}

.page-title {
    /* font-family: 'Montserrat'; */
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
}

.block-number-point {
    color: #54B69C;
    font-size: 120px;
    font-weight: bold;
    margin: 0 auto;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
}

.block-number-point::before {
    content: "";
    /* Указываем что наши линии будут строчно-блочные и 
    ** выравнивание по высоте - по центру */
    display: inline-block;
    vertical-align: middle;
    /* Задаем ширину 100% и выбираем высоту линии, 
    ** в нашем примере она равна 4 пикселям */
    width: 100%;
    height: 4px;
    /* Добавляем цвет для линии */
    background-color: #F3F3F3;
    /* Добавляем пседоэлемантам возможность изменить 
    ** позицию линии, для создания отступов от текста */
    position: relative;
    margin-left: -100%;
    left: -30px;
}

.block-number-point::after {
    content: "";
    /* Указываем что наши линии будут строчно-блочные и 
    ** выравнивание по высоте - по центру */
    display: inline-block;
    vertical-align: middle;
    /* Задаем ширину 100% и выбираем высоту линии, 
    ** в нашем примере она равна 4 пикселям */
    width: 100%;
    height: 4px;
    /* Добавляем цвет для линии */
    background-color: #F3F3F3;
    /* Добавляем пседоэлемантам возможность изменить 
    ** позицию линии, для создания отступов от текста */
    position: relative;
    margin-right: -100%;
    right: -30px;
}

.main-block {
    width: 40%;
    /* border: 1px solid black; */
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.main-block-actual {
    width: 40%;
}

.context {
    display: flex;
    border: 3px solid #D2D2D2;
    border-radius: 20px;
    padding: 10px;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

.context.active {
    background-color: #D4E9F5;
    border-color: #D4E9F5;
}

.context.active .context-point {
    color: #2D89C2
}

.context-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 16.8px
}

.context-point {
    color: #d2d2d2;
    font-size: 80px;
    font-weight: 700;
}

.actualisations,
.indicative {
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.actualisation-item {
    padding: 10px;
    width: 30%;
    background-color: #F3F3F3;
    margin-bottom: 2rem;
    border-radius: 15px;
}

.hrr {
    width: 50%;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.actualisations .actualisation-item .actualisation-title a {
    text-decoration: none;
    color: #2D89C2;
}

.indicative .actualisation-item .actualisation-title a {
    text-decoration: none;
    color: #54B69C;
}
.no-datas{
    width: 100%;
}
.actualisation-title{
    border-bottom: 1px solid lightgray;
    text-align: left;
}
.actualisation-content{
    text-align: left;
    margin: 1rem;
}
.actualisation-content span{
    color: #2d89c2;
    font-weight: bolder;
}
</style>

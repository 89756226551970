<template>
  <main class="main">
    <back-images-comp />
    <YearsMenu :page="page" :title="title" />
    <section v-if="loader" class="eleven">
      <loader
        object="#ff9633"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular"
      ></loader>
    </section>
    <section v-else class="seven">
      <div class="seven__wrapper">
        <div class="seven__head-block">
          <h2 class="seven__head-title">{{ trgData.etkstructure_num }}</h2>
          <h3 class="seven__head-subtitle">
            {{ trgData.etkstructure_title }}
          </h3>
        </div>
        <TrgSubMenu />
        <div class="twentysix__items" v-if="meetings.length">
          <div
            class="twentysix__item"
            v-for="meeting in meetings"
            :key="meeting.etkmeetings_id"
          >
            <TK113Accordeon>
              <template v-slot:title>
                <!-- <div class="twentysix__item-wrapp"> -->
                  <div class="twentysix__block1">
                    <span class="twentysix__block1-span">{{
                      meeting["etkmeetings_dateb"]
                    }}</span>
                    <h3 href="#" class="twentysix__block1-h3">
                      {{ meeting["etkmeetings_name"] }}
                    </h3>
                  </div>
                <!-- </div> -->
              </template>
              <template v-slot:content>
                <!-- <div class="twentysix__prolapse"> -->
                  <div class="twentysix__prolapse-wrapp">
                    <div class="twentysix__prolapse1">
                      <span class="twentysix__text1">место проведения</span>
                      <a href="#" class="twentysix__text2">
                        {{ meeting["etkmeetings_place"] }}
                      </a>
                    </div>
                  </div>
                  <div class="twentysix__prolapse2">
                    <span class="twentysix__text3">материалы заседания</span>
                    <div class="twentysix__files-link">
                      <a
                        :href="makeFileUrl(file.etkmeetingsfiles_id)"
                        class="twentysix__file-link"
                        v-for="file in meeting['files']"
                        :key="file['etkmeetingsfiles_id']"
                      >
                        <img
                          src="/img/49.png"
                          alt=""
                          class="twentysix__img-link"
                        />
                        {{ file["etkmeetingsfiles_name"] }}
                      </a>
                    </div>
                  </div>
                <!-- </div> -->
              </template>
            </TK113Accordeon>
          </div>
        </div>
        <div class="twentysix__items_nodata" v-else>
          <h3>Данные отсутствуют</h3>
        </div>
        <BackButton :back="backLink" />
      </div>
    </section>
  </main>
</template>

<script>
import useTrgMeetings from "@/composables/trg/trg_meetings";
import useTrgMessages from "@/composables/trg/trg_messages";
import YearsMenu from "../YearsMenu.vue";
import BackImagesComp from "../BackImagesComp.vue";
import TrgSubMenu from "./TrgSubMenu.vue";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import TK113Accordeon from "../tk113/TK113Accordeon.vue";
import BackButton from "../BackButton.vue";
export default {
  name: "TrgMeetings",
  components: {
    YearsMenu,
    BackImagesComp,
    TrgSubMenu,
    TK113Accordeon,
    BackButton,
  },
  setup() {
    const title = "Технические <br> рабочие группы"
    const page = "/trg/"
    const route = useRoute();
    const yearActive = route.params.year;
    const { trgData, fetchTrgData, loader, makeUrl } = useTrgMessages();
    const { fetchMeetings, meetings, makeFileUrl } = useTrgMeetings();
    const backLink = ref("/trg/" + yearActive); //ссылка для кнопки назад
    onMounted(() => {
      fetchTrgData(route.params.year, route.params.id);
      fetchMeetings(route.params.year, route.params.id);
    });
    return {
      trgData,
      loader,
      makeUrl,
      yearActive,
      meetings,
      makeFileUrl,
      backLink,
      page,
      title
    };
  },
};
</script>

<style scoped>

/* my */
.twentysix__items_nodata {
  text-align: center;
  margin-top: 8%;
  margin-bottom: 8%;
}
.twentysix__items_nodata h3 {
  font-size: 25px;
}
/* end my */
.seven {
  padding: 0 50px;
}
.seven__wrapper {
  margin: 5vh;
  margin-bottom: 200px;
  height: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 1vh 3vh;
  background: white;
  position: relative;
}
.seven__head-block {
  border-bottom: 2px solid black;
}
.seven__head-title {
  font-size: 22px;
}
.seven__head-subtitle {
  font-size: 35px;
  margin: 0;
}

.twentysix__items {
  display: flex;
  flex-direction: column;
}
.twentysix__item {
  border-bottom: 2px solid #a6a4a4;
  padding-bottom: 20px;
  margin-top: 30px;
}
.twentysix__item:first-child {
  margin-top: 0;
}
.twentysix__item-wrapp {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.twentysix__block1 {
  font-size: 1.1vw;
}
.twentysix__block1-h3 {
  margin: 0;
  padding: 0;
  padding-top: 0.7rem;
}
.twentysix__block2 {
  border: 1px solid red;
}
.twentysix__block2-img,
.twentysix__block2-img2 {
  height: calc(100vw / 60);
  cursor: pointer;
}

.twentysix__prolapse {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
}
.twentysix__prolapse1 {
  display: flex;
  flex-direction: column;
  /* width: 60%; */
  padding-bottom: 2vh;
  line-height: 2.7vh;
  margin-top: 1rem;
}
.twentysix__prolapse2 {
  display: flex;
  flex-direction: column;
  width: 60%;
  line-height: 2.7vh;
}

.twentysix__text1,
.twentysix__text3 {
  color: #414141;
  font-size: 0.7vw;
  font-weight: 500;
  text-transform: uppercase;
}
.twentysix__text2 {
  color: #414141;
  font-size: calc(1px + 15 * (100vw / 1700));
  font-weight: 400;
  text-decoration: none;
}

.twentysix__files-link {
  display: flex;
  flex-direction: column;
}
.twentysix__file-link {
  color: #414141;
  font-size: calc(3px + 10 * (100vw / 1280));
  font-weight: 400;
  text-decoration: none;
}
.twentysix__img-link {
  height: 18px;
}

.none {
  display: none;
}

@media (max-width: 1440px) {
  .six {
    padding: 0 2vw;
  }

  .six__wrapper {
    margin: 5vw;
  }
  .six__link-text {
    font-size: 1.4vw;
  }
  .six__track {
    width: 70%;
    border-top: 3px solid #fca14e;
  }
  .six__year span {
    font-size: 1.8vw;
    margin-top: 5px;
  }
  .six__track {
    margin-top: 8vw;
  }

  .seven__wrapper {
    margin: 3vh 0;
    padding: 3vw;
  }
  .seven__wrapp {
    margin: 5vw 0;
  }
  .seven {
    padding: 0 5.5vw;
  }
  .seven__head-title,
  .seven__head-subtitle {
    font-size: 2vw;
  }
  .seven__head-subtitle {
    padding: 1vh 0;
  }
  .seven__block3-content p,
  .seven__block-3 p {
    font-size: 1.6vw;
  }
  .seven__contact2-title {
    font-size: 1.4vw;
    margin: 0 10px;
  }
  .seven__contact-title,
  .seven__contact-subtitle {
    font-size: 1.2vw;
    margin: 0;
  }
  .seven__block-1 {
    width: 33%;
    height: 25vw;
  }
  .seven__link-content {
    font-size: 2vw;
  }
  .seven__close-end span {
    font-size: 1.5vw;
  }
  .seven__close-img {
    width: 30%;
    margin: 0 1vw;
    padding: 0;
  }
  .seven__close-end {
    width: 16%;
  }
  .seven__link-block {
    font-size: 1.4vw;
  }
}

@media (max-width: 480px) {
  .seven {
    padding: 0 3vw;
    min-height: 620px;
  }
  .seven__head-title {
    font-size: 14px;
    font-weight: 300;
    margin: 10px 0;
  }

  .seven__head-subtitle {
    font-size: 13px;
    margin: 0;
  }

  .seven__head-block {
    border-bottom: 1px solid black;
  }

  .seven__wrapper {
    padding: 10px 15px;
    margin: 0 22vw 10vh;
    width: 75%;
  }
  .twentysix__items_nodata h3 {
    font-size: 18px;
  }
  .twentysix__block1-span {
    font-size: calc(3px + 25 * (100vw / 1280));
  }
  .twentysix__block1-h3 {
    font-size: calc(1px + 40 * (100vw / 1280));
    font-weight: 500;
    padding: 0;
  }
  .twentysix__item {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 0;
  }
  .twentysix__prolapse {
    display: block;
    margin-top: 1rem;
  }
  .twentysix__prolapse1 {
    width: 90%;
  }
  .twentysix__text1 {
    font-size: calc(3px + 25 * (100vw / 1280));
  }
  .twentysix__text2 {
    font-size: calc(1px + 40 * (100vw / 1280));
    font-weight: 500;
  }
  .twentysix__text3 {
    font-size: calc(3px + 25 * (100vw / 1280));
  }
  .twentysix__files-link a {
    font-size: calc(3px + 30 * (100vw / 1400));
    text-decoration: revert;
  }
}
</style>
<template>
  <div class="its_menu_content_item">
    <br><div class="seventeen__block3-content">
    <h2>14. Итоговое голосование</h2>
<p>Голосование проводится в случае наличия замечаний к справочнику, не снятых на <a href="https://burondt.ru/itc-development3/final-meeting">итоговом заседании</a> или на <a href="https://burondt.ru/itc-development3/removal-of-disagreements">заседании межведомственного совета</a>.</p>
<p>Полноправные члены голосуют в личном кабинете ТРГ на портале Бюро НДТ.</p>
<p>При принятии решения о готовности проекта справочника к утверждению Бюро НДТ начинает этап подготовки проекта справочника к утверждению.</p>
</div>

  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}
a {
  color: #000;
}
</style>
<template>
  <div class="its_menu_content_item">
    <br><div class="seventeen__block3-content">
    <h2>10. Публичное обсуждение</h2>
<br>
<p><a href="http://burondt.ru/discussion/2022">Публичное обсуждение </a>проводит Бюро НДТ, размещая проект справочника, типовую форму отзыва и уведомление о публичном обсуждении на своем официальном сайте в разделе &laquo;Публичное обсуждение&raquo;.</p>
<p>В публичном обсуждении вправе принять участие любое заинтересованное лицо, заполнив и разместив свои предложения/замечания на сайте Бюро НДТ.</p>
<p>Срок проведения публичного обсуждения, который определяет Бюро, не может составлять менее 15 календарных дней.</p>
<p>После завершения публичного обсуждения проекта справочника Бюро НДТ формирует сводку отзывов, в которой приводит решения рабочей группы, принятые по результатам анализа каждого из замечаний.</p>
 <br>
<center><img src="/img/itc/public-discussion-img.jpg" alt="" width="1000" height="285" /></center>  
</div>

  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}
</style>
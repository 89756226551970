<template>
  <div class="its_menu_content_item">
    <br><div class="seventeen__block3-content">
    <h2>14.1 Снятие разногласий</h2>
<p>При наличии существенных разногласий по проекту справочника, созывается заседание <a href="https://burondt.ru/mvs/documents">Межведомственного совета по переходу на наилучшие доступные технологии и внедрению современных технологий</a> (МВС).
  </p>
<p>На заседании совет рассматривает разногласия, препятствующие принятию решения о готовности проекта справочника к утверждению, и выносит решение о готовности проекта или о необходимости его дополнительной доработки. Решение оформляется протоколом, направляемым в Бюро НДТ.</p>
</div>

  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}
</style>
<template>
  <div class="main">
    <BackImageComp/>
    <section class="twentyseven">
      <div class="twentyseven__wrapper">
        <h1 class="twentyseven__title-main">Контакты</h1>
        <div class="twentyseven__items">
          <div class="twentyseven__item">
            <span class="twentyseven__text">ТЕЛЕФОН</span>
            <a href="" class="twentyseven__link">+7(495)240-00-00</a>
          </div>
          <div class="twentyseven__item">
            <span class="twentyseven__text">E-MAIL</span>
            <a href="" class="twentyseven__link">buro.ndt@burondt.ru</a>
          </div>
        </div>
      </div>
    </section>
    <section v-if="contacts.length == 0" class="twentyseven no_info">
      <loader
        object="#ff9633"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular">
        </loader>
    </section>
    <section class="twentynine" v-else>
      <div class="twentynine__wrapper">
        <h2 class="twentynine__title">
          Секретари Технических рабочих групп 2023 года
        </h2>
        <div class="twentynine__items">
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ЭГ 1-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Голуб Ольга Владимировна</span>
    <a>8 495 2400000 д.1900</a>
    <a href="mailto:trg1@burondt.ru" class="twentynine__link-cont">trg1@burondt.ru</a>
  </div>
</div> 
<div class="twentynine__item" data-v-4be16f10=""></div>
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ТРГ 4-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Голуб Ольга Владимировна</span>
    <a>8 495 2400000 д.1900</a>
    <a href="mailto:trg4@burondt.ru" class="twentynine__link-cont">trg4@burondt.ru</a>
  </div>
</div> 
<div class="twentynine__item" data-v-4be16f10=""></div>
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ТРГ 16-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Курошев Илья Сергеевич</span>
    <a>8 495 2400000 д.1201</a>
    <a href="mailto:trg16@burondt.ru" class="twentynine__link-cont">trg16@burondt.ru</a>
  </div>
</div> 
<div class="twentynine__item" data-v-4be16f10=""></div>
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ТРГ 18-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Кондрашова Анастасия Станиславовна</span>
    <a>8 495 2400000 д.110</a>
    <a href="mailto:trg18@burondt.ru" class="twentynine__link-cont">trg18@burondt.ru</a>
  </div>
</div> 
<div class="twentynine__item" data-v-4be16f10=""></div>
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ТРГ 25-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Курошев Илья Сергеевич</span>
    <a>8 495 2400000 д.1201</a>
    <a href="mailto:trg25@burondt.ru" class="twentynine__link-cont">trg25@burondt.ru</a>
  </div>
</div> 
<div class="twentynine__item" data-v-4be16f10=""></div>
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ТРГ 27-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Курошев Илья Сергеевич</span>
    <a>8 495 2400000 д.1201</a>
    <a href="mailto:trg27@burondt.ru" class="twentynine__link-cont">trg27@burondt.ru</a>
  </div>
</div> 
<div class="twentynine__item" data-v-4be16f10=""></div>
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ТРГ 37-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Курчакова Анна Сергеевна</span>
    <a>8 495 2400000 д.1204</a>
    <a href="mailto:trg37@burondt.ru" class="twentynine__link-cont">trg37@burondt.ru</a>
  </div>
</div> 
<div class="twentynine__item" data-v-4be16f10=""></div>
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ТРГ 39-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Рудомазин Виктор Викторович</span>
    <a>8 495 2400000 д.1700</a>
    <a href="mailto:trg39@burondt.ru" class="twentynine__link-cont">trg39@burondt.ru</a>
  </div>
</div> 
<div class="twentynine__item" data-v-4be16f10=""></div>
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ТРГ 41-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Рудомазин Виктор Викторович</span>
    <a>8 495 2400000 д.1700</a>
    <a href="mailto:trg41@burondt.ru" class="twentynine__link-cont">trg41@burondt.ru</a>
  </div>
</div> 
<div class="twentynine__item" data-v-4be16f10=""></div>
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ТРГ 43-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Рудомазин Виктор Викторович</span>
    <a>8 495 2400000 д.1700</a>
    <a href="mailto:trg43@burondt.ru" class="twentynine__link-cont">trg43@burondt.ru</a>
  </div>
</div> 
<div class="twentynine__item" data-v-4be16f10=""></div>
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ТРГ 44-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Рудомазин Виктор Викторович</span>
    <a>8 495 2400000 д.1700</a>
    <a href="mailto:trg44@burondt.ru" class="twentynine__link-cont">trg44@burondt.ru</a>
  </div>
</div> 
<div class="twentynine__item" data-v-4be16f10=""></div>
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ТРГ 45-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Рудомазин Виктор Викторович</span>
    <a>8 495 2400000 д.1700</a>
    <a href="mailto:trg45@burondt.ru" class="twentynine__link-cont">trg45@burondt.ru</a>
  </div>
</div> 
<div class="twentynine__item" data-v-4be16f10=""></div>
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ТРГ 47-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Кондрашова Анастасия Станиславовна</span>
    <a>8 495 2400000 д.110</a>
    <a href="mailto:trg47@burondt.ru" class="twentynine__link-cont">trg47@burondt.ru</a>
  </div>
</div> 
<div class="twentynine__item" data-v-4be16f10=""></div>
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ТРГ 48-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Рудомазин Виктор Викторович</span>
    <a>8 495 2400000 д.1700</a>
    <a href="mailto:trg48@burondt.ru" class="twentynine__link-cont">trg48@burondt.ru</a>
  </div>
</div> 
<div class="twentynine__item" data-v-4be16f10=""></div>
<div class="twentynine__item-wrapp">
  <div class="twentynine__block1">
    <h3 class="twentynine__block1-h3">ТРГ 49-2023</h3>
  </div>

  <div class="twentynine__block2">
    <span>Курошев Илья Сергеевич</span>
    <a>8 495 2400000 д.1201</a>
    <a href="mailto:trg49@burondt.ru" class="twentynine__link-cont">trg49@burondt.ru</a>
  </div>
</div>
          <!-- <div class="twentynine__item" v-for="contact in contacts" :key="contact.users_id">
            <div class="twentynine__item-wrapp">
              <div class="twentynine__block1">
                <h3 class="twentynine__block1-h3">{{ contact.etkstructure_num }}</h3>
              </div>
              <div class="twentynine__block2">
                <span class="twentynine__name"
                  >{{ contact.users_fio.split("(")[0] }}</span
                >
                <div class="twentynine__link-wrapp">
                  <a href="+74952400000" class="twentynine__link-cont"
                    >{{ contact.user_tel }}</a
                  >
                </div>
                <a href="" class="twentynine__link-cont">{{ contact.users_email }}</a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import getTrgsContacts from '@/composables/contacts/contacts'
import { onMounted } from '@vue/runtime-core';
import BackImageComp from '../BackImagesComp.vue'
export default {
  name: "Contacts",
  components: {BackImageComp},
  setup() {
    const { contacts, fetchTrgsContacts } = getTrgsContacts()
    onMounted(() => {
      fetchTrgsContacts()
    })
    return {
      contacts, fetchTrgsContacts
    }
  },
};
</script>
<style scoped>
body {
  font-size: 15px;
  line-height: 1.2;
  font-weight: normal;
  font-style: normal;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  min-width: 320px;
  background: #fafafa;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
}





/*----------------------------twentytwo---------------------------------------*/


.twentyseven {
  padding: 0 7vw;
}

.twentyseven__wrapper {
  margin: 3vh 3vh 0 3vh;
  height: auto;
}
.twentyseven__title-main {
  color: #0073af;
  font-size: calc(100vw / 35);
}
.twentyseven__items {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 5vw;
}
.twentyseven__item {
  display: flex;
  flex-direction: column;
  color: black;
  padding: 1vh 0;
  font-size: calc(100vw / 75);
}

.twentyseven__text {
  font-size: calc(100vw / 125);
  line-height: 2vw;
}
.twentyseven__link {
  text-decoration: none;
  color: black;
}

/*--------------------------twentythree------------------------------------*/

.twentynine {
  padding: 0 7vw;
}
.twentynine__wrapper {
  margin: 3vh;
  margin-bottom: 10vw;
  height: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 0.01vw 2vw;
  background: white;
}
.twentynine__title {
  font-size: calc(100vw / 55);
  color: black;
  padding: 2vh 0;
  border-bottom: 1px solid black;
}
.twentynine__items {
  display: flex;
  flex-direction: column;
}
.twentynine__item {
  border-bottom: 0.3vh solid #bdbbba;
}

.twentynine__item:last-child {
  margin-bottom: 10vh;
  border: none;
}
.twentynine__item-wrapp {
  display: flex;
  height: 9vh;
  margin: 3vh 0;
}
.twentynine__block1 {
  font-size: calc(100vw / 105);
  width: 15%;
}
.twentynine__block2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: calc(100vw / 105);
}
.twentynine__link-cont {
  text-decoration: none;
  color: black;
}
.twentynine__dob {
  margin-left: 1vw;
}
.twentynine__block1-h3 {
  margin: 0;
  padding: 0;
  color: #525252;
}

.twentynine__prolapse {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
}
.twentynine__prolapse1 {
  display: flex;
  flex-direction: column;
  width: 400px;
  border: 1px solid red;
}
.twentynine__text1,
.twentynine__text3 {
  color: #414141;
  font-size: calc(3px + 10 * (100vw / 1280));
  font-weight: 500;
  text-transform: uppercase;
}
.twentynine__text2 {
  color: #414141;
  font-size: calc(1px + 15 * (100vw / 1280));
  font-weight: 400;
  text-decoration: none;
}
.twentynine__prolapse2 {
  display: flex;
  flex-direction: column;
  width: 300px;
  border: 1px solid red;
  margin-top: 10px;
}

.twentynine__files-link {
  display: flex;
  flex-direction: column;
}
.twentynine__file-link {
  color: #414141;
  font-size: calc(3px + 10 * (100vw / 1280));
  font-weight: 400;
  text-decoration: none;
}
.twentynine__img-link {
  height: 18px;
}
.none {
  display: none;
}

@media (max-width: 1440px) {

  /*.twentyseven,.twentynine {
    padding: 0 7vw;
  }*/
  .twentyseven__item {
    font-size: 1.4vw;
  }
  .twentyseven__text {
    font-size: 1.1vw;
    line-height: 2.5vw;
  }
  .twentynine__block2 {
    font-size: 1.2vw;
  }
  .twentynine__item-wrapp {
    height: 8vh;
    font-size: 1.2vw;
  }
  .twentynine__item {
    border-bottom: 0.1vh solid #bdbbba;
  }
  .twentynine__block1-h3 {
    font-weight: 500;
  }
  .twentynine__link-wrapp {
    margin: 0.7vw 0;
  }
}

/*----------------------------MOBILE---------------------------------*/

@media (max-width: 480px) {
  

  /*-------------------------twentytwo-------------------------------*/

  .twentytwo {
    padding: 0 30px;
  }

  /*--------------------------twentyfive-----------------------------------*/

  .twentyfive__input {
    width: calc(3px + 520 * (100vw / 1280));
  }
  .twentyfive__wrapper {
    margin-bottom: 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }
  .twentyfive__nomination,
  .twentyfive__items-mob {
    display: block;
  }
  .twentyfive__table-wrapp {
    display: none;
  }
  .twentyfive__nomination {
    display: flex;
    justify-content: space-between;
    padding: 0 7px;
    width: 230px;
  }
  .twentyfive__filter-text {
    font-size: calc(3px + 33 * (100vw / 1280));
  }
  .twentyfive__item-one {
    font-size: calc(3px + 33 * (100vw / 1280));
  }
  .twentyfive__items-mob {
    margin-bottom: 100px;
  }
  .twentyfive__item-mob2 {
    font-weight: 600;
  }
  .twentyfive__item-mobile {
    width: 95%;
    max-width: 1720px;
    margin: 7px auto;
    height: auto;
    max-height: 5000px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    padding: 20px 15px;
    display: flex;
  }
  .twentyfive__number {
    width: 40px;
  }
  .twentyfive__span {
    font-size: 18px;
    padding-left: 7px;
  }
  .twentyfive__block-wrapp {
    width: 280px;
  }

  .twentyfive__h3 {
    margin: 5px 0;
    font-size: calc(3px + 38 * (100vw / 1280));
  }
  .twentyfive__p {
    margin: 0;
  }
  .twentyfive__p:last-child {
    margin: 5px 0;
  }

  .twentyfive__years {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  .twentyfive__year {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 45px;
  }
  .twentyfive__download {
    height: 17px;
  }

  /*---------------------------twentyseven----------------------------------*/
  .twentyseven {
    padding: 0 2vh;
  }
  .twentyseven__wrapper {
    margin: 2vh;
  }
  .twentyseven__text {
    font-size: calc(100vw / 40);
  }

  .twentyseven__title-main {
    font-size: 29px;
    max-width: 300px;
  }

  .twentyseven__items {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 3vh;
  }
  .twentyseven__item {
    font-size: 16px;
    max-width: 250px;
    width: 100%;
  }
  /*---------------------------twentynine----------------------------------*/

  .twentynine {
    padding: 0 6vw;
  }
  .twentynine__wrapper {
    padding: 20px;
    margin: 0 auto 7vh auto;
  }
  .twentynine__title {
    padding: 0 0 1vh 0;
    font-size: calc(100vw / 20);
  }
  .twentynine__item-wrapp {
    width: 100%;
    height: 10vh;
    margin: 3vh 0;
  }
  .twentynine__block1 {
    font-size: calc(100vw / 105);
    width: 30%;
  }
  .twentynine__block2 {
    width: 100%;
    font-size: calc(100vw / 30);
  }
  .twentynine__block1-span {
    font-size: calc(3px + 30 * (100vw / 1280));
  }
  .twentynine__block1-h3 {
    font-size: calc(3px + 33 * (100vw / 1280));
  }
  .twentynine__block2-img {
    height: calc(1px + 50 * (100vw / 1280));
  }
  .twentynine__prolapse1 {
    width: calc(3px + 870 * (100vw / 1280));
  }
  .twentynine__prolapse2 {
    width: calc(3px + 870 * (100vw / 1280));
  }
  .twentynine__text1,
  .twentynine__text3 {
    font-size: calc(3px + 25 * (100vw / 1280));
  }
  .twentynine__text2 {
    font-size: calc(1px + 40 * (100vw / 1280));
    font-weight: 600;
  }
  .twentynine__file-link {
    font-size: calc(3px + 30 * (100vw / 1280));
    text-decoration: revert;
  }
}

@media (max-width: 375px) {
  .twentyseven__item {
    font-size: 14px;
  }
}
</style>
import { ref } from "vue";
import { environment } from '@/composables/realhost.js';

export default function useTrgMessages(){
    const trgData = ref([]);
    const loader = ref(true);

    function makeUrl(a, b){
        // return `http://burondt.ru/NDT/NDTDocsDetail.php?UrlId=${a}&etkstructure_id=${b}`
        return `${environment.urlMakerHost}NDT/NDTDocsDetail.php?UrlId=${a}&etkstructure_id=${b}`
    }

    function fetchTrgData(year, id){
        // fetch("http://slimtest/trg/" + year + "/" + id)
        fetch(environment.realHost + "trg/" + year + "/" + id + "/info")
        .then(response => response.json())
        .then(data => {
            trgData.value = data
            loader.value = false
        })
    }
    // function hideLoader(){
    //     loader.value = false
    // }

    // function fetchMeeting(meetingId){
    //     fetch(`url/${meetingId}`)
    //     .then(response => response.json())
    //     .then(data => meeting.value = data)
    // }
    return { fetchTrgData,  trgData, loader, makeUrl }
}
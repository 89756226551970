import { ref } from "vue";
import { environment } from '@/composables/realhost.js';

export default function useYears(){
    const years = ref([]);

    function fetchYears(year){
        // fetch("http://slimtest/discussion/years/"+year)
        fetch(environment.realHost + "discussion/years/"+year)
        .then(response => response.json())
        .then(data => {
            years.value = data
        })
       
    }
    return { fetchYears, years }
}
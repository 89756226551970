<template>
  <div class="its_menu_content_item">
    <br><div class="seventeen__block3-content">
    <h2>17. Утверждение</h2>
 <p>Бюро НДТ посредствам почтового отправления направляет в Росстандарт подготовленный комплект документов по проекту ИТС НДТ.</p>
<p>Росстандарт в течение 10 календарных дней со дня получения документов осуществляет их проверку и при отсутствии замечаний принимает решение об утверждении проекта справочника*.</p>
<p>В случае наличия замечаний или неполного комплекта документов Бюро НДТ в течение 3 календарных дней после получения запроса исправляет недочеты или направляет недостающие документы.</p>
<p>По итогам решения Росстандарта об утверждении справочника формируется приказ ведомства и в течение 5 календарных дней после подписания в Бюро НДТ направляется копия докладной записки, подписанная заместителем руководителя Росстандарта и копия приказа.</p>
<p>*<em><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=783&amp;etkstructure_id=1872">Приказ Росстандарта от 04 июня 2020 г. №1032 &laquo;Об утверждении порядка утверждения проекта информационно - технического справочника по наилучшим доступным технологиям&raquo;</a></em></p>
 <br>
<center><img src="/img/itc/statement-img.jpg" alt="" width="1000" height="308" /></center>  
</div>

  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}
</style>
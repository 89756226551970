<template>
  <div class="its_menu_content_item">
    <br><div class="seventeen__block3-content">
    <h2>Основные документы</h2>
    <p>&bull; <a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=768&amp;etkstructure_id=1872" target="_blank">Федеральный закон от 10 января 2002 г. № 7-ФЗ &laquo;Об охране окружающей среды&raquo;</a></p>
<p>&bull; <a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=771&amp;etkstructure_id=1872" target="_blank">Федеральный закон от 29.06.2015 № 162 &laquo;О стандартизации в Российской Федерации&raquo;</a></p>
<p>&bull; <a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=772&amp;etkstructure_id=1872" target="_blank">Постановлением Правительства РФ от 23 декабря 2014 г. №1458 &laquo;О порядке определения технологии в качестве наилучшей доступной технологии, а также разработки, актуализации и опубликования информационно-технических справочников по наилучшим доступным технологиям&raquo;&nbsp; (ред. от 03.03.2021)</a></p>
<p>&bull; <a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=773&amp;etkstructure_id=1872" target="_blank">Постановление Правительства РФ от 31 декабря 2020 г. N 2398 &laquo;Об утверждении критериев отнесения объектов, оказывающих негативное воздействие на окружающую среду, к объектам I,II,III и IV категорий&raquo;</a></p>
<p>&bull; <a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=777&amp;etkstructure_id=1872" target="_blank">Распоряжение Правительства РФ от 24 декабря 2014 г. N 2674-р &laquo;Об утверждении Перечня областей применения наилучших доступных технологий&raquo;</a><strong><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1757&amp;etkstructure_id=1872" target="_blank"><br /><br /></a></strong>&bull; <a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1757&amp;etkstructure_id=1872" target="_blank">Распоряжение Правительства РФ от 10 июня 2022 года № 1537-р &laquo; Об утверждении поэтапного графика актуализации информационно-технических справочников по наилучшим доступным технологиям&raquo;</a></p>
<p>&bull; <a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1050&amp;etkstructure_id=1872" target="_blank">Приказ № 4841 от 18 декабря 2019 г. &laquo;Об утверждении порядка сбора и обработки данных, необходимых для разработки и актуализации информационно-технических справочников по наилучшим доступным технологиям&raquo;</a></p>
<p>&bull; <a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1328&amp;etkstructure_id=1872" target="_blank">Приказ Министерства промышленности и торговли РФ&nbsp; 19 июня 2019 г. </a><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1328&amp;etkstructure_id=1872" target="_blank">N 2130</a><a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1328&amp;etkstructure_id=1872" target="_blank"> &laquo;Об утверждении порядка формирования и осуществления деятельности технических рабочих групп&raquo;</a></p>
<p>&bull; <a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=789&amp;etkstructure_id=1872" target="_blank">Приказ Росстандарта от 12 августа 2016 N 1080 &laquo;Об утверждении Порядка проведения экспертизы проектов ИТС НДТ в техническом комитете по стандартизации</a>&raquo;</p>
<p>&bull; <a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=783&amp;etkstructure_id=1872" target="_blank">Приказ Росстандарта от 04 июня 2020 г. №1032 &laquo;Об утверждении порядка утверждения проекта информационно - технического справочника по наилучшим доступным технологиям&raquo;</a></p>
<p>С полным перечнем документов можно ознакомиться <a href="https://burondt.ru/documents" target="_blank">здесь</a></p>
</div>

  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}
</style>
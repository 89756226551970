<template>
  <div class="its_menu_content_item">
    <br />
    <div class="seventeen__block3-content">
      <h2>13. Итоговое заседание</h2>
<p>Итоговое заседание технической рабочей группы обязательно проводится перед отправкой документа на утверждение в Росстандарт.</p>
<p>Материалы заседаний ТРГ, уведомления о проведении, форме, месте и времени заседания, а также повестка заседания направляются секретариатом ТРГ членам группы в электронном виде заблаговременно.</p>
<p>Итоги заседаний ТРГ оформляются протоколами, утверждаемыми руководителем Бюро НДТ, и размещаются на официальном сайте в разделе &laquo;Заседания&raquo;.</p>
<p>В рамках итогового заседания рабочая группа обсуждает положения справочника и принимает решение о готовности/не готовности отправить справочник на утверждение в Росстандарт. Если по результату заседания была выявлена необходимость внесения дополнительных корректировок в положения справочника, то проект отправляется на доработку.</p><br>

    </div>
  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js";
import { onMounted } from "vue";
export default {
  setup() {
    const { scrollToEl } = useScrollToEl();
    onMounted(() => {
      scrollToEl();
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
  padding: calc(100vw / 22);
  padding-top: calc(100vw / 60);
  background: white;
  margin-bottom: calc(100vw / 20);
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
}
</style>
<template>
  <div class="its_menu_content_item">
<br><div class="seventeen__block3-content">
<h2>5. Сбор данных</h2>
<p>Главной целью сбора данных является получение от предприятий, относящихся к областям применения НДТ, достоверной информации о применяемых ими технологиях, оборудовании, данных о сбросах и (или) выбросах загрязняющих веществ, образовании отходов, других факторах воздействия на окружающую среду, а также экономических показателях (материальных и организационно-технических затратах на внедрение и эксплуатацию технологии, оборудования).</p>
<p>Этап сбора данных начинается с момента размещение на официальном сайте Бюро НДТ уведомления о сборе данных (не позднее 5 календарных дней с момента согласования унифицированного отраслевого шаблона с рабочей группой).</p>
<p>Срок сбора данных определяет Бюро НДТ, но он не может быть менее 15 календарных дней и более 45 календарных дней со дня размещения уведомления.</p>
<p>Вся информация с предприятий, поступающая в Бюро НДТ, анализируется секретариатом ТРГ, кодируется, загружается в базу данных и имеет конфиденциальный характер. По итогам анализа и обработки данных руководитель секретариата направляет в рабочую группу обобщённую информацию для рассмотрения и включения в проект справочника.</p>
 <br>
<center><img style="max-width:100%;height:auto;" src="/img/itc/data-collect-img.jpg" /></center>
 <br>
 <center><h2>База данных Бюро НДТ</h2></center>
<center><img style="max-width:100%;height:auto;" src="/img/itc/data-collect-img2.jpg" /></center>
</div>    
    
  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}
</style>
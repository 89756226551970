<template>
  <div class="thirteen__item" :class="dSmaller" @click.self="openBlock">
    <h2 class="eleven__item-title" v-html="dName"></h2>
    <router-link :to="dHref" class="eleven__item-a">
      <div class="eleven__hover">
        <div class="eleven__hover-flex">
          <a href="#" class="eleven__subtitle" v-html="dAnnotation"></a>
          <img src="/img/6.png" alt="" class="eleven__hover-img" />
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { ref } from "vue";
import useOpenBlocks from "../composables/openblocks.js";

export default {
  name: "BlocksMenu",
  props: ["dirName", "dirHref", "dirAnnotation", "dirSmaller"],
  setup(props) {
    const dName = ref(props.dirName);
    const dHref = ref(props.dirHref);
    const dAnnotation = ref(props.dirAnnotation);
    const dSmaller = ref(props.dirSmaller);
    const { openBlock } = useOpenBlocks();

    return {
      dName,
      dHref,
      dAnnotation,
      dSmaller,
      openBlock,
    };
  },
};
</script>

<style scoped>
.thirteen__item {
  width: 22vw;
  height: 12vw;
  border: 0.2vw solid #354951;
  margin: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* background: white; */
  transition: 0.5s;
}
.thirteen__item:hover {
  box-shadow: 0 0 1vw rgb(0 0 0 / 50%);
  transform: scale(1.02);
}
.thirteen__item-title {
  font-size: calc(100vw / 88);
  color: black;
  padding: 0 0 0 3%;
  text-transform: uppercase;
}
.thirteen__hover {
  display: none;
  margin: 0 10px;
  width: 95%;
}
.thirteen__hover.active {
  display: block;
}
.thirteen__hover-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.thirteen__hover-img {
  display: block;
  width: 2.5vw;
}
.thirteen__subtitle {
  padding: 1vh 0 2vh 0;
  margin: 0;
  width: 82%;
  font-size: calc(100vw / 107);
  color: #3f606b;
  text-decoration: none;
  margin-left: 1%;
}
.thirteen__hover.active {
  display: block;
}
.eleven__item-title {
  font-size: calc(100vw / 88);
  color: black;
  padding: 0 0 0 1vw;
  text-align: left;
}
.eleven__item-a {
  width: 100%;
}
a {
  text-decoration: none;
}
.eleven__hover.active {
  display: block;
  position: relative;
}
.eleven__hover {
  display: none;
  padding: 0 1vw;
  width: 95%;
}
.eleven__hover-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.eleven__subtitle {
  padding: 1vh 0 1vh 0;
  margin: 0;
  width: 82%;
  font-size: calc(100vw / 107);
  color: #3f606b;
  text-decoration: none;
  margin-left: 1%;
  text-align: left;
}
.eleven__hover-img {
  width: 2.5vw;
  position: absolute;
  right: 0.1vw;
  bottom: 0.7vw;
}

@media (max-width: 480px) {
  .eleven__item-title {
    font-size: 15px;
    transform: none;
    padding-left: 9px;
  }
  .eleven__hover {
    width: auto;
  }
  .eleven__subtitle {
    transform: none;
    width: 85%;
    font-size: calc(3px + 2.34375vw);
  }
  .eleven__hover-img {
    width: 8vw;
    left: auto;
    right: 2vw;
    bottom: 1.7vw;
  }
  .thirteen__item {
    width: 100%;
    height: 40vw;
    border: 3px solid #b2bab5;
    background: white;
  }
  .thirteen__item.smaller {
    width: 75%;
  }
  .thirteen__item-title {
    font-size: calc(100vw / 25);
    padding: 0 12px;
  }
  .thirteen__subtitle {
    transform: none;
    width: 85%;
    font-size: calc(3px + 30 * (100vw / 1280));
  }
  .thirteen__hover-flex {
    height: auto;
    transform: translateY(calc(1px + -20 * (100vw / 1280)));
  }
  .thirteen__item:hover .thirteen__hover-img {
    width: calc(100vw / 15);
  }
  .thirteen__item:hover .thirteen__item-title {
    transform: translateY(7px);
  }
}
</style>
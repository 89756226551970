<template>
  <div class="main">
    <BackImagesComp />
    <section class="twentyseven">
      <div class="twentyseven__wrapper">
        <h1 class="twentyseven__title-main">Новости</h1>
      </div>
    </section>
    <section v-if="loader" class="no_info">
      <loader
        object="#ff9633"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular"
      ></loader>
    </section>
    <section class="twentynine" v-else>
      <div class="twentynine__wrapper">
        <div class="twentynine__items">
          <div class="twentynine__item" v-for="n in newsData" :key="n.id">
            <div class="twentynine__item-wrapp">
              <div class="twentynine__block1">
                <!-- <div class="twentynine__bg-img" style="background: url('/img/20.png') no-repeat;"></div> -->
                <img
                  class="twentynine__bg-img"
                  :src="`${environment.urlMakerHost}assets/lib/resized/${
                    n.asset_id
                  }/240x180.${getPicExtension(n.stub)}`"
                  alt=""
                />
                <!-- <img :src="'https://burondt.ru/assets/lib/' + n.stub" alt="" width="120"> -->
              </div>
              <div class="twentynine__block2">
                <h2 class="twentynine__title">{{ n.pagetitle }}</h2>
                <span class="twentynine__subtitle">{{
                  makeNewsDate(n.createdon)
                }}</span>
                <!-- <router-link :to="`/news/${n.id}`" class="twentynine__name">{{
                  n.pagetitle
                }}</router-link> -->
                <p class="twentynine__text">{{ n.content }}</p>
              </div>
            </div>
            <div class="twentynine__block-btn">
              <router-link :to="`/news/${n.id}`" class="twentynine__btn-link"
                >читать далее</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="navigation2">
      <div class="navigation2__wrapper">
        <paginate
          :page-count="count"
          :page-range="3"
          :margin-pages="2"
          :click-handler="clickCallback"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :hide-prev-next="true"
        >
        </paginate>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { environment } from "@/composables/realhost.js"
import Paginate from "vuejs-paginate-next";
// import "bootstrap/dist/css/bootstrap.min.css"
import useAllNews from "@/composables/news/news.js";
import { onMounted } from "vue";
import BackImagesComp from "../BackImagesComp.vue";

export default {
  name: "News",
  components: {
    BackImagesComp,
    Paginate,
  },
  setup() {
    const {
      fetchNews,
      newsData,
      loader,
      makeNewsDate,
      getPicExtension,
      cutTegs,
      count,
    } = useAllNews();
    onMounted(() => {
      fetchNews();
    });
    const clickCallback = function (pageNum) {
      fetchNews(pageNum * 10 - 10, 10);
    };
    return {
      newsData,
      loader,
      makeNewsDate,
      getPicExtension,
      cutTegs,
      clickCallback,
      count,
      environment
    };
  },
};
</script>

<style lang="scss">
.no_info {
  min-height: 50vh;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  justify-content: center !important;
  font-size: 1vw;
}
.page-item {
  // padding: .5rem;
  // border: 1px solid blue !important;
  // border-radius: 3px !important;
  // margin: .2vw;
  cursor: pointer;
  // color:red;
}
.page-item:not(:first-child) .page-link {
    margin-left: -1px;
}
.page-link {
    padding: 0.375rem 0.75rem;
}
.page-link {
    position: relative;
    display: block;
    color: #242424;
    font-weight: 600;
    text-decoration: none;
/*    background-color: #fff;
    border: 1px solid #dee2e6;*/
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.page-item.active .page-link{
  background-color: #005f84 !important;
  color: white;
}
.page-item{
  // cursor: pointer;
}

.twentyseven {
  padding: 0 7vw;
}

.twentyseven__wrapper {
  margin: 3vh 3vh 0 3vh;
  height: auto;
}
.twentyseven__title-main {
  color: #0073af;
  font-size: calc(100vw / 35);
}

.twentynine {
  padding: 0 7vw;
}
.twentynine__wrapper {
  margin: 3vh;
  /* margin-bottom: 10vw; */
  height: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 0.01vw 2vw;
  background: white;
}
/*.twentynine__title {
  font-size: calc(100vw/55);
  color: black;
  padding: 2vh 0;
  border-bottom: 1px solid black;
}*/
.twentynine__items {
  display: flex;
  flex-direction: column;
}
.twentynine__item {
  border-bottom: 0.3vh solid #bdbbba;
  padding-bottom: 2vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1vw;
}

.twentynine__item:last-child {
  margin-bottom: 10vh;
  border: none;
}
.twentynine__item-wrapp {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 3vh 0;
}
.twentynine__bg-img {
  width: 17vw;
}
.twentynine__block1 {
  width: auto;
  padding-right: 2vw;
}
.twentynine__bg-img {
  // width: 100%;
}
.twentynine__block2 {
  width: 90%;
}
.twentynine__title {
  margin: 0;
  width: 100%;
}
.twentynine__subtitle {
  padding: 0.5vw 0;
  display: inline-block;
  color: #396371;
}
.twentynine__text {
  margin: 0;
}

.twentynine__block-btn {
  display: flex;
  justify-content: flex-end;
}
.twentynine__btn-link {
  background: #005f84;
  color: white;
  padding: 5px;
  text-decoration: none;
}

@media (max-width: 1440px) {
  /*.twentyseven,.twentynine {
    padding: 0 7vw;
  }*/
  .twentyseven__item {
    font-size: 1.4vw;
  }
  .twentyseven__text {
    font-size: 1.1vw;
    line-height: 2.5vw;
  }
  .twentynine__block2 {
    /* font-size: 1.2vw;*/
  }
  .twentynine__item-wrapp {
    /*font-size: 1.2vw;*/
  }
  .twentynine__item {
    border-bottom: 0.1vh solid #bdbbba;
  }
  .twentynine__block1-h3 {
    font-weight: 500;
  }
  .twentynine__link-wrapp {
    margin: 0.7vw 0;
  }
}
@media (max-width: 480px) {

  .pagination {
    font-size: 3vw;
  }

  .twentyseven {
    padding: 0 2vh;
  }
  .twentyseven__wrapper {
    margin: 2vh;
  }
  .twentyseven__text {
    font-size: calc(100vw / 40);
  }

  .twentyseven__title-main {
    font-size: 29px;
    max-width: 300px;
  }

  .twentyseven__items {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 3vh;
  }
  .twentyseven__item {
    font-size: 16px;
    max-width: 250px;
    width: 100%;
  }
  /*---------------------------twentynine----------------------------------*/

  .twentynine {
    padding: 0 6vw;
  }
  .twentynine__wrapper {
    padding: 20px;
    margin: 0 auto 7vh auto;
  }
  .twentynine__subtitle {
    padding: 2.5vw 0;
  }
  .twentynine__item {
    font-size: 3vw;
  }
  .twentynine__item-wrapp {
    width: 100%;
    height: auto;
    margin: 1vh 0;
    flex-direction: column;
  }
  .twentynine__block1 {
    width: 30%;
    padding: 0;
  }
  .twentynine__block2 {
    width: 100%;
  }
  .twentynine__title {
    width: auto;
  }
}
@media (max-width: 375px) {
  .twentyseven__item {
    font-size: 14px;
  }
}

/*------------------------NAVIGATION-2-------------------------------*/
.navigation2 {
  margin-bottom: 10vw;
  /* margin-top: -25vh; */
}
.navigation2__wrapper {
  margin: 0 auto;
  width: 50%;
}
/*.navigation2__list {
  list-style: none;
  padding: 0;
  display: flex;
  width: 50%;
  margin: 0 auto;
}
.navigation2__list li {
  width: 2.5vw;
  text-align: center;
}
.navigation2__list li:first-child {
  background: #005f84;
}
.one {
  color: white !important;
}
.navigation2__link {
  text-decoration: none;
  color: black;
}*/
</style>
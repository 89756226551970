<template>
  <main class="main">
    <BackImagesComp />
    <YearsMenu :page="page" :title="title" />
    <section v-if="loader" class="eleven">
      <loader
        object="#ff9633"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular"
      ></loader>
    </section>
    <section v-else class="eleven">
      <div v-if="!discussionsData" class="container">
        <h3>Ничего не найдено</h3>
      </div>
      <div v-else class="container">
        <div class="eleven__wrapper">
          <div class="eleven__items">
            <BlocksMenu
              v-for="data in discussionsData"
              :key="data.publicdiscussiondirectories_id"
              :dirName="data.publicdiscussiondirectories_name"
              :dirHref="`/discussion/${$route.params.year}/${data.publicdiscussiondirectories_id}`"
              :dirAnnotation="data.publicdiscussiondirectories_annotations"
              :dirSmaller="smaller"
            />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import useDiscussions from "@/composables/discussion/discussions";
import YearsMenu from "../YearsMenu.vue";
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import BackImagesComp from "../BackImagesComp.vue";
import BlocksMenu from "../BlocksMenu.vue";

export default {
  name: "Discussion",
  components: {
    BackImagesComp,
    YearsMenu,
    BlocksMenu,
  },
  props: {},
  setup() {
    const title = "Публичное <br> обсуждение"
    const page = "/discussion/";
    const smaller = 'smaller'
    const route = useRoute();
    const { discussionsData, fetchDiscussions, loader } = useDiscussions();
    onMounted(() => {
      fetchDiscussions(route.params.year);
    });
    watch(
      () => route.params.year,
      newYear => {
        fetchDiscussions(newYear)
      }
    )
    return { discussionsData, loader, page, smaller, title };
  },
};
</script>

<style scoped>

.container {
  text-align: center;
  min-height: 400px;
}
/*--------------------------------ELEVEN-----------------------------------*/
.eleven {
  padding: 0 4.5vw;
}

.eleven__hover.active {
  display: block;
}

.eleven__items {
  width: 90%;
  max-width: 2945px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10vw;
}
.eleven__item {
  width: 22vw;
  height: 12vw;
  border: 2px solid #354951;
  margin: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* background: white; */
  transition: 0.5s;
  align-items: flex-start;
}
.eleven__item:hover {
  box-shadow: 0 0 1vw rgb(0 0 0 / 50%);
  transform: scale(1.02);
}
.eleven__item-title {
  font-size: calc(100vw / 88);
  color: black;
  padding: 0 0 0 3%;
}
.eleven__hover {
  display: none;
  margin: 0 10px;
  width: 95%;
}
.eleven__hover-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.eleven__hover-img {
  width: 2.5vw;
  margin-bottom: 1rem;
}
a {
  /* по другому что то ни как */
  text-decoration: none !important;
}
.eleven__subtitle {
  padding: 1vh 0 2vh 0;
  margin: 0;
  width: 82%;
  font-size: calc(100vw / 107);
  color: #3f606b;
  /* text-decoration: none !important; */
  margin-left: 1%;
  text-align: left;
}
@media (max-width: 480px) {
  .container {
    min-height: 650px;
  }
  .eleven {
    padding: 0 15px;
  }

  .eleven__items {
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 50px;
  }

  .eleven__item {
    width: 75%;
    height: 145px;
    margin: 10px 0;
  }

  .eleven__item-title {
    font-size: 15px;
    transform: none;
    padding-left: 9px;
  }

  .eleven__subtitle {
    transform: none;
    font-size: 3vw;
    margin-left: 0;
  }

  .eleven__hover {
    width: auto;
  }

  .eleven__item:hover .eleven__hover-img {
    transform: none;
  }

  .eleven__item:hover .eleven__hover {
    transform: none;
  }

  .eleven__hover-img {
    display: block;
    width: 7vw;
  }
  .eleven__items {
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 50px;
  }
  .eleven__item {
    width: 75%;
    height: 145px;
    margin: 10px 0;
    background: white;
  }
  .eleven__item-title {
    font-size: 15px;
    transform: none;
    padding-left: 9px;
  }
  .eleven__subtitle {
    transform: none;
    font-size: 3vw;
    margin-left: 0;
  }
  .eleven__hover {
    width: auto;
  }
  .eleven__item:hover .eleven__hover-img {
    transform: none;
  }

  .eleven__item:hover .eleven__hover {
    transform: none;
  }

  .eleven__hover-img {
    display: block;
    width: 7vw;
    margin-bottom: 0;
  }
}
</style>

import {ref} from 'vue';
import { environment } from '@/composables/realhost.js';

export default function getMvsMeetingsData(){
    const meetings = ref([])

    function fetchMvsMeetings(){
        fetch(environment.realHost + "MVC/meetings")
        .then(response => response.json())
        .then(data => {
            meetings.value = data
        })
    }
    function makeFileUrl(a){
        // return `http://burondt.ru/ETK/EtkMeetingsFilesDownload.php?UrlId=${a}`
        return `${environment.urlMakerHost}ETK/EtkMeetingsFilesDownload.php?UrlId=${a}`
    }

    return {
        meetings,
        fetchMvsMeetings,
        makeFileUrl
    }
}
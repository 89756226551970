<template>
<main class="main">
  <back-images-comp/>
  <YearsMenu :page="page" :title="title" />
  <section v-if="loader" class="eleven">
    <loader
      object="#ff9633"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      name="circular"
    ></loader>
  </section>
  <section class="nineteen" v-else>
    <div class="nineteen__block-wrapp">
      <div class="nineteen__wrapper">
        <div v-if="discussionData.names" class="nineteen__head-block">
          <h2 class="nineteen__head-title">
            {{ discussionData.names.publicdiscussiondirectories_name }}
          </h2>
          <h3 class="nineteen__head-subtitle">
            {{ discussionData.names.publicdiscussiondirectories_annotations }}
          </h3>
        </div>

        <div v-if="discussionData && discussionData.messages.length" class="nineteen__wrapp">
          <div class="nineteen__block-1">
            <div
              v-for="data in discussionData.messages"
              :key="data.publicdiscussion_id"
              class="nineteen__contact"
            >
              <a
                :href="
                  makeDiscussionUrl(
                    data.publicdiscussion_id,
                    discussionData.names.publicdiscussiondirectories_id
                  )
                "
                class="nineteen__link"
                >{{ data.publicdiscussion_name }}</a
              >
            </div>
            <BackButton :back="backLink"/>
          </div>
        </div>
        <div v-else class="nineteen__wrapp">
          <h3>На данный момент публичное обсуждение не проводится</h3>
          <BackButton :back="backLink"/>
        </div>
      </div>
    </div>
  </section>
</main>
</template>

<script>
import useDiscussionsMessages from "@/composables/discussion/discussions_messages";
import YearsMenu from "../YearsMenu.vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import BackImagesComp from '../BackImagesComp.vue';
import BackButton from "../BackButton.vue"

export default {
  name: "DiscussionMessages",
  components: {
    // DiscussionMenu,
    BackImagesComp,
    BackButton,
    YearsMenu
  },
  props: {},
  setup() {
    const title = "Публичное <br> обсуждение"
    const page = "/discussion/"
    const route = useRoute();
    const yearActive = route.params.year;
    const { discussionData, fetchDiscussionData, loader, makeDiscussionUrl } =
      useDiscussionsMessages();
    const backLink = ref('/discussion/' + yearActive)//ссылка для кнопки назад
    onMounted(() => {
      fetchDiscussionData(route.params.year, route.params.id);
    });
    return { discussionData, loader, makeDiscussionUrl, yearActive, backLink, page, title };
  },
};
</script>

<style scoped>

/*----------------------------nineteen--------------------------------------*/

.nineteen__wrapper {
  margin: 0 5vw;
  margin-bottom: 9vh;
  height: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 3vw;
  margin-top: 1vh;
  background: white;
  position: relative;
}

.nineteen__head-block {
  border-bottom: 0.15vw solid black;
}
.nineteen__head-title {
  font-size: calc(100vw / 80);
  font-weight: 300;
}
.nineteen__head-subtitle {
  font-size: calc(100vw / 55);
  width: 55%;
  margin: 0;
}
.nineteen__wrapp {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
  font-size: 1vw;
}
.nineteen__block-1 {
  width: 100%;
}
.nineteen__contact {
  line-height: 9vh;
}
.nineteen__link {
  margin: 5px 2px;
  font-weight: 300;
  font-size: calc(100vw / 80);
  color: #242424;
}

.ninetteen__track {
  display: none;
}

@media (max-width: 480px) {

  .nineteen {
    padding: 0 20px;
    min-height: 700px;
  }

  .nineteen__block-wrapp {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }
  .nineteen__head-title {
    font-size: 15px;
    font-weight: 300;
    margin: 10px 0;
  }

  .nineteen__head-subtitle {
    font-size: 18px;
    margin: 0;
    width: auto;
  }

  .nineteen__wrapper {
    padding: 10px 15px;
    width: 268px;
    /*height: 600px;*/
    margin-left: 70px;
    margin: 0 0 50px auto;
  }

  .nineteen__link {
    font-size: 14px;
    margin: 0;
  }

  .nineteen__contact {
    line-height: 4vh;
  }

  .nineteen__contact:first-child {
    line-height: 20px;
  }

  .nineteen__contact:nth-child(2) {
    padding-top: 10px;
  }

  .nineteen__wrapp {
    flex-wrap: nowrap;
    flex-direction: column;
    height: auto;
  }

  .nineteen__block-1 {
    width: auto;
  }

  .ninetteen__track {
    border-left: 2px solid #fca14e;
    border-top: none;
    height: auto;
    width: 20%;
    position: absolute;
    display: block;
    margin-bottom: 17%;
  }

  .ninetteen__year {
    width: 100%;
    max-width: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    right: 11px;
    position: relative;
  }

  .ninetteen__year span {
    font-size: calc(3px + 35 * (100vw / 1280));
  }

  .ninetteen__year:first-child,
  .ninetteen__year:last-child {
    left: -11px;
  }

  .ninetteen__year:nth-child(2) span {
    color: black;
    font-weight: 600;
  }

  .ninetteen__years {
    flex-direction: column;
    width: 90%;
    height: 600px;
    bottom: 25px;
    display: flex;
    justify-content: space-between;
  }

  .ninetteen__block-top {
    height: 55px;
    margin: 20px 0 70px 0;
  }

  .ninetteen__year-img {
    width: 20px;
  }

  .nineteen__head-block {
    border-bottom: 1px solid black;
  }
}
</style>

import { ref } from "vue";
import { environment } from '@/composables/realhost.js';

export default function useDiscussionsMessages(){
    const discussionData = ref([]);
    const loader = ref(true);

    function makeDiscussionUrl(a, b){
        // return `http://burondt.ru/NDT/PublicDiscussionDetail.php?UrlId=${a}&UrlDId=${b}`
        return `${environment.urlMakerHost}NDT/PublicDiscussionDetail.php?UrlId=${a}&UrlDId=${b}`
    }

    function fetchDiscussionData(year, id){
        // fetch("http://slimtest/discussion/" + year + "/" + id)
        fetch(environment.realHost + "discussion/" + year + "/" + id)
        .then(response => response.json())
        .then(data => {
            
            if(data.names && data.messages){
                discussionData.value = data
            } else {
                discussionData.value = false
            }
            loader.value = false
        })
    }
    // function hideLoader(){
    //     loader.value = false
    // }

    // function fetchMeeting(meetingId){
    //     fetch(`url/${meetingId}`)
    //     .then(response => response.json())
    //     .then(data => meeting.value = data)
    // }
    return { fetchDiscussionData,  discussionData, loader, makeDiscussionUrl }
}
<template>
  <Header></Header>
  <!-- <router-view :key="$route.path" /> -->
  <router-view/>
  <Footer></Footer>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
header{
  flex: 0 0 auto;
}
footer {
  flex: 0 0 auto;
}
.main {
  flex: 1 0 auto;
}
</style>

<template>
  <div class="its_menu_content_item">
    <br><div class="seventeen__block3-content">
<h2>4. Разработка унифицированного отраслевого шаблона анкеты</h2>
<p>Одним из наиболее важных этапов разработки ИТС НДТ считается сбор данных, необходимых для определения технологических процессов, оборудования, технических способов, методов в качестве наилучшей доступной технологии. (<a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1050&amp;etkstructure_id=1872\">Приказ № 4841 от 18 декабря 2019 г. &laquo;Об утверждении порядка сбора и обработки данных, необходимых для разработки и актуализации информационно-технических справочников по наилучшим доступным технологиям&raquo; </a>)</p>
<p>Для сбора данных Бюро НДТ, в течение 30 календарных дней с даты утверждения составов рабочих групп, разрабатывает&nbsp;унифицированные отраслевые шаблоны для сбора данных, на основании информации, представленной разработчиком. После этого руководитель секретариата ТРГ организует ее рассмотрение&nbsp;и согласование&nbsp;на Портале Бюро НДТ в рабочем кабинете ТРГ.</p>
<p>По результатам рассмотрения&nbsp;проекта унифицированного отраслевого шаблона, членами ТРГ принимается решение об утверждении или доработки шаблона.</p>
</div>
    
  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}
</style>
<template>
  <div class="its_menu_content_item">
    
    <br>
<div class="seventeen__block3-content">
  <h2>Общее</h2>
<p><strong>Информационно-технический справочник (ИТС НДТ)&nbsp;</strong>&ndash; это документ национальной системы стандартизации, утвержденный федеральным органом исполнительной власти в сфере стандартизации (<a href="https://www.rst.gov.ru/portal/gost">Росстандартом</a>), содержащий систематизированные данные в определенной области и включающий в себя описание технологий, процессов, методов, способов, оборудования и иные данные (<a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=771&amp;etkstructure_id=1872">Федеральный закон от 29.06.2015 № 162</a>&nbsp;<u>)</u>.</p>
<ul>
</ul>
<p>ИТС НДТ является одним из ключевых аспектов перехода промышленности на принципы государственного регулирования на основе наилучших доступных технологий (НДТ). Документ разрабатывается с учетом имеющихся в Российской Федерации технологий, оборудования, сырья и других ресурсов, а также с учетом климатических, экономических и социальных особенностей и отражает текущий уровень эмиссий промышленных предприятий (<a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=768&amp;etkstructure_id=1872">Федеральный закон от 10 января 2002 г. № 7-ФЗ</a>&nbsp;<u>)</u>. Порядок определения технологии в качестве наилучшей доступной технологии, а также разработки, актуализации и опубликования ИТС НДТ определен&nbsp;<a href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=772&amp;etkstructure_id=1872">постановлением Правительства РФ от 23 декабря 2014 г. №1458 (ред. от 03.03.2021)</a>.</p>
<p>Разработка ИТС НДТ организует Министерство промышленности и торговли Российской Федерации (Минпромторг России) в соответствии с утвержденным графиком разработки/актуализации (<strong>распоряжение Правительства РФ от 10 июня 2022 года № 1537-р</strong>) и по запросу федеральных органов исполнительной власти, ответственных за разработку/актуализацию ИТС НДТ.&nbsp;</p>
<p>Ответственные федеральные органы исполнительной власти определяют разработчика проекта справочника</p>
<p>С полным перечнем ИТС НДТ можно ознакомиться <a href="http://burondt.ru/itc">здесь</a></p>
<br><p><strong>Участники процесса разработки/актуализации ИТС НДТ:</strong></p>
<ul>
<li>федеральный орган исполнительной власти, уполномоченный Правительством Российской Федерации на определение технологических процессов, оборудования, технических способов и методов в качестве наилучшей доступной технологии (Минпромторг России);</li>
<li>федеральный орган исполнительной власти в сфере стандартизации (Росстандарт);</li>
<li>ответственные федеральные органы исполнительной власти за разработку ИТС НДТ (Минприроды России, Минэнерго России, Минсельхоз России, Минстрой России, Минэкономразвития России, Минпромторг России, Роспотребнадзор, Росстандарт);</li>
<li>Бюро наилучших доступных технологий&nbsp;(<a href="http://burondt.ru/">Бюро НДТ</a>, <a href="http://burondt.ru/">Бюро</a>);</li>
<li>межведомственный совет по переходу на принципы наилучших доступных технологий и внедрению современных технологий (<a href="http://burondt.ru/mvs/documents">межведомственный совет</a>, <a href="http://burondt.ru/mvs/documents">МВС</a>);</li>
<li>технический комитет по стандартизации &laquo;Наилучшие доступные технологии&raquo; 113 (<a href="http://burondt.ru/tk113/about">ТК 113</a>);</li>
<li>технические рабочие группы разработчик проекта справочника (<a href="http://burondt.ru/trg/2022">ТРГ</a>, <a href="http://burondt.ru/trg/2022">рабочие </a><a href="http://burondt.ru/trg/2022">группы</a>).</li>
</ul>
  </div></div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}
</style>
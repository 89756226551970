<template>
  <main class="main">
    <section>
      <div class="title">
        <span>Общее</span>
      </div>
      <div class="text">
        <div class="fon color">
          <p class="textFon">
            <b>Информационно-технический справочник (ИТС НДТ)</b> – это документ
            национальной системы стандартизации, утвержденный федеральным
            органом исполнительной власти в сфере стандартизации
            (Росстандартом), содержащий систематизированные данные в
            определенной области и включающий в себя описание технологий,
            процессов, методов, способов, оборудования и иные данные (<a
              href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=771&etkstructure_id=1872"
              >Федеральный закон от 29.06.2015 № 162</a
            >).
          </p>
        </div>
        <p>
          ИТС НДТ является одним из ключевых аспектов перехода промышленности на
          принципы государственного регулирования на основе наилучших доступных
          технологий (НДТ). Документ разрабатывается с учетом имеющихся в
          Российской Федерации технологий, оборудования, сырья и других
          ресурсов, а также с учетом климатических, экономических и социальных
          особенностей и отражает текущий уровень эмиссий промышленных
          предприятий (<a
            href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=768&etkstructure_id=1872"
            >Федеральный закон от 10 января 2002 г. № 7-ФЗ</a
          >). Порядок определения технологии в качестве наилучшей доступной
          технологии, а также разработки, актуализации и опубликования ИТС НДТ
          определен
          <a
            href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=772&etkstructure_id=1872"
            >постановлением Правительства РФ от 23 декабря 2014 г. №1458 (ред.
            от 03.03.2021)</a
          >.
        </p>
        <p>
          Разработка ИТС НДТ организует Министерство промышленности и торговли
          Российской Федерации (Минпромторг России) в соответствии с
          утвержденным графиком разработки/актуализации (<b
            >распоряжение Правительства РФ от 10 июня 2022 года № 1537-р</b
          >) и по запросу федеральных органов исполнительной власти,
          ответственных за разработку/актуализацию ИТС НДТ.
        </p>
        <p>
          Ответственные федеральные органы исполнительной власти определяют
          разработчика проекта справочника
        </p>
        <p>
          С полным списком ИТС НДТ можно ознакомиться
          <a href="http://burondt.ru/itc">здесь</a>
        </p>
      </div>
    </section>

    <section>
      <div class="title">
        <span>ОСНОВНЫЕ ДОКУМЕНТЫ</span>
      </div>
      <div class="text">
        <div class="fon color">
          <ul style="list-style-type: circle">
            <li>
              <a
                target="_blank"
                href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=768&etkstructure_id=1872"
                >Федеральный закон от 10 января 2002 г. № 7-ФЗ «Об охране
                окружающей среды»</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=771&etkstructure_id=1872"
                >Федеральный закон от 29.06.2015 № 162 «О стандартизации в
                Российской Федерации»</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=772&etkstructure_id=1872"
                >Постановлением Правительства РФ от 23 декабря 2014 г. №1458 «О
                порядке определения технологии в качестве наилучшей доступной
                технологии, а также разработки, актуализации и опубликования
                информационно-технических справочников по наилучшим доступным
                технологиям» (ред. от 03.03.2021)</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=773&etkstructure_id=1872"
                >Постановление Правительства РФ от 31 декабря 2020 г. N 2398 «Об
                утверждении критериев отнесения объектов, оказывающих негативное
                воздействие на окружающую среду, к объектам I,II,III и IV
                категорий»</a
              >
            </li>
          </ul>
        </div>
        <ul style="list-style-type: circle">
          <li>
            <a
              target="_blank"
              href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=777&etkstructure_id=1872"
              >Распоряжение Правительства РФ от 24 декабря 2014 г. N 2674-р «Об
              утверждении Перечня областей применения наилучших доступных
              технологий» Распоряжение Правительства РФ от 10 июня 2022 года №
              1537-р « Об утверждении поэтапного графика актуализации
              информационно-технических справочников по наилучшим доступным
              технологиям»</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1757&etkstructure_id=1872"
              >Распоряжение Правительства РФ от 10 июня 2022 года № 1537-р « Об
              утверждении поэтапного графика актуализации
              информационно-технических справочников по наилучшим доступным
              технологиям»</a
            >
          </li>

          <li>
            <a
              target="_blank"
              href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1050&etkstructure_id=1872\"
              >Приказ № 4841 от 18 декабря 2019 г. «Об утверждении порядка сбора
              и обработки данных, необходимых для разработки и актуализации
              информационно-технических справочников по наилучшим доступным
              технологиям»</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1328&etkstructure_id=1872"
              >Приказ Министерства промышленности и торговли РФ 19 июня 2019 г.
              N 2130 «Об утверждении порядка формирования и осуществления
              деятельности технических рабочих групп»</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=789&etkstructure_id=1872"
              >Приказ Росстандарта от 12 августа 2016 N 1080 «Об утверждении
              Порядка проведения экспертизы проектов ИТС НДТ в техническом
              комитете по стандартизации»</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=783&etkstructure_id=1872"
              >Приказ Росстандарта от 04 июня 2020 г. №1032 «Об утверждении
              порядка утверждения проекта информационно - технического
              справочника по наилучшим доступным технологиям»</a
            >
          </li>
        </ul>
        <p>
          С полным перечнем документов можно ознакомиться
          <a href="https://burondt.ru/documents" target="_blank">здесь</a>
        </p>
      </div>
    </section>

    <section>
      <div class="title">
        <span>ОБЩЕЕ</span>
      </div>
      <div class="text">
        <div class="fon">
          <ul style="list-style-type: disc">
            <li>
              федеральный орган исполнительной власти, уполномоченный
              Правительством Российской Федерации на определение технологических
              процессов, оборудования, технических способов и методов в качестве
              наилучшей доступной технологии (Минпромторг России);
            </li>
            <li>
              федеральный орган исполнительной власти в сфере стандартизации
              (Росстандарт);
            </li>
            <li>
              ответственные федеральные органы исполнительной власти за
              разработку ИТС НДТ (Минприроды России, Минэнерго России,
              Минсельхоз России, Минстрой России, Минэкономразвития России,
              Минпромторг России, Роспотребнадзор, Росстандарт);
            </li>
            <li>
              Бюро наилучших доступных технологий (<a
                target="_blank"
                href="http://burondt.ru/"
                >Бюро НДТ</a
              >, <a target="_blank" href="http://burondt.ru/">Бюро</a>);
            </li>
            <li>
              межведомственный совет по переходу на принципы наилучших доступных
              технологий и внедрению современных технологий (<a
                target="_blank"
                href="http://burondt.ru/mvs/documents"
                >межведомственный совет</a
              >,
              <a target="_blank" href="http://burondt.ru/mvs/documents">МВС</a
              >);
            </li>
            <li>
              технический комитет по стандартизации «Наилучшие доступные
              технологии» 113 (<a
                target="_blank"
                href="http://burondt.ru/tk113/about"
                >ТК 113</a
              >);
            </li>
            <li>
              технические рабочие группы разработчик проекта справочника (<a
                target="_blank"
                href="http://burondt.ru/trg/2022"
                >ТРГ</a
              >,
              <a target="_blank" href="http://burondt.ru/trg/2022"
                >рабочие группы</a
              >).
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span>ЭТАПЫ</span>
      </div>
      <div class="text">
        <div class="fon img">
          <img src="img/stages.png" alt="" />
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span
          >РАЗРАБОТКА/АКТУАЛИЗАЦИЯ ИНФОРМАЦИОННО-ТЕХНИЧЕСКОГО СПРАВОЧНИКА<br />
          ПО НАИЛУЧШИМ ДОСТУПНЫМ ТЕХНОЛОГИЯМ</span
        >
      </div>
      <div class="text">
        <div class="fon img">
          <img src="img/info_tech.png" alt="" />
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span class="flex"
          ><span class="count">1</span>Формирование технической рабочей
          группы</span
        >
      </div>
      <div class="text">
        <div class="fon color">
          <p class="textFon">
            Согласно
            <a
              href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1328&etkstructure_id=1872"
              >приказу Министерства промышленности и торговли РФ 19 июня 2019 г.
              N 2130 «Об утверждении порядка формирования и осуществления
              деятельности технических рабочих групп»</a
            >
            формирование технических рабочих групп (<a
              target="_blank"
              href="http://burondt.ru/trg/2022"
              >ТРГ</a
            >) осуществляет Минпромторг России.
          </p>
          <p class="textFon">
            Схематично порядок формирования ТРГ представлен на рисунке. После
            опубликования уведомления о формировании ТРГ на
            <a target="_blank" href="http://burondt.ru/">сайте Бюро НДТ</a>
            заинтересованные лица, к которым относятся представители
            промышленных предприятий, федеральных органов исполнительной власти
            (ФОИВ), научно-исследовательских институтов и экспертных
            организаций, учебных институтов, промышленных союзов(ассоциаций),
            общественных объединений и др. направляют в адрес Бюро НДТ заявку на
            участие в создаваемой ТРГ.
          </p>
          <p class="textFon">
            Заявка включает в себя официальное письмо с данными о кандидате в
            члены ТРГ: ФИО, организация, должность, контактные данные, сведения
            об опыте работы в области, соответствующей области применения
            справочника, и квалификации, желаемый статус участия, дополнительные
            данные, и согласие на обработку персональных данных.
          </p>
          <p class="textFon">
            Срок формирования ТРГ определяется в уведомлении и не может быть
            менее 14 дней.
          </p>
        </div>
        <div class="fon img">
          <img src="img/work_group.png" alt="" />
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span>СОСТАВ И СТРУКТУРА ТРГ</span>
      </div>
      <div class="text structure">
        <div class="fon">
          <img src="img/structure_trg.png" alt="" />
        </div>
        <div>
          <p>
            Структурно техническая рабочая группа состоит из секретариата,
            руководит которым руководитель секретариата, разработчика проекта
            ИТС НДТ (<a
              target="_blank"
              href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=772&etkstructure_id=1872"
              >по ПП РФ от 23 декабря 2014 г. №1458</a
            >), полноправных членов и наблюдателей.
          </p>
          <p>
            Полноправные члены имеют право на голосование, а наблюдатели имеют
            право давать свою позицию на стадии рассмотрения документов.
          </p>
          <p>
            В состав группы могут входить представители: федеральных органов
            исполнительной власти, государственных научных организаций,
            некоммерческих организаций государственных корпораций, экспертных
            организаций, промышленных союзов (ассоциаций), объединений
            предпринимателей, иных организаций (<a
              target="_blank"
              href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1328&etkstructure_id=1872"
              >Приказ Минпромторга России № 2130 от 19.06.2019 г.</a
            >).
          </p>
          <p>
            Состав ТРГ должен быть сбалансирован и включать в себя
            представителей как органов власти, так и науки и бизнеса.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span class="flex"
          ><span class="count">2</span>Утверждение состава ТРГ</span
        >
      </div>
      <div class="text">
        <div class="fon color">
          <p class="textFon">
            После окончания формирования
            <a target="_blank" href="http://burondt.ru/trg/2022">ТРГ</a> Бюро
            НДТ обрабатывает полученные заявки в течение 10 рабочих дней и
            направляет в Минпромторг России предложения по составам ТРГ. При
            формировании предложений по составу рабочих групп не учитываются
            заявки, в которых отсутствуют сведения об опыте работы в области,
            соответствующей области применения справочника, и квалификации
            представителей организации, подавшей заявку.
          </p>
        </div>
        <p>
          Минпромторг России рассматривает предложения по составу ТРГ и
          утверждает составы приказом. Электронная копия приказа размещается на
          официальном сайте Министерства в информационно-телекоммуникационной
          сети «Интернет» и сайте Бюро НДТ в течение 5 рабочих дней со дня его
          подписания.
        </p>
        <p>
          В течение 30 рабочих дней со дня утверждения состава рабочей группы
          руководитель секретариата подготавливает и представляет на утверждение
          в Бюро предложения по установлению статуса членов рабочей группы.
        </p>
        <p>
          Вся работа рабочих групп проходит в личных кабинетах ТРГ на портале
          Бюро НДТ.
        </p>
      </div>
    </section>

    <section>
      <div class="title" style="margin-bottom: 30px">
        <span class="flex"
          ><span class="count">3</span>Установочное заседание</span
        >
      </div>
      <div class="text">
        <p class="textFon">
          В течение 30 календарных дней со дня утверждения состава
          <a target="_blank" href="http://burondt.ru/trg/2022">ТРГ</a> Бюро НДТ
          проводит первое (установочное) заседание.
        </p>
        <p>
          На нем руководитель секретариата ТРГ инициирует и организует
          разработку плана деятельности ТРГ, содержащего сроки и этапы работ,
          установленные Бюро НДТ, знакомит членов группы с порядком и правилами
          работы, обозначает первостепенные задачи ТРГ: определение области
          распространения ИТС НДТ, согласование отраслевой анкеты для сбора
          данных.
        </p>
        <p>
          Заседания ТРГ в процессе разработки ИТС НДТ проводятся по мере
          необходимости, по решению секретаря ТРГ или самой ТРГ. Зачастую для
          урегулирования спорных вопросов и достижения консенсуса при
          пересечении областей применения ИТС НДТ Бюро НДТ проводит совместные
          заседания нескольких ТРГ.
        </p>
        <p>
          Материалы заседаний ТРГ, уведомления о проведении, форме, месте и
          времени заседания, а также повестка заседания направляются
          секретариатом ТРГ членам группы в электронном виде заблаговременно и
          размещается на сайте. Итоги заседаний ТРГ оформляются протоколами,
          утверждаемыми руководителем Бюро НДТ и размещаются на официальном
          сайте в разделе «Заседания».
        </p>
      </div>
    </section>

    <section>
      <div class="title">
        <span>УСТАНОВОЧНОЕ ЗАСЕДАНИЕ ТРГ</span>
      </div>
      <div class="text">
        <div class="fon img">
          <img src="img/meet_trg.png" alt="" />
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span class="flex"
          ><span class="count">4</span>Разработка унифицированного отраслевого
          шаблона анкеты</span
        >
      </div>
      <div class="text">
        <div class="fon color">
          <p class="textFon">
            Одним из наиболее важных этапов разработки ИТС НДТ считается сбор
            данных, необходимых для определения технологических процессов,
            оборудования, технических способов, методов в качестве наилучшей
            доступной технологии. (<a
              target="_blank"
              href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=1050&etkstructure_id=1872\"
              >Приказ № 4841 от 18 декабря 2019 г. «Об утверждении порядка сбора
              и обработки данных, необходимых для разработки и актуализации
              информационно-технических справочников по наилучшим доступным
              технологиям»</a
            >)
          </p>
          <p class="textFon">
            Для сбора данных Бюро НДТ, в течение 30 календарных дней с даты
            утверждения составов рабочих групп, разрабатывает унифицированные
            отраслевые шаблоны для сбора данных, на основании информации,
            представленной разработчиком. После этого руководитель секретариата
            ТРГ организует ее рассмотрение и согласование на Портале Бюро НДТ в
            рабочем кабинете ТРГ.
          </p>
        </div>
        <p>
          По результатам рассмотрения проекта унифицированного отраслевого
          шаблона, членами ТРГ принимается решение об утверждении или доработки
          шаблона.
        </p>
      </div>
    </section>

    <section>
      <div class="title" style="margin-bottom: 30px">
        <span class="flex"><span class="count">5</span>Сбор данных</span>
      </div>
      <div class="text">
        <p>
          Главной целью сбора данных является получение от предприятий,
          относящихся к областям применения НДТ, реальных данных о применяемых
          ими технологиях, оборудовании, данных о сбросах и (или) выбросах
          загрязняющих веществ, образовании отходов, других факторах воздействия
          на окружающую среду, а также экономических показателях (материальных и
          организационно-технических затратах на внедрение и эксплуатацию
          технологии, оборудования.
        </p>
        <p>
          Срок сбора данных определяет Бюро НДТ, но не может быть менее 15
          календарных дней и более 45 календарных дней со дня размещения
          уведомления.
        </p>
        <p>
          Вся информация с предприятий, поступающая в Бюро НДТ, анализируется
          секретариатом ТРГ, кодируется, загружается в базу данных и имеет
          конфиденциальный характер. По итогам анализа и обработки руководитель
          секретариата направляет в рабочую группу обобщённую информацию для
          рассмотрения и включения в проект справочника.
        </p>
      </div>
    </section>

    <section>
      <div class="title">
        <span>УСТАНОВОЧНОЕ ЗАСЕДАНИЕ ТРГ</span>
      </div>
      <div class="text">
        <div class="fon img">
          <img src="img/seet_trg.png" alt="" />
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span>БАЗА ДАННЫХ БЮРО НДТ</span>
      </div>
      <div class="text">
        <div class="fon img">
          <img src="img/bd_ndt.png" alt="" />
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span class="flex"
          ><span class="count">6</span>Разработка проекта ИТС НДТ</span
        >
      </div>
      <div class="text">
        <div class="fon">
          <p>
            Основные задачами
            <a target="_blank" href="http://burondt.ru/trg/2022">ТРГ</a>
            заключаются в анализе/рассмотрении/согласовании:
          </p>
          <ul style="list-style-type: disc">
            <li>проектов унифицированных отраслевых шаблонов,</li>
            <li>
              полученных от Бюро НДТ данных, необходимых для определения
              технологических процессов, оборудования, технических способов и
              методов в качестве НДТ,
            </li>
            <li>проекта справочника, представленных разработчиком,</li>
            <li>дополнительных документов.</li>
          </ul>
          <p>
            Информационно-технические справочники делятся на межотраслевые
            (горизонтальные) и отраслевые (вертикальные). Отличаются они друг от
            друга в первую очередь наличием в последних маркерных веществ и
            технологических показателей.
          </p>
          <p>
            Структура справочников утверждена национальным стандартом ГОСТ Р
            113.00.03-2019.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span>СТРУКТУРА ИТС НДТ</span>
      </div>
      <div class="text">
        <div class="fon img">
          <img src="img/structire_ndtpng.png" alt="" />
        </div>
      </div>
    </section>

    <section>
      <div class="title" style="margin-bottom: 30px">
        <span class="flex"
          ><span class="count">6</span>Разработка проекта ИТС НДТ</span
        >
      </div>
      <div class="text">
        <p>
          Проект справочника, в утвержденные рабочей группы сроки,
          разрабатывается по разделам, каждый из которых проходит этапы
          рассмотрения, доработки и голосования в рабочем кабинете ТРГ на
          информационном портале Бюро НДТ. Все замечания и предложения по итогам
          рассмотрения документов в личном кабинете ТРГ оформляются в сводку
          отзывов.
        </p>
        <p>
          Разработка/актуализация проекта ИТС НДТ начинается параллельно с
          процессом сбора данных. Первоочередной и ключевой задачей стоит
          определение области применения справочника, которая также, как и любой
          его раздел утверждается голосованием в ТРГ. Параллельно с определением
          области применения разрабатываются разделы «введение» и «предисловие».
          Далее по завершению процесса сбора данных готовятся остальные разделы
          ИТС НДТ в соответствии со структурой, приведенной в ГОСТ Р
          113.00.03-2019 «НДТ. Структура информационно-технического справочника.
        </p>
        <p>
          Кроме непосредственно самих разделов ИТС НДТ разрабатываются и
          утверждаются в ТРГ следующие приложения/документы: перечень маркерных
          веществ, перечень технологических показателей, перечень наилучших
          доступных технологий, сведения о ресурсной эффективности, заключение
          по наилучшим доступным технологиям.
        </p>
      </div>
    </section>

    <section>
      <div class="title">
        <span>РАЗРАБОТКА ПРОЕКТА ИТС НДТ</span>
      </div>
      <div class="text">
        <div class="fon img">
          <img src="img/develop_ndt.png" alt="" />
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span class="flex"
          ><span class="count">7</span>Рассмотрение проекта справочника</span
        >
      </div>
      <div class="text">
        <div class="fon color">
          <p class="textFon">
            Разработчик справочника направляет в секретариат рабочей группы
            проект документа для размещения на согласование в личном кабинете
            ТРГ.
          </p>
          <p class="textFon">
            Члены рабочей группы рассматривают проект справочника и размещают
            замечания/ предложения по его содержанию в информационной системе
            уполномоченного органа в соответствии с порядком формирования и
            осуществления деятельности рабочих групп, утвержденным
            уполномоченным органом.
          </p>
        </div>
        <p>
          Все замечания и предложения формируют в сводку отзывов и направляются
          секретариатом рабочей группы разработчику для доработки проекта
          справочника. После доработки, документ направляется на голосование в
          личном кабинете ТРГ.
        </p>
      </div>
    </section>

    <section>
      <div class="title">
        <span class="flex"><span class="count">8</span>Доработка проекта</span>
      </div>
      <div class="text">
        <div class="fon color">
          <p class="textFon">
            Разработчик проекта справочника дорабатывает проект справочника по
            замечаниям и предложениям рабочей группы и направляет его в Бюро для
            размещения на голосование.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span class="flex"
          ><span class="count">9</span>Голосование перед публичным обсуждением
          проекта справочника</span
        >
      </div>
      <div class="text">
        <div class="fon color">
          <p class="textFon">
            В голосовании (очном/заочном) принимают участие члены рабочей группы
            со статусом «Полноправный член». По итогам голосования принимается
            решение о готовности или не готовности проекта справочника к
            публичному обсуждению.
          </p>
          <p class="textFon">
            Голосование считается состоявшимся, если в нем приняло участие не
            менее половины от общего количества полноправных членов.
          </p>
          <p class="textFon">
            При равенстве голосов проводятся дополнительное обсуждение и
            повторное голосование до принятия решения.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span class="flex"
          ><span class="count">9</span>Голосование перед публичным обсуждением
          проекта справочника</span
        >
      </div>
      <div class="text">
        <div class="fon color">
          <p>
            <a target="_blank" href="http://burondt.ru/discussion/2022">Публичное обсуждение</a> проводит Бюро НДТ, размещая проект справочника, типовую форму отзыва и уведомление о публичном обсуждении на своем официальном сайте в разделе «Публичное обсуждение».
          </p>
          <p>
            В публичном обсуждение вправе принять участие любое заинтересованное лицо заполнив и разместив свои предложения/замечания на сайте Бюро НДТ.
          </p>
          <p>
            Срок проведения публичного обсуждения, который определяет Бюро, не может составлять менее 30 календарных дней.
          </p>
          <p>
            После завершения публичного обсуждения проекта справочника, Бюро НДТ формирует сводку отзывов, в которой приводит решения рабочей группой, принятые по результатам анализа каждого из замечаний.
          </p>
        </div>
      </div>
    </section>


    <section>
      <div class="title">
        <span class="flex"
          ><span class="count">10</span>Публичное обсуждение</span>
      </div>
      <div class="text">
        <div class="fon">
          <p>
            Публичное обсуждение проводит Бюро НДТ, размещая проект справочника, типовую форму отзыва и уведомление о публичном обсуждении на своем официальном сайте в разделе «Публичное обсуждение».
          </p>
          <p>
            В публичном обсуждение вправе принять участие любое заинтересованное лицо заполнив и разместив свои предложения/замечания на сайте Бюро НДТ.
          </p>
          <p>
            Срок проведения публичного обсуждения, который определяет Бюро, не может составлять менее 30 календарных дней.
          </p>
          <p>
            После завершения публичного обсуждения проекта справочника, Бюро НДТ формирует сводку отзывов, в которой приводит решения рабочей группой, принятые по результатам анализа каждого из замечаний.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span>СТРУКТУРА ИТС НДТ</span>
      </div>
      <div class="text">
        <div class="fon img">
          <img src="img/project_ndt.png" alt="" />
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span class="flex"
          ><span class="count">11</span>Экспертиза в ТК 113</span>
      </div>
      <div class="text">
        <div class="fon color">
          <p>
            Экспертиза* проекта информационно-технического справочника по НДТ в техническом комитете «Наилучшие доступные технологии» 113 проводится на предмет соответствия требованиям, установленным к его содержанию и структуре в документах национальной системы стандартизации. В ТК 113 разработаны и утверждена система стандартов НДТ для систематизации и стандартизации разработки/актуализации ИТС НДТ.
          </p>
          <p>
            Экспертиза может проводиться параллельно с публичным обсуждением
          </p>
          <p>
            В этот же период, при необходимости,  могут быть проведены иные экспертизы проектов ИТС НДТ, например, экспертизы в отраслевых союзах и объединениях, профильных ТК по стандартизации, научно-исследовательских институтах и т.д.
          </p>
          <p>
            ТК 113 по итогам экспертизы подготавливает и направляет в Бюро НДТ экспертное заключение.
          </p>
        </div>
        <p>*<a target="_blank" href="https://burondt.ru/NDT/NDTDocsDetail.php?UrlId=789&etkstructure_id=1872">Приказ Росстандарта от 12 августа 2016 N 1080 «Об утверждении Порядка проведения экспертизы проектов ИТС НДТ в техническом комитете по стандартизации»</a></p>
      </div>
    </section>

    <section>
      <div class="title">
        <span class="flex"
          ><span class="count">12</span>Доработка проекта ИТС НДТ</span>
      </div>
      <div class="text">
        <div class="fon color">
          <p>
            По итогам <a target="_blank" href="http://burondt.ru/discussion/2022">публичного обсуждения</a> и экспертизы в ТК 113 секретариат рабочей группы формирует сводки  с замечаниями/предложениями и направляет ее в личный кабинет ТРГ и разработчику проекта справочника на рассмотрение.
          </p>
          <p>
            Рабочая группа совместно с разработчиком проекта справочника рассматривает сводку отзывов и формирует позицию по представленным замечаниям и предложениям.
          </p>
          <p>
            Разработчик дорабатывает проект справочника в соответствии с отведенными сроками по плану работы ТРГ и согласованной сводной отзывов. По итогам доработанный документ  направляется разработчиком в Бюро НДТ для размещения в личном кабинете ТРГ.  
          </p>
          <p>
            Бюро размещает доработанный проект справочника в личном кабинете ТРГ для принятия решения о готовности документа к утверждению или о необходимости доработки.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div class="title">
        <span class="flex"
          ><span class="count">13</span>Итоговое заседание</span>
      </div>
      <div class="text">
        <div class="fon color">
          <p>
            Итоговое заседание технической рабочей группы обязательно проводится перед отправкой документа на утверждение в Росстандарт.
          </p>
          <p>
            Материалы заседаний ТРГ, уведомления о проведении, форме, месте и времени заседания, а также повестка заседания направляются секретариатом ТРГ членам группы в электронном виде заблаговременно.
          </p>
          <p>
            Итоги заседаний ТРГ оформляются протоколами, утверждаемыми руководителем Бюро НДТ и размещаются на официальном сайте в разделе «Заседания».
          </p>
          <p>
            В рамках итогового заседания рабочая группа обсуждает положения справочника и принимает решение о готовности/не готовности отправить справочник на утверждение в Росстандарт.
          </p>
          <p>
            В случае невозможности снятия разногласий по проекту ИТС НДТ на итогом заседании ТРГ обсуждение ИТС переносится на площадку Межведомственного совета по переходу на наилучшие доступные технологии и внедрению современных технологий (МВС).
          </p>
        </div>
      </div>
    </section>

  </main>
</template>

<script>
// import BackImagesComp from "../BackImagesComp.vue";
export default {
  //   components: { BackImagesComp },
  name: "ItcDevelopment2",
  setup() {},
};
</script>

<style scoped>
.main {
  margin: 50px auto;
}

.title {
  background: #1769f4;
  color: #fff;
  padding: 30px 50px;
  text-transform: uppercase;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.title.flex {
  display: flex;
  align-items: center;
}

.title > .flex > .count {
  font-size: 42px;
  color: #9dc3e6;
  margin-right: 100px;
}

.fon {
  padding: 30px 0;
  margin-bottom: 30px;
}

.fon.img {
  display: flex;
  justify-content: center;
  margin: 0 20px;
}

.fon.img img {
  width: 100%;
}

.fon.color {
  background: #deebf7;
}

.text {
  margin-bottom: 30px;
}

.text p,
.text ul li {
  width: 90%;
  margin: 0 auto;
  /* font-size: calc(100vw / 80); */
  padding: 0 3vh;
  margin-bottom: 20px;
}

.text ul li {
  margin-bottom: 10px;
}

.structure {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.structure .fon img {
  width: 600px;
}

@media (max-width: 1100px) {
  .structure {
    flex-direction: column;
  }

  .structure .fon {
    display: flex;
    justify-content: center;
  }

  .structure .fon img {
    width: 80%;
  }
}

.bg-pl {
  background: url("/img/header_menu.png") no-repeat;
  background-size: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 39%;
  z-index: -1;
}

.pl-left {
  position: absolute;
  right: 0;
  width: calc(100vw / 6);
  z-index: -1;
}
.pl-right {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100vw / 8);
  z-index: -1;
}

/*---------------------------thirty-------------------------------*/

.pl1 {
  position: absolute;
  top: 250px;
  right: 0;
  z-index: 1;
}
.thirty__wrapper {
  width: 90%;
  margin: 50px auto;
  height: auto;
}
.thirty__title {
  font-size: calc(100vw / 35);
  color: #0071af;
  margin: 3vw 0;
}
.thirty__text {
  font-size: calc(100vw / 80);
}
/*---------------------------thirtyone---------------------------------*/

.thirtyone__title {
  font-size: calc(100vw / 55);
  padding: 2vh 0;
  margin: 2vh auto;
  border-bottom: 2px solid black;
  width: 97%;
}

.thirtyone__text {
  font-size: calc(100vw / 80);
  padding: 0 3vh;
}
.thirtyone__bg {
  background: url("/img/50.png") no-repeat center;
  height: calc(100vw / 3);
  background-size: 90% 100%;
}
.thirtyone__bg2 {
  background: url("/img/51.png") no-repeat center;
  height: calc(100vw / 3);
  background-size: 90%;
}
.thirtyone__bg3 {
  background: url("/img/52.png") no-repeat center;
  height: calc(100vw / 3);
  background-size: 65% 75%;
}
.thirtyone__bg4 {
  background: url("/img/53.png") no-repeat center;
  height: calc(100vw / 3);
  background-size: 80% 90%;
}
.thirtyone__bg5 {
  background: url("/img/54.png") no-repeat center;
  height: calc(100vw / 3);
  background-size: 90% 100%;
}

.thirtyone__down-block {
  display: flex;
  justify-content: flex-end;
  margin: 7vh 0 2vh 0;
}
.thirtyone__wrapp-link {
  width: 7%;
  display: flex;
  align-items: center;
}
.thirtyone__link-img {
  height: 4vh;
}
.thirtyone__link {
  font-size: calc(100vw / 80);
  color: #4183a5;
}

/*----------------------------MOBILE---------------------------------*/

@media (max-width: 480px) {
  .twenty__title {
    font-size: calc(3px + 80 * (100vw / 1280));
  }
  .twenty__subtitle {
    font-size: calc(3px + 41 * (100vw / 1280));
    margin: 0;
  }
  .twenty__link-one,
  .twenty__link-two,
  .twenty__subtitle2 {
    font-size: calc(3px + 40 * (100vw / 1280));
  }

  /*--------------------------twentyone-----------------------------------*/

  .twentyone__input {
    width: calc(3px + 520 * (100vw / 1280));
  }
  .twentyone__wrapper {
    margin-bottom: 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }
  .twentyone__nomination,
  .twentyone__items-mob {
    display: block;
  }
  .twentyone__table-wrapp {
    display: none;
  }
  .twentyone__nomination {
    display: flex;
    justify-content: space-between;
    padding: 0 7px;
    width: 230px;
  }
  .twentyone__filter-text {
    font-size: calc(3px + 33 * (100vw / 1280));
  }
  .twentyone__item-one {
    font-size: calc(3px + 33 * (100vw / 1280));
  }
  .twentyone__items-mob {
    margin-bottom: 100px;
  }
  .twentyone__item-mob2 {
    font-weight: 600;
  }
  .twentyone__item-mobile {
    width: 95%;
    max-width: 1720px;
    margin: 7px auto;
    height: auto;
    max-height: 5000px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    padding: 20px 15px;
    display: flex;
  }
  .twentyone__number {
    width: 40px;
  }
  .twentyone__span {
    font-size: 18px;
    padding-left: 7px;
  }
  .twentyone__block-wrapp {
    width: 280px;
  }

  .twentyone__h3 {
    margin: 5px 0;
    font-size: calc(3px + 38 * (100vw / 1280));
  }
  .twentyone__p {
    margin: 0;
  }
  .twentyone__p:last-child {
    margin: 5px 0;
  }

  .twentyone__years {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  .twentyone__year {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 45px;
  }
  .twentyone__download {
    height: 17px;
  }
}
</style>
import { ref } from "vue"
import { environment } from '@/composables/realhost.js';

export default function getIts(){
    const itsData = ref('');
    const loader = ref(true);
    // const isVisible1 = ref(true);

    function fetchIts() {
        // let year = (new Date()).getFullYear()
        // fetch("http://slimtest/its")
        fetch(environment.realHost + "its")
            .then(response => response.json())
            .then(data => {
                let div = document.createElement("div")
                div.innerHTML = data
                let table = div.querySelector("table")
                table.removeAttribute("width")
                table.removeAttribute("border")
                // table.style.cssText = `width: 100%;
                // font-size: calc(100vw/97);`
                table.style.cssText = `width: 100%;
                font-size: calc(100vw/97);
                border: none;
                border-spacing: 7px 11px;`
                let firstTr = table.querySelectorAll("tr")[1]
                firstTr.classList.add("tr-head")
                firstTr.style.cssText = `height: 30px;
                box-shadow: none;
                padding: 1.5vw 0;`
                let trs = table.querySelectorAll("tr")
                for(let i = 2; i < trs.length; i++){
                    trs[i].style.cssText = `
                        // display: inline-block;
                        width: 100%;
                        margin: 5px auto;
                        box-shadow: 0 0 5px rgb(0 0 0 / 50%);
                        padding: 0.7vw 0;`
                    let tds = trs[i].querySelectorAll("td")
                    for(let j = 0; j < tds.length; j++){
                        tds[j].style.padding = '1rem'

                        let p = tds[j].querySelectorAll("p")
                        p.forEach(el => {
                            el.style.textAlign = "start"
                        })
                        let a = tds[j].querySelectorAll("p a img")
                        a.forEach(el => {
                            el.setAttribute("src", "/img/45.png")
                            el.style.width = 'calc(100vw/125)'
                            el.style.height = '20px'
                            el.style.verticalAlign = '-webkit-baseline-middle'
                        })
                        // console.log(a)
                        // a.setAttribute("src", "/img/45.png")
                    }
                }
                itsData.value = table
                loader.value = false
                // isVisible1.value = true
            })
    }
    return { fetchIts, itsData, loader }
}
<template>
  <div class="seven__wrapp">
    <div class="seven__bottoms">
      <router-link
        :to="`/trg/${$route.params.year}/${$route.params.id}/info`"
      >
        <span class="seven__bottom">Общая информация</span>
      </router-link>
      <router-link
        :to="`/trg/${$route.params.year}/${$route.params.id}/meetings`"
      >
        <span class="seven__bottom">Заседания ТРГ</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrgSubMenu",
};
</script>

<style scoped>
.seven__wrapp {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 7vh 0;
  height: auto;
}
.seven__bottoms {
  display: flex;
  justify-content: space-between;
  width: 35%;
}
.seven__bottom {
  color: #a6a4a4;
  border-left: 1px solid #a6a4a4;
  padding: 1vw;
  font-size: calc(100vw / 75);
}
.seven__bottom:hover {
  color: black;
  border-left: 1px solid black;
  cursor: pointer;
}
.router-link-active span {
  color: black;
  border-left: 1px solid black;
  cursor: pointer;
}
.seven__bottoms a{
    text-decoration: none;
}

@media (max-width: 480px) {
  .seven__wrapp {
    margin: 0;
    height: auto;
  }
  .seven__bottoms {
    width: 100%;
    margin-top: .5rem;
    /*justify-content: center;*/
    margin-right: 65px;
  }
  .seven__bottom{
    font-size: calc(100vw / 46);
    padding: 1vh 1vh;
  }
}
</style>
<template>
  <div class="eight__close-wrap">
    <div class="eight__close-end">
      <router-link :to="back" class="eight__close-link">
        <img src="/img/6-2.png" alt="" class="eight__close-img" />
        <span>НАЗАД</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackButton",
  props: ["back"],
  setup() {},
};
</script>

<style scoped>
.eight__close-wrap{
    margin-top: 1rem;
}
.eight__close-end > a {
  text-decoration: none;
}
.eight__close-end {
  display: flex;
  justify-content: flex-end;
}
.eight__close-link {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.eight__close-img {
  width: 40%;
  padding: 0 10px;
}
.eight__close-end span {
  border-bottom: 1px solid #0074b0;
  color: #0074b0;
  display: inline-block;
  font-size: calc(100vw / 105);
  font-style: italic;
}
@media (max-width: 480px) {
  .eight__close-link {
    justify-content: flex-end;
  }
  .eight__close-img {
    width: 40%;
  }
  .eight__close-end span {
    font-size: 3vw;
  }
}
</style>
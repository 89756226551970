<template>
    <div class="container">
        <table>
            <thead>
                <tr>
                    <td>ФИО</td>
                    <td>Email</td>
                    <td>Организация</td>
                    <td>Должность</td>
                    <td>дата</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in discussionsData" :key="user.publicdiscussionreg_id">
                    <td>{{ user.publicdiscussionreg_fio }}</td>
                    <td>{{ user.publicdiscussionreg_email }}</td>
                    <td>{{ user.publicdiscussionreg_organiz }}</td>
                    <td>{{ user.publicdiscussionreg_position }}</td>
                    <td>{{ user.publicdiscussionreg_date }}</td>
                </tr>
            </tbody>
        </table>

    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { environment } from '@/composables/realhost.js';
export default {
  name: "DiscussionUsersTest",
  setup() {
    const discussionsData = ref([]);
    onMounted(() => {
        fetch(environment.realHost + "discussion/discussion-users")
        .then(response => response.json())
        .then(data => {
            discussionsData.value = data
        })
    });

    return { discussionsData };
  },
};
</script>
<style  scoped>
.container {
    width: 90%;
    margin: 0 auto;
    margin-top: 5rem;
}
table {
  border-collapse: collapse;
  /* border: 1px solid; */
}
table, td {
  border: 1px solid;
}
td {
  text-align: center;
}
</style>
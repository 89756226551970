import { ref } from "vue";
import { environment } from '@/composables/realhost.js';

export default function useTrgMeetings(){
    // const trgData = ref([]);
    // const loader = ref(true);
    const meetings = ref([])

    function makeFileUrl(a){
        return `${environment.urlMakerHost}ETK/EtkMeetingsFilesDownload.php?UrlId=${a}`
    }

    function fetchMeetings(year, id){
        fetch(environment.realHost + "trg/" + year + "/" + id + "/meetings")
        .then(response => response.json())
        .then(data => {
            meetings.value = data
            // loader.value = false
        })
    }

    
    return { fetchMeetings, meetings, makeFileUrl }
}
import { ref } from "vue"
import { environment } from "@/composables/realhost.js"

export default function useDocuments() {
	// let meeting = ref({});
	let documents = ref([])
	// let isOpen = ref(false)

	function fetchDocuments() {
		// fetch("http://slimtest/tk113/documents")
		fetch(environment.realHost + "tk113/documents")
			.then((response) => response.json())
			.then((data) => {
				data.forEach((el) => {
					let db = formatDate(el.project_dateb)
					el.project_dateb = db
					let de = formatDate(el.project_datee)
					el.project_datee = de
				})
				documents.value = data
			})
	}
	function formatDate(date) {
		var d = new Date(date)
		var month = d.getMonth() + 1
		date =
			d.getFullYear() +
			"-" +
			dateLength(month) +
			"-" +
			dateLength(d.getDate())
		return date
	}
	function dateLength(dd) {
		let d = String(dd)
		return d.length > 1 ? d : "0" + String(d)
	}

	return { fetchDocuments, formatDate, dateLength, documents }
}

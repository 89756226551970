<template>
  <div class="its_menu_content_item">
    <br><div class="seventeen__block3-content">
<h2>8. Голосование перед публичным обсуждением проекта справочника</h2>
<p>В голосовании (очном/заочном) принимают участие члены рабочей группы со статусом &laquo;Полноправный член&raquo;. По итогам голосования принимается решение о готовности или не готовности проекта справочника к публичному обсуждению.</p>
<p>Голосование считается состоявшимся, если в нем приняло участие не менее половины от общего количества полноправных членов.</p>
<p>При равенстве голосов проходит дополнительное обсуждение и повторное голосование до принятия решения.</p>
</div>
    
  </div>
</template>

<style scoped>
</style>

<script>
import useScrollToEl from "@/composables/its/scrollToEl.js"
import { onMounted } from "vue";
export default {
  setup() {
    const {scrollToEl} = useScrollToEl()
    onMounted(() => {
      scrollToEl()
    });
  },
};
</script>
<style scoped>
.seventeen__block3-content {
  
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding: calc(100vw / 22);
    padding-top: calc(100vw / 60);
    background: white;
    margin-bottom: calc(100vw / 20);
  
}
.seventeen__block3-content p {
  font-size: calc(80vw / 65);
    
}
.seventeen__block3-content li {
  font-size: calc(80vw / 65);
  
}
</style>
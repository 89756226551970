<template>
  <main class="main">
    <section v-if="loader" class="eleven">
      <loader object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793"
        opacity="80" name="circular"></loader>
    </section>
    <section v-else class="one">
      <div class="one__wrapper">

        <div class="one__items">
          <router-link to="/itc" class="one__item">
            <div class="one__item-head">
              <h2 class="one__head-text">
                перечень<br />
                итс ндт
              </h2>
              <img src="/img/15.png" alt="" class="one__head-img" />
            </div>
            <div class="one__item-foot">
              <div class="one__foot-text">
                Перечень информационно-технических справочников по наилучшим
                доступным технологиям
              </div><br><br><br><br><br><br><br><br>
              <button class="one__foot-btn">читать далее</button>
            </div>
          </router-link>

          <router-link to="/itc-orders" class="one__item">
            <div class="one__item-head">
              <h2 class="one__head-text">
                Приказы по <br />
                технологическим показателям НДТ
              </h2>
              <img src="/img/16.png" alt="" class="one__head-img" />
            </div>
            <div class="one__item-foot">
              <div class="one__foot-text">
                Перечень приказов Минприроды России об утверждении технологических показателей наилучших доступных технологий
              </div><br><br><br><br><br><br>
              <button class="one__foot-btn">читать далее</button>
            </div>
          </router-link>

          <router-link :to="'/trg/' + new Date().getFullYear()" class="one__item">
            <div class="one__item-head">
              <h2 class="one__head-text">
                технические<br />
                рабочие группы
              </h2>
              <img src="/img/18.png" alt="" class="one__head-img" />
            </div>
            <div class="one__item-foot">
              <div class="one__foot-text">
                Представители соответствующих федеральных и региональных органов
                власти, промышленных предприятий и ассоциаций, университетов,
                научно-исследовательских учреждений, инженерных и
                консультационных компаний, а также экологических
                неправительственных организаций
              </div>
              <button class="one__foot-btn">читать далее</button>
            </div>
          </router-link>

          <router-link :to="'/discussion/' + new Date().getFullYear()" class="one__item">
            <div class="one__item-head">
              <h2 class="one__head-text">
                публичное<br />
                обсуждение
              </h2>
              <img src="/img/17.png" alt="" class="one__head-img" />
            </div>
            <div class="one__item-foot">
              <div class="one__foot-text">
                Площадка для публичного обсуждения проектов
                информационно-технических справочников по наилучшим доступным
                технологиям и документов
              </div><br><br>
              <button class="one__foot-btn">читать далее</button>
            </div>
          </router-link>
          
          <router-link to="/tk113/about" class="one__item">
            <div class="one__item-head">
              <h2 class="one__head-text">
                технический<br />
                комитет 113
              </h2>
              <img src="/img/19.png" alt="" class="one__head-img" />
            </div>
            <div class="one__item-foot">
              <div class="one__foot-text">
                Драйвер технологического развития в сторону обеспечения
                энергетической эффективности, ресурсосбережения, охраны
                окружающей среды, промышленной безопасности и здоровья человека
              </div>
              <button class="one__foot-btn">читать далее</button>
            </div>
          </router-link>
        </div>
        <div class="one__dots-bg">
          <img src="/img/14.png" alt="" class="one__dots-left" />
          <div class="one__block-right">
            <img src="/img/20.png" alt="" class="one__img-bg" />
            <img src="/img/34.png" alt="" class="one__img-bgM" />
            <img src="/img/22.png" alt="" class="one__trian" />
            <img src="/img/21.png" alt="" class="one__dots-right" />
          </div>
        </div>
      </div>
    </section>

    <section class="two">
      <div class="two__wrapper">
        <div class="two__heading">
          <h2 class="two__title">Новости</h2>
          <router-link to="/news" class="two__title-link">Все новости</router-link>
        </div>
        <div class="two__slider">
          <Carousel :settings="settings" :breakpoints="breakpoints">
            <Slide v-for="n in newsData" :key="n.id">
              <div class="carousel__item">
                <div class="two__slider-item">
                  <h2 class="two__slider-title">{{ n.pagetitle }}</h2>
                  <div class="two__slider-time">
                    <span class="two__data">{{
                      makeNewsDate(n.createdon).split("|")[0]
                    }}</span>
                    <div class="two__time">
                      {{ makeNewsDate(n.createdon).split("|")[1] }}
                    </div>
                  </div>
                  <div class="two__description">{{ n.content }}</div>
                  <div class="two__btn-wrap">
                    <router-link :to="`/news/${n.id}`">
                      <button class="two__button">читать далее</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Navigation />
              <!-- <Pagination /> -->
            </template>
          </Carousel>
        </div>
        <div class="two__footer-wrap">
          <div class="two__footer-items">
            <div class="two__footer-item">
              <img src="/img/27.png" alt="" class="two__footer-img" />
            </div>

            <div class="two__footer-item">
              <img src="/img/28.png" alt="" class="two__footer-img" />
            </div>
          </div>
          <div class="two__foot-wrap">
            <div class="two__footer-block">
              <h2 class="two__footer-title">Документы</h2>
              <p class="two__footer-text">
                Перечень нормативных правовых и организационных документов,
                унифицированных шаблонов и ИТС НДТ
              </p>
              <router-link to="/documents" class="two__footer-link">
                Узнать больше
              </router-link>
            </div>
            <div class="two__footer-block">
              <h2 class="two__footer-title">Разработка ИТС НДТ</h2>
              <p class="two__footer-text">
                Процесс разработки/актуализации информационно-технического
                справочника по наилучшим доступным технологиям
              </p>
              <router-link to="/itc-development" class="two__footer-link">
                Узнать больше
              </router-link>
            </div>
          </div>
        </div>
        <img src="/img/9.png" alt="" class="two__img-1" />
        <img src="/img/22.png" alt="" class="two__img-2" />
        <img src="/img/8.png" alt="" class="two__img-3" />
      </div>
    </section>
    <section class="three"></section>
  </main>
</template>
<script>
// import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import { onMounted } from "vue";
import useAllNews from "@/composables/news/news.js";
export default {
  name: "Home",
  components: {
    Carousel,
    Slide,
    // Pagination,
    Navigation,
  },
  setup() {
    // const loader = ref(true);
    const {
      fetchNews,
      newsData,
      loader,
      makeNewsDate,
      getPicExtension,
      cutTegs,
      count,
    } = useAllNews();
    onMounted(() => {
      // setTimeout(function () {
      //   loader.value = !loader.value;
      // }, 500);
      fetchNews(0, 12);
    });
    return {
      // loader,
      fetchNews,
      newsData,
      loader,
      makeNewsDate,
      getPicExtension,
      cutTegs,
      count,
      settings: {
        itemsToShow: 1,
        itemsToScroll: 1,
        snapAlign: "start",
        transition: 500,
      },
      breakpoints: {
        // 700px and up
        500: {
          itemsToShow: 2,
          itemsToScroll: 2,
          snapAlign: "start",
          transition: 500,
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          itemsToScroll: 3,
          snapAlign: "start",
          transition: 500,
        },
      },
    };
  },
};
</script>

<style>
/* my */
.carousel__viewport {
  height: auto !important;
}

.carousel__prev,
.carousel__next {
  top: 50%;
}

.carousel__prev {
  left: -2rem;
}

.carousel__next {
  right: -2rem;
}

.two__slider-item {
  /* ??????? */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (max-width: 2300px) {
  .two__slider-item {
    /* ??????? */
    /* height: 30vw !important; */
  }
}

@media (max-width: 1920px) {
  .two__slider-item {
    /* ??????? */
    /* height: 60vh !important; */
  }
}

@media (max-width: 1480px) {
  .two__slider-item {
    /* ??????? */
    /* height: 50vh !important; */
  }
}

@media (max-width: 1024px) {
  .two__slider-item {
    /* ??????? */
    /* height: 32vmin !important; */
  }

  .two__description {
    margin-bottom: 0 !important;
  }

  .carousel__prev {
    left: 0;
  }

  .carousel__next {
    right: 0;
  }
}

@media (max-width: 480px) {
  .two__slider-item {
    /* ??????? */
    /* height: 40vh !important; */
  }

  .two__slider-title {
    margin-left: 0 !important;
    width: 100% !important;
  }

  .two__slider-time {
    margin-left: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

.two__slider-title {
  /* white-space: nowrap; */
  overflow: hidden;
  /* height: 50%; */
  /* font-size: 25px !important; */
  text-overflow: ellipsis;
}

.two__slider-time {
  height: 10% !important;
}

.two__description {
  /*  height: 40% !important;*/
}

.two__btn-wrap {
  height: 10% !important;
}

.carousel__item {
  padding: 1rem;
  /*border:1px solid red;*/
}

.carousel {
  width: 90%;
  margin: 0 auto;
}

.two__slider-title {
  color: #000000;
  font-size: calc(3px + 29 * (100vw / 1920));
  margin: 10px 0;
  text-align: start;
  margin-left: 1rem;
}

.two__slider-time {
  font-size: calc(100vw / 125);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 87%;
  color: #626262;
  margin-left: 1rem;
}

.two__time {
  border-left: 1px solid #626262;
  padding: 0 10px;
}

.two__description {
  color: #111111;
  margin: 10px 0;
  font-size: calc(3px + 19 * (100vw / 1920));
  text-align: start;
  margin-left: 1rem;
}

.two__btn-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: .3rem;
}

.two__button {
  position: relative;
  width: calc(100vw / 16);
  font-size: calc(100vw / 122);
  height: calc(100vw / 60);
  background: #005f84;
  color: white;
  cursor: pointer;
  border: none;
}

/* end my */

.main {
  height: auto;
  position: relative;
}

.one__img-bgM {
  display: none;
}

.one__wrapper {
  position: relative;
}

.one__items {
  width: 65%;
  margin: 50px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
  z-index: 5;
  /* было 5 */
  /*border: 1px solid red;*/
}

.one__item {
  margin: 1vw;
  /*padding: calc(3px + 20 * (100vw / 1800));*/
  padding: 1vw 1.5vw;
  width: calc(100vw / 6.1);
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-top-left-radius: 3vw;
  /*border-top-left-radius: 40px;*/
  transition: 0.5s;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
}

.one__item:hover .one__foot-text {
  color: #fff;
}

.one__item:hover {
  background: #005f84;
  color: white;
  cursor: pointer;
  transform: scale(1.03);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.one__item:hover .one__item-foot a .one__foot-btn:hover {
  background: #d69464;
  transform: scale(1.1);
}

.one__item:hover>.one__item-head>.one__head-text {
  color: white;
}

.one__item:hover>.one__item-head {
  border-bottom: 1px solid #fafafa;
}

.one__item:hover>.one__item-foot>.one__foot-btn {
  background: #ffffff;
  color: #005f84;
}

.one__item:hover>.one__item-foot>a>.one__foot-btn {
  background: #ffffff;
  color: #005f84;
}

.one__item-head {
  border-bottom: 1px solid #0d0504;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.one__head-text {
  color: #242424;
  font-size: calc(3px + 15 * (100vw / 1800));
  text-transform: uppercase;
}

.one__head-img {}

.one__item-foot a {
  text-decoration: none;
}

.one__foot-text {
  color: #848181;
  font-size: calc(3px + 13 * (100vw / 1800));
  text-align: left;
  padding: 30px 0;
}

.one__foot-btn {
  font-size: calc(100vw / 122);
  background: #005f84;
  color: white;
  padding: 0 10px;
  position: relative;
  left: 58%;
  cursor: pointer;
  border: none;
  height: calc(100vw / 55);
  transition: 0.5s;
  border-radius: 2px;
}

.one__dots-bg {}

.one__dots-left {
  /*position: relative;
  bottom: 50vw;
  z-index: -1;*/
  display: none;
}

.one__block-right {}

.one__img-bg {
  position: absolute;
  top: 140px;
  right: 0;
  height: calc(3px + 655 * (100vw / 1800));
  width: calc(3px + 1200 * (100vw / 1800));
  z-index: 1;
}

.one__trian {
  position: absolute;
  right: calc(3px + 230 * (100vw / 1800));
  top: calc(3px + 715 * (100vw / 1800));
  width: calc(3px + 150 * (100vw / 1800));
}

.one__dots-right {
  width: calc(3px + 326 * (100vw / 1800));
  position: absolute;
  right: 0;
  top: calc(3px + 611 * (100vw / 1800));
}

/*--------------------------------TWO-----------------------------------*/

.two__wrapper {
  border-top: 2px solid black;
  margin-top: 17vw;
}

.two__heading {
  width: 22%;
  position: relative;
  bottom: calc(100vw / 38);
  left: 12vw;
  z-index: 3;
  background: #fafafa;
}

.two__title {
  font-size: calc(15px + 55 * (100vw / 1920));
  color: #1788b3;
  margin: 0;
  left: calc(100vw / 30);
  position: relative;
}

.two__title-link {
  color: #323232;
  font-size: calc(3px + 24 * (100vw / 1920));
  position: relative;
  left: calc(100vw / 28);
}

.two__slider {}

/* .two__slider-items {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
} */
/* my */
.two__slider-items {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  max-width: 2200px;
  /* max-width: 1100px; */
  padding: 0 10vh;
}

/* end my */
/* border: 1px solid red;
}
.two__slider-item {
  padding: 0 1vh;
}
.two__slider-item:nth-child(even) {

}
.two__slider-title {
  color: #000000;
  font-size: calc(3px + 29 * (100vw / 1920));
  margin: 10px 0;
}
.two__slider-time {
  font-size: calc(100vw/125);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 87%;
  color: #626262;
}
.two__data {

}
.two__time {
  border-left: 1px solid #626262;
  padding: 0 10px;
}
.two__description {
  color: #111111;
  margin: 10px 0;
  font-size: calc(3px + 19 * (100vw / 1920));
}
.two__btn-wrap {
  display: flex;
  justify-content: flex-end;
}
.two__button {
  position: relative;
  width: calc(100vw/16);
  font-size: calc(100vw/122);
  height: calc(100vw/60);
  background: #005f84;
  color: white;
  cursor: pointer;
  border: none;
}


/*----------стрелки слайдера-----------*/
.arrow7 {
  background: url(/img/23.png) no-repeat right;
  width: 50px;
  height: 70px;
  position: absolute;
  left: 0;
  cursor: pointer;
  top: 36%;
  /* border: 1px solid red;*/
}

.arrow8 {
  background: url(/img/24.png) no-repeat left;
  width: 50px;
  height: 70px;
  position: absolute;
  top: 36%;
  z-index: 1;
  cursor: pointer;
  right: 0;
  /*border: 1px solid red;*/
}

.two__footer-wrap {
  position: relative;
  z-index: 111;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 25%;
}

.two__footer-items {
  display: flex;
  z-index: 1;
  position: absolute;
  top: 30vh;
  right: 3vw;
}

.two__footer-item {}

.two__foot-wrap {
  width: 50%;
  display: flex;
  justify-content: space-between;
  position: relative;
  right: 6vw;
  top: 50vh;
  z-index: 1;
}

.two__footer-img {
  width: 30vw;
  height: 100%;
  margin: 0 1vw;
}

.two__footer-block {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 1.5vw;
  background: #fafafa;
  /* background: linear-gradient(-226deg, transparent 18px, #fafafa 0) top left;*/
  border-top-left-radius: 3vw;
  width: 36%;
  /*height: 19vh;*/
  transition: 0.5s;
}

.two__footer-block:hover {
  transform: scale(1.05);
}

/* .two__footer-block a:hover{
  transform: scale(1.05);
} */
.two__footer-title {
  font-size: 1vw;
  color: #000000;
  margin: 0;
  height: 5vh;
}

.two__footer-text {
  font-size: 1vw;
  color: #4d4d4d;
  margin-top: 0;
}

/* dfdf */
.two__footer-block:hover .two__footer-link:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: -3px;
  width: 0;
  height: 2px;
  /* Высота линии */
  background-color: black;
  /* Цвет подчеркивания при исчезании линии*/
  transition: width 0.5s;
  /* Время эффекта */
}

.two__footer-block:hover .two__footer-link:after {
  content: "";
  width: 50%;
  margin-left: 50%;
  display: block;
  position: absolute;
  left: 0;
  bottom: -3px;
  height: 2px;
  /* Высота линии */
  background-color: black;
  /* Цвет подчеркивания при появлении линии*/
  transition: width 0.5s;
  /* Время эффекта */
}

/* sdfsdf end */

.two__footer-link {
  color: #4d4d4d;
  font-size: 1vw;
  position: relative;
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
}

.two__img-1 {
  position: absolute;
  bottom: 0;
  max-width: 300px;
  width: calc(3px + 222 * (100vw / 1280));
}

.two__img-2 {
  position: absolute;
  left: -3%;
  width: 10%;
  bottom: 11%;
}

.two__img-3 {
  position: relative;
  left: 30vw;
  bottom: 25vw;
  width: 15%;
}

@media (max-width: 1920px) {
  .slick-slider {
    width: calc(80px + 1500 * (100vw / 1920));
  }

  .two__img-3 {}

  /* .two__slider-item {
    width: 100%;
    max-width: 660px;
    width: 100%;
    height: 30vh;
  } */
}

/*----------------------------MOBILE---------------------------------*/

@media (max-width: 1440px) {
  .one__items {
    width: 80vw;
  }

  .one__item {
    width: 20vw;
  }

  .one__head-img {
    height: 4vw;
  }

  .one__foot-text {
    /*font-size: 1.2vw;*/
    padding: 1.5vh 0;
  }

  .one__foot-btn {
    /*font-size: 1.2vw;*/
    padding: 0 1vw;
    left: 47%;
    height: 2vw;
  }

  .two__title {
    font-size: 3.5vw;
  }

  .two__title-link {
    font-size: 1.7vw;
  }

  .two__wrapper {
    border-top: 1px solid black;
  }

  .two__slider-time {
    font-size: 1.3vw;
  }

  .arrow8,
  .arrow7 {
    width: 30px;
    /*border: 1px solid red;*/
    background-size: 1vw;
  }

  .two__button {
    width: auto;
    font-size: 1.3vw;
    height: auto;
  }

  .two__footer-title,
  .two__footer-text,
  .two__footer-link {
    font-size: inherit;
  }

  .two__footer-title {
    height: auto;
    padding-bottom: 1.5vh;
  }

  .two__foot-wrap {
    width: 51%;
    display: flex;
    justify-content: space-between;
    position: relative;
    right: 6vw;
    top: 9vw;
    z-index: 1;
    bottom: 0;
    /* border: 1px solid red;*/
  }

  .two__footer-block {
    /*padding: 20px 20px 10px 20px;
    font-size: 1.2vw;*/
    width: 37%;
  }

  .two__footer-wrap {
    /* border: 1px solid red;*/
    top: 11vw;
  }

  .two__footer-items {
    position: absolute;
    top: 0;
    right: 3vw;
  }

  .two__img-3 {
    bottom: 30vw;
  }

  /*.slick-slider {
      max-width: 1100px !important;
    }
    .two__slider-item {
      max-width: 330px;
    }
    .two__foot-wrap {
    max-width: 845px;
    top: 430px;
    }
    .two__footer-img {
    max-width: 485px;
    }*/
}

@media (max-width: 480px) {
  .carousel__item {
    padding: 1rem;
  }

  .carousel__prev,
  .carousel__next {
    width: 20px !important;
  }

  .carousel__prev--in-active {
    opacity: 0.5 !important;
  }

  .main {
    height: auto;
  }

  .one__img-bg {
    width: 100%;
    height: auto;
    top: 0;
    display: none;
  }

  .one__img-bgM {
    display: inline-block;
    width: 100%;
    height: auto;
    top: 0;
    position: absolute;
  }

  .one__items {
    flex-direction: column;
    top: 100px;
    width: auto;
  }

  .one__item {
    padding: calc(3px + 40 * (100vw / 1280));
    width: calc(3px + 1015 * (100vw / 1280));
    height: auto;
    margin: 5vw;
    border-top-left-radius: 10vw;
  }

  .one__head-text {
    font-size: calc(3px + 60 * (100vw / 1280));
  }

  .one__foot-text {
    font-size: calc(3px + 40 * (100vw / 1280));
    padding: 18px 10px;
  }

  .one__foot-btn {
    /*font-size: calc(3px + 25 * (100vw / 1280));*/
    font-size: 12px;
    width: calc(3px + 370 * (100vw / 1280));
    height: calc(3px + 95 * (100vw / 1280));
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    /*font-size: 12px;
    text-transform: uppercase;
    height: 30px;
    padding: 0 10px;*/
  }

  .one__dots-right {
    width: calc(3px + 685 * (100vw / 1280));
    position: absolute;
    right: 0;
    top: 138%;
  }

  .one__dots-left {
    /*bottom: calc(3px + 4990 * (100vw / 1280));
    width: calc(3px + 315 * (100vw / 1280));*/
    width: calc(100vw / 3);
    position: absolute;
    bottom: 88%;
    display: block;
  }

  .one__trian {
    right: calc(3px + 11 * (100vw / 1280));
    top: calc(3px + 1400 * (100vw / 1280));
    width: calc(3px + 154 * (100vw / 1280));
  }

  .one__head-img {
    height: auto;
  }

  .one__foot-btn {
    left: 58%;
  }

  /*---------------------------TWO----------------------------------*/

  .two {
    width: 99%;
    margin: 0 auto;
  }

  .two__wrapper {
    height: 555px;
    margin-top: 44vw;
    border-top: 1px solid black;
  }

  /* .two__slider-item {
    max-width: 100%;
  } */
  .two__slider-title {
    height: auto !important;
  }

  .arrow8 {
    background: url(/img/24.png) no-repeat left;
    background-size: 20%;
    width: 10vw;
    height: 70px;
    position: absolute;
    top: 35vw;
    left: 87%;
    cursor: pointer;
  }

  .arrow7 {
    background: url(/img/23.png) no-repeat right;
    background-size: 20%;
    width: 10vw;
    height: 70px;
    position: relative;
    top: 35vw;
    left: auto;
    right: 28%;
    /*right: auto;*/
    z-index: 1;
    cursor: pointer;
  }

  .two__heading {
    left: 45px;
    bottom: 35px;
    width: 225px;
  }

  .two__title {
    font-size: 45px;
  }

  .two__title-link {
    font-size: 18px;
    left: 22px;
  }

  .two__description {
    font-size: 15px;
    margin: 10px auto;
    width: auto;
    height: auto;
  }

  .two__slider-title {
    font-size: 20px;
    position: relative;
    left: auto;
    width: 80%;
  }

  .two__slider-time {
    max-width: 245px;
    font-size: 15px;
    position: relative;
    left: auto;
  }

  .two__button {
    left: auto;
    font-size: 12px;
    text-transform: uppercase;
    height: 30px;
    width: calc(100vw / 3.1);
    /* top: 22vw; */
  }

  .two__img-3 {
    left: 70%;
    bottom: 15vh;
    width: 30%;
  }

  .two__img-1 {
    width: calc(3px + 515 * (100vw / 1280));
  }

  .two__img-2 {
    width: calc(3px + 215 * (100vw / 1280));
    height: 54px;
    top: 21%;
    left: 0;
    z-index: -1;
  }

  .two__footer-block {
    /*height: 32%;*/
    /*height: auto;*/
    width: 82%;
    border-top-left-radius: 8vw;
    padding: 5vw;
  }

  .two__footer-img {
    width: calc(3px + 993 * (100vw / 1280));
    height: 107%;
  }

  .two__footer-items {
    align-items: flex-start;
    width: 100%;
    height: 480px;
    left: auto;
    top: 100px;
    flex-direction: column;
    justify-content: space-between;
    /*margin: 0px 170px 0 0;*/
    margin: 0;
  }

  .two__footer-title {
    font-size: 4vw;
    height: 40px;
    padding-bottom: 1vh;
  }

  .two__footer-text {
    font-size: 3.3vw;
  }

  .two__footer-link {
    font-size: 3.3vw;
    left: auto;
  }

  .two__foot-wrap {
    flex-direction: column;
    top: 175px;
    height: 450px;
    right: auto;
    width: 75%;
  }
}

.carousel__slide {
  scroll-snap-stop: auto;
  flex-shrink: 0;
  margin: 0;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel {
  position: relative;
  text-align: center;
  box-sizing: border-box;
}

.carousel * {
  box-sizing: border-box;
}

.carousel__track {
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
}

.carousel__viewport {
  overflow: hidden;
  height: auto;
}

@media (max-width: 1440px) {
  .carousel__viewport {
    height: auto;

  }
}

@media (max-width: 480px) {
  .carousel__viewport {
    height: auto;

  }

  .carousel__next,
  .carousel__prev {
    width: 7vw !important;
    height: 7vw !important;
  }
}

:root {
  /* Color */
  --vc-clr-primary: #642afb;
  --vc-clr-secondary: #8e98f3;
  --vc-clr-white: #ffffff;

  /* Icon */
  --vc-icn-width: 1.2em;

  /* Navigation */
  --vc-nav-width: 30px;
  --vc-nav-color: #ffffff;
  --vc-nav-background-color: var(--vc-clr-primary);

  /* Pagination */
  --vc-pgn-width: 10px;
  --vc-pgn-height: 5px;
  --vc-pgn-margin: 5px;
  --vc-pgn-border-radius: 0;
  --vc-pgn-background-color: var(--vc-clr-secondary);
  --vc-pgn-active-color: var(--vc-clr-primary);
}

.carousel__prev,
.carousel__next {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  border-radius: var(--vc-nav-width);
  width: 3vw;
  height: 3vw;
  text-align: center;
  font-size: 7vw;
  padding: 0;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
}

.carousel__prev {
  top: 40%;
  left: 0;
  transform: translate(-50%, -50%);
}

.carousel__next {
  top: 40%;
  right: 0;
  transform: translate(50%, -50%);
}

.carousel__icon {
  width: var(--vc-icn-width);
  height: var(--vc-icn-width);
  fill: currentColor;
}

.carousel__pagination {
  display: flex;
  justify-content: center;
  list-style: none;
}

.carousel__pagination-button {
  margin: var(--vc-pgn-margin);
  width: var(--vc-pgn-width);
  height: var(--vc-pgn-height);
  border-radius: var(--vc-pgn-height);
  border: 0;
  cursor: pointer;
  background-color: var(--vc-pgn-background-color);
}

.carousel__pagination-button--active {
  background-color: var(--vc-pgn-active-color);
}</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import loader from "vue-ui-preloader"
import breadcrumbs from 'vue-3-breadcrumbs'
import VueYandexMetrika from 'vue-v3-yandex-metrika'

createApp(App).use(router).use(VueYandexMetrika, {
    id: 39514275,
    router: router,
    env: process.env.NODE_ENV,
    // other options
}).use(breadcrumbs, {includeComponent: true}).use(loader).mount('#app')

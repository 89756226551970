<template>
  <main class="main">
    <back-images-comp />
    <TK113Menu />
    <section class="seventeen">
      <div class="seventeen__wrapper">
        <div class="seventeen__head-block">
          <h2 class="seventeen__head-title">{{ about["etkstructure_num"] }}</h2>
        </div>

        <div class="seventeen__block-3">
          <h3 class="seventeen__block3-subtitle">
            Повседневная практика показывает
          </h3>
          <div class="seventeen__block3-content">
            <img class="seventeen__block3-img" src="/img/41-1.png" alt="" />
            <p>
              Техническое регулирование и стандартизация — драйвер технологического развития, обеспечивающий как
              разработку новой высокотехнологичной продукции, технологическое перевооружение и создание
              конкурентоспособных производств, так и прогресс в части энергетической эффективности и ресурсосбережения,
              охраны окружающей среды, защиты жизни и здоровья человека, промышленной безопасности. <br>

              Технический комитет по стандартизации № 113 «Наилучшие доступные технологии» создан в 2014 г. для
              содействия интересам российской промышленности и сегодня обеспечивает научно-методическое и
              экспертно-аналитическое сопровождение внедрения НДТ в базовых отраслях российской промышленности. <br>


            </p>
          </div>
          <p>
            В начале 2019 г. технический комитет 113 начал новый этап – создал систему стандартов НДТ (ГОСТ Р 113.00.01
            - 2019).<br><br>
            Создание такой системы было обусловлено необходимостью систематизации документов по стандартизации в области
            НДТ в рамках нормативно – методического сопровождения внедрения НДТ.
            Система стандартов включает в себя:<br><br>
            – организационно-методические и общетехнические документы по стандартизации, устанавливающие основные
            положения, правила и рекомендации по определению технологических процессов, оборудования, технических
            способов в качестве НДТ и их применения для снижения негативного воздействия на окружающую среду, не
            затрагивания основные технологические процессы производства;<br><br>
            – методические рекомендации по выбору маркерных веществ (показателей) для выбросов в атмосферу от
            промышленных источников при производстве в различных отраслях промышленности;<br><br>
            – методические рекомендации, устанавливающие подходы к заполнению формы заявки на комплексное экологическое
            разрешение для предприятий, отнесенных в Российской Федерации к I категории объектов негативного воздействия
            на окружающую среду;<br><br>
            – методические рекомендации, устанавливающие подходы к обоснованию приоритетных источников и факторов
            негативного воздействия на окружающую среду для включения в программу производственного экологического
            контроля на предприятиях;<br><br>
            – методические рекомендации по проектированию и эксплуатации автоматических систем контроля и учета объема
            и/или массы и концентрации выбросов вредных (загрязняющих) веществ;<br><br>
            – другие методические рекомендации, необходимые для перехода на государственное регулирование на основе
            НДТ.<br><br>

            2021 г. прошел под знаком низкоуглеродного развития: давно анонсированная климатическая политика РФ получила
            импульс к развитию после принятия Стратегии социально-экономического развития Российской Федерации с низким
            уровнем выбросов парниковых газов до 2050 года. Правительство поставило задачу создать действенный механизм
            низкоуглеродной трансформации экономики, объединив для этого усилия бизнеса, государства, общества,
            экспертных и научных организаций. Для формирования национальных подходов к процедуре бенчмаркинга в ТК 113
            был разработан национальный стандарт, определяющий порядок проведения отраслевого бенчмаркинга удельных
            выбросов парниковых газов<br><br>

            Важность документов по стандартизации, разрабатываемых ТК 113, заключается в их дальнейшем правоприменении.
            Возможность использования документов системы стандартов НДТ станет одним из действенных механизмов
            стимулирования технологического развития основных отраслей отечественного производства.

          </p>
        </div>

        <div v-if="about['etkstructure_status']" class="seventeen__wrapp">
          <div class="seventeen__block-1" v-for="secr in about['secr_data']" :key="secr.users_id">
            <div class="seventeen__contact">
              <h3 class="seventeen__contact-title">СТАТУС</h3>
              <span v-if="about['etkstructure_status']" class="seventeen__contact-subtitle">АКТИВЕН</span>
              <span v-else class="seventeen__contact-subtitle">Не Активен</span>
            </div>
            <div class="seventeen__contact">
              <h3 class="seventeen__contact-title">ОТВЕТСТВЕННЫЙ СЕКРЕТАРЬ</h3>
              <span class="seventeen__contact-subtitle">{{
              secr.users_fio
              }}</span>
            </div>
            <div class="seventeen__contact">
              <h3 class="seventeen__contact-title">ТЕЛЕФОН</h3>
              <a href="#" class="seventeen__contact-subtitle">{{
              secr.user_tel
              }}</a>
            </div>
            <div class="seventeen__contact">
              <h3 class="seventeen__contact-title">ЭЛЕКТРОННЫЙ АДРЕС</h3>
              <a href="#" class="seventeen__contact-subtitle">{{
              secr.users_email
              }}</a>
            </div>
          </div>
          <div class="seventeen__block-2">
            <div class="seventeen__contact2">
              <a href="#" class="seventeen__link-contact">
                <h3 class="seventeen__contact2-title">Полноправные члены</h3>
              </a>
              <div class="seventeen__link-block">
                <p v-for="org in about.members" :key="org.organiz_id">
                  {{ org.organiz_nameshort }}
                </p>
              </div>
            </div>
            <div class="seventeen__contact2">
              <a href="#" class="seventeen__link-contact">
                <h3 class="seventeen__contact2-title">Наблюдатели</h3>
                <span class="seventeen__link-content">...</span>
              </a>
              <div class="seventeen__link-block block2"></div>
            </div>
            <div class="seventeen__contact2">
              <a href="#" class="seventeen__link-contact">
                <h3 class="seventeen__contact2-title">
                  Организация, ведущая секретариат
                </h3>
              </a>
              <div v-for="mainOrg in about.main_org" :key="mainOrg.organiz_id" class="seventeen__link-block block3">
                <p>наименнование, адрес, e-mail</p>

                <p>{{ mainOrg.organiz_namelong }}</p>

                <p>{{ mainOrg.organiz_address }}</p>

                <p>{{ mainOrg.organiz_contactemail }}</p>
              </div>
            </div>
            <div class="seventeen__contact2">
              <a href="#" class="seventeen__link-contact">
                <h3 class="seventeen__contact2-title">
                  Область деятельности и др.
                </h3>
                <span class="seventeen__link-content">...</span>
              </a>
              <div v-html="about.etkstructure_note" class="seventeen__link-block block4"></div>
            </div>
            <div class="seventeen__contact2">
              <a href="#" class="seventeen__link-contact">
                <h3 class="seventeen__contact2-title">Документы</h3>
              </a>
              <div class="seventeen__link-block block5">
                <div v-for="doc in about.docs" :key="doc.etkdocs_id">
                  <a :href="makeUrl(doc.etkdocs_id, about.etkstructure_id)" class="seventeen__link-title">{{
                  doc.etkdocs_ond }}</a>
                  <p class="seventeen__link-description">
                    {{ doc.etkdocs_nnd }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="seventeen__wrapp no_info">
          <loader object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793"
            opacity="80" name="circular"></loader>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import TK113Menu from "./TK113Menu.vue";
import useAbout from "@/composables/tk113/about";
import { onMounted } from "vue";
import BackImagesComp from "../BackImagesComp.vue";

export default {
  name: "TK113About",
  components: {
    TK113Menu,
    BackImagesComp,
  },
  setup() {
    const { about, fetchAbout, makeUrl } = useAbout();
    onMounted(() => fetchAbout());
    return { about, makeUrl };
  },
};
</script>

<style scoped>
.sixteen__wrapper {
  margin: 3vh 3vh;
  height: auto;
}

.sixteen__title-main {
  color: #0073af;
  font-size: calc(100vw / 35);
}

.sixteen__items {
  display: flex;
  justify-content: space-between;
  width: 85%;
}

.sixteen__item {
  color: #a6a4a4;
  border-left: 1px solid #a6a4a4;
  padding: 1vh 3vh;
  font-size: calc(100vw / 70);
}

.sixteen__item:hover {
  color: black;
  border-left: 1px solid black;
  cursor: pointer;
}

/*----------------------------seventeen--------------------------------------*/

.seventeen {
  padding: 0 50px;
}

.seventeen__wrapper {
  background: white;
  margin: 3vh 3vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 20px 50px;
  margin-bottom: 200px;
}

.seventeen__head-block {
  border-bottom: 0.1vw solid black;
}

.seventeen__head-title {
  font-size: calc(100vw / 50);
}

.seventeen__head-subtitle {
  font-size: 35px;
  margin: 0;
}

.seventeen__block-3 {
  display: block;
  margin: 10vh 0;
}

.seventeen__block-3 p {
  font-size: calc(100vw / 65);
}

.seventeen__block3-subtitle {
  font-size: calc(100vw / 55);
}

.seventeen__block3-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.seventeen__block3-content p {
  font-size: calc(100vw / 65);
  margin-left: 30px;
}

.seventeen__block3-img {
  height: calc(100vw / 5);
}

.seventeen__wrapp {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}

.seventeen__block-1 {
  width: 40%;
  height: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.seventeen__contact-title {
  margin: 5px 2px;
  font-weight: 300;
  font-size: calc(100vw / 111);
}

.seventeen__contact-subtitle {
  margin: 0 2px;
  color: black;
  font-weight: 700;
  font-size: calc(100vw / 90);
  text-decoration: none;
}

.seventeen__block-2 {
  width: 55%;
}

.seventeen__contact2 {
  background: #f4f9fb;
  margin-bottom: 30px;
}

.seventeen__link-contact {
  margin: 0 10px;
  text-decoration: none;
}

.seventeen__contact2-title {
  margin: 10px;
  color: black;
  font-size: calc(100vw / 80);
}

.seventeen__link-content {
  margin: 0 10px;
  font-size: 2vh;
}

.seventeen__link-block {
  background: #f4f9fb;
  padding: 10px;
  font-size: calc(100vw / 100);
}

.seventeen__link-title {
  color: #010101;
  font-size: calc(100vw / 90);
}

.seventeen__link-content {
  display: none;
}

.seventeen__contact2:nth-child(2) .seventeen__link-content {
  display: block;
}

.seventeen__link-description {
  color: #848182;
  font-size: calc(100vw / 100);
}

.seventeen__block-3 {
  display: block;
}

.seventeen__block-3 p {
  font-size: calc(100vw / 65);
}

.seventeen__block3-subtitle {
  font-size: calc(100vw / 55);
}

.seventeen__block3-content {
  display: flex;
  justify-content: space-between;
  width: 95%;
}

.seventeen__block3-content p {
  font-size: calc(100vw / 65);
  margin-left: 2vw;
}

@media (max-width: 1440px) {
  .sixteen__item {
    padding: 0 0 0 2vh;
    width: 14vw;
  }

  .sixteen__items {
    width: 85%;
  }

  .seventeen__wrapper {
    margin-bottom: 10vw;
  }

  .seventeen__head-block {
    /*border-bottom: 1px solid black;*/
  }

  .seventeen__wrapper {
    padding: 2vw 3vw;
  }

  .seventeen__block-3 {
    margin: 4vh 0;
  }

  .seventeen__block3-img {
    height: 17vw;
  }

  .seventeen__contact,
  .seventeen__block-2,
  .seventeen__link-block {
    font-size: 1.2vw;
  }

  .seventeen__contact-title,
  .seventeen__contact-subtitle,
  .seventeen__contact2-title,
  .seventeen__link-block,
  .seventeen__link-title,
  .seventeen__link-description {
    font-size: inherit;
  }
}

/*----------------------------MOBILE---------------------------------*/

@media (max-width: 480px) {
  .sixteen__items {
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 95%;
  }

  .sixteen__item {
    padding: 5px 15px;
    margin: 9px 0;
    font-size: 14px;
    max-width: 171px;
    width: 50%;
  }

  .seventeen {
    padding: 0;
  }

  .seventeen__head-title {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
  }

  .seventeen__head-subtitle {
    font-size: 19px;
    margin: 0;
  }

  .seventeen__wrapper {
    padding: 10px 15px;
    margin-bottom: 55px;
  }

  .seventeen__wrapp {
    flex-wrap: nowrap;
    flex-direction: column;
    height: auto;
  }

  .seventeen__block-1 {
    width: auto;
    height: 250px;
  }

  .seventeen__block-2 {
    width: auto;
    height: auto;
  }

  .seventeen__block-3 {
    margin: 3vh 0;
  }

  .seventeen__block3-img {
    height: calc(100vw / 1.7);
  }

  .seventeen__contact-title {
    font-size: calc(100vw / 35);
  }

  .seventeen__contact-subtitle {
    font-size: calc(100vw / 30);
  }

  .seventeen__block3-content {
    flex-direction: column;
    width: auto;
  }

  .seventeen__block3-subtitle {
    display: none;
  }

  .seventeen__block3-content p {
    font-size: calc(3px + 45 * (100vw / 1280));
    margin-left: 0;
  }

  .seventeen__head-block {
    /*border-bottom: 1px solid black;*/
  }

  .seventeen__block-3 p {
    font-size: 3vw;
  }

  .seventeen__contact2 {
    margin: 20px 0;
  }

  .seventeen__contact2:nth-child(1) {
    padding-bottom: 10px;
  }

  .seventeen__contact2:nth-child(2) {
    height: 11vh;
  }

  .seventeen__contact2-title {
    font-size: 14px;
  }

  .seventeen__link-block {
    font-size: calc(100vw / 35);
    padding: 0 10px;
  }

  .seventeen__link-title {
    color: #010101;
    font-size: calc(100vw / 35);
  }

  .seventeen__link-description {
    color: #848182;
    font-size: calc(100vw / 35);
  }
}
</style>
<template>
  <img src="/img/21.png" alt="" class="pl-left" />
  <img src="/img/22.png" alt="" class="pl-leftm" />
  <img src="/img/22.png" alt="" class="pl-left2m" />
  <img src="/img/8.png" alt="" class="pl-centr" />
  <img src="/img/8.png" alt="" class="pl-rightm" />
  <img src="/img/9.png" alt="" class="pl-right" />
</template>
<script>
export default {
  name: "BackImagesComp",
  setup() {},
};
</script>
<style>
.pl-left {
  position: absolute;
  right: 0;
  width: calc(100vw / 6);
  z-index: -1;
}
.pl-right {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100vw / 8);
  z-index: -1;
}
.pl-leftm,
.pl-left2m,
.pl-rightm {
  display: none;
}
.pl-centr {
  display: block;
    position: absolute;
    left: 45%;
    width: 14vw;
    top: 35%;
    z-index: -1;
}
@media (max-width: 480px) {
  .pl-leftm,
  .pl-left2m,
  .pl-rightm {
    display: block;
    position: absolute;
    z-index: -1;
  }
  .pl-left {
    width: calc(100vw / 3);
    top: 0;
  }
  .pl-leftm {
    top: 23%;
    left: 91%;
    right: 0;
    width: calc(100vw / 12);
  }
  .pl-left2m {
    top: 44%;
    left: 0;
    width: calc(100vw / 12);
  }
  .pl-right {
    width: calc(100vw / 4);
  }
  .pl-rightm {
    right: 0;
    width: calc(100vw / 6);
    top: 75%;
  }
}
</style>
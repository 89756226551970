<template>
  <main class="main">
    <back-images-comp />
    <section v-if="loader" class="eleven">
      <loader
        object="#ff9633"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular"
      ></loader>
    </section>
    <section v-else class="nine">
      <div class="nine__wrapper">
        <h1 class="nine__title">Документы</h1>
        <div class="nine__content-block">
          <section v-if="loaderPagination" class="eleven">
            <loader
              object="#ff9633"
              color1="#ffffff"
              color2="#17fd3d"
              size="5"
              speed="2"
              bg="#343a40"
              objectbg="#999793"
              opacity="80"
              name="circular"
            ></loader>
          </section>
          <div class="nine__wrapp">
            <h2
              class="nine__description"
              v-html="docsByIdData.title[0]['etkdocstype_title']"
            ></h2>
            <div class="nine__wrapp-bloks">
              <div v-if="Array.isArray(docsByIdData.docs)" class="nine__block2">
                <div
                  class="nine__block2-item"
                  v-for="data in docsByIdData.docs"
                  :key="data.etkdocs_id"
                >
                  <img src="/img/43.png" alt="" class="nine__block2-img" />
                  <a
                    :href="makeUrl(data.etkdocs_id, data.etkstructure_id)"
                    class="nine__block2-link"
                    v-html="data.etkdocs_nnd"
                  ></a>
                </div>
              </div>
              <div v-else>
                <div
                  v-for="(data, index) in docsByIdData.docs"
                  :key="data.etkdocs_id"
                >
                  <div class="nine__block1-item">
                    <img
                      class="nine__arrow"
                      :class="{ open: !Array.isArray(data) }"
                      src="/img/43.svg"
                      alt=""
                    />
                    <h3
                      class="nine__block1-title"
                      @click="makeVisible($event, 'div.nine__block1-item')"
                    >
                      {{ index }}
                    </h3>
                  </div>
                  <div
                    v-if="Array.isArray(data)"
                    class="visible1 nine__block2"
                    ref="vis1"
                  >
                    <div
                      class="nine__block2-item"
                      v-for="data1 in data"
                      :key="data1.etkdocs_id"
                    >
                      <img src="/img/43.png" alt="" class="nine__block2-img" />
                      <a
                        :href="makeUrl(data1.etkdocs_id, data1.etkstructure_id)"
                        class="nine__block2-link"
                        >{{ data1.etkdocs_nnd }}</a
                      >
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-for="(da, ind) in data"
                      :key="da.etkdocs_id"
                      class="nine__blocks-wrapper"
                    >
                      <div class="nine__block1-item1">
                        <img class="nine__arrow" src="/img/43.svg" alt="" />
                        <h3
                          class="nine__block1-title"
                          @click="makeVisible($event, 'div.nine__block1-item1')"
                        >
                          {{ ind }}
                        </h3>
                      </div>
                      <div
                        v-if="Array.isArray(da)"
                        class="visible1 nine__block2"
                      >
                        <div
                          class="nine__block2-item"
                          v-for="data2 in da"
                          :key="data2.etkdocs_id"
                        >
                          <img
                            src="/img/43.png"
                            alt=""
                            class="nine__block2-img"
                          />
                          <a
                            :href="
                              makeUrl(data2.etkdocs_id, data2.etkstructure_id)
                            "
                            class="nine__block2-link"
                            >{{ data2.etkdocs_nnd }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="navigation2">
              <div class="navigation2__wrapper">
                <paginate
                  :page-count="count"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="clickCallback"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
                  :hide-prev-next="true"
                >
                </paginate>
              </div>
            </div>
            <BackButton :back="backLink" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Paginate from "vuejs-paginate-next";
import useDocumentsById from "@/composables/documents/documentsById.js";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { ref } from "vue";
import BackImagesComp from "../BackImagesComp.vue";
import BackButton from "../BackButton.vue";
export default {
  components: { BackImagesComp, BackButton, Paginate },
  name: "Documents",
  setup() {
    const backLink = ref("/documents"); //ссылка для кнопки назад
    const route = useRoute();
    const vis1 = ref(null);
    const {
      docsByIdData,
      fetchDocsById,
      loader,
      isVisible1,
      makeUrl,
      count,
      fetchDocsByIdLimit,
      loaderPagination,
    } = useDocumentsById();

    onMounted(() => {
      fetchDocsById(route.params.id);
    });
    const clickCallback = function (pageNum) {
      fetchDocsByIdLimit(route.params.id, pageNum * 10 - 10, 10);
      document.querySelector(".nine__description").scrollIntoView({block: "center", behavior: "smooth"})
    };
    return {
      docsByIdData,
      loader,
      isVisible1,
      vis1,
      makeUrl,
      backLink,
      count,
      clickCallback,
      fetchDocsByIdLimit,
      loaderPagination,
    };
  },
  methods: {
    makeVisible(e, selector) {
      let parent = e.target.closest(selector);
      let neededEl = parent.nextSibling;
      if (neededEl.classList.contains("visible1")) {
        document.querySelectorAll(".nine__block2").forEach((el) => {
          if (!el.classList.contains("visible1")) {
            el.classList.add("visible1");
          }
        });
        // document.querySelectorAll(".nine__arrow").forEach(el => {
        //   el.classList.remove("open")
        // })
        neededEl.classList.remove("visible1");
      } else {
        neededEl.classList.add("visible1");
      }
      this.rotateArrow(e.target);
      // document.querySelectorAll(".nine__block2").forEach(el => {
      //   el.classList.add("visible1")
      // })
      // neededEl.classList.remove("visible1")
    },
    rotateArrow(el) {
      el.scrollIntoView({ block: "center", behavior: "smooth" });
      if (el.previousSibling.classList.contains("open")) {
        el.previousSibling.classList.remove("open");
      } else {
        el.previousSibling.classList.add("open");
      }
    },
  },
};
</script>

<style scoped>
/* my */
.navigation2 {
  margin: 0;
}
/* end my */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.nine__block2-img {
  margin-right: 10px;
  width: 2vw;
}
.eight__items {
  display: flex;
  flex-wrap: wrap;
}

.nine__wrapper {
  width: 95%;
  margin: 3vw 3vw;
}
.nine__title {
  font-size: 40px;
  color: #0074b0;
}
.nine__content-block {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 100%;
  margin-bottom: 100px;
  padding: 10px 0;
  height: auto;
  background: white;
}
.nine__wrapp {
  width: 95%;
  margin: 0 auto;
}
.nine__description {
  font-size: 1.5vw !important;
  color: black;
  margin: auto;
  padding: 0.5vw 0 !important;
  border-bottom: 0.15vw solid black;
}
.nine__wrapp-bloks {
  width: 96%;
}
.nine__block1 {
  width: 30%;
  margin: 0 30px;
}
.nine__block1-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.nine__block1-item1 {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 5rem;
  width: 80% !important;
}
.nine__arrow.open {
  transition: all 0.5s linear;
  transform: rotate(90deg);
  width: 1vw;
}
.nine__arrow {
  transition: all 0.5s linear;
  width: 1vw;
}
.nine__block1-title {
  font-size: 1.1vw;
  font-weight: 300;
}
.nine__block2 {
  display: flex;
  flex-wrap: wrap;
  margin: 3vh 0;
  flex-direction: column;
  /*align-items: flex-end;*/
}
.nine__block2-item {
  display: flex;
  align-items: center;
  width: auto;
  /*max-width: 640px;*/
  margin: 20px 0 30px;
}
/* my */
.nine__blocks-wrapper {
  display: flex;
  align-items: flex-start;
}
.nine__blocks-wrapper .nine__block2 {
  margin: 0;
  width: 95%;
}
.nine__blocks-wrapper .nine__block2 .nine__block2-item {
  display: flex;
  align-items: center;
  width: 90%;
  max-width: none;
  margin: 20px 30px;
}
.nine__blocks-wrapper .nine__block1-item1 {
  width: 30%;
}

/* end my */
.nine__block2-img {
}
.nine__block2-link {
  color: black;
  font-size: 1.1vw;
}
.visible1 {
  display: none;
}

@media (max-width: 480px) {
  .nine__arrow {
    width: 4vw;
  }
  .nine__arrow.open {
    width: 4vw;
  }
  .nine__blocks-wrapper {
    flex-direction: column;
  }
  .nine__title {
    font-size: calc(100vw / 15);
  }
  .nine__content-block {
    margin-bottom: 30px;
  }
  .nine__wrapp {
    width: 96%;
    margin: 0 auto;
  }
  .nine__description {
    font-size: 3.5vw !important;
    margin: 0 15px;
  }
  .eight__close-end {
    width: 95% !important;
  }
  .nine__block1-title {
    font-size: 4vw;
  }
  .nine__block2 {
    display: flex;
    flex-wrap: wrap;
    margin: 3vh 0;
  }
  .nine__block2.visible1 {
    display: none;
  }
  .nine__block2-item {
    width: 95%;
    margin: 15px auto;
  }
  .nine__block2-img {
    width: 5vw;
  }
  .nine__block2-link {
    font-size: calc(100vw / 28);
  }
  .nine__wrapper {
    width: 89%;
    margin: 0 auto;
  }
  .nine__wrapp-bloks {
    width: 100%;
    padding: 0 3vw;
  }

  .nine__block1-item1 {
    width: 90% !important;
    margin-left: 2rem;
  }
}

/* my */
.nine__blocks-wrapper {
  display: flex;
  align-items: flex-start;
}
.nine__blocks-wrapper .nine__block2 .nine__block2-item {
  display: flex;
  align-items: center;
  /* width: 20%; */
  /* max-width: 640px; */
  margin: 20px 30px;
}
.nine__blocks-wrapper .nine__block1-item1 {
  width: 30%;
}

/* end my */
</style>
<template>
  <main class="main">
    <back-images-comp />
    <section class="twentysix"></section>
    <TK113Menu />
    <!-- <section v-if="!table" class="twentyseven no_info">
      <loader
        object="#ff9633"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular"
      ></loader>
    </section> -->

    <section class="twentyseven">
      <div class="twentyseven__wrapper">
        <div class="twentyseven__table-wrapp">

<table width="100%">
<tbody>
<tr>
<td width="64">Тип</td>
<td width="226">Номер</td>
<td width="1465">Название</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.1-2015</td>
<td width="1465">Наилучшие доступные технологии. Методические рекомендации по описанию перспективных технологий в информационно-техническом справочнике по наилучшим доступным технологиям</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.2-2015</td>
<td width="1465">Наилучшие доступные технологии. Методические рекомендации представления информации по экономическим аспектам реализации наилучших доступных технологий в информационно-техническом справочнике по наилучшим доступным технологиям</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.3-2015</td>
<td width="1465">Наилучшие доступные технологии. Методические рекомендации по представлению в информационно-техническом справочнике по наилучшим доступным технологиям информации по основному технологическому оборудованию</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.4-2015</td>
<td width="1465">Наилучшие доступные технологии. Подходы к проведению сравнительного анализа ресурсоэффективности и экологической результативности предприятий для предупреждения или минимизации негативного воздействия на окружающую среду</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.5-2015</td>
<td width="1465">Наилучшие доступные технологии. Методические рекомендации по порядку применения информационно-технического справочника по наилучшим доступным технологиям при оценке воздействия проектируемых предприятий на окружающую среду</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.6-2015</td>
<td width="1465">Наилучшие доступные технологии. Методические рекомендации по порядку применения информационно-технического справочника по наилучшим доступным технологиям при оценке (экспертизе, конкурсном отборе) проектов модернизации предприятий, направленных на достижение требований наилучших доступных технологий (внедрение НДТ)</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.7-2015</td>
<td width="1465">Наилучшие доступные технологии. Методические рекомендации представления информации по текущим уровням выбросов/сбросов загрязняющих веществ (эмиссий) и потребления ресурсов в информационно-техническом справочнике по наилучшим доступным технологиям</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.8-2015</td>
<td width="1465">Наилучшие доступные технологии. Методические рекомендации по описанию наилучших доступных технологий в информационно-техническом справочнике по наилучшим доступным технологиям</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.9-2015</td>
<td width="1465">Наилучшие доступные технологии. Методические рекомендации по проведению сравнительного анализа производств при разработке информационно-технического справочника по наилучшим доступным технологиям</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.10-2015</td>
<td width="1465">Наилучшие доступные технологии. Методические рекомендации по актуализации информационно-технического справочника по наилучшим доступным технологиям</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.11-2015</td>
<td width="1465">Наилучшие доступные технологии. Методические рекомендации по разработке раздела информационно-технического справочника по наилучшим доступным технологиям по описанию приоритетных проблем отрасли</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.12-2016</td>
<td width="1465">Наилучшие доступные технологии. Классификация водных объектов для технологического нормирования сбросов сточных вод централизованных систем водоотведения поселений&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.15-2016</td>
<td width="1465">Наилучшие доступные технологии. Термины и определения</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">57007-2016</td>
<td width="1465">Наилучшие доступные технологии. Биологическое разнообразие. Термины и определения</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.16-2017</td>
<td width="1465">Наилучшие доступные технологии. Энергосбережение. Методология планирования показателей (индикаторов) энергоэффективности</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.17-2017</td>
<td width="1465">Наилучшие доступные технологии. Ресурсосбережение. Стратегии и методы термической обработки опасных отходов</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.18-2017</td>
<td width="1465">Наилучшие доступные технологии. Производство цемента. Аспекты повышения энергетической эффективности</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td>&nbsp;56828.19-2017</td>
<td width="1465">Наилучшие доступные технологии. Энергосбережение. Методология идентификации показателей энергоэффективности</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.20-2017</td>
<td width="1465">Наилучшие доступные технологии. Производство керамической плитки. Аспекты повышения энергетической и экологической эффективности</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.21-2017</td>
<td width="1465">Наилучшие доступные технологии. Производство керамического кирпича и огнеупорных изделий. Аспекты повышения энергетической и экологической эффективности</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.22-2017</td>
<td width="1465">Наилучшие доступные технологии. Ресурсосбережение. Стратегии, принципы и методы экологически ориентированного обращения с отходами</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.23-2017</td>
<td width="1465">Наилучшие доступные технологии. Производство извести. Аспекты повышения энергетической эффективности</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.24-2017</td>
<td width="1465">Наилучшие доступные технологии. Энергосбережение. Руководство по применению наилучших доступных технологий для повышения энергоэффективности</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.25-2017</td>
<td width="1465">Наилучшие доступные технологии. Ресурсосбережение. Контроль качества отходов, поступающих на мусоросжигательные заводы</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.26-2017</td>
<td width="1465">Наилучшие доступные технологии. Ресурсосбережение. Аспекты эффективного обращения с отходами в цементной промышленности</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.27-2017</td>
<td width="1465">Наилучшие доступные технологии. Ресурсосбережение. Методология обработки отходов в целях получения вторичных материальных ресурсов</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">&nbsp;56828.28-2017</td>
<td width="1465">Наилучшие доступные технологии. Производство стекла. Аспекты повышения энергетической эффективности</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.29-2017</td>
<td width="1465">Наилучшие доступные технологии. Энергосбережение. Порядок определения показателей (индикаторов) энергоэффективности</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.30-2017</td>
<td width="1465">Наилучшие доступные технологии. Ресурсосбережение. Методология обработки отходов в целях получения вторичных топливно-энергетических ресурсов</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.31-2017</td>
<td width="1465">Наилучшие доступные технологии. Ресурсосбережение. Иерархический порядок обращения с отходами</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.32-2017</td>
<td width="1465">Наилучшие доступные технологии. Ресурсосбережение. Методологии идентификации</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.33-2017</td>
<td width="1465">Наилучшие доступные технологии. Ресурсосбережение. Аспекты эффективного обращения с отходами в известковой промышленности</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.34-2017</td>
<td width="1465">Наилучшие доступные технологии. Ресурсосбережение. Методология принятия управленческих решений для сохранения водных биоресурсов и среды их обитания</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">57446-2017</td>
<td width="1465">Наилучшие доступные технологии. Рекультивация нарушенных земель и земельных участков. Восстановление биологического разнообразия</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">57447-2017</td>
<td width="1465">Наилучшие доступные технологии. Рекультивация земель и земельных участков, загрязненных нефтью и нефтепродуктами. Основные положения</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.35-2018</td>
<td width="1465">Наилучшие доступные технологии. Водопользование. Термины и определения</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.36-2018</td>
<td width="1465">Наилучшие доступные технологии. Кожевенная промышленность. Наилучшие доступные технологии использования энергоресурсов</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">&nbsp;56828.37-2018</td>
<td width="1465">Наилучшие доступные технологии. Нормирование. Термины и определения</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.38-2018</td>
<td width="1465">Наилучшие доступные технологии. Окружающая среда. Термины и определения</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.39-2018</td>
<td width="1465">Наилучшие доступные технологии. Производственные аспекты. Термины и определения</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.40-2018</td>
<td width="1465">Наилучшие доступные технологии. Размещение отходов. Термины и определения</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.41-2018</td>
<td width="1465">Наилучшие доступные технологии. Обезвреживания продукции, содержащей галогенированные органические вещества, в том числе стойкие органические загрязнители. Показатели для идентификации</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.42-2018</td>
<td width="1465">Наилучшие доступные технологии. Утилизация отработанных масел. Показатели для идентификации</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56282.43-2018</td>
<td width="1465">Наилучшие доступные технологии. Утилизация нефтесодержащих отходов. Показатели для идентификации</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.44-2018</td>
<td width="1465">Наилучшие доступные технологии. Производство аммиака, минеральных удобрений и неорганических кислот. Выбор маркерных веществ для выбросов в атмосферу от промышленных источников</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.45-2018&nbsp;</td>
<td width="1465">Наилучшие доступные технологии. Производство цемента. Производственный экологический контроль</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.46-2018&nbsp;</td>
<td width="1465">Наилучшие доступные технологии. Производство цемента. Порядок подготовки заявки на комплексное экологическое разрешение</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">56828.47-2018&nbsp;</td>
<td width="1465">Наилучшие доступные технологии. Производство цемента. Выбор маркерных показателей для выбросов в атмосферу от промышленных источников</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">113.00.01.2019</td>
<td width="1465">Система стандартов НДТ</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">113.00.02.2019</td>
<td width="1465">Наилучшие доступные технологии. Методические рекомендации по построению структуры оценки технологических процессов, оборудования, технических способов и методов в части выполнения требований НДТ</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">113.05.01-2019</td>
<td width="1465">Наилучшие доступные технологии. Производство листового стекла. Порядок подготовки заявки на КЭР</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">113.42.01-2019</td>
<td width="1465">Наилучшие доступные технологии. Интенсивное разведение сельскохозяйственной птицы. Рекомендации по производственному экологическому контролю</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">113.41.01-2019</td>
<td width="1465">Наилучшие доступные технологии. Интенсивное разведение свиней. Рекомендации по производственному экологическому контролю&nbsp;</td>
</tr>
<tr>
<td width="64">ГОСТ Р&nbsp;</td>
<td width="226">113.38.01-2019</td>
<td width="1465">Наилучшие доступные технологии. Малые котельные. Стандартные правила</td>
</tr>
<tr>
<td width="64">ГОСТ Р</td>
<td width="226">113.15.01-2019</td>
<td width="1465">Наилучшие доступные технологии. Рекомендации по обработке, утилизации и обезвреживанию органических отходов сельскохозяйственного производства</td>
</tr>
<tr>
<td width="64">ГОСТ Р</td>
<td width="226">113.00.03-2019</td>
<td width="1465">Наилучшие доступные технологии. Структура информационно-технического справочника</td>
</tr>
<tr>
<td width="64">ГОСТ Р</td>
<td width="226">113.00.04-2020</td>
<td width="1465">Наилучшие доступные технологии. Формат описания технологий</td>
</tr>
<tr>
<td width="64">ГОСТ Р</td>
<td width="226">&nbsp;113.37.01-2019</td>
<td width="1465">Наилучшие доступные технологии. Системы автоматического контроля и учета сбросов загрязняющих веществ угольной промышленности в водные объекты. Основные требования</td>
</tr>
<tr>
<td width="64">ГОСТ Р</td>
<td width="226">113.38.02-2019</td>
<td width="1465">Наилучшие доступные технологии. Методические рекомендации по оценке затрат предприятий электроэнергетики по снижению выбросов загрязняющих веществ для достижения ими технологических показателей наилучших доступных технологий</td>
</tr>
<tr>
<td width="64">ГОСТ Р</td>
<td>113.00.09-2020</td>
<td width="1465">Наилучшие доступные технологии. Производство аммиака, минеральных удобрений и неорганический кислот. Выбор маркерных веществ для сборов от промышленных источников</td>
</tr>
<tr>
<td width="64">ГОСТ Р</td>
<td>113.00.10-2020</td>
<td width="1465">Наилучшие доступные технологии. Производство аммиака, минеральных удобрений и неорганических кислот. Производственный экологический контроль</td>
</tr>
<tr>
<td width="64">ГОСТ Р</td>
<td>113.16.01-2020</td>
<td width="1465">Наилучшие доступные технологии. Пылеподавление. Средства на основе водных растворов хлорида кальция и хлорида магния. Технические условия</td>
</tr>
<tr>
<td width="64">ГОСТ Р</td>
<td>113.00.08-2020</td>
<td width="1465">Система оценки наилучших доступных технологий. Общие требования</td>
</tr>
<tr>
<td width="64">ГОСТ Р</td>
<td>113.00.06-2020</td>
<td width="1465">Наилучшие доступные технологии. Порядок отбора и назначение экспертов для определения соответствия&nbsp; наилучшим доступным технологиям. Общие требования</td>
</tr>
<tr>
<td width="64">ГОСТ Р</td>
<td>113.00.07-2020</td>
<td width="1465">Наилучшие доступные технологии. Методические рекомендации по порядку рассмотрения проектов программ повышения экологической эффективности экспертами НДТ</td>
</tr>
<tr>
<td width="64">ГОСТ Р</td>
<td>113.00.05-2020&nbsp;</td>
<td width="1465">Наилучшие доступные технологии. Методические рекомендации по проведению общественной проверки внедрения наилучших доступных технологий на предприятиях</td>
</tr>
<tr>
<td width="64">ГОСТ Р</td>
<td width="226">113.38.03-2021</td>
<td width="1465">Наилучшие доступные технологии. Системы автоматического контроля и учета выбросов загрязняющих веществ тепловых электростанций в атмосферный воздух. Основные требования</td>
</tr>
</tbody>
</table>

        </div>
        <div class="twentyseven__static-mobile">

          <div class="twentyone__flex-block">
          <div class="twentyone__form-block">
            <form action="" class="twentyone__form">
              <button class="twentyone__button-search">
                <img src="/img/44.png" alt="" class="twentyone__button-img">
              </button>
              <input type="text" class="twentyone__input" placeholder="Поиск">
            </form>
          </div>
          <div class="twentyone__filter">
            <p class="twentyone__filter-text">Показывать:</p>
            <div class="twentyone__filter-item">
              <p class="twentyone__item-one">Все</p>
              <img src="/img/46.png" alt="" class="twentyone__filter-img">
            </div>
          </div>
        </div>




        <div class="twentyseven__nomination">
          <div class="twentyseven__item-mob">№</div>
          <div class="twentyseven__item-mob2">Наименование документа</div>
        </div>
        

      </div>
      <div class="twentyseven__items-mob">
        <div class="twentyseven__item-mobile">
          <div class="twentyseven__number">
            <div class="twentyseven__span">1</div>
          </div>
          <div class="twentyseven__block-wrapp">
            <div class="twentyseven__description">
              <h3 class="twentyseven__h3">Наилучшие доступные технологии. Методические рекомендации по описанию перспективных технологий в информационно-техническом справочнике по наилучшим доступным технологиям</h3>
              <p class="twentyseven__p">Номер стандарта:   56828.1-2015</p>
              <p class="twentyseven__p">Тип:   ГОСТ Р</p>
            </div>
          </div>
        </div>


        <div class="twentyseven__item-mobile">
          <div class="twentyseven__number">
            <div class="twentyseven__span">2</div>
          </div>
          <div class="twentyseven__block-wrapp">
            <div class="twentyseven__description">
              <h3 class="twentyseven__h3">Наилучшие доступные технологии. Методические рекомендации по описанию перспективных технологий в информационно-техническом справочнике по наилучшим доступным технологиям</h3>
              <p class="twentyseven__p">Номер стандарта:   56828.1-2015</p>
              <p class="twentyseven__p">Тип:   ГОСТ Р</p>
            </div>
          </div>
        </div>


        <div class="twentyseven__item-mobile">
          <div class="twentyseven__number">
            <div class="twentyseven__span">3</div>
          </div>
          <div class="twentyseven__block-wrapp">
            <div class="twentyseven__description">
              <h3 class="twentyseven__h3">Наилучшие доступные технологии. Методические рекомендации по описанию перспективных технологий в информационно-техническом справочнике по наилучшим доступным технологиям</h3>
              <p class="twentyseven__p">Номер стандарта:   56828.1-2015</p>
              <p class="twentyseven__p">Тип:   ГОСТ Р</p>
            </div>
          </div>
        </div>


        <div class="twentyseven__item-mobile">
          <div class="twentyseven__number">
            <div class="twentyseven__span">4</div>
          </div>
          <div class="twentyseven__block-wrapp">
            <div class="twentyseven__description">
              <h3 class="twentyseven__h3">Наилучшие доступные технологии. Методические рекомендации по описанию перспективных технологий в информационно-техническом справочнике по наилучшим доступным технологиям</h3>
              <p class="twentyseven__p">Номер стандарта:   56828.1-2015</p>
              <p class="twentyseven__p">Тип:   ГОСТ Р</p>
            </div>
          </div>
        </div>


        <div class="twentyseven__item-mobile">
          <div class="twentyseven__number">
            <div class="twentyseven__span">5</div>
          </div>
          <div class="twentyseven__block-wrapp">
            <div class="twentyseven__description">
              <h3 class="twentyseven__h3">Наилучшие доступные технологии. Методические рекомендации по описанию перспективных технологий в информационно-техническом справочнике по наилучшим доступным технологиям</h3>
              <p class="twentyseven__p">Номер стандарта:   56828.1-2015</p>
              <p class="twentyseven__p">Тип:   ГОСТ Р</p>
            </div>
          </div>
        </div>


        <div class="twentyseven__item-mobile">
          <div class="twentyseven__number">
            <div class="twentyseven__span">6</div>
          </div>
          <div class="twentyseven__block-wrapp">
            <div class="twentyseven__description">
              <h3 class="twentyseven__h3">Наилучшие доступные технологии. Методические рекомендации по описанию перспективных технологий в информационно-техническом справочнике по наилучшим доступным технологиям</h3>
              <p class="twentyseven__p">Номер стандарта:   56828.1-2015</p>
              <p class="twentyseven__p">Тип:   ГОСТ Р</p>
            </div>
          </div>
        </div>


        <div class="twentyseven__item-mobile">
          <div class="twentyseven__number">
            <div class="twentyseven__span">7</div>
          </div>
          <div class="twentyseven__block-wrapp">
            <div class="twentyseven__description">
              <h3 class="twentyseven__h3">Наилучшие доступные технологии. Методические рекомендации по описанию перспективных технологий в информационно-техническом справочнике по наилучшим доступным технологиям</h3>
              <p class="twentyseven__p">Номер стандарта:   56828.1-2015</p>
              <p class="twentyseven__p">Тип:   ГОСТ Р</p>
            </div>
          </div>
        </div>


        <div class="twentyseven__item-mobile">
          <div class="twentyseven__number">
            <div class="twentyseven__span">8</div>
          </div>
          <div class="twentyseven__block-wrapp">
            <div class="twentyseven__description">
              <h3 class="twentyseven__h3">Наилучшие доступные технологии. Методические рекомендации по описанию перспективных технологий в информационно-техническом справочнике по наилучшим доступным технологиям</h3>
              <p class="twentyseven__p">Номер стандарта:   56828.1-2015</p>
              <p class="twentyseven__p">Тип:   ГОСТ Р</p>
            </div>
          </div>
        </div>


        <div class="twentyseven__item-mobile">
          <div class="twentyseven__number">
            <div class="twentyseven__span">9</div>
          </div>
          <div class="twentyseven__block-wrapp">
            <div class="twentyseven__description">
              <h3 class="twentyseven__h3">Наилучшие доступные технологии. Методические рекомендации по описанию перспективных технологий в информационно-техническом справочнике по наилучшим доступным технологиям</h3>
              <p class="twentyseven__p">Номер стандарта:   56828.1-2015</p>
              <p class="twentyseven__p">Тип:   ГОСТ Р</p>
            </div>
          </div>
        </div>


        <div class="twentyseven__item-mobile">
          <div class="twentyseven__number">
            <div class="twentyseven__span">10</div>
          </div>
          <div class="twentyseven__block-wrapp">
            <div class="twentyseven__description">
              <h3 class="twentyseven__h3">Наилучшие доступные технологии. Методические рекомендации по описанию перспективных технологий в информационно-техническом справочнике по наилучшим доступным технологиям</h3>
              <p class="twentyseven__p">Номер стандарта:   56828.1-2015</p>
              <p class="twentyseven__p">Тип:   ГОСТ Р</p>
            </div>
          </div>
        </div>
        </div>
      </div>
    </section>
    <section class="twentyseven"></section>
  </main>
</template>

<script>
import TK113Menu from "./TK113Menu.vue";
// import useTK113Standarts from "@/composables/tk113/standarts";
// import { onMounted } from "vue";
import BackImagesComp from "../BackImagesComp.vue";
export default {
  name: "TK113Standarts",
  components: {
    TK113Menu,
    BackImagesComp,
  }
};
</script>

<style scoped>


.twentyone__flex-block {
  display: none !important;
}



.twentyseven {
  padding: 0 50px;
}

.twentyseven__nomination,
.twentyseven__items-mob {
  display: none;
}
/* my */
.twentyseven.no_info {
  height: 600px;
}
/* end my */
.twentyseven__wrapper {
  margin: 3vh;
  height: auto;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  padding: 20px 15px;
  margin-bottom: 200px;
  background: white;
}
table {
  width: 100%;
}
tr {
  display: inline-block;
  width: 100%;
  margin: 5px auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

td {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  /* height: 45px; */
}
th {
  display: inline-block;
}
tr:not(:first-child) {
  padding: 15px 0;
}
.tr-head {
  height: 30px;
  box-shadow: none;
}
.th-one {
  width: calc(3px + 35 * (100vw / 1280));
  height: 30px;
}
.th-two {
  width: 50%;
  height: 30px;
  text-align: start;
}
.th-all {
  display: inline-block;
  height: 30px;
  width: 30%;
}
.td-one {
  width: calc(3px + 35 * (100vw / 1280));
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.td-two {
  width: 50%;
  display: inline-flex;
  align-items: center;
  text-align: flex-start;
}

.twentyseven__flex-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.twentyseven__form {
  display: flex;
  align-items: center;
}
.twentyseven__button-search {
  background: none;
  border: none;
  width: 30px;
}
.twentyseven__button-img {
  background: none;
  border: none;
  width: 20px;
}
.twentyseven__input {
  border: none;
  background: none;
  max-width: 500px;
  width: calc(3px + 250 * (100vw / 1280));
}
.twentyseven__filter {
  display: flex;
}
.twentyseven__filter-item {
  display: flex;
  align-items: center;
}
.twentyseven__filter-img {
  width: 20px;
}







.twentyone__flex-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.twentyone__form-block {

}
.twentyone__form {
  display: flex;
  align-items: center;
}
.twentyone__button-search {
  background: none;
  border: none;
  width: 30px;
}
.twentyone__button-img {
  background: none;
  border: none;
  width: 1vw;
}
.twentyone__input {
  border: none;
  background: none;
  max-width: 500px;
  width: calc(3px + 250 * (100vw/1280));
  margin-left: 1vh;
  font-size: 1vw;
}
.twentyone__filter {
  display: flex;
  font-size: calc(100vw/105);
}
.twentyone__filter-text {
  color: #848891;
}
.twentyone__filter-item {
  display: flex;
  align-items: center;
}
.twentyone__table-wrapp {
  color: #525252;
}
.twentyone__filter-img {
  width: 1vw;
}









@media (max-width: 480px) {
  .twentyseven {
    padding: 0 0;
  }


  .twentyone__flex-block {
    display: flex !important;
  }


.pl-leftm,.pl-left2m,.pl-rightm {
    display: block;
    position: absolute;
    z-index: -1;
  }
  .pl-left {
    width: calc(100vw/3);
    bottom: 94%;
  }
  .pl-leftm {
    top: 30%;
    left: 91%;
    right: 0;
    width: calc(100vw/12);
  }
  .pl-left2m {
    top: 60%;
    left: 0;
    width: calc(100vw/12);
  }
  .pl-right {
    width: calc(100vw/4);
  }
  .pl-rightm {
    right: 0;
    width: calc(100vw/6);
    top: 75%;
  }








/*--------------------------------------------------------------*/

.twentyone__input {
  width: calc(3px + 520 * (100vw/1280));
  font-size: 3vw;
  margin-left: auto;
}
.twentyone__filter-img {
  width: 4vw;
}
.twentyone__wrapper {
  margin-bottom: 0;
  box-shadow: 0 0 4px rgba(0,0,0,0.5);
  width: 90%;
  margin: auto;
  padding: 5px 10px 0;
}

.twentyone__nomination,.twentyone__items-mob {
  display: block;
}
.twentyone__table-wrapp {
  display: none;
}

.twentyone__button-img {
  width: 4vw;
}

.twentyone__nomination {
  display: flex;
  justify-content: space-between;
  padding: 10px 7px;
  width: 230px;
}
.twentyone__filter-text {
  font-size: 3vw;
  color: #a6a4a4;
}
.twentyone__item-one {
  font-size: 3vw;
  font-weight: 600;
}
.twentyone__items-mob {
  margin-bottom: 100px;

}
.twentyone__item-mob2 {
  font-weight: 600;
  font-size: 14px;
}
.twentyone__item-mobile {
  width: 90%;
  max-width: 1720px;
  margin: 7px auto;
  height: auto;
  max-height: 5000px;
  box-shadow: 0 0 4px rgba(0,0,0,0.5);
  padding: 20px 15px;
  display: flex;
  background: white;
}
.twentyone__number {
  width: 55px;
}
.twentyone__span {
  font-size: 15px;
  padding-left: 7px;
}
.twentyone__block-wrapp {
  width: 280px;
}

.twentyone__h3 {
  margin: 5px 0;
  font-size: 14px;
  /*width: 215px;*/
}
.twentyone__p {
  margin: 0;
  font-size: 12px;
  padding-top: 1vh;
}
.twentyone__p:last-child {
  margin: 5px 0;
}

.twentyone__years {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  width: 250px;
}
.twentyone__year {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  color: #525252;
}
.twentyone__download {
  height: 14px;
}
.img-wrapp {
  display: flex;
  align-items: flex-end;
}
.twentyone__button-search {
  width: 6vw;
  padding: 0;
}

/*-----------------------------------------------------------*/





  .twentyseven {
  padding: 0 6vw;
}
.twentyseven__button-img {
  width: 4vw;
}
.twentyseven__button-search {
  width: 6vw;
  padding: 0;
}
.twentyseven__input {
  width: calc(3px + 520 * (100vw/1280));
  font-size: 3vw;
  margin-left: auto;
}
.twentyseven__wrapper {
  margin-bottom: 0;
  box-shadow: 0 0 4px rgba(0,0,0,0.5);
  margin: auto;
  padding: 5px 10px 0;
}
.twentyseven__nomination,.twentyseven__items-mob {
  display: block;
}
.twentyseven__table-wrapp {
  display: none;
}
.twentyseven__nomination {
  display: flex;
  justify-content: space-between;
  padding: 10px 7px;
  width: 250px;
}
.twentyseven__filter-text {
  font-size: 3vw;
  color: #a6a4a4;
}
.twentyseven__item-one {
  font-size: 3vw;
  font-weight: 600;
}
.twentyseven__filter-img {
  width: 3vw;
}
.twentyseven__items-mob {
  margin-bottom: 100px;
  padding: 10px 0;
}
.twentyseven__item-mob2 {
  font-weight: 600;
  font-size: 14px;
}
.twentyseven__item-mobile {
  max-width: 1720px;
  margin: 7px auto;
  height: auto;
  box-shadow: 0 0 4px rgba(0,0,0,0.5);
  padding: 20px 15px;
  display: flex;
  background: white;
}
.twentyseven__number {
  width: 55px;
}
.twentyseven__span {
  font-size: 15px;
  padding-left: 7px;
}
.twentyseven__block-wrapp {
  width: 270px;
}
.twentyseven__description {
 
 
}
.twentyseven__h3 {
  margin: 5px 0;
  font-size: 3vw;
}
.twentyseven__p {
  margin: 0;
  font-size: 12px;
  margin-top: 10px;
}
.twentyseven__p:last-child {
  margin: 5px 0;
}

.twentyseven__years {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  width: calc(3px + 980 * (100vw / 1280));
  height: 65px;
}
.twentyseven__year {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /*height: 45px;*/
}
.twentyseven__download {
  height: 17px;
  margin: 0;
}
}
</style>
import { ref } from "vue"
import { environment } from "@/composables/realhost.js"

export default function usePublications() {
	const publicationsData = ref([])
	const count = ref(0);
	const loader = ref(true)

	function fetchPublications(type) {
		loader.value = true
		// let year = (new Date()).getFullYear()
		// fetch("http://slimtest/documents")
		fetch(environment.realHost + "publications/" + type)
			.then((response) => response.json())
			.then((data) => {
				publicationsData.value = data
				// count.value = Math.ceil(data['count']/10) 
				loader.value = false
			})
	}
	function makePublicationPicUrl(a){
        // return `http://burondt.ru/ETK/EtkMeetingsFilesDownload.php?UrlId=${a}`
        return `${environment.urlMakerHost}files/PublishingImages/PublishingImageSmall${a}.jpg`
    }
	function makePublicationFileUrl(a, ext){
        // return `http://burondt.ru/ETK/EtkMeetingsFilesDownload.php?UrlId=${a}`
        return `${environment.urlMakerHost}files/Publishing/PublishingFile${a}.${ext}`
    }

	function makeNewsDate(ms) {
		ms = ms + "000"
		ms = parseInt(ms, 10)
		let d = new Date(ms)
		let formatter = new Intl.DateTimeFormat("ru", {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric",
		})
		let formatter1 = new Intl.DateTimeFormat("ru", {
			hour: "numeric",
			minute: "numeric",
		})
		return formatter.format(d) + " | " + formatter1.format(d)
	}

	function getPicExtension(str) {
		if(str !== null){
			let arr = str.split(".")
			let ext = arr[arr.length - 1]
			return ext
		}
		
	}
	function cutTegs(str) {
		var regex = /( |<([^>]+)>)/ig
		var result = str.replace(regex, " ")
		return result
	}

	return {
		fetchPublications,
		publicationsData,
		loader,
		makeNewsDate,
		getPicExtension,
		cutTegs,
		count,
		makePublicationPicUrl,
		makePublicationFileUrl
	}
}
<template>
  <main class="main">
    <back-images-comp/>
    <MvsMenu />
    <section v-if="docs.length == 0" class="twentyfive no_info">
      <loader
        object="#ff9633"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        name="circular"
      ></loader>
    </section>
    <section v-else class="twentyone">
      <div class="twentyone__wrapper">
        <div class="twentyone__flex-block">
          <div class="twentyone__form-block">
            <form action="" class="twentyone__form">
              <button class="twentyone__button-search">
                <img src="/img/44.png" alt="" class="twentyone__button-img" />
              </button>
              <input type="text" class="twentyone__input" placeholder="Поиск" />
            </form>
          </div>
          <div class="twentyone__filter">
            <p class="twentyone__filter-text">Показывать:</p>
            <div class="twentyone__filter-item">
              <p class="twentyone__item-one">Все</p>
              <img src="/img/46.png" alt="" class="twentyone__filter-img" />
            </div>
          </div>
        </div>
        <div class="twentyone__table-wrapp">
          <table>
            <tr class="tr-head">
              <th class="th-one">№</th>
              <th class="th-two">Наименование документа</th>
              <th class="th-all">Статус</th>
              <th class="th-all">
                Начало<br />
                разработки
              </th>
              <th class="th-all">
                Окончательная<br />
                редакция
              </th>
              <th class="th-all">
                Сопроводительные<br />
                документы
              </th>
            </tr>

            <tr v-for="(doc, index) in docs" :key="doc.project_id">
              <th class="td-one">{{ index + 1 }}</th>
              <th class="td-two">{{ doc.project_name }}</th>
              <td>{{ doc.pgsflag_name }}</td>
              <td>{{ formatDate(doc.project_dateb) }}</td>
              <td>{{ formatDate(doc.project_datee) }}</td>
              <td>
                <a class="twentyone__table_a" :href="makeFileUrl(doc.status)">
                  <img src="/img/43.png" alt="" class="twentyone__img" />{{
                    doc.projectfile_name
                  }}</a
                >
              </td>
            </tr>
          </table>
        </div>

        <div class="twentyone__nomination">
          <div class="twentyone__item-mob">№</div>
          <div class="twentyone__item-mob2">Наименование ИТС НДТ</div>
        </div>
      </div>
      <div class="twentyone__items-mob">
        <div
          class="twentyone__item-mobile"
          v-for="(doc, index) in docs"
          :key="doc.project_id"
        >
          <div class="twentyone__number">
            <div class="twentyone__span">{{ index + 1 }}</div>
          </div>
          <div class="twentyone__block-wrapp">
            <div class="twentyone__description">
              <h3 class="twentyone__h3">{{ doc.project_name }}</h3>
            </div>
            <div class="twentyone__years">
              <div class="twentyone__year">
                <p class="twentyone__p">Статус</p>
                <p class="twentyone__p">{{ doc.pgsflag_name }}</p>
              </div>
              <div class="twentyone__year">
                <p class="twentyone__p">Начало разработки</p>
                <p class="twentyone__p">{{ formatDate(doc.project_dateb) }}</p>
              </div>
              <div class="twentyone__year">
                <p class="twentyone__p">Окончательная редакция</p>
                <p class="twentyone__p">{{ formatDate(doc.project_datee) }}</p>
              </div>
            </div>
            <div class="twentyone__documents">
              <span>Сопроводительные документы:</span>
              <div class="twentyone__docs">
                <img src="/img/43.png" alt="" />
                <span>{{ doc.projectfile_name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import MvsMenu from "./MvsMenu.vue";
import getMvsDocumentsData from "@/composables/mvs/mvsdocuments";
import { onMounted } from "vue";
import BackImagesComp from '../BackImagesComp.vue';

export default {
  name: "MvsDocuments",
  components: {
    MvsMenu,
    BackImagesComp,
  },
  setup() {
    const { docs, fetchMvsDocuments, formatDate, makeFileUrl } =
      getMvsDocumentsData();
    onMounted(() => {
      fetchMvsDocuments();
    });
    return {
      docs,
      fetchMvsDocuments,
      formatDate,
      makeFileUrl,
    };
  },
};
</script>

<style scoped>

.twentyone__table_a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
}
.twentyone__nomination,
.twentyone__items-mob {
  display: none;
}

.twentyone__wrapper {
  width: 95%;
  margin: 7px auto;
  height: auto;
  max-height: 5000px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px 15px;
  margin-bottom: 200px;
  background: white;
}

table {
  width: 100%;
  font-size: calc(100vw / 125);
}

tr {
  display: inline-block;
  width: 100%;
  margin: 5px auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  padding: 0.8vw 0;
}

td {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16%;
  height: 45px;
}

th {
  display: inline-block;
}

.tr-head {
  box-shadow: none;
  padding: 1.5vw 0;
}

.th-one {
  width: calc(3px + 35 * (100vw / 1280));
  height: 30px;
  color: #848891;
}

.th-two {
  width: 31%;
  height: 30px;
  text-align: start;
}

.th-all {
  display: inline-block;
  height: 30px;
  width: 16%;
  color: #848891;
}

.td-one {
  width: calc(3px + 35 * (100vw / 1280));
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.td-two {
  width: 31%;
  display: inline-flex;
  align-items: center;
  text-align: start;
}

.twentyone__documents {
  display: none;
}

.twentyone__img {
  width: calc(100vw / 77);
  padding: 3px;
}

.twentyone__flex-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.twentyone__form {
  display: flex;
  align-items: center;
}

.twentyone__button-search {
  background: none;
  border: none;
  width: 30px;
}

.twentyone__button-img {
  background: none;
  border: none;
  width: 1vw;
}

.twentyone__input {
  border: none;
  background: none;
  max-width: 500px;
  width: calc(3px + 250 * (100vw / 1280));
  margin-left: 1vh;
  font-size: 1vw;
}

.twentyone__filter {
  display: flex;
  font-size: calc(100vw / 105);
}

.twentyone__filter-text {
  color: #848891;
}

.twentyone__filter-item {
  display: flex;
  align-items: center;
}

.twentyone__table-wrapp {
  color: #525252;
}

.twentyone__filter-img {
  width: 1vw;
}
@media (max-width: 480px) {
  .twentyone__input {
    width: calc(3px + 520 * (100vw / 1280));
    font-size: 3vw;
    margin-left: auto;
  }

  .twentyone__wrapper {
    margin-bottom: 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    width: 90%;
    padding: 5px 15px 0;
  }

  .twentyone__nomination,
  .twentyone__items-mob {
    display: block;
  }

  .twentyone__button-search {
    width: 6vw;
    padding: 0;
  }

  .twentyone__filter-img {
    width: 4vw;
  }

  .twentyone__button-img {
    width: 4vw;
  }

  .twentyone__table-wrapp {
    display: none;
  }

  .twentyone__nomination {
    display: flex;
    justify-content: space-between;
    padding: 10px 7px;
    width: 230px;
  }

  .twentyone__filter-text {
    font-size: 3vw;
    color: #a6a4a4;
  }

  .twentyone__item-one {
    font-size: 3vw;
    font-weight: 600;
  }

  .twentyeight__items {
    width: 70%;
  }

  .twentyone__items-mob {
    margin-bottom: 100px;
  }

  .twentyone__item-mob2 {
    font-weight: 600;
    font-size: 14px;
  }

  .twentyone__item-mobile {
    width: 90%;
    max-width: 1720px;
    margin: 7px auto;
    height: auto;
    max-height: 5000px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    padding: 20px 15px;
    display: flex;
    background: white;
  }

  .twentyone__number {
    width: 55px;
  }

  .twentyone__span {
    font-size: 15px;
    padding-left: 7px;
  }

  .twentyone__block-wrapp {
    width: 280px;
  }

  .twentyone__h3 {
    margin: 5px 0;
    font-size: 3vw;
  }

  .twentyone__p {
    margin: 0;
    text-align: center;
  }

  .twentyone__p:last-child {
    margin: 5px 0;
  }

  .twentyone__years {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    font-size: 11px;
  }

  .twentyone__year {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 75px;
    color: #525252;
    font-weight: 600;
  }

  .twentyone__download {
    height: 17px;
  }

  .twentyone__documents {
    display: block;
    font-size: 13px;
    line-height: 22px;
  }

  .twentyone__docs {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
  }

  .twentyone__docs img {
    width: 4vw;
  }
}
</style>
import { createRouter, createWebHistory } from "vue-router"

import TK113About from "../components/tk113/TK113About.vue"
import TK113Meetings from "../components/tk113/TK113Meetings.vue"
import TK113Documents from "../components/tk113/TK113Documents.vue"
import TK113Standarts from "../components/tk113/TK113Standarts.vue"
import Discussion from "../components/discussion/Discussion.vue"
import DiscussionMessages from "../components/discussion/DiscussionMessages.vue"
import Trg from "../components/trg/Trg.vue"
import TrgMessages from "../components/trg/TrgMessages.vue"
import DocumentsTypes from "../components/documents/DocumentsTypes.vue"
import Documents from "../components/documents/Documents.vue"
import Itc from "../components/its/Itc.vue"
import ItcOrders from "../components/its/ItcOrders.vue"
import Home from "../components/Home.vue"
import Contacts from "../components/contacts/Contacts.vue"
// import ItcDevelopment from "../components/its/ItcDevelopment.vue"
import ItcDevelopment2 from "../components/its/ItcDevelopment2.vue"
import MvsDocuments from "../components/mvs/MvsDocuments.vue"
import MvsMeetings from "../components/mvs/MvsMeetings.vue"
import TrgMeetings from "../components/trg/TrgMeetings.vue"
import AboutRegulation from "../components/about/AboutRegulation.vue"
import AboutStructure from "../components/about/AboutStructure.vue"
import NotFound from "../components/NotFound.vue"
import AllNews from "../components/news/AllNews.vue"
import OneNews from "../components/news/OneNews.vue"
import { environment } from "@/composables/realhost.js"
import PublicationArticles from "../components/publications/PublicationsArticles.vue"
import PublicationCollections from "../components/publications/PublicationCollections.vue"
import Sak from "../components/sak/Sak.vue"
import ItsMenu from "../components/its-development/ItsMenu.vue"
import TrgFormation from "../components/its-development/TrgFormation.vue"
import TrgCompositionApproval from "../components/its-development/TrgCompositionApproval.vue"
import KickOffMeeting from "../components/its-development/KickOffMeeting.vue"
import UnifiedЕemplateDevelopment from "../components/its-development/UnifiedЕemplateDevelopment.vue"
import DataCollect from "../components/its-development/DataCollect.vue"
import ProjectDevelopment from "../components/its-development/ProjectDevelopment.vue"
import ReviewOfDraftGuide from "../components/its-development/ReviewOfDraftGuide.vue"
import ProjectFinalization from "../components/its-development/ProjectFinalization.vue"
import VotingBeforeDiscussion from "../components/its-development/VotingBeforeDiscussion.vue"
import PublicDiscussion from "../components/its-development/PublicDiscussion.vue"
import ExpertiseTK113 from "../components/its-development/ExpertiseTK113.vue"
import ProjectFinalizationTwo from "../components/its-development/ProjectFinalizationTwo.vue"
import FinalMeeting from "../components/its-development/FinalMeeting.vue"
import FinalVote from "../components/its-development/FinalVote.vue"
import RemoveDisagreements from "../components/its-development/RemoveDisagreements.vue"
import EditorialEditing from "../components/its-development/EditorialEditing.vue"
import PreparingForApproval from "../components/its-development/PreparingForApproval.vue"
import Statement from "../components/its-development/Statement.vue"
import DocumentsPlacements from "../components/its-development/DocumentsPlacements.vue"
import General from "../components/its-development/Genaral.vue"
import MainDocuments from "../components/its-development/MainDocuments.vue"
import ItcActualisation2023 from "../components/its/ItcActualisation2023.vue"
import ItcActualisation2023async from "../components/its/ItcActualisation2023async.vue"
import DiscussionUsersTest from "../components/discussion/DiscussionUsersTest.vue"

const routes = [
	{
		path: "/tk113/about",
		name: "TK113About",
		component: TK113About,
		meta: {
			title: "ТК113",
			breadcrumb: "ТК113 Описание",
		},
	},
	// test
	{
		path: "/discussion/qwertyytrewq",
		name: "DiscussionUsersTest",
		component: DiscussionUsersTest,
		meta: {
			title: "DiscussUsers",
			breadcrumb: "",
		},
	},
	// test
	{
		path: "/tk113/meetings",
		name: "TK113Meetings",
		component: TK113Meetings,
		meta: { title: "ТК113 Заседания", breadcrumb: "ТК113 Заседания" },
	},
	{
		path: "/tk113/documents",
		name: "TK113Documents",
		component: TK113Documents,
		meta: { title: "ТК113 Документы", breadcrumb: "ТК113 Документы" },
	},
	{
		path: "/tk113/standarts",
		name: "TK113Standarts",
		component: TK113Standarts,
		meta: { title: "ТК113 Стандарты", breadcrumb: "ТК113 Стандарты" },
	},
	{
		path: "/discussion/:year/:id",
		name: "DiscussionMessages",
		component: DiscussionMessages,
		meta: {
			title: "Публичные обсуждения",
			breadcrumb: {
				label: "тип документов",
			},
		},
	},
	{
		path: "/discussion/:year",
		name: "Discussion",
		component: Discussion,
		meta: {
			title: "Публичные обсуждения",
			breadcrumb(route) {
				return `${route.params.year} год`
			},
		},
	},
	{
		path: "/trg/:year/:id/info",
		name: "TrgMessages",
		component: TrgMessages,
		meta: {
			title: "Технические рабочие группы",
			breadcrumb: {
				label: "информация",
			},
		},
	},
	{
		path: "/trg/:year/:id/meetings",
		name: "TrgMeetings",
		component: TrgMeetings,
		meta: {
			title: "Технические рабочие группы - заседания",
			breadcrumb: {
				label: "заседания",
			},
		},
	},
	{
		path: "/trg/:year",
		name: "Trg",
		component: Trg,
		meta: {
			title: "Технические рабочие группы",
			breadcrumb(route) {
				return `${route.params.year} год`
			},
		},
	},
	{
		path: "/documents",
		name: "DocumentsTypes",
		component: DocumentsTypes,
		meta: { title: "Документы", breadcrumb: "Документы" },
	},
	{
		path: "/documents/:id",
		name: "Documents",
		component: Documents,
		meta: {
			title: "Документы",
			breadcrumb: {
				label: "тип документов",
			},
		},
	},
	{
		path: "/itc",
		name: "Itc",
		component: Itc,
		meta: {
			title: "Информационно-технические справочники",
			breadcrumb: "Информационно-технические справочники",
		},
	},
	{
		path: "/itc-orders",
		name: "ItcOrders",
		component: ItcOrders,
		meta: {
			title: "Приказы по технологическим показателям НДТ",
			breadcrumb: "Приказы по технологическим показателям НДТ",
		},
	},
	{
		path: "/contacts",
		name: "Contacts",
		component: Contacts,
		meta: { title: "Контакты", breadcrumb: "Контакты" },
	},
	{
		path: "/itc-actualisation-2023",
		name: "ItcActualisation2023",
		component: ItcActualisation2023,
		meta: { title: "Актуализация ИТС НДТ 2023", breadcrumb: "Актуализация ИТС НДТ 2023" },
	},
	{
		path: "/itc-actualisation-2023-async",
		name: "ItcActualisation2023async",
		component: ItcActualisation2023async,
		meta: { title: "Актуализация ИТС НДТ 2023", breadcrumb: "Актуализация ИТС НДТ 2023" },
	},
	// {
	// 	path: "/itc-development",
	// 	name: "ItcDevelopment",
	// 	component: ItcDevelopment,
	// 	meta: {
	// 		title: "Информационно-технические справочники",
	// 		breadcrumb: "разработка информационно-технических справочников",
	// 	},
	// },
	{
		path: "/itc-development2",
		name: "ItcDevelopment2",
		component: ItcDevelopment2,
		meta: {
			title: "Информационно-технические справочники",
			breadcrumb: "разработка информационно-технических справочников",
		},
	},
	{
		path: "/itc-development",
		name: "ItsMenu",
		component: ItsMenu,
		meta: {
			title: "Информационно-технические справочники",
			breadcrumb: "Разработка информационно-технических справочников",
		},
		children: [
			{
				path: 'trg-formation',
				component: TrgFormation,
				meta: {
					title: "Формирование ТРГ",
					breadcrumb: "Формирование ТРГ",
					scroll: 'no'
				},
			},
			{
				path: 'trg-composition-approval',
				component: TrgCompositionApproval,
				meta: {
					title: "Утверждение состава ТРГ",
					breadcrumb: "Утверждение состава ТРГ",
					scroll: 'no'
				},
			},
			{
				path: 'kick-off-meeting',
				component: KickOffMeeting,
				meta: {
					title: "Установочное заседание",
					breadcrumb: "Установочное заседание",
					scroll: 'no'
				},
			},
			{
				path: 'development-unified-template',
				component: UnifiedЕemplateDevelopment,
				meta: {
					title: "Разработка унифицированного отраслевого шаблона анкеты",
					breadcrumb: "Разработка унифицированного отраслевого шаблона анкеты",
					scroll: 'no'
				},
			},
			{
				path: 'data-collect',
				component: DataCollect,
				meta: {
					title: "Сбор данных",
					breadcrumb: "Сбор данных",
					scroll: 'no'
				},
			},
			{
				path: 'its-project-development',
				component: ProjectDevelopment,
				meta: {
					title: "Разработка проекта ИТС НДТ",
					breadcrumb: "Разработка проекта ИТС НДТ",
					scroll: 'no'
				},
			},
			{
				path: 'review-of-draft-guide',
				component: ReviewOfDraftGuide,
				meta: {
					title: "Рассмотрение проекта справочника",
					breadcrumb: "Рассмотрение проекта справочника",
					scroll: 'no'
				},
			},
			{
				path: 'project-finalization',
				component: ProjectFinalization,
				meta: {
					title: "Доработка проекта",
					breadcrumb: "Доработка проекта",
					scroll: 'no'
				},
			},
			{
				path: 'voting-before-discussion',
				component: VotingBeforeDiscussion,
				meta: {
					title: "Голосование перед публичным обсуждением проекта справочника",
					breadcrumb: "Голосование перед публичным обсуждением проекта справочника",
					scroll: 'no'
				},
			},
			{
				path: 'public-discussion',
				component: PublicDiscussion,
				meta: {
					title: "Публичное обсуждение",
					breadcrumb: "Публичное обсуждение",
					scroll: 'no'
				},
			},
			{
				path: 'expertise-tk113',
				component: ExpertiseTK113,
				meta: {
					title: "Экспертиза в ТК 113",
					breadcrumb: "Экспертиза в ТК 113",
					scroll: 'no'
				},
			},
			{
				path: 'project-finalization-two',
				component: ProjectFinalizationTwo,
				meta: {
					title: "Доработка проектра ИТС НДТ",
					breadcrumb: "Доработка проектра ИТС НДТ",
					scroll: 'no'
				},
			},
			{
				path: 'final-meeting',
				component: FinalMeeting,
				meta: {
					title: "Итоговое заседание",
					breadcrumb: "Итоговое заседание",
					scroll: 'no'
				},
			},
			{
				path: 'final-vote',
				component: FinalVote,
				meta: {
					title: "Итоговое голосование",
					breadcrumb: "Итоговое голосование",
					scroll: 'no'
				},
			},
			{
				path: 'removal-of-disagreements',
				component: RemoveDisagreements,
				meta: {
					title: "Снятие разногласий",
					breadcrumb: "Снятие разногласий",
					scroll: 'no'
				},
			},
			{
				path: 'editorial-editing',
				component: EditorialEditing,
				meta: {
					title: "Издательское редактирование",
					breadcrumb: "Издательское редактирование",
					scroll: 'no'
				},
			},
			{
				path: 'preparing-for-approval',
				component: PreparingForApproval,
				meta: {
					title: "Подготовка к утверждению",
					breadcrumb: "Подготовка к утверждению",
					scroll: 'no'
				},
			},
			{
				path: 'statement',
				component: Statement,
				meta: {
					title: "Утверждение",
					breadcrumb: "Утверждение",
					scroll: 'no'
				},
			},
			{
				path: 'documents-placements',
				component: DocumentsPlacements,
				meta: {
					title: "Размещение документов",
					breadcrumb: "Размещение документов",
					scroll: 'no'
				},
			},
			{
				path: 'general',
				component: General,
				meta: {
					title: "Общее",
					breadcrumb: "Общее",
					scroll: 'no'
				},
			},
			{
				path: 'main-documents',
				component: MainDocuments,
				meta: {
					title: "Основные документы",
					breadcrumb: "Основные документы",
					scroll: 'no'
				},
			},
		]
	},
	{
		path: "/mvs/documents",
		name: "MvsDocuments",
		component: MvsDocuments,
		meta: { title: "МВС Документы", breadcrumb: "МВС Документы" },
	},
	{
		path: "/mvs/meetings",
		name: "MvsMeetings",
		component: MvsMeetings,
		meta: { title: "МВС Заседания", breadcrumb: "МВС Заседания" },
	},
	{
		path: "/publications/articles",
		name: "PublicationArticles",
		component: PublicationArticles,
		meta: {
			title: "Публикации - статьи",
			breadcrumb: "Публикации - статьи",
		},
	},
	{
		path: "/publications/collections",
		name: "PublicationCollections",
		component: PublicationCollections,
		meta: {
			title: "Публикации - сборники",
			breadcrumb: "Публикации - сборники",
		},
	},
	{
		path: "/about/regulation",
		name: "AboutRegulation",
		component: AboutRegulation,
		meta: {
			title: "О нас - государственное регулирование",
			breadcrumb: "О нас - государственное регулирование",
		},
	},
	{
		path: "/about/structure",
		name: "AboutStructure",
		component: AboutStructure,
		meta: {
			title: "О нас - организационная структура",
			breadcrumb: "О нас - организационная структура",
		},
	},
	{
		path: "/news",
		name: "AllNews",
		component: AllNews,
		meta: { title: "Новости", breadcrumb: "Новости", modalShow: false },
		children: [
			{
				// UserProfile will be rendered inside User's <router-view>
				// when /user/:id/profile is matched
				path: ":id",
				component: OneNews,
				meta: { title: "Новости", modalShow: true },
			},
		],
	},
	{
		path: "/sak",
		name: "Sak",
		component: Sak,
		meta: {
			title: "Системы автоматического контроля",
			breadcrumb: "Системы автоматического контроля",
		},
	},
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: { title: "БЮРО НДТ", breadcrumb: "Главная" },
	},
	{ 
		path: "/:pathMatch(.*)*",
		component: NotFound,
		meta: {
			title: "404",
			breadcrumb: {
				label: "",
			},
		},
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

router.afterEach((to) => {
	if(to.meta.scroll != 'no'){
		document.querySelector("header").scrollIntoView()
	}
  })

router.beforeEach(async (to, from, next) => {
	// beforeEach - это функция перехвата маршрутизатора, выполняемая перед входом в маршрут
	// Маршрутизация изменяется для изменения заголовка страницы
	if (to.meta.title) {
		// Определяем, есть ли заголовок
		document.title = to.meta.title
	}
	if (to.meta.breadcrumb.label && to.params.id) {
		let table, field, idName, dopInfo = ""
		if (to.name == "Documents") {
			table = "ipsuser_etkdocstype"
			field = "etkdocstype_name"
			idName = "etkdocstype_id"
		}
		if (to.name == "DiscussionMessages") {
			table = "ipsuser_publicdiscussiondirectories"
			field = "publicdiscussiondirectories_name"
			idName = "publicdiscussiondirectories_id"
		}
		if (to.name == "TrgMessages" || to.name == "TrgMeetings") {
			table = "ipsuser_etkstructure"
			field = "etkstructure_num"
			idName = "etkstructure_id"
			if(to.name == "TrgMessages"){
				dopInfo = " - информация"
			} else {
				dopInfo = " - заседания"
			}
		}
		await fetch(
			environment.realHost +
				"short/" +
				to.params.id +
				"/" +
				table +
				"/" +
				field +
				"/" +
				idName
		)
			.then((response) => response.json())
			.then((data) => {
				to.meta.breadcrumb.label = data + dopInfo
			})
	}
	// document.querySelector(".main").scrollIntoView({block: "center", behavior: "smooth"})
	next() // Выполнение входит в маршрут, если вы не напишите, оно не войдет на целевую страницу
	
})

export default router

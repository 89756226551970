<template>
  <main class="main">
    <back-images-comp />
    <TK113Menu />
    <section v-if="documents.length == 0" class="twentyfive no_info">
      <loader object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793"
        opacity="80" name="circular"></loader>
    </section>

    <section v-else class="twentyfive">
      <div class="twentyfive__wrapper">
        <div class="twentyfive__flex-block">
          <div class="twentyfive__form-block">
            <form action="" class="twentyfive__form">
              <button class="twentyfive__button-search">
                <img src="/img/44.png" alt="" class="twentyfive__button-img" />
              </button>
              <input type="text" class="twentyfive__input" placeholder="Поиск" />
            </form>
          </div>
          <div class="twentyfive__filter">
            <p class="twentyfive__filter-text">Показывать:</p>
            <div class="twentyfive__filter-item">
              <p class="twentyfive__item-one">Все</p>
              <img src="/img/46.png" alt="" class="twentyfive__filter-img" />
            </div>
          </div>
        </div>
        <div class="twentyfive__table-wrapp">
          <table>
            <tr class="tr-head">
              <th class="th-one">№</th>
              <th class="th-two">Наименование ИТС НДТ</th>
              <th class="th-all">Статус</th>
              <th class="th-all">Начало разработки</th>
              <th class="th-all">Окончательная редакция</th>
            </tr>

            <tr v-for="(doc, index) in documents" :key="doc.project_id">
              <th class="td-one">{{ index + 1 }}</th>
              <th class="td-two">{{ doc.project_name }}</th>
              <td>{{ doc.status }}</td>
              <td>{{ doc.project_dateb }}</td>
              <td>{{ doc.project_datee }}</td>
            </tr>
          </table>
        </div>

        <div class="twentyfive__nomination">
          <div class="twentyfive__item-mob">№</div>
          <div class="twentyfive__item-mob2">Наименование ИТС НДТ</div>
        </div>
      </div>
      <div class="twentyfive__items-mob">
        <div class="twentyfive__item-mobile" v-for="(doc, index) in documents" :key="doc.project_id">
          <div class="twentyfive__number">
            <div class="twentyfive__span">{{ index + 1 }}</div>
          </div>
          <div class="twentyfive__block-wrapp">
            <div class="twentyfive__description">
              <h3 class="twentyfive__h3">{{ doc.project_name }}</h3>
            </div>
            <div class="twentyfive__years">
              <div class="twentyfive__year">
                <p class="twentyfive__p">Статус</p>
                <p class="twentyfive__download">{{ doc.status }}</p>
              </div>
              <div class="twentyfive__year">
                <p class="twentyfive__p">Начало разработки</p>
                <p class="twentyfive__download">{{ doc.project_dateb }}</p>
              </div>
              <div class="twentyfive__year">
                <p class="twentyfive__p">Окончательная редакция</p>
                <p class="twentyfive__download">{{ doc.project_datee }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import TK113Menu from "./TK113Menu.vue";
import BackImagesComp from '../BackImagesComp.vue'
import useDocuments from "@/composables/tk113/documents";
import { onMounted } from "vue";
export default {
  name: "TK113Documents",
  components: {
    TK113Menu,
    BackImagesComp
  },
  setup() {
    const { documents, fetchDocuments } = useDocuments();
    onMounted(() => fetchDocuments());
    return { documents };
  },
};
</script>

<style scoped>
/* my */
.twentyfive.no_info {
  height: 600px;
}

/* end my */
:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}




.twentyfour {
  padding: 0 50px;
}

.twentyfour__wrapper {
  height: auto;
  margin: 3vh;
}

.twentyfour__title-main {
  color: #0073af;
  font-size: calc(100vw / 35);
}

.twentyfour__items {
  display: flex;
  justify-content: space-between;
  width: 85%;
}

.twentyfour__item {
  color: #a6a4a4;
  border-left: 1px solid #a6a4a4;
  padding: 2vh 3vh;
  font-size: calc(100vw / 70);
}

.twentyfour__item:hover {
  color: black;
  border-left: 1px solid black;
  cursor: pointer;
}

/*---------------------------twentyfive---------------------------------*/

.twentyfive__nomination,
.twentyfive__items-mob {
  display: none;
}

.twentyfive {
  padding: 0 50px;
}

.twentyfive__wrapper {
  margin: 3vh;
  height: auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px 15px;
  margin-bottom: 200px;
  background: white;
}

table {
  width: 100%;
  font-size: calc(100vw / 100);
}

tr {
  display: inline-block;
  width: 100%;
  margin: 5px auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

tr:not(:first-child) {
  padding: 0.7vw 0;
}

td {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 45px;
}

th {
  display: inline-block;
  height: 60px;
}

.tr-head {
  height: 30px;
  box-shadow: none;
  padding: 1.5vw 0;
}

.th-one {
  width: calc(3px + 35 * (100vw / 1280));
  height: 30px;
  color: #848891;
}

.th-two {
  width: 35%;
  height: 30px;
  text-align: start;
}

.th-all {
  display: inline-block;
  height: 30px;
  width: 20%;
  color: #848891;
}

.td-one {
  width: calc(3px + 35 * (100vw / 1280));
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.td-two {
  width: 35%;
  display: inline-flex;
  align-items: center;
  text-align: start;
  height: auto;
}

.twentyfive__flex-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.twentyfive__form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.twentyfive__button-search {
  background: none;
  border: none;
  width: 30px;
}

.twentyfive__button-img {
  background: none;
  border: none;
  width: 1vw;
}

.twentyfive__input {
  border: none;
  background: none;
  width: calc(3px + 250 * (100vw / 1280));
  margin-left: 1vh;
  font-size: 1vw;
}

.twentyfive__filter {
  display: flex;
  font-size: calc(100vw / 105);
}

.twentyfive__filter-text {
  color: #848891;
}

.twentyfive__filter-item {
  display: flex;
  align-items: center;
}

.twentyfive__table-wrapp {
  color: #525252;
}

.twentyfive__filter-img {
  width: 1vw;
}

.twentyfive__img {
  width: calc(100vw / 125);
}

/*----------------------------MOBILE---------------------------------*/

@media (max-width: 480px) {

  .twentyfour {
    padding: 0 3vw;
  }

  .twentyfour__wrapper {
    margin: 3vh;
  }

  .twentyfour__title-main {
    font-size: 29px;
    max-width: 300px;
  }

  .twentyfour__items {
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 95%;
  }

  .twentyfour__item {
    padding: 5px 15px;
    margin: 9px 0;
    font-size: 14px;
    max-width: 171px;
    width: 50%;
  }

  /*--------------------------twentyfive-----------------------------------*/

  .twentyfive {
    padding: 0 6vw;
  }

  .twentyfive__button-img {
    width: 4vw;
  }

  .twentyfive__input {
    width: calc(3px + 520 * (100vw / 1280));
    font-size: 3vw;
    margin-left: auto;
  }

  .twentyfive__filter-img {
    width: 4vw;
  }

  .twentyfive__button-search {
    width: 6vw;
    padding: 0;
  }

  .twentyfive__wrapper {
    margin-bottom: 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    margin: auto;
    padding: 5px 10px 0;
  }

  .twentyfive__nomination,
  .twentyfive__items-mob {
    display: block;
  }

  .twentyfive__table-wrapp {
    display: none;
  }

  .twentyfive__nomination {
    display: flex;
    justify-content: space-between;
    padding: 10px 7px;
    width: 237px;
    color: #525252;
  }

  .twentyfive__filter-text {
    font-size: 3vw;
    color: #a6a4a4;
  }

  .twentyfive__item-one {
    font-size: 3vw;
    font-weight: 600;
  }

  .twentyfive__items-mob {
    margin-bottom: 100px;
  }

  .twentyfive__item-mob2 {
    font-weight: 600;
    font-size: 14px;
  }

  .twentyfive__item-mobile {
    max-width: 1720px;
    margin: 7px auto;
    height: auto;
    max-height: 5000px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    padding: 20px 15px;
    display: flex;
    background: white;
  }

  .twentyfive__number {
    width: 55px;
  }

  .twentyfive__span {
    font-size: 15px;
    padding-left: 7px;
  }

  .twentyfive__block-wrapp {
    width: 295px;
  }

  .twentyfive__description {
    width: auto;
  }

  .twentyfive__h3 {
    margin: 5px 0;
    font-size: 3vw;
    color: #525252;
  }

  .twentyfive__p {
    margin: 0;
    text-align: center;
  }

  .twentyfive__p:last-child {
    margin: 5px 0;
  }

  .twentyfive__years {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    height: 65px;
    width: 90%;
  }

  .twentyfive__year {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
    width: 30%;
    color: #525252;
    font-weight: 600;
  }

  .twentyfive__year:nth-child(1),
  .twentyfive__year:nth-child(2) {
    width: 25%;
  }

  .twentyfive__download {
    height: 17px;
    margin: 0;
  }
}
</style>
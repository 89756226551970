import { ref } from "vue"
import { environment } from "@/composables/realhost.js"

export default function useDocumentsTypes() {
	const docTypesData = ref([])
	const loader = ref(true)

	function fetchDocTypes() {
		// let year = (new Date()).getFullYear()
		// fetch("http://slimtest/documents")
		fetch(environment.realHost + "documents")
			.then((response) => response.json())
			.then((data) => {
				docTypesData.value = data
				loader.value = false
			})
	}

	// function openBlock(event) {
	// 	// if((!event.target.classList.contains("thirteen__hover") && !event.target.classList.contains("thirteen__hover-flex"))
	// 	//  || event.target.classList.contains("thirteen__item-title")){
	// 	//     document.querySelectorAll(".thirteen__hover").forEach(el => {
	// 	//         el.classList.remove("active")
	// 	//     })
	// 	//     event.target.querySelector(".thirteen__hover").classList.add("active")
	// 	// }
	// 	document.querySelectorAll(".eleven__hover").forEach((el) => {
	// 		el.classList.remove("active")
	// 	})
	// 	event.target.querySelector(".eleven__hover").classList.add("active")
	// }

	return { fetchDocTypes, docTypesData, loader }
}

export const environment = {
	// realHost: 'http://localhost/api/',

	// локалка
	// realHost: "http://slimtest/",
	// urlMakerHost: "http://localhost/",

	// тестовый сервер
	// realHost: 'http://192.168.9.52/api/',
	// urlMakerHost: "http://192.168.9.52/",


	// Прод сервер
	realHost: 'https://burondt.ru/api/',
	urlMakerHost: "https://burondt.ru/",
}
